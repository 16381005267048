import { User } from 'types/user.type';

export interface UserData {
  data: User;
  error?: string;
  firebaseToken: string;
  gotCredit?: boolean;
  success?: boolean;
  token: string;
}

export interface LogoutResponse {
  data: { success?: boolean };
  error?: string;
  success?: boolean;
}

export const GET_USER = 'GET_USER';

import { useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { RequiredImage } from 'layout/image-container/image.utils';
import { ProgressiveImageProps } from './progressive-image.props';
import { ProgressiveImagePresets } from './progressive-image.presets';
import { StyledImage, StyledImageContainer } from './progressive-image.styles';

export const ProgressiveImage = (props: ProgressiveImageProps) => {
  const {
    alt,
    height,
    imageData,
    immediateAvailable,
    objectFit,
    objectPosition,
    visible,
    width,
  } = { ...ProgressiveImagePresets, ...props };
  const [innerImage, setInnerImage] = useState<RequiredImage>(null);
  const [loaded, setLoaded] = useState(false);

  const spring = useSpring({
    config: {
      duration: immediateAvailable ? 0 : 300,
    },
    opacity: loaded || immediateAvailable ? 1 : 0,
  });

  useEffect(() => {
    if (imageData) {
      setInnerImage(imageData);
    }
  }, [imageData]);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  if (!imageData) {
    return null;
  }

  return (
    <StyledImageContainer>
      {(visible || immediateAvailable) && (
        <animated.picture style={spring} onLoad={onLoad}>
          {innerImage?.multiple ? (
            <>
              <source
                media="(max-width: 640px)"
                srcSet={innerImage?.smSrcWebP}
                type="image/webp"
              />
              <source media="(max-width: 640px)" srcSet={innerImage?.smSrc} />

              <source
                media="(min-width: 641px) and (max-width: 1024px)"
                srcSet={innerImage?.mdSrcWebP}
                type="image/webp"
              />
              <source
                media="(min-width: 641px) and (max-width: 1024px)"
                srcSet={innerImage?.mdSrc}
              />

              <source
                media="(min-width: 1025px)"
                srcSet={innerImage?.lgSrcWebP}
                type="image/webp"
              />
              <source media="(min-width: 1025px)" srcSet={innerImage?.lgSrc} />
            </>
          ) : (
            <>
              <source srcSet={innerImage?.srcWebP} type="image/webp" />
              <source srcSet={innerImage?.src} />
            </>
          )}

          <StyledImage
            alt={alt}
            draggable="false"
            fit={objectFit}
            innerheight={height}
            innerwidth={width}
            position={objectPosition}
          />
        </animated.picture>
      )}
    </StyledImageContainer>
  );
};

import {
  StyledCloseButtonContainer,
  StyledDialog,
  StyledDialogBackdrop,
  StyledDialogContainer,
} from 'core/dialog/dialog.styles';
import { RoundButton } from 'core/round-button';
import { useSpring } from 'react-spring';
import { useNotification } from 'context/notification.context';
import { Icon } from 'core/icon/icon';
import { DialogPresets } from './dialog.presets';
import { DialogProps } from './dialog.props';

export const Dialog = (props: DialogProps) => {
  const {
    children,
    clickOutsideToClose,
    closable,
    dialogAnimatedProps,
    height,
    level,
    onClose,
    onCloseCallback,
    variant,
    width,
  } = { ...DialogPresets, ...props };

  const { visible: notificationVisible } = useNotification();

  const { opacity, top } = useSpring({
    config: { duration: 300 },
    from: {
      opacity: 0,
      top: 0,
    },
    opacity: 0.8,
    top: notificationVisible ? 40 : 0,
  });

  const closeDialog = () => {
    onClose();

    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  const onBackdropClose = (): void => {
    if (clickOutsideToClose) {
      closeDialog();
    }
  };

  return (
    <>
      <StyledDialogContainer level={level} variant={variant} width={width}>
        {closable && (
          <StyledCloseButtonContainer style={{ opacity }} variant={variant}>
            <RoundButton
              color="translucentBlack"
              icon={<Icon color="white" name="close" size="1rem" />}
              opacity={0.8}
              onClick={closeDialog}
            />
          </StyledCloseButtonContainer>
        )}
        <StyledDialog
          height={height}
          style={dialogAnimatedProps}
          variant={variant}
        >
          {children}
        </StyledDialog>
      </StyledDialogContainer>
      <StyledDialogBackdrop
        level={level}
        style={{ top }}
        onClick={onBackdropClose}
      />
    </>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgLogo = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 125 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path d="M0 .517h15.149v18.599H0z" id="logo_svg__a" />
        <path d="M.072.643H12.12v13.524H.072z" id="logo_svg__c" />
        <path d="M.211.643H12.26v13.524H.211z" id="logo_svg__e" />
        <path d="M.127.668h12.775v18.273H.127z" id="logo_svg__g" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .316)">
          <mask fill="#fff" id="logo_svg__b">
            <use xlinkHref="#logo_svg__a" />
          </mask>
          <path
            d="M11.05 6.517c-.076-.625-.3-3.175-2.926-3.175-2.874 0-4.074 3-4.074 6.725 0 1.075 0 6.2 3.774 6.2 1.625 0 3.175-1.025 3.25-3.425h4.075c-.075 1.074-.175 2.75-1.625 4.25-1.6 1.65-3.625 2.024-5.275 2.024C2.024 19.116 0 14.517 0 10.042 0 4.317 3.2.517 8.274.517c3.95 0 6.725 2.4 6.725 6h-3.95z"
            fill="#FFF"
            mask="url(#logo_svg__b)"
          />
        </g>
        <path
          d="M16.69 9.483c0-.6-.1-2.425-.176-3.15h3.475l.075 2.425c.475-1.075 1.3-2.6 4.3-2.45v3.325c-3.75-.35-3.975 1.575-3.975 3.15v6.225h-3.7V9.483z"
          fill="#FFF"
        />
        <g transform="translate(25 5.316)">
          <mask fill="#fff" id="logo_svg__d">
            <use xlinkHref="#logo_svg__c" />
          </mask>
          <path
            d="M8.322 5.992c0-.575-.05-2.825-2.2-2.825-1.625 0-2.3 1.35-2.375 2.825h4.575zm-4.7 2.325c0 .825 0 3.375 2.575 3.375.925 0 1.85-.35 2.2-1.75h3.375c-.05.6-.175 1.675-1.2 2.75-.925.975-2.575 1.475-4.425 1.475-1.075 0-3.125-.2-4.45-1.575-1.15-1.2-1.625-2.9-1.625-4.925 0-2.05.5-4.5 2.625-5.975.975-.65 2.175-1.05 3.55-1.05 1.8 0 3.95.7 5.1 3.05.85 1.725.8 3.575.75 4.625H3.622z"
            fill="#FFF"
            mask="url(#logo_svg__d)"
          />
        </g>
        <path
          d="M41.822 15.108c0 .85.575 1.8 1.8 1.8.675 0 1.225-.325 1.575-.725.65-.8.7-1.775.7-3.125-2.375-.2-4.075.55-4.075 2.05zm4.55 3.9c-.125-.775-.125-1.35-.125-1.725-1.4 2.025-3.075 2.025-3.775 2.025-1.825 0-2.65-.525-3.15-1-.75-.75-1.1-1.775-1.1-2.775s.35-2.85 2.675-3.925c1.55-.7 3.775-.7 5-.7 0-.825-.025-1.225-.175-1.675-.325-.925-1.3-1.05-1.65-1.05-.525 0-1.075.225-1.4.7-.275.425-.275.825-.275 1.125h-3.625c.025-.675.1-2.025 1.425-2.975 1.15-.825 2.625-1.025 3.825-1.025 1.05 0 3.25.176 4.5 1.625.85 1.025.875 2.525.875 3.1l.025 5.075c0 1.075.075 2.15.3 3.2h-3.35zm13.035-.175c-1.2.325-2.274.4-2.875.4-3.724 0-3.724-2.35-3.724-3.4V8.908h-2.426v-2.45h2.426V3.983l3.674-1.35v3.825h3v2.45h-3v5.7c.026 1.15.026 1.875 1.675 1.875.55 0 .8-.025 1.25-.075v2.425z"
          fill="#FFF"
        />
        <g transform="translate(60 5.316)">
          <mask fill="#fff" id="logo_svg__f">
            <use xlinkHref="#logo_svg__e" />
          </mask>
          <path
            d="M8.461 5.992c0-.575-.05-2.825-2.2-2.825-1.625 0-2.3 1.35-2.375 2.825h4.575zm-4.7 2.325c0 .825 0 3.375 2.575 3.375.925 0 1.85-.35 2.2-1.75h3.376c-.05.6-.176 1.675-1.2 2.75-.925.975-2.575 1.475-4.425 1.475-1.075 0-3.125-.2-4.45-1.575-1.15-1.2-1.625-2.9-1.625-4.925 0-2.05.5-4.5 2.624-5.975.976-.65 2.175-1.05 3.55-1.05 1.8 0 3.95.7 5.1 3.05.85 1.725.8 3.575.75 4.625H3.761z"
            fill="#FFF"
            mask="url(#logo_svg__f)"
          />
        </g>
        <path
          d="M80.187.933h3.875v7.225h6.825V.933h3.924v18.075h-3.875v-7.925h-6.874v7.925h-3.875zM101.074 6.383v7.525c0 1.1 0 2.774 1.975 2.774 2.375 0 2.375-1.775 2.375-3.924V6.383h3.799v8.325c.05 2.724.076 3.05.076 3.375.024.35.05.574.1.924h-3.6l-.125-1.674c-.375.55-1.325 2-3.725 2-1.7 0-3.1-.776-3.8-1.7-.825-1.025-.825-2.776-.825-3.4v-7.85h3.75z"
          fill="#FFF"
        />
        <g transform="translate(111.667 .316)">
          <mask fill="#fff" id="logo_svg__h">
            <use xlinkHref="#logo_svg__g" />
          </mask>
          <path
            d="M6.402 8.367c-2.725 0-2.725 3.1-2.725 3.9 0 2.875 1.225 4.25 2.9 4.25 2.55 0 2.55-3.175 2.55-3.825 0-1-.05-4.325-2.725-4.325zM.127.667h3.7v6.925c1.25-1.65 2.925-1.7 3.575-1.7 3.075 0 5.5 2.425 5.5 6.675 0 4.725-2.75 6.374-5.275 6.374-.65 0-2.65-.049-3.8-2.024-.025.4-.05 1-.125 1.775H.127V.668z"
            fill="#FFF"
            mask="url(#logo_svg__h)"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgLogo;

import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledTileGrid = styled(Grid)<{
  $isActive: boolean;
}>`
  ${({ $isActive, theme }) => {
    return css`
      cursor: pointer;
      border: 1px solid
        ${$isActive ? theme.colors.typography.link : theme.colors.dividers.t1};
      border-radius: 6px;
    `;
  }}
`;

import styled, { css } from 'styled-components';
import { ButtonToggleProps } from 'core/button-toggle/button-toggle.props';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { media } from 'styles/media';

export const StyledToggleButton = styled.div<Partial<ButtonToggleProps>>`
  ${({ selected, selectedBackgroundColor, theme }) => css`
    cursor: pointer;
    background-color: ${selected
      ? getThemeColor(selectedBackgroundColor)
      : 'none'};
    padding: 0 2rem;
    height: 3.2rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${selected ? theme.shadow : 'unset'};

    ${media.lessThan('sm')`
      padding: 0 1.2rem;
    `}
  `}
`;

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgBox = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 4.155L7.5 7.45V16L0 12.694v-8.54zm16-.001v8.54L8.5 16V7.452L16 4.155zM3.85 1.83l7.195 3.398L8 6.567.542 3.288 3.85 1.83zM8 0l7.458 3.288-3.188 1.4L5.074 1.29 8 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgBox;

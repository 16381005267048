import { ButtonToggleGroupProps } from 'core/button-toggle-group/button-toggle-group.props';

export const ButtonToggleGroupPresets: ButtonToggleGroupProps = {
  itemsGap: '2rem',
  itemsWidth: {
    lg: '14rem',
    sm: 'unset',
  },
  selectedBackgroundColor: 'primary',
  selectedTextColor: 'white',
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgSortDown = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      aria-hidden="true"
      data-icon="sort-down"
      data-prefix="fad"
      height="1em"
      viewBox="0 0 320 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={color}>
        <path
          d="M279.07 224.05h-238c-21.4 0-32.1-25.9-17-41l119-119a23.9 23.9 0 0133.8-.1l.1.1 119.1 119c15.07 15.05 4.4 41-17 41z"
          opacity={0.4}
        />
        <path d="M296.07 329.05L177 448.05a23.9 23.9 0 01-33.8.1l-.1-.1-119-119c-15.1-15.1-4.4-41 17-41h238c21.37 0 32.04 25.95 16.97 41z" />
      </g>
    </svg>
  );
});
export default SvgSortDown;

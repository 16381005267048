import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledPhoto = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;

      &:hover ${StyledActions} {
        display: flex;
      }
    `;
  }}
`;

export const StyledNewPhoto = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px dashed ${legacyTheme.colors.lightGrey};
      width: 20rem;
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      ${media.lessThan('sm')`
      width: 11rem;
      height: 11rem;
    `}
    `;
  }}
`;

export const StyledActions = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: none;
    `;
  }}
`;

export const StyledAction = styled.div`
  ${({ theme }) => {
    return css`
      width: 3.2rem;
      height: 3.2rem;
      background-color: ${legacyTheme.colors.white};
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${legacyTheme.colors.cream};
    `;
  }}
`;

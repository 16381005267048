import { Product } from 'types/order.type';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_STATS = 'GET_DASHBOARD_STATS';

export interface GetDashboardResponse {
  counts: Counts;
  designs: Design[];
  nextUps: DashboardProduct[];
  notifications: any[];
  success: boolean;
}

export interface DashboardProduct {
  body: string;
  design?: {
    id: number;
    sketches: {
      front: {
        uploadedFile: {
          fileUrl?: string;
        };
      };
    };
  };
  header: string;
  link: {
    color: string;
    label: string;
    url: string;
  };
  product: Product;
}

export interface Counts {
  designs: Designs;
  products: Products;
}

export interface Designs {
  concept: number;
  content: number;
  engineering: number;
  model: number;
}

export interface Products {
  active: number;
  offMarket: number;
  preparation: number;
}

export interface Design {
  action: null;
  category: null | string;
  completedAt: null;
  concept: Concept;
  content: Content;
  createdAt: Date;
  createdAtSEC: number;
  currentStep: string;
  designer: Designer;
  designerName: string;
  engineering: Engineering;
  failReason: null;
  id: number;
  messages: any[];
  model: Model;
  name: string;
  newStep: string;
  notificationLink: null;
  size: Size;
  sketchUrls: null;
  sketches: Sketches;
  status: string;
  step: string;
  subCategory: null | string;
  timelines: any[];
  updatedAt: Date;
  updatedAtSEC: number;
  user: User;
  userID: number;
}

interface Concept {
  approvedAt: null;
  completedAt: null;
  refinementsNeededAt: null;
  review: Review;
  status: string;
  stepProgress: number;
  subSteps: { [key: string]: ConceptSubStep };
}

interface Review {}

export interface ConceptSubStep {
  category?: null | string;
  changeBulb?: null;
  describe?: null;
  howDesignUsed?: null;
  inspiration?: null;
  inspirationConnect?: null;
  interiorUrls?: any[];
  name?: string;
  purpose?: null;
  rooms?: any[];
  size?: Size;
  sketches?: Sketches;
  subCategory?: null | string;
  targetUser?: null;
  uniqueDesign?: null;
  userNeeds?: null;
  whenDesignUsed?: null;
}

export interface Size {
  code: string;
  description: string;
  label: string;
  name: string;
}

export interface Sketches {
  front: null;
  model: null;
  side: null;
  top: null;
}

interface Content {
  completedAt: null;
  productFinalization: ProductFinalization;
  status: string;
  stepProgress: number;
}

export interface ProductFinalization {
  approveDesignerProfile: ApproveDesignerProfile;
  completedAt: null;
  copy: ApproveDesignerProfile;
  packagingAssets: ApproveDesignerProfile;
  photos: ApproveDesignerProfile;
  technicalSpecs: ApproveDesignerProfile;
}

export interface ApproveDesignerProfile {
  approved: boolean;
  completedAt: null;
}

export interface Designer {
  name: string;
  photoURL: null;
  profileLink: string;
}

export interface Engineering {
  completedAt: null;
  finalization: Finalization;
  modelingOptimization: ModelingOptimization;
  prototyping: Prototyping;
  qualityTesting: QualityTesting;
  status: string;
  stepProgress: number;
}

export interface Finalization {
  assemblyInstructions: boolean;
  completedAt: null;
  finishedProductsInAllColorways: boolean;
  finishedPrototypes: any[];
  gCode: boolean;
  gatheringProductSpecs: boolean;
  stepProgress: number;
}

export interface ModelingOptimization {
  assemblyAndMatingFeatures: boolean;
  completedAt: null;
  detailWork: boolean;
  functionalComponentAccommodation: boolean;
  gCodeSlicingSetting: boolean;
  lightTransmission: boolean;
  overhangMitigation: boolean;
  partitioningAndPrintOrientation: boolean;
  stepProgress: number;
  usability: boolean;
}

export interface Prototyping {
  completedAt: null;
  images: any[];
}

export interface QualityTesting {
  assembly: boolean;
  completedAt: null;
  finishing: boolean;
  heat: boolean;
  interaction: boolean;
  light: boolean;
  shipping: boolean;
  stepProgress: number;
  strength: boolean;
}

interface Model {
  approvedAt: null;
  completedAt: null;
  refinementsNeededAt: null;
  review: Review;
  status: string;
  stepProgress: number;
  subSteps: { [key: string]: ModelSubStep };
}

export interface ModelSubStep {
  assemblyFile?: null;
  colors?: any[];
  parts?: any[];
}

interface User {
  firstName: string;
  lastName: string;
  photoURL: string;
}

export interface GetDashboardStatsRequest {
  endDate: string;
  startDate: string;
}

export interface GetDashboardStatsResponse {
  success: boolean;
  totalStatsData: DashboardStatsData[];
}

export interface DashboardStatsData {
  date: string;
  name: StatsFilterName;
  value: number | string;
}

export type StatsFilterName = 'earning' | 'orders' | 'units';

export interface UpdateDashboardRequest {
  label: string;
  value: boolean;
}

export interface UpdateDashboardResponse {
  success: boolean;
}

import { ProgressProps } from 'core/progress/progress.props';
import { ProgressPresets } from 'core/progress/progress.presets';
import {
  StyledProgress,
  StyledProgressIndicator,
} from 'core/progress/progress.styles';
import { useSpring } from '@react-spring/core';
import useResizeObserver from 'use-resize-observer/polyfilled';

export const Progress = (props: ProgressProps) => {
  const { background, color, value, width } = {
    ...ProgressPresets,
    ...props,
  };

  const { ref: containerRef, width: containerWidth } = useResizeObserver();

  const { width: animatedWidth } = useSpring({
    width: Math.min((containerWidth * value) / 100, containerWidth) || 0,
  });

  return (
    <StyledProgress ref={containerRef} background={background} width={width}>
      <StyledProgressIndicator color={color} style={{ width: animatedWidth }} />
    </StyledProgress>
  );
};

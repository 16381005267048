import {
  extractValuesFromResolutionAwareProp,
  media,
  Stack,
} from 'gantri-components';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import styled, { css } from 'styled-components';

export const StyledCategoryStack = styled(Stack)<{
  $aspectRatio: ResolutionAwareProp<string>;
  $src: string;
}>`
  ${({ $aspectRatio, $src }) => {
    const {
      lg: aspectRatioLg,
      md: aspectRatioMd,
      sm: aspectRatioSm,
    } = extractValuesFromResolutionAwareProp<string>($aspectRatio);

    return css`
      background: url('${$src}') center / cover;
      aspect-ratio: ${aspectRatioLg};

      ${media.lessThan('md')`
        aspect-ratio: ${aspectRatioMd};
      `}

      ${media.lessThan('sm')`
        aspect-ratio: ${aspectRatioSm};
      `}
    `;
  }}
`;

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgLoader = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 400 400"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={200}
        cy={200}
        fill="none"
        r={160}
        stroke="#D6D4D2"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={200}
        cy={200}
        fill="none"
        r={160}
        stroke={color}
        strokeDasharray={1000}
        strokeDashoffset={1000}
        strokeWidth={strokeWidth}
        transform="rotate(-90 200 200)"
      >
        <animate
          attributeName="stroke-dashoffset"
          calcMode="spline"
          dur="2s"
          keySplines=".5 .16 .13 .5"
          repeatCount="indefinite"
          values="1000;-1000"
        />
      </circle>
    </svg>
  );
});
export default SvgLoader;

import { useQueryMutation } from '../use-mutation';
import { useNotification } from '../useNotification';
import { UseGenericMutateQueryProps } from './use-generic-mutate-query.types';

/**
 * @example
 * export const useYourMutateQuery = <
 *   TransformedData = YOUR_RESPONSE_TYPE,
 * >(
 *   props?: GenericMutateQueryProps<
 *     YOUR_REQUEST_ARGS_TYPE,
 *     YOUR_RESPONSE_TYPE,
 *     TransformedData
 *   >,
 * ) => {
 * const { onMutate: YOUR_RETURNED_FUNCTION_NAME, ...rest } = useGenericMutateQuery({
 *     fallbackErrorMessage: 'Unable to ___________.',
 *     mutationFn: YOUR_MUTATE_FUNCTION,
 *     ...props,
 * });
 *
 * return { ...rest, YOUR_RETURNED_FUNCTION_NAME };
 * };
 */
export const useGenericMutateQuery = <
  RequestArgs extends Record<string, any>,
  FetchResponse extends Record<string, any>,
  TransformedData = FetchResponse,
>(
  props?: UseGenericMutateQueryProps<
    RequestArgs,
    FetchResponse,
    TransformedData
  >,
) => {
  const {
    fallbackErrorMessage,
    mutationFn,
    onError,
    onSuccess,
    showLoading,
    transform = (response) => {
      return response;
    },
  } = props;

  const { notify, notifyAxiosError } = useNotification();

  return useQueryMutation<RequestArgs, TransformedData>({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: fallbackErrorMessage,
        });
        onError?.(error);
      },
      onSuccess: async (data, requestArgs) => {
        const { notice } = data;

        notify(notice);

        await onSuccess?.(data, requestArgs);
      },
    },
    mutationFn: async (args) => {
      return mutationFn(args).then(
        // @ts-expect-error
        transform,
      );
    },
    showLoading,
  });
};

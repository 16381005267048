import { FC, useState } from 'react';
import { Typography } from 'core/typography';
import { DividedContainer } from 'layout/divided-container';
import { FlexContainer } from 'layout/flex-container';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Icon } from 'core/icon';
import { ImageContainer } from 'layout/image-container';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { LoadingOverlay } from 'layout/loading-overlay';
import { DesignerInfo } from 'types/user.type';
import { useNotification } from 'context/notification.context';
import { useAuth } from 'context/auth.context';
import { useModalClose } from 'context/modal.context';
import { BoxContainer } from 'layout/box-container';
import { LocationFormValues } from './components/location-form/location-form.types';
import { BrandFormValues } from './components/brand-form/brand-form.types';
import { BrandForm, LocationForm, LogoForm, UrlForm } from './components';

export const IntroModal: FC = () => {
  const [step, setStep] = useState(1);
  const [brandFormValues, setBrandFormValues] = useState<BrandFormValues>();
  const [locationFormValues, setLocationFormValues] =
    useState<LocationFormValues>();

  const queryClient = useQueryClient();
  const { hidePreloader, showPreloader } = useNotification();
  const [{ campaignInfo }] = useAuth();
  const { closeLastOpened } = useModalClose();

  const query = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });

  const updateDesignerMutation = useMutation(
    async (data: DesignerInfo) => api.designer.update(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const handleBrandFormChange = (values: BrandFormValues) => {
    setBrandFormValues(values);
    setStep(3);
  };

  const handleLogoFormChange = () => {
    setStep(4);
  };

  const handleLocationFormChange = (values: LocationFormValues) => {
    setLocationFormValues(values);
    setStep(5);
  };

  const handleUrlFormChange = async (profileLink: string) => {
    showPreloader();

    await updateDesignerMutation.mutateAsync({
      ...query.data?.data?.designerInfo,
      ...({
        browserUserId: campaignInfo.browserUserId,
        name: brandFormValues.designerName,
        title: brandFormValues.title,
        userId: query.data?.data?.id,
        ...locationFormValues,
        profileLink,
      } as Partial<DesignerInfo>),
    });

    closeLastOpened();

    hidePreloader();
  };

  if (!query?.data?.data) {
    return <LoadingOverlay />;
  }

  const renderSmallerResolutionTitles = () => (
    <BoxContainer hidden={{ lg: true, md: false }} marginBottom="s4">
      {step === 1 && (
        <Typography
          align={{ md: 'center' }}
          display={{ lg: 'none', md: 'block' }}
          htmlText="Welcome to your <br/>Create Hub"
          variant="h2"
        />
      )}

      {step === 2 && (
        <div>
          <Typography
            align={{ md: 'center' }}
            htmlText="Your brand"
            marginBottom="s1"
            variant="h2"
          />

          <Typography
            align="center"
            color="grey"
            text="Your studio name and a one line tagline."
          />
        </div>
      )}

      {step === 3 && (
        <BoxContainer>
          <Typography
            align={{ md: 'center' }}
            htmlText="Logo"
            marginBottom="s1"
            variant="h2"
          />

          <Typography align="center" color="grey" text="Make it distinctive." />
        </BoxContainer>
      )}

      {step === 4 && (
        <BoxContainer>
          <Typography
            align={{ md: 'center' }}
            htmlText="Location"
            marginBottom="s1"
            variant="h2"
          />

          <Typography align="center" color="grey" text="Where you're based." />
        </BoxContainer>
      )}

      {step === 5 && (
        <BoxContainer>
          <Typography
            align={{ md: 'center' }}
            htmlText="Storefront URL"
            marginBottom="s1"
            variant="h2"
          />

          <Typography align="center" color="grey" text="Your personal URL." />
        </BoxContainer>
      )}
    </BoxContainer>
  );

  return (
    <DividedContainer
      leftContent={
        <FlexContainer
          alignItems={{ lg: 'stretch', md: 'center' }}
          direction="column"
          justifyContent="space-between"
          maxWidth="30rem"
          minHeight="34rem"
          minWidth={{ lg: '30rem', md: 'unset' }}
        >
          {step === 1 && (
            <>
              <Typography
                align={{ md: 'center' }}
                display={{ lg: 'block', md: 'none' }}
                htmlText="Welcome to <br/>your <br/>Create Hub"
                variant="h2"
              />

              <TypographyWithIcon
                align="center"
                color="primary"
                icon={
                  <Icon
                    color="primary"
                    cursor="pointer"
                    name="arrow-right"
                    width="2rem"
                    onClick={() => setStep(2)}
                  />
                }
                iconPosition="right"
                text="Start"
                textStyle="bold"
                onClick={() => setStep(2)}
              />
            </>
          )}

          {step === 2 && (
            <>
              <BoxContainer hidden={{ lg: false, md: true }}>
                <Typography
                  align={{ md: 'center' }}
                  htmlText="Your brand"
                  marginBottom="s1"
                  variant="h2"
                />

                <Typography
                  color="grey"
                  maxWidth="28rem"
                  text="Your studio name and a one line tagline."
                />
              </BoxContainer>

              <Typography color="grey" text="2 of 5" textStyle="bold" />
            </>
          )}

          {step === 3 && (
            <>
              <BoxContainer hidden={{ lg: false, md: true }}>
                <Typography
                  align={{ md: 'center' }}
                  htmlText="Logo"
                  marginBottom="s1"
                  variant="h2"
                />

                <Typography
                  color="grey"
                  maxWidth="28rem"
                  text="Make it distinctive."
                />
              </BoxContainer>

              <Typography color="grey" text="3 of 5" textStyle="bold" />
            </>
          )}

          {step === 4 && (
            <>
              <BoxContainer hidden={{ lg: false, md: true }}>
                <Typography
                  align={{ md: 'center' }}
                  htmlText="Location"
                  marginBottom="s1"
                  variant="h2"
                />

                <Typography
                  color="grey"
                  maxWidth="28rem"
                  text="Where you're based."
                />
              </BoxContainer>

              <Typography color="grey" text="4 of 5" textStyle="bold" />
            </>
          )}

          {step === 5 && (
            <>
              <BoxContainer hidden={{ lg: false, md: true }}>
                <Typography
                  align={{ md: 'center' }}
                  htmlText="Storefront URL"
                  marginBottom="s1"
                  variant="h2"
                />

                <Typography
                  color="grey"
                  maxWidth="28rem"
                  text="Your personal URL."
                />
              </BoxContainer>

              <Typography color="grey" text="5 of 5" textStyle="bold" />
            </>
          )}
        </FlexContainer>
      }
      leftContentProps={{
        alignItems: { lg: 'unset', md: 'center' },
        direction: 'column',
        padding: {
          lg: '6rem 4rem',
          md: '0 3rem',
          sm: '0 1.2rem',
        },
      }}
      rightContent={
        <>
          {renderSmallerResolutionTitles()}

          <FlexContainer
            alignItems="center"
            alignSelf="center"
            backgroundColor="white"
            borderRadius="6px"
            height={{ lg: '34rem', md: 'umset' }}
            horizontalPadding={{ lg: 'unset', md: 's2' }}
            justifyContent={{ lg: 'unset', md: 'center' }}
            minWidth={{ lg: '30rem', md: 'unset' }}
            verticalPadding={{ lg: 'unset', md: 's5' }}
            width={{ lg: 'unset', md: 'max-content' }}
          >
            {step === 1 && (
              <FlexContainer direction="column" justifyContent="center">
                <ImageContainer
                  aspectRatio="1:1"
                  src="pages/dashboard/intro1.jpg"
                  width="21.5rem"
                />
              </FlexContainer>
            )}

            {step === 2 && (
              <BrandForm
                designerName={query.data?.data?.designerInfo?.name || ''}
                title={query.data?.data?.designerInfo?.title || ''}
                onBrandFormSubmit={handleBrandFormChange}
              />
            )}

            {step === 3 && (
              <LogoForm
                designerInfo={query.data?.data?.designerInfo}
                logoUrl={query.data?.data?.designerInfo?.logoUrl}
                userId={query.data?.data?.id}
                onLogoFormSubmit={handleLogoFormChange}
              />
            )}

            {step === 4 && (
              <LocationForm
                city={query.data?.data?.designerInfo?.city || ''}
                country={query.data?.data?.designerInfo?.country || ''}
                state={query.data?.data?.designerInfo?.state || ''}
                onLocationFormSubmit={handleLocationFormChange}
              />
            )}

            {step === 5 && (
              <UrlForm
                profileLink={query.data?.data?.designerInfo?.profileLink || ''}
                onUrlFormSubmit={handleUrlFormChange}
              />
            )}
          </FlexContainer>
        </>
      }
      rightContentProps={{
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
        direction: 'column',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        padding: {
          lg: '6rem 4rem',
          md: '12rem 3rem',
          sm: '6rem 1.2rem',
        },
      }}
    />
  );
};

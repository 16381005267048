import { useFetchDesign } from 'api/designs/routes/fetch-design';
import { Conditional, Grid, Stack, Typography } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { Form, Formik } from 'formik';
import { StepFooter } from 'src/pages/design-flow/components/step-footer';
import { ModelStepHeader } from '../model-step-header';
import { useOnUpdateModel } from '../../hooks/use-on-update-model';
import { useModelNavigation } from '../../hooks/use-model-navigation';
import { modelStepTwoFormSchema } from './model-step-two.schema';
import { NewPartUploader } from './components/new-part-uploader';
import { ExistingPartUploader } from './components/existing-part-uploader';

export const ModelStepTwo = () => {
  const { id } = useParams<{ id: string }>();

  const { onNavigateToNext, onNavigateToPrev } = useModelNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const expectedExtensions = ['step'];
  const maxFileSizeMB = 50;

  const { isApproved: isModelApproved } = getDesignStatusDetails(
    design?.model?.status,
  );

  const isDisabled = isModelApproved;

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToPrev,
  });

  const { onSubmit, processing: isSubmitting } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToNext,
  });

  return design ? (
    <Formik
      enableReinitialize
      initialValues={design.model.subSteps[2]}
      validateOnMount
      validationSchema={modelStepTwoFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Stack gap="5x">
              <ModelStepHeader design={design} showBackButton />

              <Stack gap="2x">
                <Typography
                  text="Upload all parts of your design"
                  variant="h3"
                />
                <Typography color="t2" text="For each part, choose material" />
              </Stack>

              <Grid alignItems="start" columns={{ lg: 2, sm: 1 }} gap="8x">
                {values.parts.map(({ fileName, fileUrl, material }, index) => {
                  return (
                    <ExistingPartUploader
                      key={fileName}
                      designId={design?.id}
                      expectedExtensions={expectedExtensions}
                      fileName={fileName}
                      fileUrl={fileUrl}
                      index={index}
                      isDisabled={isDisabled}
                      material={material}
                      maxFileSizeMB={maxFileSizeMB}
                    />
                  );
                })}

                <Conditional condition={!isDisabled}>
                  <NewPartUploader
                    expectedExtensions={expectedExtensions}
                    maxFileSizeMB={maxFileSizeMB}
                  />
                </Conditional>
              </Grid>

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

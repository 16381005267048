import styled from 'styled-components';

export const StyledClickAwareContainer = styled.div`
  z-index: 2;
  position: relative;
`;

export const StyledClickAwareContainerBackdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

import { Stack, Badge } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledExampleBadge = styled(Badge)`
  letter-spacing: 3px;
`;

export const StyledCardStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

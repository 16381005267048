import { useFormikContext } from 'formik';
import { FileUploader } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { ConceptStepTwoFormDef } from '../../concept-step-two.types';
import { NewInteriorUploaderPropsDef } from './new-interior-uploader.types';

export const NewInteriorUploader = (props: NewInteriorUploaderPropsDef) => {
  const { expectedExtensions, maxFileSizeMB } = props;

  const { id } = useParams<{ id: string }>();

  const { setFieldValue, values } = useFormikContext<ConceptStepTwoFormDef>();

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: undefined,
    handleUploadsComplete: async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue('interiorUrls', [
        ...values.interiorUrls,
        {
          fileExtension,
          fileName,
          fileSize,
          fileUrl,
          loading: false,
          percentage: 100,
        },
      ]);
    },
    onFileDelete: undefined,
    path: `designs/${id}/interior`,
  });

  return (
    <FileUploader
      {...fileUploaderProps}
      expectedExtensions={expectedExtensions}
      fileName={undefined}
      isUploaderOnly
      maxFileSizeMB={maxFileSizeMB}
      thumbnailSize="36rem"
      variant="thumbnail"
    />
  );
};

import { FC } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from 'layout/grid';
import { Button } from 'core/button';
import { TextInput } from 'core/text-input';
import { Spacer } from 'layout/spacer';
import { SelectInput } from 'core/select-input';
import {
  CANADA_PROVINCES,
  COUNTRIES,
  US_STATES_FULL_NAME,
} from 'constants/countries';
import { LocationFormSchema } from './location-form.schema';
import { LocationFormProps, LocationFormValues } from './location-form.types';

export const LocationForm: FC<LocationFormProps> = props => {
  const { city, country, onLocationFormSubmit, state } = props;

  const handleSubmit = (values: LocationFormValues) => {
    onLocationFormSubmit(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        city,
        country,
        state,
      }}
      validateOnMount
      validationSchema={LocationFormSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form autoComplete="off" style={{ width: '100%' }}>
          <Grid
            alignContent="space-between"
            backgroundColor={{ md: 'white' }}
            gap="s4"
            height="100%"
            minWidth={{ lg: 'unset', md: '70vw' }}
            padding="unset"
            width="100%"
          >
            <div>
              <TextInput
                error={props.errors.city}
                name="city"
                touched={props.touched.city}
                value={props.values.city}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />

              <Spacer height="s4" />

              {props.values.country === 'US' && (
                <SelectInput
                  error={props.values.state}
                  name="state"
                  options={US_STATES_FULL_NAME}
                  value={props.values.state}
                  onBlur={props.handleBlur}
                  onNativeChange={props.handleChange}
                />
              )}

              {props.values.country === 'CA' && (
                <SelectInput
                  error={props.values.state}
                  name="state"
                  options={CANADA_PROVINCES}
                  value={props.values.state}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />
              )}

              {!['US', 'CA'].includes(props.values.country) && (
                <TextInput
                  error={props.errors.state}
                  name="state"
                  touched={props.touched.state}
                  value={props.values.state}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />
              )}

              <Spacer height="s4" />

              <SelectInput
                error={props.values.country}
                labelProperty="name"
                name="country"
                options={COUNTRIES}
                value={props.values.country}
                valueProperty="code"
                onBlur={props.handleBlur}
                onNativeChange={props.handleChange}
              />
            </div>

            <Button
              disabled={!props.isValid}
              text="Continue"
              type="submit"
              variant="primary"
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

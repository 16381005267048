import { useCallback, useRef, useState } from 'react';
import { environment } from 'config/environment';
import { useAsync } from 'react-use';
import {
  AddressParts,
  AddressPrediction,
  UseAddressPredictionsResponse,
} from 'core/address-autocomplete/address-autocomplete.types';

const SCRIPT_ID = '__google-key-id';

const injectGoogleMapApiScript = async () =>
  new Promise(resolve => {
    {
      const script = document.createElement('script');
      script.id = SCRIPT_ID;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAPS_API_KEY}&language=en&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    }
  });

const extractAddressPart = (
  addressComponents,
  type,
  componentName: string = 'long_name',
) => {
  const component = addressComponents.find(components =>
    components.types.includes(type),
  );

  return component ? component[componentName] : '';
};

export const useAddressPredictions = (): UseAddressPredictionsResponse => {
  const [predictions, setPredictions] = useState<AddressPrediction[]>([]);

  const autocomplete = useRef<any>();

  const tryToFetchReference = useCallback(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      autocomplete.current =
        new window.google.maps.places.AutocompleteService();
    } catch (e) {}
  }, []);

  const getPredictions = useCallback(
    input => {
      if (!autocomplete.current) {
        tryToFetchReference();
      }

      if (autocomplete.current && input) {
        autocomplete.current.getPlacePredictions({ input }, predictions => {
          setPredictions(
            predictions?.map(prediction => ({
              address: prediction.description,
              placeId: prediction.place_id,
            })) ?? [],
          );
        });
      } else {
        setPredictions([]);
      }
    },
    [!!autocomplete.current],
  );

  const extractAddressParts = useCallback(
    async (placeId: number) =>
      new Promise<AddressParts>(resolve => {
        if (placeId) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode(
            { placeId: placeId.toString() },
            (results, status) => {
              if (status === 'OK' && results.length) {
                const { address_components, formatted_address } = results[0];

                resolve({
                  city: extractAddressPart(address_components, 'locality'),
                  country: extractAddressPart(address_components, 'country'),
                  countryShort: extractAddressPart(
                    address_components,
                    'country',
                    'short_name',
                  ),
                  full: formatted_address,
                  state: extractAddressPart(
                    address_components,
                    'administrative_area_level_1',
                    'short_name',
                  ),
                  street: extractAddressPart(address_components, 'route'),
                  streetNumber: extractAddressPart(
                    address_components,
                    'street_number',
                  ),
                  zipcode: extractAddressPart(
                    address_components,
                    'postal_code',
                  ),
                });
              } else {
                resolve({});
              }
            },
          );
        } else {
          resolve({});
        }
      }),
    [!!autocomplete.current],
  );

  useAsync(async () => {
    if (!document.getElementById(SCRIPT_ID)) {
      const initialized = await injectGoogleMapApiScript();

      if (initialized) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        autocomplete.current =
          new window.google.maps.places.AutocompleteService();
      }
    }
  }, []);

  return { extractAddressParts, getPredictions, predictions };
};

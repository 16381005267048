import { FileDetails } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { useFormikContext } from 'formik';
import { FileUploader } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { minInteriors, emptyFileDetails } from '../../concept-step-two.schema';
import { ConceptStepTwoFormDef } from '../../concept-step-two.types';
import { ExistingInteriorUploaderPropsDef } from './existing-interior-uploader.types';

export const ExistingInteriorUploader = (
  props: ExistingInteriorUploaderPropsDef,
) => {
  const {
    expectedExtensions,
    fileName,
    fileUrl,
    index,
    isDisabled,
    maxFileSizeMB,
  } = props;

  const { id } = useParams<{ id: string }>();

  const { setFieldValue, values } = useFormikContext<ConceptStepTwoFormDef>();

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl,
    handleUploadsComplete: async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue(
        'interiorUrls',
        values.interiorUrls.map((item, savedIndex) => {
          const isUpdatedIndex = savedIndex === index;

          const fileDetails: FileDetails = isUpdatedIndex
            ? {
                fileExtension,
                fileName,
                fileSize,
                fileUrl,
                loading: false,
                percentage: 100,
              }
            : item;

          return fileDetails;
        }),
      );
    },
    onFileDelete: async () => {
      if (values.interiorUrls.length <= minInteriors) {
        await setFieldValue(
          'interiorUrls',
          values.interiorUrls.map((item, savedIndex) => {
            return savedIndex === index ? emptyFileDetails : item;
          }),
        );
      } else {
        await setFieldValue(
          'interiorUrls',
          values.interiorUrls.filter((_item, savedIndex) => {
            return savedIndex !== index;
          }),
        );
      }
    },
    path: `designs/${id}/interior`,
  });

  return (
    <FileUploader
      {...fileUploaderProps}
      expectedExtensions={expectedExtensions}
      fileName={fileName}
      isDisabled={isDisabled}
      maxFileSizeMB={maxFileSizeMB}
      thumbnailSize="36rem"
      variant="thumbnail"
    />
  );
};

import { FC, useEffect, useState } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { api } from 'services/api';
import { Typography } from 'core/typography';
import { Table } from 'core/table';
import { TableColumn } from 'core/table/table.props';
import { Grid } from 'layout/grid';
import { Icon } from 'core/icon';
import { Link } from 'react-router-dom';
import { GET_ORDERS, Order } from 'services/api/order';
import { DEFAULT_PAGING_SIZE } from 'constants/common';
import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/formaters';
import { SortInfo } from 'types/paging-info';
import { useNotification } from 'context/notification.context';
import { OrdersFilters } from './components';

const columns = [
  {
    Header: 'ID #',
    accessor: 'id',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Cell: ({ value }) => (
      <Typography color="grey" text={value ? formatDate(value) : 'TBD'} />
    ),
    Header: 'Created',
    accessor: 'createdAt',
    sortable: true,
  },
  {
    Cell: ({ value }) => (
      <Typography color="grey" text={value ? formatDate(value) : 'TBD'} />
    ),
    Header: 'Completed',
    accessor: 'completedAt',
    sortable: true,
    width: '26rem',
  },
  {
    Header: 'Ship to',
    accessor: 'shipTo',
    width: '25%',
  },
  {
    Header: 'Items',
    accessor: ({ items }) => Object.keys(items).join(', '),
  },
  {
    Header: 'Total',
    accessor: ({ amount }) => formatCurrency(amount * 100),
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortable: true,
  },
  {
    Cell: ({ row }) => (
      <Link to={`/orders/${row.original.id}`}>
        <Icon name="arrow-right" />
      </Link>
    ),
    Header: '',
    accessor: 'userId',
    width: '1rem',
  },
] as TableColumn<Order>[];

const initial = {
  search: '',
  statuses: [],
  types: [],
};

export const OrdersPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState(initial);

  const [sortInfo, setSortInfo] = useState<SortInfo>({});

  const { hidePreloader, showPreloader } = useNotification();

  const ordersQuery = useQuery(
    [GET_ORDERS, currentPage, sortInfo, filters],
    () =>
      api.order.getOrders({
        count: DEFAULT_PAGING_SIZE,
        page: currentPage + 1,
        sortInfo: {
          sortField: sortInfo.sortField || 'createdAt',
          sortOrder: sortInfo.sortOrder || 'DESC',
        },
        ...filters,
      }),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (ordersQuery.isFetching) {
      showPreloader();
    } else {
      hidePreloader();
    }
  }, [ordersQuery.isFetching]);

  const onSort = (info: SortInfo) => {
    setCurrentPage(0);
    setSortInfo(info);
  };

  return (
    <Page>
      <Grid gap={{ lg: 's5', md: 's4' }}>
        <Typography text="Orders" variant="h2" />

        <OrdersFilters
          count={ordersQuery.data?.orders.length}
          onApply={setFilters}
          onReset={() => setFilters(initial)}
        />

        <Table
          columns={columns}
          data={ordersQuery.data?.orders || []}
          manualSortBy={false}
          paging={{
            currentPage,
            onPageChange: setCurrentPage,
            total: ordersQuery.data?.allOrders,
          }}
          onSort={onSort}
        />
      </Grid>
    </Page>
  );
};

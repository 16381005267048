import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import { LogoutResponse, UserData } from 'services/api/auth/auth.types';

export class AuthApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/auth');
  }

  logout = async (): Promise<LogoutResponse> => {
    const response: ApiResponse<LogoutResponse> = await this.api.post(
      '/user/logout',
    );
    return this.handleResponse<LogoutResponse>(response);
  };

  getLogged = async (): Promise<UserData> => {
    const response: ApiResponse<UserData> = await this.api.get('/user');
    return this.handleResponse<UserData>(response);
  };
}

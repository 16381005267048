export const environment = {
  API_URL: process.env.REACT_APP_API_URL,
  APP_STAGE: process.env.REACT_APP_STAGE,
  COOKIES_DOMAIN:
    process.env.REACT_APP_STAGE === 'local' ? 'localhost' : '.gantri.com',
  FIREBASE_DOMAIN: process.env.REACT_APP_FIREBASE_DOMAIN,
  FIREBASE_KEY: process.env.REACT_APP_FIREBASE_KEY,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_URL: process.env.REACT_APP_FIREBASE_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  IS_PRODUCTION: process.env.REACT_APP_STAGE === 'production',
  SEGMENT_KEY: process.env.REACT_APP_SEGMENT_KEY,
  SHOP_URL: process.env.REACT_APP_SHOP_URL,
};

import { ButtonToggleProps } from 'core/button-toggle/button-toggle.props';
import { ButtonTogglePresets } from 'core/button-toggle/button-toggle.presets';
import { StyledToggleButton } from 'core/button-toggle/button-toggle.styles';
import { Typography } from 'core/typography';

export const ButtonToggle = (props: ButtonToggleProps) => {
  const {
    icon,
    onChange,
    selected,
    selectedBackgroundColor,
    selectedTextColor,
    value,
    ...other
  } = { ...ButtonTogglePresets, ...props };

  const handleChange = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <StyledToggleButton
      selected={selected}
      selectedBackgroundColor={selectedBackgroundColor}
      onClick={handleChange}
    >
      <Typography
        {...other}
        color={selected ? selectedTextColor : 'grey'}
        textStyle={selected ? 'bold' : 'regular'}
      />
    </StyledToggleButton>
  );
};

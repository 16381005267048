export const GET_DOWNLOADS = 'GET_DOWNLOADS';

export interface GetAllRequest {
  search: string;
  sortingField: string;
  sortingType: string;
  types: string[];
}

export interface GetAllResponse {
  downloads: Download[];
  success: boolean;
}

export interface Download {
  createdAt: Date;
  description: string;
  fileName: string;
  id: number;
  link: string;
  name: string;
  removedAt: null;
  thumbnail: string;
  type: DownloadType;
  updatedAt: Date;
}

export enum DownloadType {
  Component = 'component',
  Design = 'design',
  Helper = 'helper',
}

import { FC } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { OtherProduct } from 'services/api';
import { Thumbnail } from 'core/thumbnail';
import { Typography } from 'core/typography';
import { PurchasesListPresets } from './purchases-list.presets';
import { PurchasesListProps } from './purchases-list.props';
import { StyledProductListItem } from './purchases-list.styles';

export const PurchasesList: FC<PurchasesListProps> = props => {
  const { products } = { ...PurchasesListPresets, ...props };

  return (
    <StackedContainer marginTop={{ lg: 's3', md: 's2' }} padding="unset">
      {products.map((product: OtherProduct) => (
        <StyledProductListItem key={product.sku}>
          <Thumbnail src={product.productInfo.thumbnailLink} />

          <div>
            <Typography text={product.productInfo.fullName} />
            <Typography
              color="grey"
              text={product.productInfo.designerName}
              variant="p3"
            />
          </div>
        </StyledProductListItem>
      ))}
    </StackedContainer>
  );
};

import {
  useInvalidateFetchDesignsCache,
  useAddDesign,
} from 'api/designs/routes';
import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { addDesignModalSteps } from '../../../add-design-modal.constants';
import { AddDesignModalNameStepFooterPropsDef } from './add-design-modal-name-step-footer.types';

export const AddDesignModalNameStepFooter = (
  props: AddDesignModalNameStepFooterPropsDef,
) => {
  const { onClose } = props;

  const navigate = useNavigate();

  const category = useRecoilValue(addDesignModalAtoms.category);
  const subCategory = useRecoilValue(addDesignModalAtoms.subCategory);
  const sizeDetails = useRecoilValue(addDesignModalAtoms.sizeDetails);
  const name = useRecoilValue(addDesignModalAtoms.name);
  const setStep = useSetRecoilState(addDesignModalAtoms.step);

  const { invalidateFetchDesignsCache } = useInvalidateFetchDesignsCache();

  const { isLoading, onAddDesign } = useAddDesign({
    onSuccess: async ({ data: design }) => {
      onClose();
      navigate(routePaths.designFlowStepList(design.id));
      await invalidateFetchDesignsCache();
    },
  });

  const isDisabled = name.length < 3;

  return (
    <>
      <Button
        size="large"
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(addDesignModalSteps.size);
        }}
      />
      <Button
        disabled={isDisabled}
        processing={isLoading}
        size="large"
        text="Next"
        onClick={async () => {
          await onAddDesign({
            category,
            name,
            size: sizeDetails,
            subCategory,
          });
        }}
      />
    </>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDimming = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      fill="none"
      fillRule="evenodd"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.75 11.976a4.75 4.75 0 01-9.5 0 4.75 4.75 0 019.5 0z"
        fill={color}
      />
      <path
        d="M12 19.412v1.56m5.462-3.534l1.17 1.17m.78-6.608h1.56m-3.5-5.484l1.17-1.17M12 4.6V3.03M6.54 6.516l-1.17-1.17M4.588 12h-1.56m3.512 5.438l-1.17 1.17"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={2.111}
      />
    </svg>
  );
});
export default SvgDimming;

import { Stack, Line, Flex, Button } from 'gantri-components';
import { StepFooterPropsDef } from './step-footer.types';

export const StepFooter = (props: StepFooterPropsDef) => {
  const { backButtonProps, nextButtonProps } = props;

  return (
    <Stack>
      <Line />

      <Flex alignItems="center" justifyContent="space-between">
        <Button
          size="large"
          text="Back"
          variant="secondary"
          {...backButtonProps}
        />
        <Button size="large" text="Next" {...nextButtonProps} />
      </Flex>
    </Stack>
  );
};

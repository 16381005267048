import {
  DesignStatus,
  designStatuses,
} from 'api/designs/routes/fetch-designs/fetch-designs.types';

export const getDesignStatusDetails = (status: DesignStatus) => {
  return {
    isApproved: status === designStatuses.approved,
    isCompleted: status === designStatuses.completed,
    isError: status === designStatuses.error,
    isFailed: status === designStatuses.failed,
    isInProgress:
      status === designStatuses.inProgress ||
      status === designStatuses.inprogress,
    isReturned: status === designStatuses.returned,
    isSubmitted: status === designStatuses.submitted,
    isWaiting: status === designStatuses.waiting,
  };
};

import firebase from 'firebase/app';
import 'firebase/storage';

export const isValidImage = ({
  file,
  imgInstance,
  maxFileSize,
  maxHeight = null,
  maxWidth = null,
  minHeight,
  minWidth,
}) => {
  if (
    minWidth &&
    minHeight &&
    (imgInstance.width < minWidth || imgInstance.height < minHeight)
  ) {
    return {
      errorMessage: `Image resolution should be at least ${minWidth} x ${minHeight}`,
    };
  }

  if (
    maxWidth &&
    maxHeight &&
    (imgInstance.width > maxWidth || imgInstance.height > maxHeight)
  ) {
    return {
      errorMessage: `Image resolution should be at least ${maxWidth} x ${maxHeight}`,
    };
  }

  if (maxFileSize && file.size > maxFileSize) {
    return {
      errorMessage: `Please limit image size to ${maxFileSize / 1000000}MB`,
    };
  }

  return {
    valid: true,
  };
};

export const uploadBlob = ({
  blob,
  filename,
  metadata = null,
  onComplete,
  onError,
  path,
}) => {
  const storageRef = firebase.storage().ref(path);
  const uploadTask = storageRef.child(filename).put(blob, metadata);
  let runningNotificationShown = false;

  return uploadTask.on(
    'state_changed',
    snapshot => {
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          break;
        case firebase.storage.TaskState.RUNNING:
          if (!runningNotificationShown) {
            runningNotificationShown = true;
          }
          break;
        default: {
          break;
        }
      }
    },
    error => {
      if (onError) {
        onError(error.message);
      }
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        onComplete(downloadURL);
      });
    },
  );
};

export const deleteImage = ({ fileUrl, onComplete = null, onError = null }) => {
  const fileRef = firebase.storage().refFromURL(fileUrl);

  fileRef.delete().then(
    () => {
      if (onComplete) {
        onComplete();
      }
    },
    error => {
      if (onError) {
        onError(error.message_);
      }
    },
  );
};

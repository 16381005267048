import { FC, useEffect, useState } from 'react';
import { FlexContainer } from 'layout/flex-container';
import { SelectInput } from 'core/select-input';
import { Button } from 'core/button';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Image } from 'gantri-components';
import { ProductPhotosFileType } from 'services/api';
import { StyledPhoto } from './product-assets.styles';
import { ProductAssetsProps } from './product-assets.types';
import { getProductPhotoUrl } from '../../product.helpers';

export const ProductAssets: FC<ProductAssetsProps> = props => {
  const { product } = props;
  const [selectedSku, setSelectedSku] = useState(product.skus?.[0].code);
  const [lifestylePhotos, setLifestylePhotos] = useState([]);

  useEffect(() => {
    if (selectedSku) {
      setLifestylePhotos(
        product.skusData[selectedSku].photoUrls['lifestyle-photos']?.map(
          photo =>
            getProductPhotoUrl(
              'lifestyle-photos',
              product.id,
              selectedSku,
              photo,
            ),
        ) || [],
      );
    } else {
      setLifestylePhotos([]);
    }
  }, [selectedSku]);

  const downloadAllAssets = () => {
    const { photoUrls } = product.skusData[selectedSku];
    const keys = Object.keys(photoUrls);

    if (!!keys.length) {
      const link = document.createElement('a');
      link.target = '_blank';

      keys.forEach((key: ProductPhotosFileType) => {
        if (photoUrls[key]?.length) {
          photoUrls[key].forEach((photo: string) => {
            link.href = getProductPhotoUrl(key, product.id, selectedSku, photo);
            link.download = photo;
            document.body.appendChild(link);
            link.click();
          });
        }
      });

      document.body.removeChild(link);
    }
  };

  return (
    <StackedContainer gap={{ lg: 's6', md: 's5' }} padding="unset">
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        marginTop="s2"
      >
        <SelectInput
          label="SKU"
          labelProperty="code"
          options={product.skus}
          value={selectedSku}
          valueProperty="code"
          onChange={setSelectedSku}
        />

        <Button
          text="Download All"
          variant="secondary"
          onClick={downloadAllAssets}
        />
      </FlexContainer>

      <div>
        <Typography marginBottom="s3" text="Product photos" variant="h3" />

        <Grid
          columns={{
            lg: 'repeat(auto-fit, 20rem)',
            sm: 'repeat(auto-fit, 11rem)',
          }}
        >
          {(
            product.skusData[selectedSku].photoUrls?.['product-photos'] || []
          ).map((photo: string, index: number) => (
            <Image
              key={photo}
              alt=""
              height={{
                lg: 200,
                sm: 110,
              }}
              objectFit="cover"
              source="dynamic"
              src={getProductPhotoUrl(
                'product-photos',
                product.id,
                selectedSku,
                photo,
              )}
              width={{
                lg: 200,
                sm: 110,
              }}
            />
          ))}
        </Grid>
      </div>

      <div>
        <Typography marginBottom="s3" text="Dimming photos" variant="h3" />

        <Grid
          columns={{
            lg: 'repeat(auto-fit, 20rem)',
            sm: 'repeat(auto-fit, 11rem)',
          }}
        >
          {(
            product.skusData[selectedSku].photoUrls?.['dimming-photos'] || []
          ).map((photo: string) => (
            <Image
              key={photo}
              alt=""
              height={{
                lg: 200,
                sm: 110,
              }}
              objectFit="cover"
              source="dynamic"
              src={getProductPhotoUrl(
                'dimming-photos',
                product.id,
                selectedSku,
                photo,
              )}
              width={{
                lg: 200,
                sm: 110,
              }}
            />
          ))}
        </Grid>
      </div>

      <div>
        <Typography text="Lifestyle photos" variant="h3" />
        <Typography
          color="grey"
          marginBottom="s3"
          maxWidth={{ lg: '67.5rem', sm: 'unset' }}
          text="Show how your product is used in a room setting. We recommend 1600 x 800 and up to 250KB per photo. Note that it will be shown in 1:1 format on mobile and tablet."
          variant="p3"
        />

        <Grid
          columns={{
            lg: 'repeat(auto-fit, 20rem)',
            sm: 'repeat(auto-fit, 11rem)',
          }}
        >
          {(lifestylePhotos || []).map((photo: string, index: number) => (
            <StyledPhoto key={photo}>
              <Image
                alt=""
                height={{
                  lg: 200,
                  sm: 110,
                }}
                objectFit="cover"
                source="dynamic"
                src={photo}
                width={{
                  lg: 200,
                  sm: 110,
                }}
              />
            </StyledPhoto>
          ))}
        </Grid>
      </div>
    </StackedContainer>
  );
};

import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import SvgChevronDown from 'core/icon/generated/ChevronDown';

export const StyledSelectContainer = styled.div<{ invalid: any }>`
  ${({ invalid, theme }) => {
    return css`
      border-bottom: 1px solid
        ${invalid ? legacyTheme.colors.red : legacyTheme.colors.lightGrey};
      padding-bottom: 5px;
      position: relative;
      height: 100%;
      max-height: 10.6rem;
      display: initial;
    `;
  }}
`;

export const StyledSelect = styled.select`
  border: none;
  outline: none;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'Univers 55 Roman', 'Helvetica', sans-serif;
  font-size: 15px;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const StyledChevronDown = styled(SvgChevronDown)`
  position: absolute;
  right: 0;
  bottom: 1rem;
  transform: scale(0.8);
`;

export const StyledSelectPlaceholder = styled.span<{ invalid: any }>`
  ${({ invalid, theme }) => {
    return css`
      position: absolute;
      left: 0;
      color: ${invalid ? legacyTheme.colors.red : legacyTheme.colors.grey};
      pointer-events: none;
    `;
  }}
`;

import { FC, useEffect } from 'react';
import { Page } from 'core/page';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { useNotification } from 'context/notification.context';
import { useQuery } from 'react-query';
import { api, GET_PRODUCTS, Product } from 'services/api';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { useModal } from 'context/modal.context';
import { Card } from './components';
import { ThankYouModal } from './modals';

export const ProductsPage: FC = () => {
  const { hidePreloader, showPreloader } = useNotification();

  const { showModal } = useModal({
    template: <ThankYouModal />,
    variant: { lg: 'standard', md: 'fullscreen' },
    width: { lg: '74rem', md: 'unset' },
  });

  const productsQuery = useQuery(GET_PRODUCTS, () => api.product.getProducts());

  useEffect(() => {
    if (productsQuery.isFetching) {
      showPreloader();
    } else {
      hidePreloader();
    }
  }, [productsQuery.isFetching]);

  return (
    <Page>
      <Grid gap={{ lg: 's5', md: 's4' }}>
        <FlexContainer alignItems="center" justifyContent="space-between">
          <Typography text="Products" variant="h2" />

          <Button text="Edit Thank You" onClick={showModal} />
        </FlexContainer>

        <Grid columns={{ lg: 2, sm: 1 }}>
          {productsQuery.data?.data.map((product: Product) => (
            <Card key={product.id} product={product} />
          ))}
        </Grid>
      </Grid>
    </Page>
  );
};

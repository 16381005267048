import axios from 'axios';
import { userApiUrl } from 'helpers/auth';
import { ReEditDesignArgs, ReEditDesignResponse } from './re-edit-design.types';

export const reEditDesign = ({ id }: ReEditDesignArgs) => {
  return axios
    .put<ReEditDesignResponse>(`${userApiUrl}/designs/${id}/re-edit`)
    .then(({ data }) => {
      return data;
    });
};

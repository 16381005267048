import { useFetchDesign } from 'api/designs/routes/fetch-design';
import {
  allProductColorCodes,
  Grid,
  productColorsMap,
  Stack,
  Typography,
} from 'gantri-components';
import { useParams } from 'react-router-dom';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { ColorSwatchAndLabel } from 'components/color-swatch-and-label';
import { Form, Formik } from 'formik';
import { StepFooter } from 'src/pages/design-flow/components/step-footer';
import { sortBy } from 'lodash';
import { ModelStepHeader } from '../model-step-header';
import { useModelNavigation } from '../../hooks/use-model-navigation';
import { useOnUpdateModel } from '../../hooks/use-on-update-model';
import { maxColors, modelStepThreeFormSchema } from './model-step-three.schema';

export const ModelStepThree = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isApproved: isModelApproved } = getDesignStatusDetails(
    design?.model?.status,
  );

  const { onNavigateToPrev, onNavigateToSubmitModel } = useModelNavigation();

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToPrev,
  });

  const { onSubmit, processing: isSubmitting } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToSubmitModel,
  });

  return design ? (
    <Formik
      enableReinitialize
      initialValues={design.model.subSteps[3]}
      validateOnMount
      validationSchema={modelStepThreeFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, values }) => {
        const isDisabled = isModelApproved || values.colors.length >= maxColors;

        return (
          <Form>
            <Stack gap="5x">
              <ModelStepHeader design={design} showBackButton />

              <Typography
                text="Choose up to 3 colors for your design"
                variant="h3"
              />

              <Grid columns={{ lg: 2, sm: 1 }}>
                {allProductColorCodes
                  .filter((colorCode) => {
                    if (
                      design.userId !== 25342 &&
                      colorCode === productColorsMap.glossysnowwhite.code
                    ) {
                      return false;
                    }

                    return true;
                  })
                  .map((colorCode) => {
                    const isActive = values.colors.some(({ code }) => {
                      return code === colorCode;
                    });

                    return (
                      <ColorSwatchAndLabel
                        key={colorCode}
                        active={isActive}
                        colorCode={colorCode}
                        disabled={isDisabled && !isActive}
                        labelColor={
                          isDisabled && !isActive
                            ? 't2'
                            : isActive
                            ? 'link'
                            : 't1'
                        }
                        onSelect={async ({
                          code: colorCode,
                          isActive,
                          name,
                        }) => {
                          if (isActive) {
                            await setFieldValue(
                              'colors',
                              sortBy(
                                [
                                  ...values.colors,
                                  { code: colorCode, name, selected: isActive },
                                ],
                                ['name'],
                              ),
                            );
                          } else {
                            await setFieldValue(
                              'colors',
                              values.colors.filter(({ code }) => {
                                return code !== colorCode;
                              }),
                            );
                          }
                        }}
                      />
                    );
                  })}
              </Grid>

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

import styled from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledNotificationsManager = styled<any>(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
`;

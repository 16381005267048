import { generateMedia } from 'styled-media-query';

export const mediaBreakpoints = {
  // 1024px
  lg: '75em',

  // 641px
  md: '64em',
  sm: '40.06em',
};

export const media = generateMedia(mediaBreakpoints);

import {
  Stack,
  Typography,
  Conditional,
  Button,
  Icon,
  Grid,
  Image,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { StepHeaderPropsDef } from './step-header.types';

export const StepHeader = (props: StepHeaderPropsDef) => {
  const {
    children,
    design,
    question,
    showBackButton,
    stepNumber,
    stepType,
    totalStepNumber,
  } = props;

  const { isCompleted: isDesignCompleted } = getDesignStatusDetails(
    design?.status,
  );

  return (
    <Stack>
      <Conditional condition={showBackButton}>
        <Link to={routePaths.designFlowStepList(design.id)}>
          <Button
            icon={<Icon color="link" name="arrows:arrow_left" />}
            text="Back to Overview"
            variant="ghost"
          />
        </Link>
      </Conditional>

      <Stack gap="x" paddingBottom="5x">
        <Typography text={design.name} variant="h1" />
        <Typography
          color="t2"
          text={[design.category, design.size?.name].filter(Boolean).join(', ')}
        />
      </Stack>

      <Conditional condition={isDesignCompleted}>
        <Grid alignItems="center" columns="16rem 1fr" paddingBottom="5x">
          <Image
            alt={design.name}
            containerAspectRatio="1"
            source="absolute"
            src={
              design.concept?.subSteps?.[4]?.sketches?.front?.uploadedFile
                ?.fileUrl
            }
          />
          <Stack alignContent="center">
            <Typography text={`${design.name} is completed.`} variant="p1" />

            <Conditional condition={!!design.productId}>
              <Link to={`${routePaths.products}/${design.productId}`}>
                <Button justifySelf="start" size="large" text="View Product" />
              </Link>
            </Conditional>
          </Stack>
        </Grid>
      </Conditional>

      <Conditional condition={!!stepType}>
        <Typography
          letterSpacing="3px"
          text={`${stepType} ${stepNumber}/${totalStepNumber}`}
          variant="h6"
        />
      </Conditional>

      <Conditional condition={!!question}>
        <Typography text={question} />
      </Conditional>

      {children}
    </Stack>
  );
};

import { DesignDetails } from 'api/designs/routes/fetch-design/fetch-design.types';
import { useMemo } from 'react';
import { ChecklistReviewItem } from '../../../submit-concept/checklist-review-list/checklist-review-list.types';
import { engineeringStatuses } from './use-create-section-lists.constants';

export const useCreateSectionLists = (props: { design: DesignDetails }) => {
  const { design } = props;

  const createSectionList = (
    sectionKeyName: Exclude<keyof typeof engineeringStatuses, 'prototyping'>,
  ) => {
    return engineeringStatuses[sectionKeyName].sections.map(
      ({ keyName, title }) => {
        const item: ChecklistReviewItem = {
          approved: !!design?.engineering?.[sectionKeyName]?.[keyName],
          title: title ? String(title) : '',
        };

        return item;
      },
    );
  };

  const lists = useMemo(() => {
    return {
      finalization: createSectionList('finalization'),
      modelingOptimization: createSectionList('modelingOptimization'),
      qualityTesting: createSectionList('qualityTesting'),
    };
  }, [design?.id]);

  return lists;
};

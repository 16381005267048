import { FC } from 'react';
import { Thumbnail } from 'core/thumbnail';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';
import { Cell } from 'layout/cell';
import { formatCurrency } from 'utils/formaters';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Conditional, getFileUrl } from 'gantri-components';
import { StyledCardContainer, StyledCardContentGrid } from './card.styles';
import { CardProps } from './card.types';

export const Card: FC<CardProps> = ({ product }) => {
  const firstSku = product?.skuAssets
    ? Object.keys(product.skuAssets)?.[0]
    : undefined;
  const photo = product?.skuAssets
    ? Object.values(product.skuAssets)?.[0]?.whiteBackgroundPhotos?.[0]
    : undefined;

  return (
    <StyledCardContainer>
      <TypographyWithIcon
        icon={
          <Conditional condition={!!firstSku}>
            <Thumbnail
              objectFit="cover"
              size={{ lg: '8rem', sm: '6rem' }}
              src={getFileUrl<'products'>({
                directory: 'products',
                fileName: photo,
                fileType: 'product-photos',
                identifiers: { productId: product.id, sku: firstSku },
              })}
            />
          </Conditional>
        }
        text={product.name}
        variant="h4"
      />

      <StyledCardContentGrid
        columns={2}
        gap="unset"
        justifyContent="space-between"
        verticalMargin={{ lg: 's5', md: 's4', sm: 's3' }}
      >
        <Cell>
          <div>
            {['In preparation', 'Ready'].includes(product.status) && (
              <Typography text={product.status} />
            )}

            {['Active', 'Available'].includes(product.status) && (
              <Typography color="primary" text={product.status} />
            )}

            {['Off Market'].includes(product.status) && (
              <Typography
                color="primary"
                text={`Off market ${formatDate(product.offMarketAt)}`}
              />
            )}
          </div>
        </Cell>
        <Cell />

        <Cell>
          <Typography color="grey" text="Units" />
        </Cell>
        <Cell>
          <Typography align="right" color="grey" text={product.topline.units} />
        </Cell>

        <Cell>
          <Typography color="grey" text="Sales" />
        </Cell>
        <Cell>
          <Typography
            align="right"
            color="grey"
            text={formatCurrency(product.topline.sales)}
          />
        </Cell>

        <Cell>
          <Typography color="grey" text="Earnings" />
        </Cell>
        <Cell>
          <Typography
            align="right"
            color="grey"
            text={formatCurrency(product.topline.earning)}
          />
        </Cell>
      </StyledCardContentGrid>

      <FlexContainer alignItems="center" justifyContent="space-between">
        {(product.status === 'In preparation' ||
          product.status === 'Ready') && (
          <Button
            linkHref={`/products/${product.id}`}
            minWidth="12rem"
            text="Launch"
          />
        )}

        {product.status !== 'In preparation' && product.status !== 'Ready' && (
          <Button
            linkHref={`/products/${product.id}`}
            minWidth="12rem"
            text="View"
            variant="secondary"
          />
        )}

        {product.launchedAtSec && (
          <Typography
            color="grey"
            text={`Launched ${formatDate(product.launchedAtSec)}`}
            variant="p3"
          />
        )}
      </FlexContainer>
    </StyledCardContainer>
  );
};

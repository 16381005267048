import { ImageContainerProps } from './image-container.props';

export const ImageContainerPresets: ImageContainerProps = {
  alt: '',
  animateLoading: true,
  backgroundColor: 'transparent',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100%',
  imageHeight: '100%',
  objectFit: 'cover',
  observerMargin: '0px 0px 200px 0px',
  renderMode: 'viewport',
  source: 'local',
  src: '',
};

import {
  DesignStatus,
  designStatuses,
} from 'api/designs/routes/fetch-designs/fetch-designs.types';

export const getStepButtonText = (props: {
  status: DesignStatus;
  stepProgress: number;
}) => {
  const { status, stepProgress } = props;

  if (status === designStatuses.waiting && !!stepProgress) {
    return 'Continue';
  }

  switch (status) {
    case designStatuses.inProgress:
    case designStatuses.inprogress:
    case designStatuses.returned:
      return 'Edit Design';
    case designStatuses.submitted:
    case designStatuses.approved:
      return 'View';
    default:
      return 'Start';
  }
};

import { FloatingContainerProps } from 'layout/floating-container/floating-container.props';
import { FloatingContainerPresets } from 'layout/floating-container/floating-container.presets';
import { forwardRef } from 'react';
import { StyledFloatingContainer } from 'layout/floating-container/floating-container.styles';
import { ClickAwareContainer } from 'layout/click-aware-container';

export const FloatingContainer = forwardRef(
  (props: FloatingContainerProps, ref: any) => {
    const {
      bottom,
      children,
      left,
      onClickOutside,
      right,
      top,
      visible,
      ...other
    } = { ...FloatingContainerPresets, ...props };
    return (
      <StyledFloatingContainer
        ref={ref}
        bottom={bottom}
        left={left}
        right={right}
        top={top}
        {...other}
      >
        {onClickOutside ? (
          <ClickAwareContainer
            visible={visible}
            onClickOutside={onClickOutside}
          >
            {children}
          </ClickAwareContainer>
        ) : (
          children
        )}
      </StyledFloatingContainer>
    );
  },
);

import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledPeriodContainer = styled.div`
  display: grid;

  ${media.lessThan('md')`
    justify-content: center;
    justify-items: center;
  `}
`;

export const StyledChartContainer = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px solid ${legacyTheme.colors.lightGrey};
      border-radius: ${theme.borderRadius};
      padding: ${legacyTheme.spacing.s3};

      ${media.lessThan('md')`
      padding: ${legacyTheme.spacing.s2};
    `}
    `;
  }}
`;

export const StyledChartLegend = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 1rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

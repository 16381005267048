import { FC, useState } from 'react';
import { Grid } from 'layout/grid';
import { TextInput } from 'core/text-input';
import { FiltersDropdown } from 'core/filters-dropdown';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Checkbox } from 'core/checkbox';
import { useMultipleSelectionState } from 'utils/hooks';
import { Icon } from 'core/icon';
import { DownloadsFiltersPresets } from './downloads-filters.presets';
import { DownloadsFiltersProps } from './downloads-filters.types';

const types = ['component', 'helper', 'design'];

export const DownloadsFilters: FC<DownloadsFiltersProps> = props => {
  const { count, onApply, onReset } = {
    ...DownloadsFiltersPresets,
    ...props,
  };

  const [search, setSearch] = useState('');

  const { isPropertySelected, resetSelection, selection, setProperty } =
    useMultipleSelectionState();

  const handleReset = () => {
    resetSelection();
    onReset();
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    onApply({ search: value, types: selection });
  };

  return (
    <Grid columns={{ lg: 'repeat(2, 24rem)', md: 'repeat(2, 1fr)' }}>
      <FiltersDropdown
        count={count}
        onApply={() => onApply({ search, types: selection })}
        onReset={handleReset}
      >
        <Typography color="grey" text="TYPE" variant="h5" />

        <StackedContainer marginTop="1rem" padding="unset">
          {types.map(type => (
            <Checkbox
              key={type}
              checked={isPropertySelected(type)}
              labelText={type}
              labelTransform="capitalize"
              onChange={(value: boolean) => setProperty(type, value)}
            />
          ))}
        </StackedContainer>
      </FiltersDropdown>

      <TextInput
        clearable
        paddingLeft="3rem"
        placeholder="Search"
        prefixComponent={<Icon name="search" />}
        value={search}
        onTextChange={handleSearchChange}
      />
    </Grid>
  );
};

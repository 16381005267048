import { Cell, Conditional, Grid } from 'gantri-components';
import { useFormikContext } from 'formik';
import { UploaderSectionHeader } from '../../../../../../components/uploader-section-header';
import { ConceptStepTwoFormDef } from '../../concept-step-two.types';
import { ExistingInteriorUploader } from '../existing-interior-uploader';
import { NewInteriorUploader } from '../new-interior-uploader';
import { ConceptInteriorsSectionPropsDef } from './concept-interiors-section.types';

export const ConceptInteriorsSection = (
  props: ConceptInteriorsSectionPropsDef,
) => {
  const { isDisabled } = props;

  const expectedExtensions = ['jpg', 'png', 'gif', 'pdf'];
  const maxFileSizeMB = 10;

  const { values } = useFormikContext<ConceptStepTwoFormDef>();

  const numPlaceholderInteriors = values.interiorUrls.filter(({ fileUrl }) => {
    return !fileUrl;
  }).length;

  return (
    <Cell>
      <UploaderSectionHeader
        description="Upload at least 4 photos of interiors that you're designing for. (e.g. scandinavian, wabi sabi, hygge)"
        expectedExtensions={expectedExtensions}
        label="What kind of interior will your design fit into?"
        maxFileSizeMB={maxFileSizeMB}
        tooltipSrc="graphite/design-flow/example-interior_2x.jpg"
      />

      <Grid columns={{ lg: 'repeat(2, max-content)', sm: 1 }}>
        {values.interiorUrls.map(({ fileName, fileUrl }, index) => {
          return (
            <ExistingInteriorUploader
              key={fileUrl}
              expectedExtensions={expectedExtensions}
              fileName={fileName}
              fileUrl={fileUrl}
              index={index}
              isDisabled={isDisabled}
              maxFileSizeMB={maxFileSizeMB}
            />
          );
        })}
        <Conditional condition={!isDisabled && !numPlaceholderInteriors}>
          <NewInteriorUploader
            expectedExtensions={expectedExtensions}
            maxFileSizeMB={maxFileSizeMB}
          />
        </Conditional>
      </Grid>
    </Cell>
  );
};

import { FC } from 'react';
import { Button } from 'core/button';
import { Form, Formik } from 'formik';
import { Grid } from 'layout/grid';
import { TextInput } from 'core/text-input';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { UrlFormProps } from './url-form.types';
import { UrlFormSchema } from './url-form.schema';

export const UrlForm: FC<UrlFormProps> = props => {
  const { onUrlFormSubmit, profileLink } = props;

  const handleSubmit = values => {
    onUrlFormSubmit(values.profileLink);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        profileLink,
      }}
      validateOnMount
      validationSchema={UrlFormSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form autoComplete="off" style={{ width: '100%' }}>
          <Grid
            alignContent="center"
            backgroundColor={{ md: 'white' }}
            gap="s4"
            height="100%"
            padding="unset"
            width="100%"
          >
            <StackedContainer flow="column" marginTop="s1" padding="unset">
              <Typography text="gantri.com/" textStyle="bold" />
              <TextInput
                error={props.errors.profileLink as string}
                name="profileLink"
                touched={props.touched.profileLink as boolean}
                value={props.values.profileLink}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />
            </StackedContainer>

            <Button
              disabled={!props.isValid}
              text="Done"
              type="submit"
              variant="primary"
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

import { FlexContainerProps } from './flex-container.props';

export const FlexContainerPresets: FlexContainerProps = {
  alignItems: 'unset',

  borderRadius: 'unset',

  direction: 'row',

  height: 'unset',

  justifyContent: 'unset',

  padding: 'unset',
};

import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import {
  GetAllRequest,
  GetAllResponse,
} from 'services/api/downloads/downloads-api.types';

export class DownloadsApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/user/downloads');
  }

  async fetchAll(request: GetAllRequest): Promise<GetAllResponse> {
    const response: ApiResponse<GetAllResponse> = await this.api.post(
      `${this.path}`,
      request,
    );
    return this.handleResponse<GetAllResponse>(response);
  }
}

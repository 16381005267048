import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useSpinner } from '../use-spinner';
import { PredicateFn, UseQueryFetchProps } from './react-queries.types';

export const useQueryFetch = <T>(props?: UseQueryFetchProps<T>) => {
  const {
    config,
    showLoading: enableSpinner,
    transformDependencies = [],
  } = props;
  const { hideLoading, showLoading } = useSpinner();

  const { select } = config;
  const { isFetching, isInitialLoading, ...context } = useQuery({
    ...config,
    select: useCallback(
      !!select
        ? select
        : (response) => {
            return response;
          },
      transformDependencies,
    ),
  });

  const isLoading = isFetching || isInitialLoading;

  useEffect(() => {
    if (enableSpinner) {
      if (isLoading) {
        showLoading();
      } else {
        hideLoading();
      }
    }
  }, [isLoading]);

  return { ...context, isLoading };
};

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  return async (queryKey: QueryKey) => {
    await queryClient.invalidateQueries({ queryKey });
  };
};

export const useInvalidateQueryByPredicate = () => {
  const queryClient = useQueryClient();

  return async (predicate: PredicateFn) => {
    await queryClient.invalidateQueries({
      predicate: (query) => {
        return predicate(query);
      },
    });
  };
};

export const useGetInvalidateQueryCache = <
  RequestArgs = Record<any, any>,
>(props: {
  groupKey: () => string;
  key: (args: RequestArgs) => Readonly<[{ groupKey: string; key: string }]>;
}) => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateCache = async (argsToInvalidate?: RequestArgs) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!argsToInvalidate
        ? key === props.key(argsToInvalidate)[0].key
        : groupKey === props.groupKey();
    });
  };

  return invalidateCache;
};

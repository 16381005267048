import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledBox = styled(Box)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.alert.t1};
      border: 1px solid ${theme.colors.dividers.alert};
      border-radius: ${theme.dimensions.spacing['.5x']};
    `;
  }}
`;

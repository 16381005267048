import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDownload = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 14v2H0v-2h16zM9.066 0v7.9l2.868-2.868 1.414 1.414-5.282 5.282-5.282-5.282 1.414-1.414L7.066 7.9V0h2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgDownload;

import { Conditional, Button } from 'gantri-components';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { useQuery } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN } from 'constants/common';
import { ApplyOrSignInPropsDef } from './apply-or-sign-in.types';

export const ApplyOrSignIn = (props: ApplyOrSignInPropsDef) => {
  const { applyText = 'Apply', variant } = props;

  const [cookies] = useCookies([AUTH_TOKEN]);

  const query = useQuery<UserData, { message: string }>(
    GET_USER,
    api.auth.getLogged,
    {
      enabled: !!cookies[AUTH_TOKEN],
      refetchOnWindowFocus: false,
    },
  );

  const isDesigner = query?.data?.data?.isDesigner;

  return (
    <Conditional
      condition={isDesigner}
      Fallback={
        <Link
          rel="noreferrer"
          target="_blank"
          to="https://gantri.typeform.com/to/rrDD10GF"
        >
          <Button
            justifySelf="center"
            size="large"
            text={applyText}
            variant={variant}
          />
        </Link>
      }
    >
      <Link to={routePaths.dashboard}>
        <Button
          justifySelf="center"
          size="large"
          text="Create Hub"
          variant={variant}
        />
      </Link>
    </Conditional>
  );
};

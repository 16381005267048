import { useNavigate, useParams } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { ConceptStepPageParams } from '../../concept-step.types';

export const useConceptNavigation = () => {
  const { id, stepNumber } = useParams<ConceptStepPageParams>();

  const stepNumberValue = Number(stepNumber);

  const navigate = useNavigate();

  const onNavigateToConceptStep = (stepNumber: number) => {
    navigate(
      routePaths.designFlowConceptStep({
        id,
        stepNumber,
      }),
    );
  };

  const onNavigateToPrev = () => {
    onNavigateToConceptStep(stepNumberValue - 1);
  };

  const onNavigateToNext = () => {
    onNavigateToConceptStep(stepNumberValue + 1);
  };

  const onNavigateToStepList = () => {
    navigate(routePaths.designFlowStepList(id));
  };

  const onNavigateToSubmitConcept = () => {
    navigate(routePaths.designFlowSubmitConcept(id));
  };

  const onNavigateToDesigns = () => {
    navigate(routePaths.designs);
  };

  return {
    onNavigateToPrev,
    onNavigateToConceptStep,
    onNavigateToDesigns,
    onNavigateToNext,
    onNavigateToStepList,
    onNavigateToSubmitConcept,
  };
};

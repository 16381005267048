import { environment } from 'config/environment';
import { User } from 'types/user.type';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/storage';

export const loadAnalytics = async () => {
  try {
    return await new Promise(resolve => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const analytics = (window.analytics = window.analytics || []);
      if (!analytics.initialize)
        if (analytics.invoked)
          window.console &&
            console.error &&
            console.error('Segment snippet included twice.');
        else {
          analytics.invoked = !0;
          analytics.methods = [
            'trackSubmit',
            'trackClick',
            'trackLink',
            'trackForm',
            'pageview',
            'identify',
            'reset',
            'group',
            'track',
            'ready',
            'alias',
            'debug',
            'page',
            'once',
            'off',
            'on',
            'addSourceMiddleware',
            'addIntegrationMiddleware',
            'setAnonymousId',
            'addDestinationMiddleware',
          ];
          analytics.factory = function (e) {
            return function () {
              // eslint-disable-next-line prefer-rest-params
              const t = Array.prototype.slice.call(arguments);
              t.unshift(e);
              analytics.push(t);
              return analytics;
            };
          };
          for (let e = 0; e < analytics.methods.length; e++) {
            const key = analytics.methods[e];
            analytics[key] = analytics.factory(key);
          }
          analytics.load = function (key, e) {
            const t = document.createElement('script');
            t.type = 'text/javascript';
            t.async = !0;
            t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
            const n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(t, n);
            analytics._loadOptions = e;

            resolve(true);
          };
          analytics.SNIPPET_VERSION = '4.13.2';
          analytics.load(environment.SEGMENT_KEY);
          analytics.page();
        }
    });
  } catch (error) {
    console.error(error);
  }
};

export const getAnalyticsUserId = () => {
  try {
    let userIdentifier = getFromLocalStorage('userIdentifier');

    if (userIdentifier) {
      return userIdentifier;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userIdentifier = window.analytics.user().anonymousId();
    saveToLocalStorage('userIdentifier', userIdentifier);
    return userIdentifier;
  } catch (e) {
    return '';
  }
};

export const identifyAnalyticsUser = (user: User) => {
  if (user) {
    let userType = 'user';

    if (user.isAdmin) {
      userType = 'admin';
    } else if (user.isDesigner) {
      userType = 'designer';
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.identify(user.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      type: userType,
    });
  }
};

export const resetAnalyticsUser = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.analytics.reset();
};

export const trackAnalyticsEvent = (event: string, properties?: any) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.analytics.track(event, properties);
  } catch (error) {
    console.error(error);
  }
};

export interface AnalyticsPageData {
  path?: string;
  referrer?: string;
  title?: string;
  url?: string;
}

import {
  Grid,
  Stack,
  Typography,
  Conditional,
  Flex,
  Icon,
  Button,
  Box,
  LinearProgress,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { routePaths } from 'src/app-routes';
import { Link } from 'react-router-dom';
import { getDesignStatusDetails } from '../../../../helpers/get-design-status-details';
import { StepItemContentPropsDef } from './step-item-content.types';

export const StepItemContent = (props: StepItemContentPropsDef) => {
  const { design } = props;

  const { content, engineering } = design;

  const { isCompleted: isEngineeringCompleted } = getDesignStatusDetails(
    engineering.status,
  );

  const {
    isApproved: isContentApproved,
    isCompleted: isContentCompleted,
    isReturned: isContentReturned,
    isSubmitted: isContentSubmitted,
    isWaiting: isContentWaiting,
  } = getDesignStatusDetails(content.status);

  return (
    <Grid columns="1fr max-content" gap="3x">
      <Stack gap="3x">
        <Stack gap="x">
          <Typography text="Step 4: Content" variant="h6" />
          <Typography text="We prepare your design for sale" />
          <Conditional condition={isContentWaiting}>
            <Typography color="t2" text={content.status} />
          </Conditional>

          <Conditional condition={isContentCompleted}>
            <Flex alignItems="center" gap=".5x">
              <Icon
                color="link"
                name="ui-control:check_mark_circle_filled_24"
                size="2.4rem"
              />
              <Typography
                color="link"
                text={`${content.status} ${formatDate(content.completedAt)}`}
              />
            </Flex>
          </Conditional>
        </Stack>

        <Flex alignItems="center" gap="3x">
          <Conditional
            condition={
              !!content.stepProgress &&
              !isContentApproved &&
              !isContentCompleted &&
              !isContentReturned &&
              !isContentSubmitted &&
              !isContentWaiting
            }
          >
            <Box width="16rem">
              <LinearProgress
                current={content.stepProgress}
                showText
                total={100}
              />
            </Box>
          </Conditional>

          <Conditional condition={isEngineeringCompleted}>
            <Link to={routePaths.designFlowContent(design.id)}>
              <Button
                justifySelf="start"
                size="large"
                text={isContentCompleted ? 'View' : 'See Progress'}
                variant={isContentCompleted ? 'secondary' : 'primary'}
              />
            </Link>
          </Conditional>
        </Flex>
      </Stack>
    </Grid>
  );
};

import { Stack, Typography, Flex, Tooltip, Image } from 'gantri-components';
import { UploaderSectionHeaderPropsDef } from './uploader-section-header.types';

export const UploaderSectionHeader = (props: UploaderSectionHeaderPropsDef) => {
  const { description, expectedExtensions, label, maxFileSizeMB, tooltipSrc } =
    props;

  return (
    <Stack>
      <Stack gap=".5x">
        <Typography text={label} variant="h6" />
        <Typography color="t2" text={description} variant="p3" />
      </Stack>

      <Flex gap=".5x" paddingBottom="2x">
        <Typography
          color="t2"
          text={expectedExtensions.join(', ').toUpperCase()}
          textStyle="bold"
          variant="p3"
        />
        <Typography
          color="t2"
          text={`Max file size ${maxFileSizeMB} MB`}
          variant="p3"
        />
        <Tooltip
          Component={
            <Image
              alt="Example"
              height="20rem"
              source="static"
              src={tooltipSrc}
              width="20rem"
            />
          }
          position="right"
        >
          <Typography
            color="link"
            text="Example"
            textStyle="bold"
            variant="p3"
          />
        </Tooltip>
      </Flex>
    </Stack>
  );
};

import { Stack, Typography } from 'gantri-components';
import { ApplyOrSignIn } from '../apply-or-sign-in';
import { StyledApplyStack } from './apply-section.styles';
import { sectionVerticalPadding } from '../../home.constants';

export const ApplySection = () => {
  return (
    <StyledApplyStack
      horizontalPadding="2x"
      justifyContent="center"
      justifyItems="center"
      verticalPadding={sectionVerticalPadding}
    >
      <Stack
        alignContent="center"
        gap="5x"
        justifyItems="center"
        maxWidth="78rem"
      >
        <Typography
          align="center"
          color="alt"
          text="Apply today"
          variant="h2"
        />

        <Typography
          align="center"
          color="alt"
          text="We're looking to collaborate with talented, ambitious independent designers and brands from anywhere in the world. In particular, we look for designers with a proven track record of designing home furnishing and lighting, or designers with a large existing audience. We review every application in order that it comes in and will reach out if you're a good fit. Due to the large number of applications, we're not able to always provide individual feedback."
        />

        <ApplyOrSignIn applyText="Apply Now" variant="primaryContrast" />
      </Stack>
    </StyledApplyStack>
  );
};

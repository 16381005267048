import styled, { css } from 'styled-components';
import { RoundButtonProps } from 'core/round-button/round-button.props';

export const StyledRoundButton = styled.button<RoundButtonProps>`
  ${({ color, disabled, opacity, size, theme }) => css`
    width: ${size};
    height: ${size};
    background-color: ${theme.colors[color] ?? color};
    border: unset;
    border-radius: 50%;
    cursor: pointer;
    transition: ${theme.transitions.base};
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    outline: none;
    opacity: ${disabled ? 0.5 : opacity ?? 'unset'};
    pointer-events: ${disabled ? 'none' : 'all'};

    &:hover {
      transform: scale(1.05);
    }
  `}
`;

import styled, { css } from 'styled-components';
import { legacyTheme } from 'styles/theme';

export const StyledHeading = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(2, 1fr) max-content;
      margin-bottom: ${legacyTheme.spacing.s5};
    `;
  }}
`;

export const StyledHeadingElement = styled.div`
  ${({ theme }) => {
    return css`
      cursor: pointer;

      &:not(:last-child) {
        border-right: 1px solid ${legacyTheme.colors.lightGrey};
      }

      &:not(:first-child) {
        padding-left: ${legacyTheme.spacing.s2};
      }
    `;
  }}
`;

import { range } from 'lodash';
import { useMemo } from 'react';
import { ConceptStepTwoFormDef } from '../../concept-step-two.types';
import { emptyFileDetails, minInteriors } from '../../concept-step-two.schema';
import { UseConceptStepTwoInitialValuesPropsDef } from './use-concept-step-two-initial-values.types';

export const useConceptStepTwoInitialValues = (
  props: UseConceptStepTwoInitialValuesPropsDef,
) => {
  const { design } = props;

  const savedValues = design?.concept?.subSteps?.[5];

  const initialValues = useMemo(() => {
    const savedInteriorUrls =
      savedValues?.interiorUrls?.filter(({ fileUrl }) => {
        return fileUrl;
      }) || [];

    const numInteriors = savedInteriorUrls?.length || 0;

    const placeholderInteriors =
      numInteriors < minInteriors
        ? range(numInteriors, minInteriors).map(() => {
            return emptyFileDetails;
          })
        : [];

    const initialInteriorUrls = [...savedInteriorUrls, ...placeholderInteriors];

    const initialValues: ConceptStepTwoFormDef = design
      ? {
          ...savedValues,
          interiorUrls: initialInteriorUrls,
        }
      : {
          interiorUrls: [],
          rooms: [],
          targetUser: undefined,
          userNeeds: undefined,
        };

    return initialValues;
  }, [savedValues]);

  return initialValues;
};

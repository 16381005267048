import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgNext = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      fillRule="evenodd"
      height="1em"
      viewBox="0 0 32 32"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} fill={color} fillRule="nonzero" r={16} />
      <path
        d="M12.604 22.638L14.068 24l7.298-7.842-7.298-7.84-1.464 1.363 6.03 6.477z"
        fill="#fff"
      />
    </svg>
  );
});
export default SvgNext;

import { createElement, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TypographyProps } from './typography.props';
import { TypographyPresets } from './typography.presets';
import { StyledText } from './typography.styles';

const variantToType = {
  badge: 'span',
  p1: 'p',
  p2: 'p',
  p3: 'p',
};

export const Typography = forwardRef((props: TypographyProps, ref: any) => {
  const {
    color,
    href,
    hrefAs,
    htmlText,
    linkAnalyticsEvent,
    passHref,
    prefetch,
    target,
    text,
    textStyle,
    tx,
    txValues,
    type,
    variant,
    ...others
  } = { ...TypographyPresets, ...props };

  const { t } = useTranslation();

  if (href && variant === 'link') {
    return createElement(href.startsWith('http') ? 'a' : Link, {
      children: (
        <StyledText
          color={color ?? 'primary'}
          textStyle={textStyle ?? 'bold'}
          variant={variant}
          {...others}
        >
          {tx ? t(tx, txValues) : text}
        </StyledText>
      ),
      // @ts-expect-error
      href,
      to: href,
    });
  }

  if (htmlText) {
    return (
      <StyledText
        ref={ref}
        as={type ?? variantToType[variant || ''] ?? variant}
        color={color ?? 'black'}
        dangerouslySetInnerHTML={{ __html: htmlText }}
        textStyle={textStyle}
        variant={variant}
        {...others}
      />
    );
  }

  return (
    <StyledText
      ref={ref}
      as={type ?? variantToType[variant || ''] ?? variant}
      color={color ?? 'black'}
      textStyle={textStyle}
      variant={variant}
      {...others}
    >
      {!!tx ? (
        <Trans t={t} values={txValues}>
          {tx}
        </Trans>
      ) : (
        text
      )}
    </StyledText>
  );
});

import {
  useInvalidateFetchDesignsCache,
  useDeleteDesign,
} from 'api/designs/routes';
import { Modal, Button, Stack, Typography } from 'gantri-components';
import { ArchiveDesignModalPropsDef } from './archive-design-modal.types';

export const ArchiveDesignModal = (props: ArchiveDesignModalPropsDef) => {
  const { id, onClose } = props;

  const { invalidateFetchDesignsCache } = useInvalidateFetchDesignsCache();

  const { isLoading, onDeleteDesign } = useDeleteDesign({
    onSuccess: async () => {
      await invalidateFetchDesignsCache();
      onClose();
    },
  });

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Confirm"
            onClick={async () => {
              await onDeleteDesign({ id });
            }}
          />
        </>
      }
      header="Archive"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack alignContent="center" minHeight="8rem">
        <Typography
          align="center"
          color="t2"
          text="Are you sure you want to archive this design?"
        />
      </Stack>
    </Modal>
  );
};

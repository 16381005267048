import styled, { css } from 'styled-components';
import { legacyTheme } from 'styles/theme';

export const StyledNotification = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      padding-left: 2rem;
      cursor: default;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 1rem;
        width: 6px;
        height: 6px;
        background: ${legacyTheme.colors.grey};
        border-radius: 50%;
      }
    `;
  }}
`;

export const StyledNotificationDateRow = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      column-gap: 2rem;
      grid-template-columns: repeat(2, max-content);

      & > *:last-child:not(:first-child) {
        position: relative;

        &:before {
          display: block;
          position: absolute;
          content: '';
          left: -1rem;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 1.2rem;
          width: 1px;
          background: ${legacyTheme.colors.lightGrey};
        }
      }
    `;
  }}
`;

import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { UseQueryMutationProps } from './use-query-mutation.types';
import { useSpinner } from '../use-spinner';

export const useQueryMutation = <
  TVariables,
  TResponse = Record<string, unknown>,
>(
  props: UseQueryMutationProps<TVariables, TResponse>,
) => {
  const { config, mutationFn, showLoading: enableSpinner } = props;
  const { hideLoading, showLoading } = useSpinner();
  const { isLoading, mutateAsync } = useMutation(
    mutationFn,
    omit(config, ['mutationFn']),
  );

  useEffect(() => {
    if (enableSpinner) {
      if (isLoading) {
        showLoading();
      } else {
        hideLoading();
      }
    }
  }, [isLoading]);

  return { isLoading, onMutate: mutateAsync };
};

import { getFileUrl } from 'gantri-components';

export const getProductPhotoUrl = (
  fileType:
    | 'dimming-photos'
    | 'product-photos'
    | 'lifestyle-photos'
    | 'scale-photo',
  productId: number,
  sku: string,
  fileName: string,
) =>
  // @ts-expect-error
  getFileUrl<'products'>({
    directory: 'products',
    fileName,
    fileType,
    identifiers: { productId, sku },
  });

import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';

export const StyledSelectContainer = styled.div<{ invalid: boolean }>`
  ${({ invalid, theme }) => {
    return css`
      border-bottom: 1px solid
        ${invalid ? legacyTheme.colors.red : legacyTheme.colors.lightGrey};
      padding-bottom: 5px;
      position: relative;
      max-height: 10.6rem;
      height: 3rem;
      display: grid;
      grid-template-columns: 1fr 1rem;
      align-items: center;
    `;
  }}
`;

export const StyledValueContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

import { Conditional, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Page } from 'core/page';
import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { range } from 'lodash';
import { formatDate } from 'utils/date';
import { ChecklistReviewList } from './checklist-review-list';
import { useConceptNavigation } from '../concept-step/hooks/use-concept-navigation';
import { StepHeader } from '../../components/step-header';
import { getDesignStatusDetails } from '../../helpers/get-design-status-details';
import { StepFooter } from '../../components/step-footer';
import { useOnUpdateConcept } from '../concept-step/hooks/use-on-update-concept';
import { HeaderSubmissionDetails } from '../../components/step-header/components/header-submission-details';

export const SubmitConceptPage = () => {
  const { id } = useParams<{ id: string }>();

  const { onNavigateToConceptStep, onNavigateToDesigns } =
    useConceptNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isApproved, isSubmitted } = getDesignStatusDetails(
    design?.concept?.status,
  );

  const { onSubmit, processing: isSubmitting } = useOnUpdateConcept({
    design,
    isApproved,
    isFinalSubmission: true,
    onNavigateTo: onNavigateToDesigns,
  });

  const conceptReviewSteps = data?.options?.reviewSteps?.concept;

  return (
    <Page>
      <SimpleErrorBoundary>
        {design && (
          <Stack gap="5x" maxWidth="92rem">
            <StepHeader design={design} showBackButton>
              <Conditional condition={isSubmitted}>
                <HeaderSubmissionDetails
                  imgProps={{ src: 'graphite/design-flow/icon-review_2x.png' }}
                  titleProps={{ text: "We're reviewing your concept" }}
                />
              </Conditional>

              <Conditional condition={isApproved}>
                <HeaderSubmissionDetails
                  descriptionProps={{
                    color: 'link',
                    text: `Approved ${formatDate(design.concept.approvedAt)}`,
                  }}
                  imgProps={{ src: 'graphite/design-flow/icon-approve_2x.png' }}
                  titleProps={{ text: 'Your concept is approved' }}
                />
              </Conditional>

              <Conditional condition={!isSubmitted && !isApproved}>
                <HeaderSubmissionDetails
                  descriptionProps={{
                    text: 'We will review it against the following criteria:',
                  }}
                  imgProps={{ src: 'graphite/design-flow/icon-ready_2x.png' }}
                  titleProps={{ text: 'Ready to submit your design?' }}
                />
              </Conditional>
            </StepHeader>

            {range(1, 5).map((stepNumber) => {
              return (
                <ChecklistReviewList
                  key={stepNumber}
                  isApproved={isApproved}
                  isPending={isSubmitted}
                  list={conceptReviewSteps?.[stepNumber]?.sections}
                  showApprovedStatus={isApproved}
                  title={conceptReviewSteps?.[stepNumber]?.header}
                />
              );
            })}

            <Conditional condition={!isSubmitted && !isApproved}>
              <StepFooter
                backButtonProps={{
                  onClick: () => {
                    onNavigateToConceptStep(3);
                  },
                }}
                nextButtonProps={{
                  onClick: async () => {
                    await onSubmit();
                  },
                  processing: isSubmitting,
                  text: 'Submit',
                }}
              />
            </Conditional>
          </Stack>
        )}
      </SimpleErrorBoundary>
    </Page>
  );
};

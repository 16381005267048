import { DesignDetails } from 'api/designs/routes/fetch-design/fetch-design.types';
import { designStatuses } from 'api/designs/routes/fetch-designs/fetch-designs.types';

export const getStepListTitle = (design: DesignDetails) => {
  if (!design.concept.completedAt) {
    return 'Get started with your concept.';
  }

  if (
    design.concept.status === designStatuses.submitted &&
    !design.model.completedAt
  ) {
    return "We're reviewing your concept";
  }

  if (design.concept.status === designStatuses.returned) {
    return 'Your concept needs more work';
  }

  if (design.model.status === designStatuses.returned) {
    return 'Your model needs more work';
  }

  if (design.concept.completedAt && !design.model.completedAt) {
    return 'Get started with your model.';
  }

  if (design.model.status === designStatuses.submitted) {
    return "We're reviewing your model.";
  }

  if (!design.engineering.completedAt) {
    return "We're optimizing your design";
  }

  if (!design.content.completedAt) {
    return "We're preparing your design for sale";
  }

  if (!!design.productId) {
    return 'Overview';
  }

  return '';
};

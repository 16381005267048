import { createContext, Dispatch, useContext, ReactNode } from 'react';
import { useImmerReducer } from 'use-immer';
import { Address, Payment, User } from 'types/user.type';
import { CampaignInfo } from 'types/auth.type';

export interface AuthState {
  authenticated: boolean;
  campaignInfo?: CampaignInfo;
  currentEmail?: string;
  emailSent?: boolean;
  firebaseToken?: string;
  resetLoginData?: () => void;
  status?: 'FETCHING' | 'AUTHENTICATED' | 'NOT AUTHENTICATED';
  token?: string;
  user?: User;
  userExists?: boolean;
}

const initialState: AuthState = {
  authenticated: false,
  campaignInfo: {
    browserUserId: '',
    source: '',
    user_agent: '',
    utm_campaign: '',
    utm_content: '',
    utm_medium: '',
    utm_source: '',
  },
  status: 'FETCHING',
};

type Action =
  | { data: Partial<AuthState>; type: 'SET_PROPERTIES' }
  | { address: Address; type: 'ADD_USER_ADDRESS' }
  | { address: Address; type: 'UPDATE_USER_ADDRESS' }
  | { addresses: Address[]; type: 'REPLACE_USER_ADDRESSES' }
  | { payments: Payment[]; type: 'UPDATE_USER_PAYMENTS' }
  | { id: string; type: 'UPDATE_BROWSER_ID' }
  | { type: 'RESET_LOGIN_DATA' }
  | { list: number[]; type: 'UPDATE_USER_FAVORITES_LIST' }
  | { type: 'RESET_USER_EXISTS' };

function authReducer(draft: AuthState, action: Action) {
  switch (action.type) {
    case 'SET_PROPERTIES':
      draft = { ...draft, ...action.data };
      return draft;
    case 'ADD_USER_ADDRESS':
      if (draft.user) {
        draft.user.addresses = [...draft.user.addresses, action.address];
      }
      return draft;
    case 'UPDATE_USER_ADDRESS':
      if (draft.user) {
        const addressIndex = draft.user.addresses.findIndex(
          item => item.id === action.address.id,
        );

        if (addressIndex > -1) {
          draft.user.addresses[addressIndex] = action.address;
        } else {
          draft.user.addresses = [...draft.user.addresses, action.address];
        }
      }
      return draft;
    case 'REPLACE_USER_ADDRESSES':
      if (draft.user) {
        draft.user.addresses = action.addresses;
      }
      return draft;
    case 'UPDATE_USER_PAYMENTS':
      if (draft.user) {
        draft.user.payments = action.payments || [];
      }
      return draft;
    case 'UPDATE_BROWSER_ID':
      if (draft.campaignInfo) {
        draft.campaignInfo.browserUserId = action.id;
      }
      return draft;
    case 'UPDATE_USER_FAVORITES_LIST':
      draft.user.favoriteList = action.list;
      return draft;
    case 'RESET_USER_EXISTS':
      draft.userExists = false;
      draft.currentEmail = '';
      return draft;
  }
}

const AuthStateContext = createContext<AuthState>(initialState);

const AuthDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const AuthProvider = (props: {
  children: ReactNode;
  value?: typeof initialState;
}) => {
  const { children, value } = props;

  const [state, dispatch] = useImmerReducer(authReducer, {
    ...initialState,
    ...value,
  });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export function useAuthState(): AuthState {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used inside an AuthProvider`);
  }

  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used inside an AuthProvider`);
  }

  return context;
}

export const useAuth = (): [AuthState, Dispatch<Action>] => [
  useAuthState(),
  useAuthDispatch(),
];

import styled, { css } from 'styled-components';
import { StyledImageContainer } from 'layout/image-container/image-container.styles';
import { StyledSvgIcon } from 'core/icon/icon.styles';
import { legacyTheme } from 'styles/theme';

export const StyledFirebaseUploader = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      width: 100%;
      padding-top: 100%;
      border: 1px solid ${legacyTheme.colors.cream};
      border-radius: ${theme.borderRadius};
      cursor: pointer;
      user-select: none;
    `;
  }}
`;

export const StyledFirebaseUploaderContent = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: grid;
      row-gap: ${legacyTheme.spacing.s1};
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
    `;
  }}
`;

export const StyledFirebaseUploaderImageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  border-radius: 6px;

  ${StyledImageContainer} {
    height: 100%;
  }

  &:hover {
    ${StyledSvgIcon} {
      position: absolute;
      top: 2rem;
      z-index: 3;
      right: 2rem;
    }
  }
`;

export const StyledFirebaseFileInput = styled.input`
  display: none;
`;

import {
  useInvalidateFetchDesignCache,
  useInvalidateFetchDesignsCache,
  useReEditDesign,
} from 'api/designs/routes';
import { Modal, Button } from 'gantri-components';
import { ReEditDesignModalPropsDef } from './re-edit-design-modal.types';

export const ReEditDesignModal = (props: ReEditDesignModalPropsDef) => {
  const { children, designId, header, onClose } = props;

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();
  const { invalidateFetchDesignsCache } = useInvalidateFetchDesignsCache();

  const { isLoading, onReEditDesign } = useReEditDesign({
    onSuccess: async () => {
      await invalidateFetchDesignCache();
      await invalidateFetchDesignsCache();
      onClose();
    },
  });

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={isLoading}
            size="large"
            text="Confirm"
            onClick={async () => {
              await onReEditDesign({ id: designId });
            }}
          />
        </>
      }
      header={header}
      maxWidth={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};

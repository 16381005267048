import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon';
import { Dropdown } from 'core/dropdown';
import { SelectOverlay } from 'core/select/components';
import isObject from 'lodash/isObject';
import { StyledSelectContainer, StyledValueContainer } from './select.styles';
import { SelectPresets } from './select.presets';
import { SelectProps } from './select.props';

export const Select = (props: SelectProps) => {
  const {
    error,
    errorTx,
    label,
    labelProperty,
    labelTx,
    multiple,
    onChange,
    options,
    placeholder,
    placeholderTx,
    touched,
    value,
    valueProperty,
  } = { ...SelectPresets, ...props };
  const [innerValue, setInnerValue] = useState(undefined);
  const [overlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const displayValue = useMemo(() => {
    if (innerValue) {
      if (multiple) {
        return Object.keys(innerValue || {})
          .map((key: string) =>
            isObject(innerValue[key])
              ? innerValue[key][labelProperty]
              : innerValue[key],
          )
          .join(', ');
      }
      const selectedOption = options?.find(
        option => option[valueProperty] === innerValue,
      );
      return selectedOption?.[labelProperty];
    }
    return '';
  }, [innerValue, multiple]);

  const onSelect = (value: any) => {
    setInnerValue(value?.[valueProperty] || value);
    onChange?.(multiple ? value : value?.[valueProperty] || value);
    setOverlayVisible(false);
  };

  return (
    <div>
      {(label || labelTx) && (
        <Typography text={label} tx={labelTx} variant="label" />
      )}

      <Dropdown
        closeBehavior="manual"
        opened={overlayVisible}
        overlay={
          <SelectOverlay
            {...props}
            labelProperty={labelProperty}
            multiple={multiple}
            value={innerValue}
            valueProperty={valueProperty}
            onClose={() => setOverlayVisible(false)}
            onSelect={onSelect}
          />
        }
        onClose={() => setOverlayVisible(false)}
        onOpen={() => setOverlayVisible(true)}
      >
        <StyledSelectContainer invalid={(error || errorTx) && touched}>
          {!!displayValue ? (
            <StyledValueContainer>{displayValue}</StyledValueContainer>
          ) : (
            <Typography color="grey" text={placeholder} tx={placeholderTx} />
          )}
          <Icon name="chevron-down" width="1rem" />
        </StyledSelectContainer>
      </Dropdown>
    </div>
  );
};

import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import { AddAddressResponse } from 'services/api/user/user-api.types';
import { Address } from 'types/user.type';
import { UpdateThankYouMessageResponse } from 'services/api';

export class UserApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/user');
  }

  async addAddress(request: Address): Promise<AddAddressResponse> {
    const response = await this.api.post<AddAddressResponse>(
      '/address/create',
      request,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async updateAddress(request: Address): Promise<AddAddressResponse> {
    const response = await this.api.put<AddAddressResponse>(
      '/address/update',
      request,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async removeAddress(id: number): Promise<AddAddressResponse> {
    const response = await this.api.delete<AddAddressResponse>(
      `/address/${id}/remove`,
    );
    return this.handleResponse<AddAddressResponse>(response);
  }

  async dashboardUpdate(
    bodyMessage: string,
  ): Promise<UpdateThankYouMessageResponse> {
    const response = await this.api.put<UpdateThankYouMessageResponse>(
      '/designer/body-message',
      {
        bodyMessage,
      },
    );
    return this.handleResponse<UpdateThankYouMessageResponse>(response);
  }
}

import {
  Conditional,
  FileUploader,
  Flex,
  Stack,
  Typography,
} from 'gantri-components';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { useFormikContext } from 'formik';
import { ModelStepOneFormDef } from '../../model-step-one.types';
import { ModelAssemblyFileUploaderPropsDef } from './model-assembly-file-uploader.types';

export const ModelAssemblyFileUploader = (
  props: ModelAssemblyFileUploaderPropsDef,
) => {
  const { design, isDisabled } = props;

  const { fileName, fileUrl } =
    design?.model?.subSteps?.[1]?.assemblyFile || {};

  const maxFileSizeMB = 50;

  const { setFieldValue } = useFormikContext<ModelStepOneFormDef>();

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl,
    handleUploadsComplete: async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue('assemblyFile', {
        fileExtension,
        fileName,
        fileSize,
        fileUrl,
        loading: false,
        percentage: 100,
      });
    },
    onFileDelete: async () => {
      await setFieldValue('assemblyFile', null);
    },
    path: `designs/${design?.id}`,
  });

  return (
    <Flex alignItems="center" gap="x">
      <Stack>
        <FileUploader
          {...fileUploaderProps}
          buttonProps={{ text: 'Upload your STEP assembly file' }}
          expectedExtensions={['step']}
          fileName={fileName}
          isDisabled={isDisabled}
          maxFileSizeMB={maxFileSizeMB}
          thumbnailSize="36rem"
          variant="link"
        />
      </Stack>

      <Conditional condition={!fileName}>
        <Typography
          color="t2"
          text={`Max size ${maxFileSizeMB}MB`}
          variant="p3"
        />
      </Conditional>
    </Flex>
  );
};

import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledImageContainer = styled.div<{
  height: string;
  round: boolean;
}>`
  ${({ height, round }) => css`
    overflow: hidden;
    user-select: none;
    height: ${height || 'unset'};
    border-radius: ${round ? '50%' : 'unset'};
  `}
`;

export const StyledImageChildrenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const StyledOverlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
 }
`;

import { DividedContainerProps } from 'layout/divided-container/divided-container.props';
import { DividedContainerPresets } from 'layout/divided-container/divided-container.presets';
import { StyledDividedContainer } from 'layout/divided-container/divided-container.styles';
import { FlexContainer } from 'layout/flex-container';

export const DividedContainer = (props: DividedContainerProps) => {
  const { leftContent, rightContent } = {
    ...DividedContainerPresets,
    ...props,
  };

  const leftContentProps = {
    ...DividedContainerPresets.leftContentProps,
    ...props.leftContentProps,
  };

  const rightContentProps = {
    ...DividedContainerPresets.rightContentProps,
    ...props.rightContentProps,
  };

  return (
    <StyledDividedContainer>
      <FlexContainer {...leftContentProps}>{leftContent}</FlexContainer>
      <FlexContainer {...rightContentProps}>{rightContent}</FlexContainer>
    </StyledDividedContainer>
  );
};

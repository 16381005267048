import { MutableRefObject, useCallback, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useBodyScroll = (ref: MutableRefObject<HTMLElement>) => {
  const positionLocked = useRef(false);

  const lockBodyScroll = useCallback(() => {
    if (!positionLocked.current && ref?.current) {
      disableBodyScroll(ref.current);
      document.body.style.overflow = 'hidden';
      positionLocked.current = true;
    }
    // eslint-disable-next-line
  }, [ref?.current, positionLocked.current]);

  const unlockBodyScroll = useCallback(() => {
    if (positionLocked.current) {
      enableBodyScroll(ref.current);
      document.body.style.removeProperty('overflow');
    }
    // eslint-disable-next-line
  }, [ref, positionLocked.current, enableBodyScroll]);

  return { lockBodyScroll, unlockBodyScroll };
};

import {
  Conditional,
  Typography,
  Icon,
  Flex,
  Box,
  LinearProgress,
  Cell,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { StepDetailsPropsDef } from './step-details.types';

export const StepDetails = (props: StepDetailsPropsDef) => {
  const { designFailedAt, step, stepName, stepNumber } = props;

  const {
    isApproved,
    isCompleted,
    isFailed,
    isInProgress,
    isReturned,
    isSubmitted,
    isWaiting,
  } = getDesignStatusDetails(step.status);

  return (
    <>
      <Flex justifyContent="center">
        <Conditional
          condition={isApproved || isReturned || isCompleted}
          Fallback={<Typography color="t2" text={stepNumber} />}
        >
          <Conditional condition={isApproved || isCompleted}>
            <Icon
              color="link"
              name="ui-control:check_mark_circle_filled_24"
              size="2.4rem"
            />
          </Conditional>
          <Conditional condition={isReturned || isFailed}>
            <Icon
              color="alert"
              name="ui-control:exclamation_circle_filled_24"
              size="2.4rem"
            />
          </Conditional>
        </Conditional>
      </Flex>

      <Typography color="t2" text={stepName} />

      <Cell justifySelf="end">
        <Conditional condition={isWaiting}>
          <Typography align="right" color="t2" text={step.status} />
        </Conditional>

        <Conditional condition={isInProgress && !!step.stepProgress}>
          <Box width="16rem">
            <LinearProgress current={step.stepProgress} showText total={100} />
          </Box>
        </Conditional>

        <Conditional condition={isSubmitted}>
          <Typography
            align="right"
            text={`${step.status} ${formatDate(step.completedAt)}`}
          />
        </Conditional>

        <Conditional condition={isReturned}>
          <Typography
            align="right"
            color="alert"
            text={`${step.status} ${formatDate(step.refinementsNeededAt)}`}
          />
        </Conditional>

        <Conditional condition={isFailed}>
          <Typography
            align="right"
            color="alert"
            text={`${step.status} ${formatDate(designFailedAt)}`}
          />
        </Conditional>

        <Conditional condition={isApproved}>
          <Typography
            align="right"
            color="link"
            text={`${step.status} ${formatDate(step.approvedAt)}`}
          />
        </Conditional>

        <Conditional condition={isCompleted}>
          <Typography
            align="right"
            color="link"
            text={`Completed ${formatDate(step.completedAt)}`}
          />
        </Conditional>
      </Cell>
    </>
  );
};

import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledFlex = styled(Flex)<{ disabled: boolean }>`
  ${({ disabled, onClick }) => {
    const cursorStyle = disabled ? 'not-allowed' : onClick ? 'pointer' : 'auto';

    return css`
      cursor: ${cursorStyle};

      * {
        cursor: ${cursorStyle};
      }
    `;
  }}
`;

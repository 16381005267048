import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { getThemeColor, legacyTheme } from 'styles/theme';

export const StyledColorList = styled.div<{ columnsCount; size: string }>`
  ${({ columnsCount, size, theme }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(${columnsCount || 'auto-fit'}, ${size});
      column-gap: ${legacyTheme.spacing.s1};
      row-gap: ${legacyTheme.spacing.s1};
      width: inherit;
    `;
  }}
`;

export const StyledColorOutline = styled(animated.span)<{
  color: string;
  size: string;
}>`
  ${({ color, size, theme }) => {
    return css`
      background-color: ${legacyTheme.colors.white};
      width: ${size};
      height: ${size};
      border-radius: 50%;
      border: 1px solid ${color};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    `;
  }}
`;

export const StyledColor = styled(animated.span)<{
  color: string;
  size: string;
}>`
  ${({ color, size }) => {
    return css`
      background-color: ${getThemeColor(color)};
      width: ${size};
      min-width: ${size};
      height: ${size};
      min-height: ${size};
      display: inline-block;
      border-radius: 50%;
      border: 1px solid ${getThemeColor('lightGrey')};
    `;
  }}
`;

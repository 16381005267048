import { useState } from 'react';
import { AutocompleteProps } from 'core/autocomplete/autocomplete.props';
import { Autocomplete } from 'core/autocomplete';
import { useAddressPredictions } from 'core/address-autocomplete/address-autocomplete.hooks';
import { AddressPrediction } from 'core/address-autocomplete/address-autocomplete.types';
import { StyledPoweredByGoogle } from 'core/address-autocomplete/address-autocomplete.styles';

export const AddressAutocomplete = (props: Partial<AutocompleteProps>) => {
  const { onSelect, onTextChange: onComponentTextChange, ...others } = props;
  const [value, setValue] = useState('');
  const { extractAddressParts, getPredictions, predictions } =
    useAddressPredictions();

  const onTextChange = text => {
    getPredictions(text);
    setValue(text);

    if (onComponentTextChange) {
      onComponentTextChange(text);
    }
  };

  const onOptionSelect = async (option: AddressPrediction) => {
    if (option) {
      const parts = await extractAddressParts(option.placeId);
      setValue(`${parts.streetNumber} ${parts.street}`);

      if (onSelect) {
        onSelect(parts);
      }
    } else {
      setValue('');
    }
  };

  return (
    <Autocomplete
      footerComponent={<StyledPoweredByGoogle />}
      labelProperty="address"
      options={predictions}
      value={value}
      valueProperty="placeId"
      onSelect={onOptionSelect}
      onTextChange={onTextChange}
      {...others}
    />
  );
};

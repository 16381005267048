import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledThankYouCard = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${legacyTheme.colors.white};
      border: 1px solid ${legacyTheme.colors.lightGrey};
      padding: ${legacyTheme.spacing.s3} ${legacyTheme.spacing.s2};
      margin-top: ${legacyTheme.spacing.s2};
      max-width: 29rem;
      overflow: hidden;

      ${media.lessThan('md')`
      max-width: unset;
    `}
    `;
  }}
`;

import axios from 'axios';
import { userApiUrl } from 'helpers/auth';
import { FetchDesignsResponse } from './fetch-designs.types';

export const fetchDesigns = () => {
  return axios
    .get<FetchDesignsResponse>(`${userApiUrl}/designs`)
    .then(({ data }) => {
      return data;
    });
};

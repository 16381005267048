import { RecoilState, useRecoilCallback } from 'recoil';

export const useResetRecoilAtoms = (
  atoms: RecoilState<any>[] | Record<string, RecoilState<any>>,
) => {
  const atomsArray = Array.isArray(atoms) ? atoms : Object.values(atoms || {});

  return useRecoilCallback(({ reset }) => {
    return () => {
      atomsArray?.forEach((atom) => {
        reset(atom);
      });
    };
  }, []);
};

import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { Page } from 'core/page';
import { Box } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { ModelStepOne } from './components/model-step-one';
import { ModelStepTwo } from './components/model-step-two';
import { ModelStepPageParams } from './model-step.types';
import { ModelStepThree } from './components/model-step-three';

export const ModelStepPage = () => {
  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          <ModelStepContent />
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

const ModelStepContent = () => {
  const { stepNumber } = useParams<ModelStepPageParams>();

  switch (stepNumber) {
    case '1':
      return <ModelStepOne />;

    case '2':
      return <ModelStepTwo />;

    case '3':
      return <ModelStepThree />;

    default:
      return null;
  }
};

import { Button, Grid, Stack, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { CategoryItem } from './components/category-item';
import { maxPageWidthMid, sectionVerticalPadding } from '../../home.constants';
import { storefrontRoutePaths } from '../../../../storefront-routes';

export const CategoriesSection = () => {
  return (
    <Stack
      alignContent="center"
      gap="5x"
      horizontalPadding="2x"
      justifySelf="center"
      maxWidth={maxPageWidthMid}
      verticalPadding={sectionVerticalPadding}
      width="100%"
    >
      <Grid
        alignItems="center"
        columns={{ lg: '1fr max-content', sm: 1 }}
        gap="4x"
        justifyContent="space-between"
      >
        <Typography
          align={{ lg: 'left', sm: 'center' }}
          text="Product categories"
          variant="h2"
        />
        <Link to={storefrontRoutePaths.shop.all}>
          <Button justifySelf="center" size="large" text="See Live Products" />
        </Link>
      </Grid>

      <Grid columns={{ lg: '4fr 3fr', md: 2, sm: 1 }}>
        <Stack alignContent="stretch">
          <CategoryItem
            aspectRatio="1"
            descriptions={['Dimmable LED.', 'Hinges and pivots.']}
            src="https://res.cloudinary.com/gantri/image/upload/v1727820516/static-assets/graphite/home/product-table_2x.jpg"
            title="Table Light"
          />
          <CategoryItem
            aspectRatio="1"
            descriptions={['Dimmable LED.', 'Single wall mount.']}
            src="https://res.cloudinary.com/gantri/image/upload/v1727820517/static-assets/graphite/home/product-wall_2x.jpg"
            title="Wall Light"
          />
        </Stack>
        <CategoryItem
          aspectRatio={{ lg: 'unset', sm: '1/2' }}
          descriptions={['Dimmable LED.', 'Flexible rod configurations.']}
          src="https://res.cloudinary.com/gantri/image/upload/v1727820516/static-assets/graphite/home/product-floor_2x.jpg"
          title="Floor Light"
        />
      </Grid>
    </Stack>
  );
};

import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'config/environment';
import { AUTH_TOKEN, STD_COOKIE_OPTIONS } from 'constants/common';

/** `/user` */
export const userApiUrl = `${environment.API_URL}/user`;

export const REDIRECTED_FOR_AUTHENTICATION = 'REDIRECTED_FOR_AUTHENTICATION';

export const redirectToMantle = (masqueradeToken = false) => {
  if (environment.SHOP_URL) {
    localStorage.setItem(REDIRECTED_FOR_AUTHENTICATION, '1');

    if (!masqueradeToken) {
      window.location.href = `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}`;
    } else {
      window.location.href = `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}&token=${masqueradeToken}`;
    }
  } else {
    // eslint-disable-next-line no-alert
    window.alert(
      'You may be missing your .env file! Please talk to one of the other developers to get this added to your project, then restart your local environment!',
    );
  }
};

const cookies = new Cookies();

export const getTokenCookie = () => {
  // @ts-expect-error
  const authToken: string = cookies.get(AUTH_TOKEN, STD_COOKIE_OPTIONS);

  return authToken;
};

export const SESSION_IDENTIFIER = uuidv4();

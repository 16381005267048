import { addDesignModalSteps } from '../../add-design-modal.constants';
import { AddDesignModalStep } from '../../add-design-modal.types';

export const getAddDesignHeaderText = (step: AddDesignModalStep) => {
  switch (step) {
    case addDesignModalSteps.type:
      return 'Choose design type';

    case addDesignModalSteps.category:
      return 'Choose light category';

    case addDesignModalSteps.subCategory:
      return 'Choose light type';

    case addDesignModalSteps.size:
      return "What's the size?";

    case addDesignModalSteps.name:
      return "What's the name?";

    default:
      return null;
  }
};

import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { BoxDimension } from 'layout/box-container/box-container.props';
import { generateStorageKey } from 'utils/storage';
import { environment } from 'config/environment';
import Cookies from 'universal-cookie';

export const AUTH_TOKEN = generateStorageKey('AUTH_TOKEN');

export const FIREBASE_TOKEN = generateStorageKey('FIREBASE_TOKEN');

export const CART_ITEMS = generateStorageKey('CART_COOKIE');

export const EXTERNAL_SCRIPTS_FETCH_TIME = 2000;

export const STD_COOKIE_OPTIONS = {
  domain: environment.COOKIES_DOMAIN,
  path: '/',
};

export const DEFAULT_PAGING_SIZE = 100;

export const BASE_FORM_PADDING: ResolutionAwareProp<BoxDimension> = {
  lg: 's3',
  md: 's2',
  sm: 's1',
};

export const BASE_MODAL_PADDING: {
  horizontalPadding: ResolutionAwareProp<BoxDimension>;
  verticalPadding: ResolutionAwareProp<BoxDimension>;
} = {
  horizontalPadding: { lg: 's5', md: 's4', sm: 's3' },
  verticalPadding: 's5',
};

export const placeholderImageSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png';

const cookies = new Cookies();

export const clearAllCache = () => {
  localStorage.clear(); // clear atoms

  // clear out all cookies, but keep user logged in
  const allCookies = Object.keys(cookies.getAll()).filter((cookie) => {
    return ![AUTH_TOKEN, FIREBASE_TOKEN].includes(cookie);
  });

  allCookies.forEach((cookie) => {
    return cookies.remove(cookie, STD_COOKIE_OPTIONS);
  });
};

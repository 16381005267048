import { legacyTheme } from 'styles/theme';
import styled from 'styled-components';
import { media } from 'styles/media';

export const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem;
  z-index: 1;
  background-color: ${props => {
    return legacyTheme.colors.white;
  }};

  ${media.lessThan('md')`
    padding: 2rem;
  `}

  ${media.lessThan('sm')`
    padding: 1.2rem;
  `}
`;

import { FC } from 'react';
import { Icon } from 'core/icon';
import { Button } from 'core/button';
import { Grid } from 'layout/grid';
import { StyledSelectCloseIcon, StyledSelectOverview } from './overlay.styles';
import { SelectOverlayProps } from './overlay.types';

export const Overlay: FC<Partial<SelectOverlayProps>> = props => {
  const { children, onApply, onClose, onReset } = props;

  return (
    <StyledSelectOverview>
      <StyledSelectCloseIcon>
        <Icon
          color="grey"
          cursor="pointer"
          name="close"
          size="1.2rem"
          onClick={onClose}
        />
      </StyledSelectCloseIcon>

      {children}

      <Grid
        columns="repeat(2, 12rem)"
        gap="1.2rem"
        marginBottom="s2"
        marginTop="s2"
        padding="unset"
      >
        <Button minWidth="12rem" text="Apply" onClick={onApply} />
        <Button
          minWidth="12rem"
          text="Reset"
          variant="secondary"
          onClick={onReset}
        />
      </Grid>
    </StyledSelectOverview>
  );
};

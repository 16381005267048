import { Designer } from 'types/designer.type';
import { SKU } from 'types/product.type';

export const GET_PAYOUTS = 'GET_PAYOUTS';
export const GET_PAYOUT = 'GET_PAYOUT';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';

export interface GetInfoResponse {
  data: {
    designer: Designer;
    products: SKU[];
    splitProducts: SKU[];
  };
  success: boolean;
}

export interface GetPayoutResponse {
  payout: Payout;
  success: boolean;
}

export interface GetPayoutsResponse {
  data: Payout[];
  success: boolean;
}

export interface Payout {
  amount: Amount;
  designer: string;
  email: string;
  endDate: string;
  endDateSec: number;
  id: number;
  items: PayoutItem[];
  location: Location;
  logoUrl: string;
  method: Method;
  name: string;
  notes: null;
  paidDate: string;
  paidDateSec: number;
  period: string;
  products: number;
  startDate: string;
  startDateSec: number;
  status: Status;
  stocksInfo: StocksInfo;
  thirdPartyDate: null;
  thirdPartyMethod: null;
  thirdPartyStatus: null;
  transactionDate: string;
  transactionDateSec: string;
  units: number;
  userId: number;
}

export interface Amount {
  refund: number;
  royalty: number;
  sales: number;
}

export enum Method {
  PayPal = 'PayPal',
}

export enum Status {
  Paid = 'Paid',
}

export interface StocksInfo {
  [stockId: number]: {
    [sku: string]: Total;
  };

  total: Total;
}

export interface Total {
  earnings: number;
  item?: StockSku;
  refunded: number;
  sales: number;
  sold: number;
}

export interface StockSku {
  fullName: string;
  fullSkuName: string;
  imageUrl: string;
  itemDetails: string;
  name: string;
  productId: number;
  sku: string;
  type: string;
}

export interface PayoutItem {
  amount: number;
  date: Date;
  dateSec: string;
  name: string;
  orderId: number;
  quantity: number;
  sku: string;
  type: string;
}

export interface Location {
  city: string;
  country: string;
  state: string;
}

export interface UpdateThankYouMessageResponse {
  bodyMessage: string;
  success: boolean;
}

export interface ValidateTokenResponse {
  accepted: boolean;
  expired: boolean;
  isUser: boolean;
  nameDesigner: string;
  success: boolean;
  time: string;
  user: {
    accepted: boolean;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface AcceptInviteRequest {
  browserUserId: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  token: string;
}

export interface AcceptInviteResponse {
  firebaseToken: string;
  token: string;
}

import { Children, isValidElement, cloneElement, FC } from 'react';
import { isFragment } from 'react-is';
import { environment } from 'config/environment';
import isValueSelected from 'core/button-toggle-group/button-toggle-group.helpers';
import { ButtonToggleGroupProps } from 'core/button-toggle-group/button-toggle-group.props';
import { ButtonToggleGroupPresets } from 'core/button-toggle-group/button-toggle-group.presets';
import { StyledToggleButtonGroup } from 'core/button-toggle-group/button-toggle-group.styles';
import { ButtonToggleProps } from 'core/button-toggle/button-toggle.props';

export const ButtonToggleGroup: FC<ButtonToggleGroupProps> = props => {
  const {
    children,
    onChange,
    selectedBackgroundColor,
    selectedTextColor,
    value,
    ...other
  } = { ...ButtonToggleGroupPresets, ...props };

  return (
    <StyledToggleButtonGroup
      selectedBackgroundColor={selectedBackgroundColor}
      {...other}
    >
      {Children.map(children, child => {
        if (!isValidElement(child)) {
          return null;
        }

        if (!environment.IS_PRODUCTION) {
          if (isFragment(child)) {
            console.error(
              "ToggleButtonGroup component doesn't accept a Fragment as a child.",
            );
          }
        }

        return cloneElement(child as any, {
          onChange,
          selected:
            (child.props as ButtonToggleProps).selected === undefined
              ? isValueSelected((child.props as ButtonToggleProps).value, value)
              : (child.props as ButtonToggleProps).selected,
          selectedBackgroundColor,
          selectedTextColor,
        });
      })}
    </StyledToggleButtonGroup>
  );
};

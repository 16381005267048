import { FC } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { api, GET_PRODUCT } from 'services/api';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { useParams } from 'react-router-dom';
import { LoadingOverlay } from 'layout/loading-overlay';
import { Tabs } from 'core/tabs';
import { TabConfig } from 'core/tabs/tabs.props';
import {
  ProductAssets,
  ProductInfo,
  ProductPreparation,
  ScheduleLaunch,
} from './components';

export const ProductPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const query = useQuery([GET_PRODUCT, id], () => api.product.getProduct(+id));

  if (!query.data?.product) {
    return <LoadingOverlay />;
  }

  const tabsConfig: TabConfig[] = [
    {
      content: <ProductInfo product={query.data.product} />,
      heading: 'INFO',
    },
    {
      content: <ProductAssets product={query.data.product} />,
      heading: 'ASSETS',
    },
  ];

  return (
    <Page>
      <Grid alignContent="start" gap={{ lg: 's5', md: 's4', sm: 's3' }}>
        <Typography text={query.data.product.name} variant="h2" />

        {query.data.product.status === 'In preparation' && (
          <ProductPreparation />
        )}

        {query.data.product.status === 'Ready' &&
          !query.data.product.scheduleLaunchedAt && (
            <ScheduleLaunch productId={+id} />
          )}

        <Tabs config={tabsConfig} headingWidth={{ lg: '20rem', sm: 'unset' }} />
      </Grid>
    </Page>
  );
};

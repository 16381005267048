import { TextAreaProps } from './text-area.props';

export const TextAreaPresets: TextAreaProps = {
  descriptionColor: 'grey',
  descriptionVariant: 'p3',
  id: '',
  labelColor: 'black',
  placeholderColor: 'grey',
  rows: 5,
};

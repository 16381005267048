import { media, Stack, Typography } from 'gantri-components';
import styled from 'styled-components';

export const StyledHeroStack = styled(Stack)`
  background: url('https://res.cloudinary.com/gantri/image/upload/v1727820517/static-assets/graphite/home/create-big_2x.jpg')
    center / cover;
  overflow: hidden;
  height: 100vh;
  min-height: 620px;
  position: relative;

  ${media.lessThan('md')`
    height: 100vw;
    background-image: url('https://res.cloudinary.com/gantri/image/upload/v1727820517/static-assets/graphite/home/create-mobile_2x.jpg');
  `}

  ${media.lessThan('sm')`
    height: 125vw;
  `}
`;

export const StyledShadowTypography = styled(Typography)`
  text-shadow: 0px 2px 1px rgb(0 0 0 / 20%);
`;

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgSort = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      aria-hidden="true"
      className="sort_svg__svg-inline--fa sort_svg__fa-sort sort_svg__fa-w-10"
      data-icon="sort"
      data-prefix="fad"
      height="1em"
      viewBox="0 0 320 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M279.05 288.05h-238c-21.4 0-32.07 25.95-17 41l119.1 119 .1.1a23.9 23.9 0 0033.8-.1l119-119c15.1-15.05 4.4-41-17-41zm-238-64h238c21.4 0 32.1-25.9 17-41l-119-119a.94.94 0 00-.1-.1 23.9 23.9 0 00-33.8.1l-119.1 119c-15.05 15.1-4.4 41 17 41z"
        fill={color}
        opacity={0.4}
      />
    </svg>
  );
});
export default SvgSort;

import { useEffect } from 'react';
import { TableProps } from 'core/table/table.props';
import { TablePresets } from 'core/table/table.presets';
import { StyledTableContainer } from 'core/table/table.styles';
import { Icon } from 'core/icon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { TableOptions, useSortBy, useTable } from 'react-table';
import { Paging } from 'core/paging';
import { BoxContainer } from 'layout/box-container';
import { DEFAULT_PAGING_SIZE } from 'constants/common';

export const Table = (props: TableProps) => {
  const { columns, data, footerVisible, manualSortBy, onSort, paging } = {
    ...TablePresets,
    ...props,
  };

  const {
    footerGroups,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy },
  } = useTable<TableOptions<any>>(
    {
      columns,
      data,
      manualSortBy,
    } as any,
    useSortBy,
  );

  useEffect(() => {
    if (manualSortBy && onSort) {
      const sortInfo = sortBy?.[0];

      if (sortInfo) {
        onSort({
          sortField: sortInfo.id,
          sortOrder: sortInfo.desc ? 'DESC' : 'ASC',
        });
      } else {
        onSort({
          sortField: '',
          sortOrder: '',
        });
      }
    }
  }, [sortBy, manualSortBy]);

  return (
    <div>
      <StyledTableContainer>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                      ...(column.sortable ? column.getSortByToggleProps() : {}),
                      style: {
                        ...column['style'],
                        cursor: column.sortable ? 'pointer' : 'default',
                        maxWidth: column.maxWidth,
                        minWidth: column.minWidth,
                        width: column.width,
                      },
                    })}
                  >
                    {column.render('Header')}
                    {column.sortable && (
                      <span>
                        {column.isSorted ? (
                          <Icon
                            color="black"
                            name={column.isSortedDesc ? 'sort-down' : 'sort-up'}
                            top="2px"
                          />
                        ) : (
                          <Icon name="sort" top="2px" />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column['className'],
                          style: cell.column['style'],
                        },
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>

          {footerVisible && (
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td
                      {...column.getFooterProps([
                        { style: column.footerStyle },
                      ])}
                    >
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </StyledTableContainer>

      {paging?.total > DEFAULT_PAGING_SIZE && (
        <BoxContainer marginTop={{ lg: 's6', md: 's5' }}>
          <Paging
            {...paging}
            pageCount={Math.ceil(paging.total / DEFAULT_PAGING_SIZE)}
            total={paging.total || 0}
          />
        </BoxContainer>
      )}
    </div>
  );
};

import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { media } from 'styles/media';
import { legacyTheme } from 'styles/theme';

export const StyledTooltip = styled.div<{ roundContainer: boolean }>`
  ${({ roundContainer }) => {
    return css`
      border-radius: ${roundContainer ? '50%' : 'unset'};
      cursor: pointer;
      width: min-content;
      display: flex;
    `;
  }}
`;

export const StyledTooltipContent = styled(animated.div).withConfig({
  shouldForwardProp: prop => {
    return !['visibleOnMobile'].includes(prop);
  },
})<{
  visibleOnMobile: boolean;
  width?: string;
}>`
  ${({ theme, visibleOnMobile, width }) => {
    return css`
      padding: ${legacyTheme.spacing.s2};
      position: fixed;
      background-color: ${legacyTheme.colors.white};
      border-radius: 0.3rem;
      width: ${width || '29rem'};
      box-shadow: 0 0.2rem 2.5rem rgba(0, 0, 0, 0.15);
      z-index: 90;

      &:after {
        content: '';
        position: absolute;
        height: 10px;
        width: 100%;
        bottom: -7px;
        left: 0;
      }

      ${media.lessThan('md')`
      display: ${visibleOnMobile ? 'block' : 'none'};
    `}
    `;
  }}
`;

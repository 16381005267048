import { SortInfo } from 'types/paging-info';
import { GiftCardRecord } from 'types/gift-card.type';
import { GiftCode } from 'types/user.type';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER = 'GET_ORDER';

export interface GetOrdersRequest {
  count: number;
  page: number;
  sortInfo?: SortInfo;
  statuses: OrderStatus[];
  types: OrderType[];
}

export interface GetOrdersResponse {
  allOrders: number;
  count: number;
  orders: Order[];
  page: number;
  success: boolean;
}

export interface GetOrderResponse {
  order: Order;
  success: boolean;
}

export interface Order {
  address: Address;
  allDone: boolean;
  amount: OrderAmount;
  billingAddress: BillingAddress;
  canCompleteRefund: boolean;
  completedAt: string;
  completedAtSec: string;
  completedDateSec: string;
  completedJobAmount: number;
  createdAt: string;
  createdAtSec: number;
  customerName: string;
  email: string;
  gift?: GiftCode;
  giftCards: GiftCardRecord[];
  howHeardAboutUs: any[];
  id: number;
  inProgress: boolean;
  labelUrls: null;
  notes: null;
  payment: Payment;
  productIds: number[];
  readyToShip: boolean;
  readyToShipId: null;
  shipments: Shipment[];
  shipping: Shipping;
  shipsAt: string;
  status: string;
  stocks: Stock[];
  transactionEmailLog: null;
  transactionId: null;
  type: string;
  user: User;
  usingShippo: boolean;
}

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Delivered = 'Delivered',
  PartiallyCancelled = 'Partially cancelled',
  PartiallyDelivered = 'Partially delivered',
  Processed = 'Processed',
  Refunded = 'Refunded',
  Shipped = 'Shipped',
}

export enum OrderType {
  Marketing = 'Marketing',
  Order = 'Order',
  RD = 'R&D',
  Refund = 'Refund',
  Replacement = 'Replacement',
  Trade = 'Trade',
}

export interface Address {
  city: string;
  country: string;
  countryShort?: string;
  doNotShip?: boolean;
  firstName: string;
  lastName: string;
  phone?: string;
  state: string;
  street: string;
  unit?: string;
  zip?: string;
}

export interface OrderAmount {
  credit: number;
  gift: number;
  shipping: number;
  subtotal: number;
  tax: number;
  total: number;
}

export interface BillingAddress {
  city: string;
  country: string;
  countryShort: string;
  email: string;
  firstName: string;
  isEmailRequired: boolean;
  lastName: string;
  name: string;
  phone: string;
  shipping: string;
  state: string;
  street: string;
  streetAddress: string;
  zip: string;
}

export interface Payment {
  chargeId: string;
  guest: boolean;
  type: string;
}

export interface Shipment {
  canCancel: boolean;
  createdAt: Date;
  e6SentAt: null;
  estimateDate: EstimateDate;
  id: number;
  isSignatureRequired: boolean;
  labelUrls: string[];
  rateCost: number;
  rates: null;
  revcascadeShip: boolean;
  shipmentEmailSent: boolean;
  shipmentHistory: ShipmentHistory;
  shippingOptions: ShippingOptions;
  shippoTransactionIds: string[];
  signatureRequired: boolean;
  status: string;
  statusUpdateDate: Date;
  stocks: Stock[];
  trackingNumber: string;
  transactionId: number;
  updatedAt: Date;
}

export interface EstimateDate {
  deliveredOnSec: number;
  deliveryBySec: number;
  estDeliveryBySec: number;
  leadTime: string;
  leadTimeSec: number;
  shippedOnSec: number;
}

export interface ShipmentHistory {
  address_from: AddressFrom;
  address_to: AddressFrom;
  carrier: string;
  eta: Date;
  messages: any[];
  metadata: null;
  original_eta: Date;
  servicelevel: Servicelevel;
  test: boolean;
  tracking_history: Tracking[];
  tracking_number: string;
  tracking_status: Tracking;
  transaction: string;
}

export interface AddressFrom {
  city: string;
  country: string;
  state: string;
  zip: string;
}

export interface Servicelevel {
  name: string;
  token: string;
}

export interface Tracking {
  location: AddressFrom;
  object_created: Date;
  object_id: string;
  object_updated: Date;
  status: TrackingStatusStatus;
  status_date: Date;
  status_details: string;
  substatus: Substatus;
}

export enum TrackingStatusStatus {
  Delivered = 'DELIVERED',
  Transit = 'TRANSIT',
}

export interface Substatus {
  action_required: boolean;
  code: string;
  text: string;
}

export interface ShippingOptions {
  cost: string;
  option: string;
  provider: string;
  type: string;
}

export interface Stock {
  amount: StockAmount;
  beingMade: boolean;
  completedJobPercent: number;
  giftMessage: null;
  id: number;
  isGift: boolean;
  jobs: Job[];
  payoutId: number;
  product: Product;
  productId: number;
  productOptions: ProductOptions;
  refundReason: null;
  replacementReason: null;
  saleDate: Date;
  shipmentId: number;
  status: string;
  stockInfoId: number;
  transactionId: number;
  type: string;
  userId: number;
}

export interface StockAmount {
  credit?: number;
  gift?: number;
  manufacturerPrice: number;
  subtotal: number;
  tax: number;
  transactionFee: number;
}

export interface Job {
  assignedInfo: AssignedInfo;
  cause: Cause | null;
  createdAt: Date;
  dependency: boolean;
  description: string;
  duration: number;
  endDate: Date;
  endDateSec: string;
  failedReason: FailedReason | null;
  fromTemplate: boolean;
  highPriority: boolean;
  id: number;
  instruction: Instruction | null;
  isDuplicate: boolean;
  machineId: number | null;
  machineType: MachineType | null;
  oldPartId: null;
  orderId: number;
  orderTypePriority: string;
  partId: number | null;
  printYield: string;
  scheduleSettingsId: null;
  serialNumber: number | null;
  sortOrder: number;
  startDate: Date;
  startDateSec: string;
  status: JobStatus;
  statusPriority: number;
  statusUpdateDate: Date;
  step: Step;
  stockId: number;
  type: Step;
  updatedAt: Date;
  workerId: number | null;
}

export interface AssignedInfo {
  assignedAt?: number;
  description?: string;
  userId?: number;
  userName?: string;
}

export enum Cause {
  Duplicate = 'duplicate',
  ReAssign = 're-assign',
}

export interface FailedReason {
  failedAt: Date;
  failedAtSec: string;
  reason: ReasonClass | string;
  userId: number;
  userName: string;
}

export interface ReasonClass {
  aggressiveSanding?: boolean;
  banding?: boolean;
  blowOut?: boolean;
  cracking?: boolean;
  debrisInPrint?: boolean;
  drips?: boolean;
  freeText: string;
  gunkInPaint?: boolean;
  heatingFailed?: boolean;
  imperfectionsNotAddressed?: boolean;
  layerLines?: boolean;
  layerLinesNotAddressed?: boolean;
  noExtrusion?: boolean;
  other: boolean;
  overExtrusion?: boolean;
  overspray?: boolean;
  partialPrint?: boolean;
  printComesLoose?: boolean;
  printImperfectionsNotSmoothed?: boolean;
  shifting?: boolean;
  thermalRunaway?: boolean;
  thermistorError?: boolean;
  underExtrusion?: boolean;
  warping?: boolean;
}

export interface Instruction {
  fileName?: string;
  url?: string;
}

export enum MachineType {
  CrealityHighTempPLA = 'Creality High-Temp PLA',
  CrealityTranslucentPLA = 'Creality Translucent PLA',
  Empty = '',
}

export enum JobStatus {
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum Step {
  Assemble = 'Assemble',
  Finish = 'Finish',
  Pack = 'Pack',
  Paint = 'Paint',
  Print = 'Print',
  QA = 'QA',
  Sand = 'Sand',
  Stage = 'Stage',
}

export interface Product {
  amount: StockAmount;
  category: string;
  color: Color;
  depth: number;
  designer: string;
  fullName: string;
  fullSkuName: string;
  fullUrlName: string;
  height: number;
  id: number;
  imageUrl: string;
  name: string;
  size: Size;
  sku: string;
  variant: string;
  width: number;
}

export interface Color {
  code: string;
  name: string;
}

export interface Size {
  code: string;
  id: number;
  name: string;
}

export interface ProductOptions {
  colors: Color[];
  sizes: Size[];
  variants: any[];
}

export interface Shipping {
  estimatedShipment: string;
  estimatedShipmentExpired: boolean;
  estimatedShipmentSec: number;
  leadTime: null;
  leadTimeSec: string;
  option: string;
  provider: string;
  status: null;
  statusDate: string;
  statusDateSec: string;
  tracking: null;
  trackingHistory: null;
}

export interface User {
  accepted: boolean;
  acceptedTC: boolean;
  active: boolean;
  authToken: string;
  cancelReason: null;
  cancelRewardAt: null;
  cart: null;
  cartAbandonmentEmailSentDate: null;
  cartCreateDate: null;
  createdAt: Date;
  email: string;
  facebookId: null;
  favoriteList: any[];
  firstName: string;
  giftCardCredits: number;
  giftCodesUsed: GiftCodesUsed;
  googleId: null;
  howHeardAboutUs: any[];
  id: number;
  invited: boolean;
  isAdmin: boolean;
  isDesigner: boolean;
  isWorker: boolean;
  lastActive: Date;
  lastName: string;
  mailchimpID: string;
  meta: Meta;
  notes: null;
  password: null;
  photoUrl: null;
  portfolioWebsite: null;
  postDeliveryEmailSent: boolean;
  purchaseStatus: null;
  purchasedAt: null;
  purchases: number;
  quizAnswers: null;
  referralHash: string;
  referralList: any[];
  referrals: number;
  referredByUserId: null;
  referredName: null;
  requestDate: null;
  resaleCertificate: null;
  resetToken: null;
  rewardAt: null;
  signUpEventName: string;
  subscriber: { [key: string]: boolean };
  thankYouEmailSent: boolean;
  updatedAt: Date;
  userCredits: number;
  wishlist: null;
  wishlistEmailSent: boolean;
}

export interface GiftCodesUsed {}

export interface Meta {
  ip: string;
  securityCookie: string;
  source: string;
  userAgent: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
}

export interface UpdateOrderAddressRequest {
  address: Address;
}

export interface UpdateOrderAddressResponse {}

export interface CancelOrRefundRequest {
  refundItems: Array<{ id: number; refundReason: string }>;
  status: 'Refund';
}

export interface CancelOrRefundResponse {}

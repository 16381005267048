import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgMastercard = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 26 18"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          fill="#F4F4F4"
          fillRule="nonzero"
          height={17}
          rx={2}
          stroke="#E4E5E6"
          width={25}
          x={0.5}
          y={0.5}
        />
        <g transform="translate(4 4)">
          <circle cx={5.5} cy={5} fill="red" r={5} />
          <circle cx={12.5} cy={5} fill="#FF9A00" r={5} />
          <path
            d="M9.001 1.43A4.985 4.985 0 0110.5 5 4.985 4.985 0 019 8.57 4.985 4.985 0 017.5 5c0-1.38.56-2.63 1.464-3.536z"
            fill="#FF5000"
          />
        </g>
      </g>
    </svg>
  );
});
export default SvgMastercard;

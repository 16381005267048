import { forwardRef } from 'react';
import { IconProps } from 'core/icon/icon.props';
import { StyledSvgIcon } from 'core/icon/icon.styles';
import { IconPresets } from 'core/icon/icon.presets';
import startCase from 'lodash/startCase';

export const Icon = forwardRef((props: IconProps, ref) => {
  const { height, name, width, ...others } = {
    ...IconPresets,
    ...props,
  };

  const fileName = startCase(name).replace(/ /g, '');

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const svgIcon = require(`./generated/${fileName}.tsx`).default;

  return (
    <StyledSvgIcon as={svgIcon} nheight={height} nwidth={width} {...others} />
  );
});

import { StyledLoadingOverlay } from 'layout/loading-overlay/loading-overlay.styles';
import { Icon } from 'core/icon';

export const LoadingOverlay = () => (
  <StyledLoadingOverlay>
    <Icon
      color="primary"
      name="loader"
      size={{ lg: '14rem', sm: '8rem' }}
      strokeWidth="1.6rem"
    />
  </StyledLoadingOverlay>
);

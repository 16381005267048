import { TimelineItem } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { Box, Conditional, Typography } from 'gantri-components';
import { formatDate } from 'utils/date';
import { StyledTimelineItemBox } from './timeline.styles';

export const Timeline = (props: { timeline: TimelineItem[] }) => {
  const { timeline } = props;

  return (
    <Box>
      {timeline.map(
        ({ createdAt, description, firstName, lastName }, index) => {
          const isLastIndex = index === timeline.length - 1;

          const userName = [firstName, lastName].filter(Boolean).join(' ');

          return (
            <StyledTimelineItemBox
              key={createdAt}
              $showLine={!isLastIndex}
              marginLeft="x"
              paddingBottom="3x"
              paddingLeft="3x"
            >
              <Typography
                text={formatDate(createdAt, 'MM/DD/YYYY [at] hh:mmA')}
              />
              <Box>
                <Typography color="t2" display="inline" text={description} />
                <Conditional condition={!!userName}>
                  {' '}
                  <Typography color="link" display="inline" text={userName} />
                </Conditional>
              </Box>
            </StyledTimelineItemBox>
          );
        },
      )}
    </Box>
  );
};

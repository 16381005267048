import {
  ChangeEvent,
  FC,
  forwardRef,
  KeyboardEvent,
  MutableRefObject,
  useEffect,
  useState,
} from 'react';

import { Button } from 'core/button';
import { animated, useSpring } from 'react-spring';
import { useEnsuredForwardedRef } from 'react-use';
import { Icon } from 'core/icon/icon';
import InputMask from 'react-input-mask';
import { useMounted } from 'utils/hooks';
import { isLargeScreen } from 'helpers/layout.helpers';
import { Typography } from '../typography';
import {
  StyleClearableIconContainer,
  StyledBodyContainer,
  StyledIconContainer,
  StyledInput,
  StyledInputActionContainer,
  StyledInputContainer,
  StyledInputContent,
  StyledInputDescription,
  StyledPlaceholder,
  StyledPrefixContainer,
} from './text-input.styles';
import { TextInputPresets } from './text-input.presets';
import { TextInputProps } from './text-input.props';

const MASKS = {
  phone: '9999999999',
};

export const TextInput: FC<TextInputProps> = forwardRef<
  HTMLElement,
  TextInputProps
>((props, ref) => {
  const name = props.name || props.label || props.labelTx;
  const {
    actionColor,
    actionText,
    actionTx,
    autoFocus,
    clearable,
    description,
    descriptionColor,
    descriptionTx,
    disabled,
    error,
    errorTx,
    icon,
    id,
    label,
    labelColor,
    labelTx,
    mask,
    onActionClick,
    onBlur: onInputBlur,
    onChange,
    onEnter,
    onFocus: onInputFocus,
    onIconClick,
    onTextChange,
    paddingLeft,
    placeholder,
    placeholderColor,
    placeholderTx,
    prefixComponent,
    readonly,
    selectOnFocus,
    touched,
    type,
    value,
    variant,
    ...others
  } = { ...TextInputPresets, ...props, id: props.id, name };

  const inputRef = useEnsuredForwardedRef(
    ref as MutableRefObject<HTMLInputElement>,
  );

  const [text, setText] = useState(value);
  const [focused, setFocused] = useState(false);

  const mounted = useMounted();

  const focus = () => {
    inputRef.current.focus();
  };

  const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setText(value);

    if (onTextChange) {
      onTextChange(value);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && event.key === 'Enter') {
      onEnter(value.toString());
    }
  };

  const onFocus = event => {
    setFocused(true);

    if (selectOnFocus) {
      inputRef.current.select();
    }

    if (onInputFocus) {
      onInputFocus(event);
    }
  };

  const onBlur = event => {
    setFocused(false);

    if (onInputBlur) {
      onInputBlur(event);
    }
  };

  useEffect(() => {
    if (autoFocus && isLargeScreen()) {
      focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setText(value);
  }, [value]);

  const onInputClear = (): void => {
    inputRef.current.value = '';
    onFieldChange({
      target: inputRef.current,
    } as ChangeEvent<HTMLInputElement>);
  };

  const spring = useSpring({
    opacity:
      clearable &&
      focused &&
      !readonly &&
      !disabled &&
      !!value &&
      !actionText &&
      !actionTx
        ? 1
        : 0,
  });

  const onActionButtonClick = () => {
    if (onActionClick) {
      onActionClick(inputRef.current);
    }
  };

  const onButtonIconClick = () => {
    if (onIconClick) {
      onIconClick(inputRef.current);
    }
  };

  return (
    <StyledInputContainer>
      {(label || labelTx) && (
        <Typography
          color={touched && (error || errorTx) ? 'red' : labelColor}
          text={label}
          tx={labelTx}
          variant="label"
        />
      )}
      {(description || descriptionTx) && (
        <StyledInputDescription
          color={descriptionColor}
          text={description}
          tx={descriptionTx}
          variant="p3"
        />
      )}
      <StyledBodyContainer>
        <StyledInputContent
          focused={focused}
          invalid={!!(errorTx || error)}
          variant={variant}
        >
          {mask && mounted ? (
            <InputMask
              disabled={disabled}
              id={id}
              invalid={!!(errorTx || error)}
              mask={MASKS[mask]}
              maskPlaceholder={null}
              name={name}
              readOnly={readonly}
              type={type}
              value={text ?? ''}
              variant={variant}
              onBlur={onBlur}
              onChange={onFieldChange}
              onFocus={onFocus}
              onKeyUp={onKeyUp}
              {...others}
            >
              <StyledInput ref={inputRef} />
            </InputMask>
          ) : (
            <StyledInput
              ref={inputRef}
              disabled={disabled}
              id={id}
              invalid={!!(errorTx || error)}
              name={name}
              paddingLeft={paddingLeft}
              readOnly={readonly}
              type={type}
              value={text ?? ''}
              variant={variant}
              onBlur={onBlur}
              onChange={onFieldChange}
              onFocus={onFocus}
              onKeyUp={onKeyUp}
              {...others}
            />
          )}

          {!text && (placeholder || placeholderTx) && (
            <StyledPlaceholder
              color={
                props.touched && (error || errorTx) ? 'red' : placeholderColor
              }
              marginLeft={paddingLeft}
              text={placeholder}
              tx={placeholderTx}
            />
          )}

          <StyledPrefixContainer>{prefixComponent}</StyledPrefixContainer>

          {clearable && (
            <animated.div style={spring}>
              <StyleClearableIconContainer onClick={onInputClear}>
                <Icon color="grey" name="close" size="1rem" />
              </StyleClearableIconContainer>
            </animated.div>
          )}

          {(actionText || actionTx) && (
            <StyledInputActionContainer>
              <Button
                text={actionText}
                textColor={actionColor}
                tx={actionTx}
                variant="action"
                width="unset"
                onClick={onActionButtonClick}
              />
            </StyledInputActionContainer>
          )}
        </StyledInputContent>
        {!!icon && (
          <StyledIconContainer onClick={onButtonIconClick}>
            {icon}
          </StyledIconContainer>
        )}
      </StyledBodyContainer>
    </StyledInputContainer>
  );
});

import * as yup from 'yup';
import { FileDetails } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { fileDetailsFormSchema } from '../concept-step-one/concept-step-one.schema';
import { ConceptStepTwoFormDef } from './concept-step-two.types';

const requiredText = 'Required';

export const minInteriors = 4;

export const emptyFileDetails: FileDetails = {
  fileExtension: undefined,
  fileName: undefined,
  fileSize: undefined,
  fileUrl: undefined,
  loading: undefined,
  percentage: undefined,
};

export const conceptStepTwoFormSchema: yup.SchemaOf<ConceptStepTwoFormDef> = yup
  .object()
  .shape({
    interiorUrls: yup
      .array()
      .of(fileDetailsFormSchema)
      .min(minInteriors)
      .required(requiredText),
    rooms: yup.array().of(yup.string()).min(1).max(3).required(requiredText),
    targetUser: yup.string().nullable().required(requiredText),
    userNeeds: yup.string().nullable().required(requiredText),
  });

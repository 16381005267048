import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDrag = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 56 53"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M44 24.5C44 33.614 36.836 41 28 41c-8.837 0-16-7.387-16-16.5S19.163 8 28 8c11.314 0 16 4.833 16 16.5z" />
        <path
          d="M30.777 32.063l6.371-7.562-6.37-7.563M25.223 32.063l-6.37-7.562 6.37-7.563"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.333}
        />
      </g>
    </svg>
  );
});
export default SvgDrag;

import { placeholderImageSrc } from 'constants/common';
import { Stack, Image, Box, Typography } from 'gantri-components';
import { StyledTileGrid } from './add-design-modal-content-tiles.styles';
import {
  ContentTileItemDefaultDef,
  AddDesignModalContentTilesPropsDef,
} from './add-design-modal-content-tiles.types';

export const AddDesignModalContentTiles = <
  Data extends ContentTileItemDefaultDef,
>(
  props: AddDesignModalContentTilesPropsDef<Data>,
) => {
  const { ItemIcon, getIsActive, getThumbnailSrc, items, onSetActiveItem } =
    props;

  return (
    <Stack alignSelf="start" gap="x">
      {items.map((item) => {
        const { description, name } = item;

        const isActive = getIsActive(item);

        return (
          <StyledTileGrid
            key={name}
            $isActive={isActive}
            alignItems="center"
            columns="6rem 1fr"
            gap="2x"
            padding="2x"
            onClick={() => {
              onSetActiveItem(item);
            }}
          >
            {/* Intentionally not using conditional */}
            {ItemIcon ? (
              <ItemIcon {...item} isActive={isActive} />
            ) : (
              <Image
                alt={name}
                containerAspectRatio="1"
                source="absolute"
                src={
                  getThumbnailSrc?.({ ...item, isActive }) ||
                  placeholderImageSrc
                }
              />
            )}
            <Box>
              <Typography
                color={isActive ? 'link' : 't1'}
                text={name}
                variant="p1"
              />
              <Typography
                color={isActive ? 'link' : 't2'}
                text={description}
                variant="p3"
              />
            </Box>
          </StyledTileGrid>
        );
      })}
    </Stack>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgCheckReverse = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 10 6"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path d="M0 0h10v6H0z" id="check-reverse_svg__a" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask fill="#fff" id="check-reverse_svg__b">
          <use xlinkHref="#check-reverse_svg__a" />
        </mask>
        <path
          d="M1.55 4.55V3.384h5.833V-2.45H8.55v7h-7z"
          fill={color}
          mask="url(#check-reverse_svg__b)"
          transform="rotate(45 5.05 1.05)"
        />
      </g>
    </svg>
  );
});
export default SvgCheckReverse;

import { forwardRef } from 'react';
import { StackedContainerPresets } from 'layout/stacked-container/stacked-container.presets';
import { Grid } from '../grid';
import { StackedContainerProps } from './stacked-container.props';

export const StackedContainer = forwardRef(
  (props: StackedContainerProps, ref) => {
    const {
      alignContent,
      children,
      flow,
      height,
      justifyContent,
      justifyItems,
      maxWidth,
      minHeight,
      ...others
    } = {
      ...StackedContainerPresets,
      ...props,
      horizontalPadding:
        props.horizontalPadding ||
        props.padding ||
        StackedContainerPresets.horizontalPadding,
      verticalPadding: props.verticalPadding || props.padding || 'unset',
    };
    return (
      <Grid
        ref={ref}
        alignContent={alignContent}
        alignItems="center"
        columns={
          flow === 'column'
            ? `minmax(min-content,${maxWidth || 'max-content'})`
            : 'unset'
        }
        flow={flow}
        height={height}
        justifyContent={justifyContent}
        justifyItems={justifyItems}
        maxWidth={maxWidth}
        minHeight={minHeight}
        {...others}
      >
        {children}
      </Grid>
    );
  },
);

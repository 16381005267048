import {
  useInvalidateFetchDesignCache,
  useInvalidateFetchDesignsCache,
  useUpdateDesign,
} from 'api/designs/routes';
import { DesignDetails } from 'api/designs/routes/fetch-design/fetch-design.types';
import { UpdateDesignConceptArgs } from 'api/designs/routes/update-design/update-design.types';

export const useOnUpdateConcept = (props: {
  design: DesignDetails;
  isApproved: boolean;
  isFinalSubmission?: boolean;
  onNavigateTo: () => void;
}) => {
  const { design, isApproved, isFinalSubmission, onNavigateTo } = props;

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();
  const { invalidateFetchDesignsCache } = useInvalidateFetchDesignsCache();

  const { isLoading, onUpdateDesign } = useUpdateDesign({
    onSuccess: async () => {
      onNavigateTo();
      await invalidateFetchDesignCache();
      await invalidateFetchDesignsCache();
    },
  });

  const onSubmit = async (values: Partial<UpdateDesignConceptArgs> = {}) => {
    if (isApproved) {
      onNavigateTo();
    } else {
      await onUpdateDesign({
        category: design?.category,
        changeBulb: design?.concept?.subSteps?.[6]?.changeBulb,
        describe: design?.concept?.subSteps?.[4]?.describe,
        howDesignUsed: design?.concept?.subSteps?.[6]?.howDesignUsed,
        id: design?.id,
        inspiration: design?.concept?.subSteps?.[4]?.inspiration,
        inspirationConnect: design?.concept?.subSteps?.[4]?.inspirationConnect,
        interiorUrls: design?.concept?.subSteps?.[5]?.interiorUrls,
        name: design?.name,
        purpose: design?.concept?.subSteps?.[4]?.purpose,
        rooms: design?.concept?.subSteps?.[5]?.rooms,
        size: design?.size,
        sketches: design?.concept?.subSteps?.[4]?.sketches,
        step: 'Concept',
        subCategory: design?.subCategory,
        submit: isFinalSubmission,
        targetUser: design?.concept?.subSteps?.[5]?.targetUser,
        uniqueDesign: design?.concept?.subSteps?.[4]?.uniqueDesign,
        userNeeds: design?.concept?.subSteps?.[5]?.userNeeds,
        whenDesignUsed: design?.concept?.subSteps?.[6]?.whenDesignUsed,
        ...values,
      });
    }
  };

  return { onSubmit, processing: isLoading };
};

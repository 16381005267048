import { Box, Typography } from 'gantri-components';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import { StyledCategoryStack } from './category-item.styles';

export const CategoryItem = (props: {
  aspectRatio?: ResolutionAwareProp<string>;
  descriptions: string[];
  src: string;
  title: string;
}) => {
  const { aspectRatio, descriptions, src, title } = props;

  return (
    <StyledCategoryStack
      $aspectRatio={aspectRatio}
      $src={src}
      alignContent="end"
      padding="3x"
    >
      <Box>
        <Typography paddingBottom="x" text={title} variant="h4" />
        {descriptions.map((text) => {
          return <Typography key={text} color="t2" text={text} />;
        })}
      </Box>
    </StyledCategoryStack>
  );
};

import { useMemo, FC } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { Download } from 'services/api/downloads';
import { api, GET_PAYOUTS } from 'services/api';
import { Typography } from 'core/typography';
import { Table } from 'core/table';
import { TableColumn } from 'core/table/table.props';
import { Grid } from 'layout/grid';
import { formatCurrency } from 'utils/formaters';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Icon } from 'core/icon';
import { Link } from 'react-router-dom';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { useModal } from 'context/modal.context';
import { PaypalInfoModal } from './modals';

export const PayoutsPage: FC = () => {
  const payoutsQuery = useQuery(GET_PAYOUTS, () => api.designer.getPayouts());

  const { showModal } = useModal({
    template: <PaypalInfoModal />,
    width: 'max-content',
  });

  const columns = useMemo(
    () =>
      [
        {
          Header: 'ID #',
          accessor: 'id',
        },
        {
          Header: 'Period',
          accessor: 'period',
        },
        {
          Cell: ({ value }) => (
            <Typography color="grey" text={value || 'Check'} />
          ),
          Header: 'Payment Method',
          accessor: 'method',
        },
        {
          Cell: ({ value }) => (
            <Typography color="grey" text={formatCurrency(value)} />
          ),
          Header: 'Amount',
          accessor: 'amount.royalty',
        },
        {
          Cell: ({ value }) =>
            value === 'Paid' ? (
              <TypographyWithIcon
                color="grey"
                icon={<Icon color="black" name="check-fill" size="2rem" />}
                text={value}
              />
            ) : (
              <Typography color="grey" text={value} />
            ),
          Header: 'Status',
          accessor: 'status',
        },
        {
          Cell: ({ row }) => (
            <Link to={`/payouts/${row.original.id}`}>
              <Icon name="arrow-right" />
            </Link>
          ),
          Header: '',
          accessor: 'userId',
          width: '1rem',
        },
      ] as TableColumn<Download>[],
    [],
  );

  return (
    <Page>
      <Grid gap={{ lg: 's5', md: 's4' }}>
        <FlexContainer justifyContent="space-between">
          <Typography text="Payouts" variant="h2" />

          <Button
            minWidth="12rem"
            text="Paypal Info"
            width="12rem"
            onClick={showModal}
          />
        </FlexContainer>

        <Table
          columns={columns}
          data={[...(payoutsQuery.data?.data || [])].reverse()}
        />
      </Grid>
    </Page>
  );
};

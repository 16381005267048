import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgDashboard = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 9v7H0V9h7zm9 0v7H9V9h7zM7 0v7H0V0h7zm9 0v7H9V0h7z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgDashboard;

import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import {
  CancelOrRefundRequest,
  CancelOrRefundResponse,
  GetOrderResponse,
  GetOrdersRequest,
  GetOrdersResponse,
  UpdateOrderAddressRequest,
  UpdateOrderAddressResponse,
} from 'services/api/order/order.types';

export class OrdersApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/designers');
  }

  async getOrders({
    sortInfo,
    ...request
  }: GetOrdersRequest): Promise<GetOrdersResponse> {
    const qs = new URLSearchParams({ ...sortInfo });

    const response = await this.api.post<GetOrdersResponse>(
      `/create/orders?${qs.toString()}`,
      request,
    );
    return this.handleResponse<GetOrdersResponse>(response);
  }

  async getOrder(id: number): Promise<GetOrderResponse> {
    const response = await this.api.get<GetOrderResponse>(
      `/create/orders/${id}`,
    );
    return this.handleResponse<GetOrderResponse>(response);
  }

  async updateAddress(
    id: number,
    request: UpdateOrderAddressRequest,
  ): Promise<UpdateOrderAddressResponse> {
    const response = await this.api.put<UpdateOrderAddressResponse>(
      `/user/transactions/${id}/address`,
      request,
    );
    return this.handleResponse<UpdateOrderAddressResponse>(response);
  }

  async cancelOrRefund(
    id: number,
    request: CancelOrRefundRequest,
  ): Promise<CancelOrRefundResponse> {
    const response = await this.api.post<CancelOrRefundResponse>(
      `/admin/transactions/${id}/cancel-or-refund`,
      request,
    );
    return this.handleResponse<CancelOrRefundResponse>(response);
  }
}

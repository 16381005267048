import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import {
  GetDashboardResponse,
  GetDashboardStatsRequest,
  GetDashboardStatsResponse,
  UpdateDashboardRequest,
  UpdateDashboardResponse,
} from 'services/api';

export class DashboardApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/dashboard');
  }

  async getData(): Promise<GetDashboardResponse> {
    const response = await this.api.get<GetDashboardResponse>(
      '/user/dashboard',
    );
    return this.handleResponse<GetDashboardResponse>(response);
  }

  async getStats(
    request: GetDashboardStatsRequest,
  ): Promise<GetDashboardStatsResponse> {
    const response = await this.api.post<GetDashboardStatsResponse>(
      '/user/get-stats',
      request,
    );
    return this.handleResponse<GetDashboardStatsResponse>(response);
  }

  async updateDashboard(
    request: UpdateDashboardRequest,
  ): Promise<UpdateDashboardResponse> {
    const response = await this.api.put<UpdateDashboardResponse>(
      '/user/dashboard/update',
      request,
    );
    return this.handleResponse<UpdateDashboardResponse>(response);
  }
}

import { forwardRef } from 'react';
import {
  StyledButton,
  StyledButtonContent,
  StyledProcessingOverlay,
} from 'core/button/button.styles';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon';
import { Link } from 'react-router-dom';
import { ButtonPresets } from './button.presets';
import { ButtonProps } from './button.props';

const typographyColor = {
  black: 'white',
  blue: 'white',
  default: 'primary',
  gold: 'white',
  green: 'white',
  primary: 'white',
  red: 'white',
  secondary: 'primary',
};

export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    analyticsEvent,
    analyticsEventData,
    analyticsEventType,
    disabled,
    fontSize,
    icon,
    iconLocation,
    linkAs,
    linkHref,
    linkPrefetch,
    linkTarget,
    onClick,
    processing,
    text,
    textColor,
    textStyle,
    textVariant,
    tx,
    txValues,
    type,
    ...others
  } = { ...ButtonPresets, ...props };

  const color =
    textColor ??
    (others.variant === 'secondary' || others.variant === 'action'
      ? others.color || 'primary'
      : typographyColor[others.color ?? others.variant]);

  const styledButton = (
    <StyledButton
      ref={ref}
      disabled={disabled || processing}
      textVariant={textVariant}
      type={type}
      onClick={onClick}
      {...others}
    >
      <StyledButtonContent {...others}>
        {icon && iconLocation === 'left' && icon}

        {(tx || text) && (
          <Typography
            color={color}
            fontSize={fontSize}
            text={text}
            textStyle={textStyle ?? 'bold'}
            tx={tx}
            txValues={txValues}
            variant={textVariant}
          />
        )}

        {icon && iconLocation === 'right' && icon}
      </StyledButtonContent>

      {others.variant !== 'action' && processing && (
        <StyledProcessingOverlay {...others}>
          <Icon color={color} name="loader" size="3rem" strokeWidth="4rem" />
        </StyledProcessingOverlay>
      )}
    </StyledButton>
  );

  return linkHref ? <Link to={linkHref}>{styledButton}</Link> : styledButton;
});

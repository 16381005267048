import firebase from '@firebase/app';
import '@firebase/auth';
import { environment } from 'config/environment';

export const initializeFirebase = (token: string) => {
  if (!firebase.apps.length && token) {
    firebase.initializeApp({
      apiKey: environment.FIREBASE_KEY,
      authDomain: environment.FIREBASE_DOMAIN,
      projectId: environment.FIREBASE_PROJECT_ID,
      storageBucket: environment.FIREBASE_STORAGE_BUCKET,
    });

    firebase
      .auth()
      .signInWithCustomToken(token)
      .catch(error => {
        console.error('firebase authentication error: ', error);
      });
  }
};

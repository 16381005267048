import {
  Grid,
  Stack,
  Typography,
  Conditional,
  Flex,
  Icon,
  Button,
  Box,
  LinearProgress,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { getStepButtonText } from '../../helpers/get-step-button-text';
import { getDesignStatusDetails } from '../../../../helpers/get-design-status-details';
import { StepItemModelPropsDef } from './step-item-model.types';

export const StepItemModel = (props: StepItemModelPropsDef) => {
  const { design } = props;

  const { concept, model } = design;

  const { isApproved: isConceptApproved } = getDesignStatusDetails(
    concept.status,
  );
  const {
    isApproved: isModelApproved,
    isCompleted: isModelCompleted,
    isInProgress: isModelInProgress,
    isReturned: isModelReturned,
    isSubmitted: isModelSubmitted,
    isWaiting: isModelWaiting,
  } = getDesignStatusDetails(model.status);

  return (
    <Grid columns="1fr max-content" gap="3x">
      <Stack gap="3x">
        <Stack gap="x">
          <Typography text="Step 2: Model" variant="h6" />
          <Typography
            text={
              isModelSubmitted
                ? "We're reviewing your design"
                : 'Create near-production-ready 3D model'
            }
          />
          <Conditional condition={isModelWaiting}>
            <Typography color="t2" text={model.status} />
          </Conditional>

          <Conditional condition={isModelSubmitted}>
            <Typography
              color="t2"
              text={`${model.status} ${formatDate(model.completedAt)}`}
            />
          </Conditional>

          <Conditional condition={isModelReturned}>
            <Typography
              color="alert"
              text={`${model.status} ${formatDate(model.refinementsNeededAt)}`}
            />
          </Conditional>

          <Conditional condition={isModelApproved}>
            <Flex alignItems="center" gap=".5x">
              <Icon
                color="link"
                name="ui-control:check_mark_circle_filled_24"
                size="2.4rem"
              />
              <Typography
                color="link"
                text={`${model.status} ${formatDate(model.approvedAt)}`}
              />
            </Flex>
          </Conditional>
        </Stack>

        <Conditional
          condition={
            isConceptApproved &&
            !isModelApproved &&
            !isModelReturned &&
            !isModelSubmitted
          }
        >
          <Flex>
            <a
              href="https://docs.gantri.com/design-process-1/model-submission-and-evaluation"
              rel="noreferrer"
              target="_blank"
            >
              <Flex alignItems="center" gap=".5x">
                <Icon
                  color="link"
                  name="docs:document_lines_24"
                  size="2.4rem"
                />
                <Typography
                  color="link"
                  text="Read model docs for help"
                  textStyle="bold"
                />
              </Flex>
            </a>
          </Flex>
        </Conditional>

        <Flex alignItems="center" gap="3x">
          <Conditional condition={isModelReturned}>
            <Link to={routePaths.designFlowModelFeedback(design.id)}>
              <Button
                justifySelf="start"
                size="large"
                text="See Feedback"
                variant="primaryAlert"
              />
            </Link>
          </Conditional>

          <Conditional condition={!!model.stepProgress && isModelInProgress}>
            <Box width="16rem">
              <LinearProgress
                current={model.stepProgress}
                showText
                total={100}
              />
            </Box>
          </Conditional>

          <Conditional condition={isConceptApproved}>
            <Link
              to={routePaths.designFlowModelStep({
                id: design.id,
                stepNumber: 1,
              })}
            >
              <Button
                justifySelf="start"
                size="large"
                text={getStepButtonText({
                  status: model.status,
                  stepProgress: model.stepProgress,
                })}
                variant={
                  isModelApproved || isModelCompleted || isModelSubmitted
                    ? 'secondary'
                    : 'primary'
                }
              />
            </Link>
          </Conditional>
        </Flex>
      </Stack>
    </Grid>
  );
};

import { FileDetails } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { useFormikContext } from 'formik';
import {
  Stack,
  FileUploader,
  Conditional,
  FormikInput,
  Dropdown,
} from 'gantri-components';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { ModelStepTwoFormDef } from '../../model-step-two.types';
import { ExistingPartUploaderPropsDef } from './existing-part-uploader.types';

export const ExistingPartUploader = (props: ExistingPartUploaderPropsDef) => {
  const {
    designId,
    expectedExtensions,
    fileName,
    fileUrl,
    index,
    isDisabled,
    material,
    maxFileSizeMB,
  } = props;

  const { setFieldValue, values } = useFormikContext<ModelStepTwoFormDef>();

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl,
    handleUploadsComplete: async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue(
        'parts',
        values.parts.map((item, savedIndex) => {
          const isUpdatedIndex = savedIndex === index;

          const fileDetails: FileDetails = isUpdatedIndex
            ? {
                fileExtension,
                fileName,
                fileSize,
                fileUrl,
                loading: false,
                percentage: 100,
              }
            : item;

          return fileDetails;
        }),
      );
    },
    onFileDelete: async () => {
      await setFieldValue(
        'parts',
        values.parts.filter((_item, savedIndex) => {
          return savedIndex !== index;
        }),
      );
    },
    path: `designs/${designId}`,
  });

  return (
    <Stack key={fileName}>
      <FileUploader
        {...fileUploaderProps}
        buttonProps={{ text: 'Upload your STEP file' }}
        expectedExtensions={expectedExtensions}
        fileName={fileName}
        isDisabled={isDisabled}
        maxFileSizeMB={maxFileSizeMB}
        variant="link"
      />

      <Conditional condition={!!fileName}>
        <FormikInput
          Field={
            <Dropdown
              disabled={isDisabled}
              items={[
                { label: 'High-temp PLA', value: 'High-temp PLA' },
                {
                  label: 'Light-diffusive PLA',
                  value: 'Light-diffusive PLA',
                },
              ]}
              placeholder="Select material"
              value={material}
            />
          }
          fieldVariant="standard"
          name={`parts[${index}].material`}
        />
      </Conditional>
    </Stack>
  );
};

import styled, { css } from 'styled-components';
import { ProgressProps } from 'core/progress/progress.props';
import { animated } from '@react-spring/web';

export const StyledProgress = styled.div<Partial<ProgressProps>>`
  ${({ background, color, width }) => css`
    width: ${width};
    min-width: 10rem;
    height: 4px;
    background-color: ${background};
    overflow: hidden;
  `}
`;

export const StyledProgressIndicator = styled(animated.div)<any>`
  ${({ color }) => css`
    height: 4px;
    background-color: ${color};
    position: absolute;
  `}
`;

import * as yup from 'yup';
import { ModelStepThreeFormDef } from './ModelStepThreeFormDef';

export const maxColors = 3;

const requiredText = 'Required';

export const modelStepThreeFormSchema: yup.SchemaOf<ModelStepThreeFormDef> = yup
  .object()
  .shape({
    colors: yup
      .array()
      .of(
        yup.object().shape({
          code: yup.string().required(requiredText),
          name: yup.string().required(requiredText),
          selected: yup.boolean(),
        }),
      )
      .min(1)
      .max(maxColors),
  });

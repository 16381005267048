export const cells = [
  {
    forBrands: 'You market product',
    forDesigners: 'Gantri markets product',
    label: 'Marketing',
  },
  {
    forBrands: 'Sell on Gantri and other retailers',
    forDesigners: 'Sell on Gantri',
    label: 'Distribution',
  },
  {
    forBrands: 'Smart inventory management and pre-made inventory',
    forDesigners: 'Smart inventory management',
    label: 'Inventory',
  },
  {
    forBrands: 'Variable earnings',
    forDesigners: 'You earn 5% on net sales',
    label: 'Earnings',
  },
  {
    forBrands: 'Monthly deposits',
    forDesigners: 'Monthly deposits',
    label: 'Deposit schedule',
  },
  {
    forBrands: 'Free to use',
    forDesigners: 'Free to use',
    label: 'Fees',
  },
  {
    forBrands: 'You own IP',
    forDesigners: 'You own IP',
    label: 'IP',
  },
];

import { Stack, Typography, TextArea } from 'gantri-components';
import { DesignFieldTextAreaPropsDef } from './design-field-text-area.types';

export const DesignFieldTextArea = (props: DesignFieldTextAreaPropsDef) => {
  const { description, label, ...textAreaProps } = props;

  return (
    <Stack>
      <Stack gap=".5x">
        <Typography text={label} variant="h6" />
        <Typography color="t2" text={description} variant="p3" />
      </Stack>

      <TextArea
        minRows={5}
        placeholder="Enter description"
        {...textAreaProps}
      />
    </Stack>
  );
};

import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { TabsProps } from 'core/tabs/tabs.props';
import { legacyTheme } from 'styles/theme';

export const StyledTabsContainer = styled.div``;

export const StyledTabsHeadingContainer = styled.div<Partial<TabsProps>>`
  ${({
    headerBorderVisible,
    headingAlign,
    headingGap,
    headingWidth,
    theme,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'justify-content',
        resolutionAwareProp: headingAlign,
      },
      {
        cssProperty: 'column-gap',
        resolutionAwareProp: headingGap,
        valueFormatter: value => {
          return `${value}px`;
        },
      },
      {
        cssProperty: 'grid-template-columns',
        resolutionAwareProp: headingWidth,
        valueFormatter: value => {
          return `repeat(auto-fit, minmax(0, ${
            value && value !== 'unset' ? value : '1fr'
          }))`;
        },
      },
    ];

    return css`
      display: grid;
      grid-auto-flow: column;
      border-bottom: ${headerBorderVisible
        ? `1px solid ${legacyTheme.colors.lightGrey}`
        : 'unset'};
      padding: 5px 0;
      position: relative;
      scrollbar-width: none;
      overflow: auto;
      ${generateStylesForResolutionAwareProps(entries)};

      &::-webkit-scrollbar {
        display: none;
      }
    `;
  }}
`;

export const StyledTabHeading = styled.div<{
  size: ResolutionAwareProp<any>;
}>`
  ${({ size }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'min-width',
        resolutionAwareProp: size,
      },
      {
        cssProperty: 'max-width',
        resolutionAwareProp: size,
      },
    ];

    return css`
      flex: 1;
      text-align: center;
      cursor: pointer;
      user-select: none;
      padding-bottom: 4px;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledTabsContent = styled.div`
  position: relative;
  overflow: hidden;
`;

export const StyledTabsContentItem = styled(animated.div)`
  ${({ theme }) => {
    return css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: ${legacyTheme.spacing.s4};
      transform-origin: center;
    `;
  }}
`;

export const StyledSelectedTabIndicator = styled(animated.div)`
  ${({ theme }) => {
    return css`
      height: 0.4rem;
      width: auto;
      background-color: ${legacyTheme.colors.black};
      position: absolute;
      bottom: 0;
      left: 0;
    `;
  }}
`;

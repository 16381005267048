import { FC, useRef, useState } from 'react';
import { AutocompleteProps } from 'core/autocomplete/autocomplete.props';
import {
  StyledAutocomplete,
  StyledAutocompleteOption,
  StyledAutocompletePopup,
} from 'core/autocomplete/autocomplete.styles';
import { AutocompletePresets } from 'core/autocomplete/autocomplete.presets';
import { TextInput } from 'core/text-input';
import { useSpring } from '@react-spring/core';

export const Autocomplete: FC<Partial<AutocompleteProps>> = props => {
  const {
    footerComponent,
    labelProperty,
    onBlur: onInputBlur,
    onSelect,
    onTextChange,
    options,
    value,
    valueProperty,
    ...others
  } = { ...AutocompletePresets, ...props };

  const [popupVisible, setPopupVisible] = useState(false);
  const [activePosition, setActivePosition] = useState(0);

  const ref = useRef(null);

  const spring = useSpring({
    config: { duration: 100 },
    opacity: popupVisible ? 1 : 0,
    scaleY: popupVisible ? 1 : 0,
  });

  const onFocus = () => {
    if (options.length && value && String(value).length > 2) {
      setPopupVisible(true);
    }
  };

  const onBlur = event => {
    setTimeout(() => {
      setPopupVisible(false);
    }, 100);

    if (onInputBlur) {
      onInputBlur(event);
    }
  };

  const onInputTextChange = (value: string) => {
    if (!popupVisible && value && value.length > 2) {
      setPopupVisible(true);
    }

    if (onTextChange) {
      onTextChange(value);
    }
  };

  const onEnter = () => {
    if (onSelect && activePosition !== undefined) {
      onSelect(options[activePosition]);
    }
    setPopupVisible(false);
  };

  const onKeyUp = ({ key }) => {
    let position = 0;
    const optionsCount = options.length ?? 0;

    if (key === 'ArrowUp') {
      if (!popupVisible) {
        setPopupVisible(true);
      }

      position = !activePosition ? optionsCount - 1 : activePosition - 1;
    } else if (key === 'ArrowDown') {
      if (!popupVisible) {
        setPopupVisible(true);
      }

      position =
        activePosition === null || activePosition === optionsCount - 1
          ? 0
          : activePosition + 1;
    } else if (key === 'Enter') {
      onEnter();
    }

    setActivePosition(position);
  };

  const onActionClick = option => {
    if (onSelect) {
      onSelect(option);
    }

    setPopupVisible(false);
  };

  return (
    <StyledAutocomplete>
      <TextInput
        {...others}
        autoComplete="new-password"
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={onKeyUp}
        onTextChange={onInputTextChange}
      />

      <StyledAutocompletePopup ref={ref} style={spring as any}>
        {options.map((option, index) => (
          <StyledAutocompleteOption
            key={option[valueProperty]}
            active={activePosition === index}
            tabIndex={-1}
            onClick={() => onActionClick(option)}
          >
            {option[labelProperty]}
          </StyledAutocompleteOption>
        ))}
        {footerComponent}
      </StyledAutocompletePopup>
    </StyledAutocomplete>
  );
};

import { FC } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { api } from 'services/api';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { Cell } from 'layout/cell';
import { LoadingOverlay } from 'layout/loading-overlay';
import { GET_ORDER, Shipment } from 'services/api/order';
import { StackedContainer } from 'layout/stacked-container';
import { formatCurrency } from 'utils/formaters';
import { GiftCardRecord } from 'types/gift-card.type';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { GiftCard, ShippingCard, ShippingInfo } from './components';

export const OrderPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const query = useQuery([GET_ORDER, id], () => api.order.getOrder(+id));

  if (!query.data?.order) {
    return <LoadingOverlay />;
  }

  const shippingVisible = !(
    ['Trade', 'Trade Refund', 'Refund', 'Third Party Refund'].includes(
      query.data.order.type,
    ) ||
    !query.data.order.address ||
    query.data.order.address.doNotShip
  );

  const summaryVisible = !['Trade', 'Trade Refund'].includes(
    query.data.order.type,
  );

  return (
    <Page>
      <Grid
        columnGap="unset"
        columns={{ lg: 'minmax(60rem, 7fr) 2fr 3fr', md: '1fr' }}
        rowGap={{ md: 's4', sm: 's3' }}
      >
        <Cell>
          <FlexContainer justifyContent="space-between">
            <Typography
              marginBottom="s3"
              text={`${query.data?.order.type} #${id}`}
              variant="h2"
            />

            {query.data?.order?.type === 'Third Party' && (
              <Button
                hidden={{ lg: false, sm: true }}
                linkHref={`/orders/${id}/refund`}
                minWidth="12rem"
                text="Refund"
                variant="secondary"
              />
            )}
          </FlexContainer>

          <Grid
            columnGap={{ lg: 's5', md: 's4', sm: 's3' }}
            columns="repeat(auto-fit, minmax(8rem, max-content))"
            marginBottom={{ lg: 's5', md: 's4', sm: 's3' }}
          >
            <Cell>
              <Typography text="Created" />
              <Typography
                color="grey"
                text={formatDate(
                  query.data?.order.createdAtSec,
                  'MM/DD/YYYY [at] hh:mmA',
                )}
              />
            </Cell>

            <Cell>
              <Typography text="Completed" />
              <Typography
                color="grey"
                text={
                  query.data?.order.completedAtSec === 'TBD'
                    ? 'TBD'
                    : formatDate(
                        query.data?.order.completedAtSec,
                        'MM/DD/YYYY [at] hh:mmA',
                      )
                }
              />
            </Cell>

            <Cell>
              <Typography text="Status" />
              <Typography color="grey" text={query.data?.order.status} />
            </Cell>

            {query.data?.order.type === 'Refund' && (
              <Cell>
                <Typography text="Original" />
                <Typography
                  href={`/orders/${query.data?.order.transactionId}`}
                  text={`#${query.data?.order.transactionId}`}
                  variant="link"
                />
              </Cell>
            )}
          </Grid>

          {query.data?.order?.type === 'Third Party' && (
            <Button
              hidden={{ lg: true, sm: false }}
              linkHref={`/orders/${id}/refund`}
              minWidth="12rem"
              text="Refund"
              variant="secondary"
              width="12rem"
            />
          )}

          <Cell>
            <StackedContainer padding="unset" rowGap={{ lg: 's2', sm: 's1' }}>
              {query.data.order.shipments.map((shipment: Shipment) => (
                <ShippingCard key={shipment.id} shipment={shipment} />
              ))}

              {query.data.order.giftCards.map((giftCard: GiftCardRecord) => (
                <GiftCard key={giftCard.id} card={giftCard} />
              ))}
            </StackedContainer>
          </Cell>
        </Cell>

        <Cell column={{ lg: 3, md: 1 }}>
          <StackedContainer
            padding="unset"
            paddingTop={{ lg: '7.8rem', md: '0rem' }}
            rowGap={{ lg: 's5', md: 's4', sm: 's3' }}
          >
            {shippingVisible && (
              <Cell>
                <ShippingInfo order={query.data.order} />
              </Cell>
            )}

            {summaryVisible && (
              <Cell>
                <Typography
                  marginBottom="s1"
                  text="Summary"
                  textStyle="bold"
                  variant="p1"
                />

                <Grid columns={2} gap="unset">
                  <Typography color="grey" text="Subtotal" />
                  <Typography
                    align="right"
                    color="grey"
                    text={formatCurrency(query.data.order.amount.subtotal)}
                  />

                  <Typography color="grey" text="Shipping" />
                  <Typography
                    align="right"
                    color="grey"
                    text={formatCurrency(query.data.order.amount.shipping)}
                  />

                  <Typography color="grey" text="Tax" />
                  <Typography
                    align="right"
                    color="grey"
                    text={formatCurrency(query.data.order.amount.tax)}
                  />

                  {query.data.order?.gift?.type === 'Gift' && (
                    <>
                      <Typography color="grey" text="Gift Code" />
                      <Typography
                        align="right"
                        color="grey"
                        text={query.data.order?.gift?.code}
                      />
                    </>
                  )}

                  <Typography text="Total" />
                  <Typography
                    align="right"
                    text={formatCurrency(query.data.order.amount.total)}
                  />
                </Grid>
              </Cell>
            )}
          </StackedContainer>
        </Cell>
      </Grid>
    </Page>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgNavHelp = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      fill={color}
      fillRule="evenodd"
      height="1em"
      opacity={0.7}
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 12c0-.755.222-1.452.585-2.055L4.32 5.598A9.95 9.95 0 002 12a9.95 9.95 0 002.321 6.401l4.263-4.347A3.972 3.972 0 018 12m8 0a3.96 3.96 0 01-.584 2.055l4.263 4.347A9.955 9.955 0 0022 12a9.95 9.95 0 00-2.321-6.402l-4.263 4.347c.363.602.584 1.3.584 2.055m-2.014 3.453A3.97 3.97 0 0112 16a3.96 3.96 0 01-1.986-.548L5.75 19.8A9.95 9.95 0 0012 22a9.954 9.954 0 006.25-2.2l-4.264-4.347zm-3.97-6.906C10.602 8.208 11.274 8 12 8s1.4.208 1.986.547L18.25 4.2A9.95 9.95 0 0012 2a9.958 9.958 0 00-6.25 2.2l4.264 4.348z" />
    </svg>
  );
});
export default SvgNavHelp;

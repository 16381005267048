import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Grid } from 'layout/grid';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { DividedContainer } from 'layout/divided-container';
import { Thumbnail } from 'core/thumbnail';
import { Cell } from 'layout/cell';
import { TextArea } from 'core/text-area';
import { StyledThankYouCard } from './thank-you.styles';
import { ThankYouSchema } from './thank-you.schema';

export const ThankYouModal: FC = () => {
  const [message, setMessage] = useState('');
  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    setMessage(userQuery.data.data?.designerInfo?.bodyMessage || '');
  }, [userQuery.data.data?.designerInfo?.bodyMessage]);

  const updateThankYouMessageMutation = useMutation(
    async (bodyMessage: string) =>
      api.designer.updateThankYouMessage(bodyMessage),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const handleSubmit = async values => {
    await updateThankYouMessageMutation.mutateAsync(values.bodyMessage);
  };

  return (
    <DividedContainer
      leftContent={
        <>
          <Typography
            align={{ md: 'center' }}
            color="grey"
            text="PREVIEW"
            variant="h5"
          />

          <StyledThankYouCard>
            <Typography text="Thank you!" variant="h4" />

            <Typography
              color="grey"
              text={`"${message}"`}
              variant="p3"
              verticalMargin="s2"
              whiteSpace="pre-wrap"
            />

            <Grid
              alignItems={{ lg: 'unset', md: 'center' }}
              columns="repeat(2, max-content)"
              gap="0.8rem"
            >
              <Thumbnail
                objectFit="cover"
                size="4rem"
                src={userQuery.data.data?.designerInfo.logoUrl}
              />

              <Cell>
                <Typography
                  text={userQuery.data.data?.designerInfo.name}
                  variant="p3"
                />
                <Typography
                  color="grey"
                  text={`${userQuery.data.data?.designerInfo.city}, ${
                    userQuery.data.data?.designerInfo.state ||
                    userQuery.data.data?.designerInfo.country
                  }`}
                  variant="p3"
                />
              </Cell>
            </Grid>
          </StyledThankYouCard>
        </>
      }
      leftContentProps={{
        direction: 'column',
        padding: {
          lg: '6rem 4rem',
          md: '0 3rem',
          sm: '0 1.2rem',
        },
      }}
      rightContent={
        <Formik
          enableReinitialize
          initialValues={{
            bodyMessage: message,
          }}
          validateOnMount
          validationSchema={ThankYouSchema}
          onSubmit={handleSubmit}
        >
          {props => (
            <Form autoComplete="off">
              <Typography
                align="center"
                display={{ lg: 'none', md: 'block' }}
                marginBottom="s3"
                text="Edit message"
                variant="h3"
              />

              <Grid
                backgroundColor={{ md: 'white' }}
                gap={{ lg: 's5', md: 's4', sm: 's3' }}
                horizontalPadding={{ lg: 'unset', md: 's2' }}
                padding="unset"
                verticalPadding={{ lg: 'unset', md: 's3' }}
              >
                <Typography
                  align="center"
                  display={{ lg: 'block', md: 'none' }}
                  text="Edit message"
                  variant="h3"
                />

                <TextArea
                  error={props.errors.bodyMessage as string}
                  maxLength={255}
                  name="bodyMessage"
                  rows={7}
                  touched={props.touched.bodyMessage as boolean}
                  value={props.values.bodyMessage}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  onTextChange={setMessage}
                />

                <Button
                  disabled={
                    userQuery.data.data?.designerInfo?.bodyMessage ===
                      message || !props.isValid
                  }
                  loading={updateThankYouMessageMutation.isLoading}
                  text="Save"
                  type="submit"
                  variant="primary"
                />
              </Grid>
            </Form>
          )}
        </Formik>
      }
      rightContentProps={{
        backgroundColor: {
          lg: 'white',
          md: 'cream',
        },
        direction: 'column',
        justifyContent: {
          lg: 'center',
          md: 'flex-start',
        },
        padding: {
          lg: '6rem 4rem',
          md: '12rem 3rem',
          sm: '6rem 1.2rem',
        },
      }}
    />
  );
};

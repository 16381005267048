import * as yup from 'yup';
import { ModelStepTwoFormDef } from './model-step-two.types';

const requiredText = 'Required';

export const modelStepTwoFormSchema: yup.SchemaOf<ModelStepTwoFormDef> = yup
  .object()
  .shape({
    parts: yup
      .array()
      .of(
        yup.object().shape({
          fileExtension: yup.string(),
          fileName: yup.string(),
          fileSize: yup.number(),
          fileUrl: yup.string(),
          loading: yup.boolean(),
          material: yup.string().required(requiredText),
          name: yup.string(),
          percentage: yup.number(),
        }),
      )
      .min(1)
      .required(requiredText),
  });

import { Modal, useMediaLarge } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useResetRecoilAtomsOnMount } from 'utils/hooks/use-reset-recoil-atoms-on-mount';
import { AddDesignModalPropsDef } from './add-design-modal.types';
import { addDesignModalSteps } from './add-design-modal.constants';
import { addDesignModalAtoms } from './add-design-modal.atoms';
import { AddDesignModalFooter } from './components/add-design-modal-footer';
import { AddDesignModalContent } from './components/add-design-modal-content';
import { getAddDesignHeaderText } from './helpers/get-add-design-header-text';

export const AddDesignModal = (props: AddDesignModalPropsDef) => {
  const { onClose } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(addDesignModalAtoms);

  const step = useRecoilValue(addDesignModalAtoms.step);

  const isLarge = useMediaLarge();

  return (
    <ResetAtomsWrapper>
      <Modal
        contentStackProps={{
          style: {
            alignContent:
              isLarge && step === addDesignModalSteps.name
                ? 'center'
                : undefined,
          },
        }}
        footer={<AddDesignModalFooter onClose={onClose} />}
        header={getAddDesignHeaderText(step)}
        height={{ lg: '48rem', md: '100%' }}
        width={{ lg: '48rem', md: '100%' }}
        onClose={onClose}
      >
        <AddDesignModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};

import {
  StyledSizedContainer,
  StyledSizedContainerContent,
} from 'layout/sized-container/sized-container.styles';
import { SizedContainerProps } from './sized-container.props';
import { ImageContainerPresets } from '../image-container/image-container.presets';

export const SizedContainer = (props: SizedContainerProps) => {
  const { children, ...others } = {
    ...ImageContainerPresets,
    ...props,
  };

  return (
    <StyledSizedContainer {...others}>
      <StyledSizedContainerContent>{children}</StyledSizedContainerContent>
    </StyledSizedContainer>
  );
};

import { FC, useState } from 'react';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import {
  api,
  AsSeenOnRecord,
  BulbChangeInstruction,
  ProductOption,
  UpdateAsSeenOnRequest,
  UpdateAsSeenOnResponse,
} from 'services/api';
import { StackedContainer } from 'layout/stacked-container';
import { useTheme } from 'styled-components';
import { SelectInput } from 'core/select-input';
import { formatCurrency } from 'utils/formaters';
import { Spacer } from 'layout/spacer';
import { useMutation } from 'react-query';
import { useNotification } from 'context/notification.context';
import { getFileUrl, Image } from 'gantri-components';
import { AsSeenOn } from '../as-seen-on';
import {
  StyledBulbChangeInstruction,
  StyledProductColor,
  StyledProductSize,
} from './product-info.styles';
import { ProductInfoProps } from './product-info.types';

export const ProductInfo: FC<ProductInfoProps> = props => {
  const { product } = props;
  const [selectedSku, setSelectedSku] = useState(product.skus?.[0].code);
  const [snapshot, setSnapshot] = useState(JSON.stringify(product.asSeenOn));

  const theme = useTheme();
  const { notify } = useNotification();

  const updateAsSeenOnMutation = useMutation(
    async (data: UpdateAsSeenOnRequest) =>
      api.product.updateAsSeenOn(product.id, data),
    {
      onSuccess: async (response: UpdateAsSeenOnResponse) => {
        notify(response.notice);
      },
    },
  );

  const onAsSeenOnUpdate = async (records: AsSeenOnRecord[]) => {
    const temporal = JSON.stringify(records);
    if (snapshot !== temporal) {
      await updateAsSeenOnMutation.mutateAsync({ asSeenOn: records });
      setSnapshot(temporal);
    }
  };

  return (
    <StackedContainer padding="unset" rowGap={{ lg: 's6', md: 's5' }}>
      <Cell>
        <Typography marginBottom="s3" text="About" variant="h3" />

        <Grid
          columnGap={{ lg: 's5', md: 's4', sm: 's3' }}
          columns={{
            lg: 'repeat(2, minmax(20rem, max-content))',
            sm: 'repeat(2, minmax(15rem, max-content))',
          }}
        >
          <Cell>
            <Typography text="Name" />
            <Typography color="grey" text={product.name} />
          </Cell>

          <Cell>
            <Typography text="Status" />
            <Typography color="grey" text={product.status} />
          </Cell>

          <Cell>
            <Typography text="Category" />
            <Typography color="grey" text={product.category} />
          </Cell>

          <Cell>
            <Typography text="Sub-Category" />
            <Typography color="grey" text={product.subCategory} />
          </Cell>

          <Cell>
            <Typography text="Product ID" />
            <Typography color="grey" text={product.id} />
          </Cell>

          <Cell>
            <Typography text="Style Tags" />
            <Typography
              color="grey"
              text={product.tags.join(', ')}
              transform="capitalize"
            />
          </Cell>

          <Cell width={2}>
            <Typography text="Rooms" />
            <Typography color="grey" text={product.rooms.join(', ')} />
          </Cell>

          <Cell>
            <Typography text="Size" />
            <Grid columns="repeat(3, max-content)" gap="s1" marginTop="s1">
              {product.sizes.map((size: ProductOption) => (
                <StyledProductSize key={size.code}>
                  {size.code[0]}
                </StyledProductSize>
              ))}
            </Grid>
          </Cell>

          <Cell>
            <Typography text="Colors" />
            <Grid
              columns={{
                lg: 'repeat(auto-fit, 4rem)',
                sm: 'repeat(auto-fit, 2.4rem)',
              }}
              gap="s1"
              marginTop="s1"
            >
              {product.colors.map((color: ProductOption) => (
                <StyledProductColor key={color.code} color={color.code} />
              ))}
            </Grid>
          </Cell>

          {!!product.variants?.length && (
            <Cell width={2}>
              <Typography text="Variants" />
              <Typography
                color="grey"
                text={product.variants.join(', ')}
                transform="capitalize"
              />
            </Cell>
          )}
        </Grid>
      </Cell>

      <Cell>
        <Typography marginBottom="s3" text="As Seen On" variant="h3" />
        <AsSeenOn product={product} onUpdate={onAsSeenOnUpdate} />
      </Cell>

      <Cell>
        <Typography marginBottom="s3" text="Story" variant="h3" />

        <StackedContainer gap={{ lg: 's3', md: 's2' }} padding="unset">
          <Cell>
            <Typography text="Heading" />
            <Typography color="grey" text={product.heading} />
          </Cell>
          <Cell>
            <Typography text="Description" />
            <Typography
              color="grey"
              maxWidth="65rem"
              text={product.description}
            />
          </Cell>

          <Cell>
            <Typography text="Keywords" />
            <Typography
              color="grey"
              maxWidth="65rem"
              text={product.content}
              whiteSpace="pre-line"
            />
          </Cell>
        </StackedContainer>

        <Grid
          columns={{ lg: 'repeat(4, 1fr)', sm: 'max-content 1fr' }}
          marginTop={{ lg: 's6', md: 's5' }}
          maxWidth={{ lg: '60vw', md: 'unset' }}
          rowGap="s3"
        >
          <Cell width={1}>
            <SelectInput
              label="SKU"
              labelProperty="code"
              options={product.skus}
              value={selectedSku}
              valueProperty="code"
              onChange={setSelectedSku}
            />
            <Spacer height={{ lg: 's5', md: 's4' }} />
          </Cell>

          <Cell width={{ lg: 3, sm: 2 }} />
          {!!selectedSku && (
            <>
              <Cell>
                <Typography text="Price" />
                <Typography
                  color="grey"
                  text={formatCurrency(product.skusData[selectedSku].price)}
                />
              </Cell>

              <Cell>
                <Typography text="Bulb" />
                <Typography
                  color="grey"
                  text={product.skusData[selectedSku].bulb}
                />
              </Cell>

              <Cell width={2}>
                <Typography text="Dimension" />
                <Typography
                  color="grey"
                  text={`${product.skusData[selectedSku].dimension.height}"H x ${product.skusData[selectedSku].dimension.width}"W x ${product.skusData[selectedSku].dimension.depth}"D`}
                />
              </Cell>

              <Cell>
                <Typography text="Earnings" />
                <Typography
                  color="grey"
                  text={formatCurrency(
                    product.skusData[selectedSku].designEarnings,
                  )}
                />
              </Cell>

              <Cell>
                <Typography text="Compatible with" />
                <Typography
                  color="grey"
                  text={product.skusData[selectedSku].compatibleWith}
                />
              </Cell>

              <Cell width={2}>
                <Typography text="Footprint" />
                <Typography
                  color="grey"
                  text={`${product.skusData[selectedSku].footprint.width}"W x ${product.skusData[selectedSku].footprint.depth}"D`}
                />
              </Cell>

              <Cell width={2} />
              <Cell width={2}>
                <Grid columnGap="s5" columns="repeat(2, max-content)">
                  <Cell>
                    <Typography text="Weight" />
                    <Typography
                      color="grey"
                      text={`${
                        product.skusData[selectedSku].weight || 0
                      } pounds`}
                    />
                  </Cell>

                  <Cell>
                    <Typography text="Cable length" />
                    <Typography
                      color="grey"
                      text={`${
                        product.skusData[selectedSku].cableLength || 0
                      } inches`}
                    />
                  </Cell>
                </Grid>
              </Cell>

              <Cell width={2} />
              <Cell width={2}>
                <Typography text="Materials" />
                <Typography
                  color="grey"
                  text={product.skusData[selectedSku].material}
                />
              </Cell>
            </>
          )}
        </Grid>
      </Cell>

      <Cell>
        <Typography marginBottom="s3" text="Bulb change" variant="h3" />

        {(product.bulbChangeInstructions || []).map(
          (instruction: BulbChangeInstruction, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledBulbChangeInstruction key={index}>
              <Image
                alt=""
                height={{ lg: 200, sm: 110 }}
                source="dynamic"
                src={getFileUrl<'products'>({
                  directory: 'products',
                  fileName: instruction.imageUrl,
                  fileType: 'bulb-change-instructions',
                  identifiers: {
                    productId: product.id,
                  },
                })}
                width={{ lg: 200, sm: 110 }}
              />

              <div>
                <Typography text={`Step ${index + 1}`} />
                <Typography
                  color="grey"
                  maxWidth="25rem"
                  text={instruction.content}
                />
              </div>
            </StyledBulbChangeInstruction>
          ),
        )}
      </Cell>
    </StackedContainer>
  );
};

import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AcceptPage,
  ConceptFeedbackPage,
  ConceptStepPage,
  ContentStepPage,
  DashboardPage,
  DesignsPage,
  DownloadsPage,
  EngineeringStepPage,
  HomePage,
  ModelFeedbackPage,
  ModelStepPage,
  OrderPage,
  OrdersPage,
  PayoutPage,
  PayoutsPage,
  ProductPage,
  ProductsPage,
  ProfilePage,
  RefundPage,
  StatsPage,
  StepListPage,
  SubmitConceptPage,
  SubmitModelPage,
} from './pages';

export const routePaths = {
  accept: '/accept',
  dashboard: '/dashboard',
  designFlow: '/design-flow',
  designFlowConceptFeedback: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/concept-feedback` as const;
  },
  designFlowConceptStep: (props: {
    id: number | string;
    stepNumber: number | string;
  }) => {
    const { id, stepNumber } = props;

    return `${routePaths.designFlow}/${id}/concept-step/${stepNumber}` as const;
  },
  designFlowContent: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/content` as const;
  },
  designFlowEngineering: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/engineering` as const;
  },
  designFlowModelFeedback: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/model-feedback` as const;
  },
  designFlowModelStep: (props: {
    id: number | string;
    stepNumber: number | string;
  }) => {
    const { id, stepNumber } = props;

    return `${routePaths.designFlow}/${id}/model-step/${stepNumber}` as const;
  },
  designFlowStepList: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/step-list` as const;
  },
  designFlowSubmitConcept: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/submit-concept` as const;
  },
  designFlowSubmitModel: (id: number | string) => {
    return `${routePaths.designFlow}/${id}/submit-model` as const;
  },
  designs: '/designs',
  downloads: '/downloads',
  home: '/',
  orders: '/orders',
  payouts: '/payouts',
  products: '/products',
  profile: '/profile',
  stats: '/stats',
} as const;

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route caseSensitive element={<HomePage />} path={routePaths.home} />
      <Route
        caseSensitive
        element={<DashboardPage />}
        path={routePaths.dashboard}
      />
      <Route
        caseSensitive
        element={<ProfilePage />}
        path={routePaths.profile}
      />

      <Route
        caseSensitive
        element={<DesignsPage />}
        path={routePaths.designs}
      />

      <Route
        caseSensitive
        element={<StepListPage />}
        path={routePaths.designFlowStepList(':id')}
      />

      <Route
        caseSensitive
        element={<ConceptStepPage />}
        path={routePaths.designFlowConceptStep({
          id: ':id',
          stepNumber: ':stepNumber',
        })}
      />
      <Route
        caseSensitive
        element={<ConceptFeedbackPage />}
        path={routePaths.designFlowConceptFeedback(':id')}
      />
      <Route
        caseSensitive
        element={<SubmitConceptPage />}
        path={routePaths.designFlowSubmitConcept(':id')}
      />

      <Route
        caseSensitive
        element={<ModelStepPage />}
        path={routePaths.designFlowModelStep({
          id: ':id',
          stepNumber: ':stepNumber',
        })}
      />
      <Route
        caseSensitive
        element={<ModelFeedbackPage />}
        path={routePaths.designFlowModelFeedback(':id')}
      />
      <Route
        caseSensitive
        element={<SubmitModelPage />}
        path={routePaths.designFlowSubmitModel(':id')}
      />

      <Route
        caseSensitive
        element={<EngineeringStepPage />}
        path={routePaths.designFlowEngineering(':id')}
      />

      <Route
        caseSensitive
        element={<ContentStepPage />}
        path={routePaths.designFlowContent(':id')}
      />

      <Route
        caseSensitive
        element={<RefundPage />}
        path={`${routePaths.orders}/:id/refund`}
      />
      <Route
        caseSensitive
        element={<OrderPage />}
        path={`${routePaths.orders}/:id`}
      />
      <Route caseSensitive element={<OrdersPage />} path={routePaths.orders} />
      <Route
        caseSensitive
        element={<PayoutPage />}
        path={`${routePaths.payouts}/:id`}
      />
      <Route
        caseSensitive
        element={<PayoutsPage />}
        path={routePaths.payouts}
      />
      <Route
        caseSensitive
        element={<ProductPage />}
        path={`${routePaths.products}/:id`}
      />
      <Route
        caseSensitive
        element={<ProductsPage />}
        path={routePaths.products}
      />
      <Route caseSensitive element={<StatsPage />} path={routePaths.stats} />
      <Route
        caseSensitive
        element={<DownloadsPage />}
        path={routePaths.downloads}
      />
      <Route caseSensitive element={<AcceptPage />} path={routePaths.accept} />

      <Route caseSensitive element={<StatsPage />} path="*" />
    </Routes>
  );
};

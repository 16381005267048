import {
  Grid,
  Stack,
  Typography,
  Conditional,
  Flex,
  Icon,
  Button,
  Box,
  LinearProgress,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { getDesignStatusDetails } from '../../../../helpers/get-design-status-details';
import { StepItemEngineeringPropsDef } from './step-item-engineering.types';

export const StepItemEngineering = (props: StepItemEngineeringPropsDef) => {
  const { design } = props;

  const { engineering, model } = design;

  const { isApproved: isModelApproved } = getDesignStatusDetails(model.status);

  const {
    isApproved: isEngineeringApproved,
    isCompleted: isEngineeringCompleted,
    isFailed: isEngineeringFailed,
    isReturned: isEngineeringReturned,
    isSubmitted: isEngineeringSubmitted,
    isWaiting: isEngineeringWaiting,
  } = getDesignStatusDetails(engineering.status);

  return (
    <Grid columns="1fr max-content" gap="3x">
      <Stack gap="3x">
        <Stack gap="x">
          <Typography text="Step 3: Engineering" variant="h6" />
          <Typography text="We prototype and test your design" />
          <Conditional condition={isEngineeringWaiting}>
            <Typography color="t2" text={engineering.status} />
          </Conditional>

          <Conditional condition={isEngineeringFailed}>
            <Typography color="alert" text={engineering.status} />
          </Conditional>

          <Conditional condition={isEngineeringCompleted}>
            <Flex alignItems="center" gap=".5x">
              <Icon
                color="link"
                name="ui-control:check_mark_circle_filled_24"
                size="2.4rem"
              />
              <Typography
                color="link"
                text={`${engineering.status} ${formatDate(
                  engineering.completedAt,
                )}`}
              />
            </Flex>
          </Conditional>
        </Stack>

        <Flex alignItems="center" gap="3x">
          <Conditional
            condition={
              !!engineering.modelingOptimization.stepProgress &&
              !isEngineeringApproved &&
              !isEngineeringCompleted &&
              !isEngineeringReturned &&
              !isEngineeringSubmitted &&
              !isEngineeringWaiting
            }
          >
            <Box width="16rem">
              <LinearProgress
                current={engineering.modelingOptimization.stepProgress}
                showText
                total={100}
              />
            </Box>
          </Conditional>

          <Conditional condition={isModelApproved}>
            <Link to={routePaths.designFlowEngineering(design.id)}>
              <Button
                justifySelf="start"
                size="large"
                text={isEngineeringCompleted ? 'View' : 'See Progress'}
                variant={isEngineeringCompleted ? 'secondary' : 'primary'}
              />
            </Link>
          </Conditional>
        </Flex>
      </Stack>
    </Grid>
  );
};

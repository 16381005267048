import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgShare = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 17 12"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M1 14h16V-2H1z" />
        <path
          d="M10.429 3.65s-8.93-1.007-8.93 7.386C4.358 6 10.43 7.679 10.43 7.679v2.686l6.07-4.7L10.43.964V3.65z"
          stroke={color}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
export default SvgShare;

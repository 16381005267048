import { Box, Button, Stack } from 'gantri-components';
import { useParams, Link } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Page } from 'core/page';
import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { formatDate } from 'utils/date';
import { routePaths } from 'src/app-routes';
import { useMemo } from 'react';
import { StepHeader } from '../../components/step-header';
import { HeaderSubmissionDetails } from '../../components/step-header/components/header-submission-details';
import { ChecklistReviewList } from '../submit-concept/checklist-review-list';

export const ConceptFeedbackPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const list = useMemo(() => {
    const list = [
      ...(design?.concept.review?.[1]?.sections || []),
      ...(design?.concept.review?.[2]?.sections || []),
      ...(design?.concept.review?.[3]?.sections || []),
      ...(design?.concept.review?.[4]?.sections || []),
    ].reduce((accumulator, item) => {
      if (item.approved) {
        return [...accumulator, item];
      }

      return [item, ...accumulator];
    }, []);

    return list;
  }, [design]);

  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          {design && (
            <Stack gap="5x">
              <StepHeader design={design} showBackButton>
                <HeaderSubmissionDetails
                  descriptionProps={{
                    color: 'alert',
                    text: `Returned ${formatDate(
                      design.concept.refinementsNeededAt,
                    )}`,
                  }}
                  imgProps={{ src: 'graphite/design-flow/icon-work_2x.png' }}
                  titleProps={{ text: 'Your concept needs more work' }}
                >
                  <Link
                    to={routePaths.designFlowConceptStep({
                      id: design.id,
                      stepNumber: 1,
                    })}
                  >
                    <Button size="large" text="Edit Design" />
                  </Link>
                </HeaderSubmissionDetails>
              </StepHeader>

              <ChecklistReviewList list={list} showApprovedStatus />
            </Stack>
          )}
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

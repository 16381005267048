import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgTip = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx={10} cy={10} r={9.5} stroke={color} />
        <text
          fill={color}
          fontFamily="UniversLTStd, Univers LT Std"
          fontSize={12}
        >
          <tspan x={7} y={9}>
            ?
          </tspan>
        </text>
      </g>
    </svg>
  );
});
export default SvgTip;

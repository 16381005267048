import { Stack, Image, Typography } from 'gantri-components';
import { EngineeringStepImagePropsDef } from './engineering-step-image.types';

export const EngineeringStepImage = (props: EngineeringStepImagePropsDef) => {
  const { fileUrl, text } = props;

  return (
    <Stack>
      <Image
        alt={text}
        containerAspectRatio="1"
        source="absolute"
        src={fileUrl}
      />
      <Typography text={text} />
    </Stack>
  );
};

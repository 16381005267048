import { useGenericMutateQuery } from 'api/hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from 'api/hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { addDesign } from './add-design';
import { AddDesignResponse, AddDesignArgs } from './add-design.types';

export const useAddDesign = <TransformedData = AddDesignResponse>(
  props?: GenericMutateQueryProps<
    AddDesignArgs,
    AddDesignResponse,
    TransformedData
  >,
) => {
  const { onMutate: onAddDesign, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to add design.',
    mutationFn: addDesign,
    ...props,
  });

  return { ...rest, onAddDesign };
};

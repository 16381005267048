import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledShipment = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px solid ${legacyTheme.colors.lightGrey};
      padding: ${legacyTheme.spacing.s3};
      border-radius: 0.6rem;
    `;
  }}
`;

export const StyledShipmentStock = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      column-gap: ${legacyTheme.spacing.s2};

      ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
    `}
    `;
  }}
`;

export const StyledProgressInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
  gap: 0.6rem;
`;

export const StyledRefundReason = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${legacyTheme.colors.lightGrey};
      padding-top: ${legacyTheme.spacing.s2};
      margin-top: ${legacyTheme.spacing.s2};

      ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
      padding-top: ${legacyTheme.spacing.s1};
      margin-top: ${legacyTheme.spacing.s1};
    `}
    `;
  }}
`;

import { FC, useEffect, useState } from 'react';
import { Typography } from 'core/typography';
import { isObject } from 'lodash';
import { Icon } from 'core/icon';
import { Checkbox } from 'core/checkbox';
import {
  StyledSelectHeader,
  StyledSelectOption,
  StyledSelectOverview,
} from './select-overlay.styles';
import { SelectOverlayProps } from './select-overlay.types';

export const SelectOverlay: FC<SelectOverlayProps> = props => {
  const {
    labelProperty,
    multiple,
    onClose,
    onSelect,
    options,
    renderCheckbox,
    shouldTranslateOption,
    value,
    valueProperty,
  } = props;

  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onOptionSelected = option => {
    const value = isObject(option) ? option?.[valueProperty] : option;

    if (multiple) {
      let temporal;

      if (selected?.[value]) {
        temporal = { ...selected };
        delete temporal[value];
      } else {
        temporal = { ...selected, [value]: option };
      }

      setSelected(temporal);
      onSelect?.(temporal);
    } else {
      setSelected(option);
      onSelect?.(option);
    }
  };

  return (
    <StyledSelectOverview>
      <StyledSelectHeader>
        <Typography color="grey" tx="common.items" variant="h5" />
        <Icon
          color="grey"
          cursor="pointer"
          name="close"
          size="1.2rem"
          onClick={onClose}
        />
      </StyledSelectHeader>

      {options.map(option => {
        const value = isObject(option) ? option[valueProperty] : option;
        const text = isObject(option) ? option[labelProperty] : option;

        return (
          <StyledSelectOption
            key={value}
            onClick={() => onOptionSelected(option)}
          >
            {multiple && !renderCheckbox && (
              <Checkbox checked={!!selected && selected[value]} />
            )}

            {multiple && renderCheckbox?.(option, selected && selected[value])}

            <Typography
              text={!shouldTranslateOption && text}
              tx={shouldTranslateOption && text}
              variant="p3"
            />
          </StyledSelectOption>
        );
      })}
    </StyledSelectOverview>
  );
};

import { Conditional, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Page } from 'core/page';
import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { StepHeader } from '../../components/step-header';
import { ChecklistReviewList } from '../submit-concept/checklist-review-list';
import { getDesignStatusDetails } from '../../helpers/get-design-status-details';
import { StepFooter } from '../../components/step-footer';
import { useOnUpdateModel } from '../model-step/hooks/use-on-update-model';
import { useModelNavigation } from '../model-step/hooks/use-model-navigation';
import { HeaderSubmissionDetails } from '../../components/step-header/components/header-submission-details';

export const SubmitModelPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;
  const modelReviewSteps = data?.options?.reviewSteps?.model;

  const { isApproved, isSubmitted } = getDesignStatusDetails(
    design?.model?.status,
  );

  const { onNavigateToDesigns, onNavigateToModelStep } = useModelNavigation();

  const { onSubmit, processing: isSubmitting } = useOnUpdateModel({
    design,
    isApproved,
    isFinalSubmission: true,
    onNavigateTo: onNavigateToDesigns,
  });

  return (
    <Page>
      <SimpleErrorBoundary>
        {design && (
          <Stack gap="5x" maxWidth="92rem">
            <StepHeader design={design} showBackButton>
              <Conditional
                condition={isSubmitted}
                Fallback={
                  <HeaderSubmissionDetails
                    descriptionProps={{
                      text: 'We will review it against the following criteria:',
                    }}
                    imgProps={{ src: 'graphite/design-flow/icon-ready_2x.png' }}
                    titleProps={{ text: 'Ready to submit your design?' }}
                  />
                }
              >
                <HeaderSubmissionDetails
                  imgProps={{ src: 'graphite/design-flow/icon-review_2x.png' }}
                  titleProps={{ text: "We're reviewing your model" }}
                />
              </Conditional>
            </StepHeader>

            <ChecklistReviewList
              isApproved={isApproved}
              isPending={isSubmitted}
              list={modelReviewSteps?.[1]?.sections}
              showApprovedStatus={isApproved}
              title={modelReviewSteps?.[1]?.header}
            />

            <Conditional condition={!isSubmitted && !isApproved}>
              <StepFooter
                backButtonProps={{
                  onClick: () => {
                    onNavigateToModelStep(3);
                  },
                }}
                nextButtonProps={{
                  onClick: async () => {
                    await onSubmit();
                  },
                  processing: isSubmitting,
                  text: 'Submit',
                }}
              />
            </Conditional>
          </Stack>
        )}
      </SimpleErrorBoundary>
    </Page>
  );
};

import { Dialog } from 'core/dialog';
import { useTransition } from 'react-spring';
import { DialogsManagerProps } from 'layout/dialogs-manager/dialogs-manager.props';
import { useLockBodyScroll } from 'react-use';
import { ModalInstance } from 'context/modal.context';

let index = 0;
export const DialogsManager = (props: DialogsManagerProps) => {
  const { closeLastOpened, instances } = props;

  const transition = useTransition(instances, {
    enter: { config: { duration: 250 }, opacity: 1 },
    from: { opacity: 0 },
    leave: { config: { duration: 100 }, opacity: 0 },
  });

  useLockBodyScroll(!!instances?.length);

  return transition((style, active: ModalInstance) => (
    <Dialog
      children={active.template}
      closable={active.closable ?? true}
      dialogAnimatedProps={style}
      height={active.height}
      level={++index}
      variant={active.variant}
      width={active.width}
      onClose={closeLastOpened}
      onCloseCallback={active.onClose}
    />
  ));
};

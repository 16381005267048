import { AnimatedImageProps } from 'layout/image-container/animated-image/animated-image.props';

export const AnimatedImagePresets: AnimatedImageProps = {
  alt: '',
  backgroundColor: 'cream',
  backgroundSize: 'cover',
  height: '100%',
  src: '',
  visible: false,
};

import styled, { css } from 'styled-components';
import { getThemeColor, ThemeColor, legacyTheme } from 'styles/theme';

export const StyledLoadingOverlay = styled.div<{ background?: ThemeColor }>`
  ${({ background }) =>
    css`
      z-index: 10010;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s ease;
      position: fixed;
      margin: 0 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: ${getThemeColor(background) ||
      'rgba(255, 255, 255, 0.8)'};
    `}
`;

import {
  Grid,
  Stack,
  Typography,
  Conditional,
  Flex,
  Icon,
  Button,
  Box,
  LinearProgress,
  useModal,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { useMemo } from 'react';
import { getStepButtonText } from '../../helpers/get-step-button-text';
import { ReEditDesignModal } from '../re-edit-design-modal';
import { getDesignStatusDetails } from '../../../../helpers/get-design-status-details';
import { StepItemConceptPropsDef } from './step-item-concept.types';

export const StepItemConcept = (props: StepItemConceptPropsDef) => {
  const { design } = props;

  const { concept, model } = design;

  const [showReEditModal, hideReEditModal] = useModal(() => {
    return (
      <ReEditDesignModal
        designId={design.id}
        header="Re-edit concept"
        onClose={hideReEditModal}
      >
        <Typography
          align="center"
          color="t2"
          text="After re-editing your concept, it will return to Step 1, and require another review."
        />
      </ReEditDesignModal>
    );
  }, []);

  const {
    isApproved,
    isCompleted,
    isInProgress,
    isReturned,
    isSubmitted,
    isWaiting,
  } = getDesignStatusDetails(concept.status);

  const { isApproved: isModelApproved } = getDesignStatusDetails(model.status);

  const hasFeedback = useMemo(() => {
    return (
      isInProgress &&
      Object.values(concept.review).some(({ sections }) => {
        return sections.some(({ approved }) => {
          return !approved;
        });
      })
    );
  }, [concept.review, isInProgress]);

  return (
    <Grid columns="1fr max-content" gap="3x">
      <Stack gap="3x">
        <Stack gap="x">
          <Typography text="Step 1: Concept" variant="h6" />
          <Typography
            text={
              isSubmitted
                ? "We're reviewing your design"
                : 'Set the foundation for your design'
            }
          />
          <Conditional condition={isWaiting}>
            <Typography color="t2" text={concept.status} />
          </Conditional>

          <Conditional condition={isSubmitted}>
            <Typography
              color="t2"
              text={`${concept.status} ${formatDate(concept.completedAt)}`}
            />
          </Conditional>

          <Conditional condition={isReturned}>
            <Typography
              color="alert"
              text={`${concept.status} ${formatDate(
                concept.refinementsNeededAt,
              )}`}
            />
          </Conditional>

          <Conditional condition={isApproved}>
            <Flex alignItems="center" gap=".5x">
              <Icon
                color="link"
                name="ui-control:check_mark_circle_filled_24"
                size="2.4rem"
              />
              <Typography
                color="link"
                text={`${concept.status} ${formatDate(concept.approvedAt)}`}
              />
            </Flex>
          </Conditional>
        </Stack>

        <Conditional condition={!isApproved}>
          <Flex>
            <a
              href="https://docs.gantri.com/design-process-1/concept-creation"
              rel="noreferrer"
              target="_blank"
            >
              <Flex alignItems="center" gap=".5x">
                <Icon
                  color="link"
                  name="docs:document_lines_24"
                  size="2.4rem"
                />
                <Typography
                  color="link"
                  text="Read design docs for help"
                  textStyle="bold"
                />
              </Flex>
            </a>
          </Flex>
        </Conditional>
        <Flex alignItems="center" gap="3x">
          <Conditional condition={isReturned || hasFeedback}>
            <Link to={routePaths.designFlowConceptFeedback(design.id)}>
              <Button
                justifySelf="start"
                size="large"
                text="See Feedback"
                variant="primaryAlert"
              />
            </Link>
          </Conditional>

          <Conditional condition={isApproved && !isModelApproved}>
            <Button
              icon={<Icon color="link" name="docs:pencil_24" size="2.4rem" />}
              justifySelf="start"
              size="large"
              text="Re-edit"
              variant="ghost"
              onClick={showReEditModal}
            />
          </Conditional>

          <Conditional
            condition={!!concept.stepProgress && isInProgress && !hasFeedback}
          >
            <Box width="16rem">
              <LinearProgress
                current={concept.stepProgress}
                showText
                total={100}
              />
            </Box>
          </Conditional>

          <Link
            to={routePaths.designFlowConceptStep({
              id: design.id,
              stepNumber: 1,
            })}
          >
            <Button
              justifySelf="start"
              size="large"
              text={getStepButtonText({
                status: concept.status,
                stepProgress: concept.stepProgress,
              })}
              variant={
                isApproved || isCompleted || isSubmitted
                  ? 'secondary'
                  : 'primary'
              }
            />
          </Link>
        </Flex>
      </Stack>
    </Grid>
  );
};

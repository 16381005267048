import { FC, useRef, useState } from 'react';
import { Typography } from 'core/typography';
import { EditActions } from 'core/edit-actions';
import { FlexContainer } from 'layout/flex-container';
import { Form, Formik } from 'formik';
import { TextInput } from 'core/text-input';
import { AddressAutocomplete } from 'core/address-autocomplete';
import { AddressParts } from 'core/address-autocomplete/address-autocomplete.types';
import { SelectInput } from 'core/select-input';
import {
  CANADA_PROVINCES,
  SHIPPING_COUNTRIES,
  US_STATES_FULL_NAME,
} from 'constants/countries';
import { StackedContainer } from 'layout/stacked-container';
import {
  Address,
  GET_ORDER,
  UpdateOrderAddressRequest,
} from 'services/api/order';
import { useMutation, useQueryClient } from 'react-query';
import { api } from 'services/api';
import { useNotification } from 'context/notification.context';
import { ShippingInfoSchema } from './shipping-info.schema';
import { ShippingInfoProps } from './shipping-info.types';

export const ShippingInfo: FC<ShippingInfoProps> = props => {
  const { order } = props;
  const [editing, setEditing] = useState(false);
  const formRef = useRef<any>();

  const queryClient = useQueryClient();
  const { hidePreloader, showPreloader } = useNotification();

  const onSave = () => {
    formRef.current.submitForm();
  };

  const updateAddressMutation = useMutation(
    async (data: UpdateOrderAddressRequest) => {
      await api.order.updateAddress(order.id, data);
    },
    {
      onError: () => {
        hidePreloader();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_ORDER);
        setEditing(false);
        hidePreloader();
      },
    },
  );

  const handleSubmit = (values: Address) => {
    showPreloader();
    updateAddressMutation.mutate({ address: values });
  };

  const onAddressChange = (parts: AddressParts) => {
    formRef.current.setFieldValue(
      'street',
      `${parts.streetNumber} ${parts.street}`,
    );
    formRef.current.setFieldValue('city', parts.city);
    formRef.current.setFieldValue('state', parts.state);
    formRef.current.setFieldValue('country', parts.country);
    formRef.current.setFieldValue('countryShort', parts.countryShort);
    formRef.current.setFieldValue('zip', parts.zipcode);
  };

  return (
    <div>
      <FlexContainer
        alignItems="center"
        justifyContent="space-between"
        marginBottom="s1"
      >
        <Typography text="Shipping" textStyle="bold" variant="p1" />

        {order.type === 'Third Party' && (
          <EditActions
            editing={editing}
            onEditingChange={setEditing}
            onSave={onSave}
          />
        )}
      </FlexContainer>

      {editing && (
        <Formik
          enableReinitialize
          initialValues={{
            ...order.address,
          }}
          innerRef={formRef as any}
          validateOnMount
          validationSchema={ShippingInfoSchema}
          onSubmit={handleSubmit}
        >
          {props => (
            <Form autoComplete="off">
              <StackedContainer gap="s1" padding="unset">
                <TextInput
                  error={props.errors.firstName}
                  name="firstName"
                  placeholder="First Name"
                  touched={props.touched.firstName}
                  value={props.values.firstName}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />

                <TextInput
                  error={props.errors.lastName}
                  name="lastName"
                  placeholder="Last Name"
                  touched={props.touched.lastName}
                  value={props.values.lastName}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />

                <AddressAutocomplete
                  error={props.errors.street}
                  name="street"
                  placeholder="Enter a location"
                  touched={props.touched.street}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                  onSelect={onAddressChange}
                />

                <TextInput
                  error={props.errors.unit}
                  name="unit"
                  placeholder="Unit"
                  touched={props.touched.unit}
                  value={props.values.unit}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />

                <TextInput
                  error={props.errors.city}
                  name="city"
                  placeholder="City"
                  touched={props.touched.city}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />

                {props.values.country === 'United States' && (
                  <SelectInput
                    error={props.values.state}
                    name="state"
                    options={US_STATES_FULL_NAME}
                    placeholder="State"
                    value={props.values.state}
                    onBlur={props.handleBlur}
                    onNativeChange={props.handleChange}
                  />
                )}

                {props.values.countryShort === 'Canada' && (
                  <SelectInput
                    error={props.values.state}
                    name="state"
                    options={CANADA_PROVINCES}
                    placeholder="State"
                    value={props.values.state}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                )}

                <TextInput
                  error={props.errors.zip}
                  name="zip"
                  placeholder="Zip Code"
                  touched={props.touched.zip}
                  value={props.values.zip}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />

                <SelectInput
                  error={props.values.country}
                  name="country"
                  options={SHIPPING_COUNTRIES}
                  placeholder="Country"
                  value={props.values.country}
                  onBlur={props.handleBlur}
                  onNativeChange={props.handleChange}
                />

                <TextInput
                  error={props.errors.phone}
                  mask="phone"
                  name="phone"
                  touched={props.touched.phone}
                  value={props.values.phone}
                  onBlur={props.handleBlur}
                  onChange={props.handleChange}
                />
              </StackedContainer>
            </Form>
          )}
        </Formik>
      )}

      {!editing && (
        <>
          <Typography
            color="grey"
            text={`${order.address.firstName} ${order.address.lastName}`}
          />

          <Typography
            color="grey"
            text={`${order.address.city}, ${order.address.state}`}
          />

          <Typography color="grey" text={order.address.country} />

          <Typography
            marginTop="s1"
            text={`${order.shipping.provider} ${order.shipping.option}`}
          />
        </>
      )}
    </div>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgPointing = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 22 27"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          filterUnits="objectBoundingBox"
          height="193.3%"
          id="pointing_svg__a"
          width="227.2%"
          x="-63.6%"
          y="-29.6%"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={0.7}
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy={2}
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={1}
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.582908741 0"
          />
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius={0.7}
            result="shadowSpreadOuter2"
          />
          <feOffset
            dy={3}
            in="shadowSpreadOuter2"
            result="shadowOffsetOuter2"
          />
          <feGaussianBlur
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
            stdDeviation={2}
          />
          <feComposite
            in="shadowBlurOuter2"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter2"
          />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.179347826 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
        <path
          d="M12.888 9.067a.872.872 0 00-.877-.866.871.871 0 00-.877.863v-.605c0-.628-.526-1.136-1.175-1.136-.65 0-1.167.508-1.167 1.136v-.878c0-.627-.533-1.136-1.18-1.136-.646 0-1.162.509-1.162 1.136V1.044C6.45.467 5.983 0 5.417 0c-.565 0-1.023.468-1.023 1.044v11.014l-2.64-3.412C1.503 8.216.93 8.076.48 8.33c-.452.255-.612.81-.359 1.239 0 0 2.064 3.2 2.906 4.463.842 1.263 2.225 2.822 2.225 3.547h5.852c0-1.225 1.79-3.137 1.79-4.98v-2.372l-.005-1.16z"
          id="pointing_svg__b"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#pointing_svg__a)"
          xlinkHref="#pointing_svg__b"
        />
        <path
          d="M4.044 11.034v-9.99c0-.768.613-1.394 1.373-1.394.761 0 1.383.626 1.383 1.394v5.282c.235-.146.513-.23.812-.23.716 0 1.325.483 1.488 1.137.244-.164.54-.26.859-.26.678 0 1.256.432 1.453 1.032a1.23 1.23 0 01.599-.154c.676 0 1.227.543 1.227 1.216v-.001l.004 1.161V12.6c0 .187-.016.378-.048.574-.113.687-.374 1.323-.886 2.308-.742 1.43-.855 1.686-.855 2.097v.35H4.9v-.35c0-.314-.346-.86-1.361-2.229l-.058-.078c-.349-.47-.554-.756-.747-1.046-.285-.427-.726-1.1-1.28-1.951l-.02-.032A816.85 816.85 0 01-.181 9.747c-.355-.601-.132-1.372.488-1.722.615-.347 1.397-.153 1.725.407l2.012 2.602z"
          fill="#FFF"
          stroke="#000"
          strokeWidth={0.7}
        />
      </g>
    </svg>
  );
});
export default SvgPointing;

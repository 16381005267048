import { memo, useEffect, useState } from 'react';
import { AnimatedImagePresets } from 'layout/image-container/animated-image/animated-image.presets';
import { extractValuesFromResolutionAwareProp } from 'helpers/layout.helpers';
import { useSpring } from '@react-spring/core';
import { Icon } from 'core/icon';
import { ResolutionAwarePropBase } from 'types/resolution-aware-prop.type';
import {
  StyledAnimatedImage,
  StyledAnimatedImageLoading,
} from './animated-image.styles';
import { AnimatedImageProps } from './animated-image.props';

export const AnimatedImage = memo((props: AnimatedImageProps) => {
  const {
    alt,
    backgroundSize,
    height,
    objectFit,
    objectPosition,
    src,
    visible,
  } = { ...AnimatedImagePresets, ...props };

  const [innerSrc, setInnerSrc] = useState<ResolutionAwarePropBase<string>>({
    lg: '',
    md: '',
    sm: '',
  });
  const [innerVisible, setInnerVisible] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setInnerSrc(extractValuesFromResolutionAwareProp(src));
  }, [src]);

  useEffect(() => {
    setInnerVisible(visible);
  }, [visible]);

  const { opacity } = useSpring({
    config: { duration: 100 },
    opacity: loaded ? 0 : 1,
  });

  const onLoadCompleted = (): void => {
    setLoaded(true);
  };

  return (
    <>
      {innerVisible && (
        <picture onError={onLoadCompleted} onLoad={onLoadCompleted}>
          <source media="(max-width: 1024px)" srcSet={innerSrc.sm} />
          <source media="(min-width: 1025px)" srcSet={innerSrc.lg} />
          <StyledAnimatedImage
            alt={alt}
            draggable="false"
            fit={objectFit}
            innerheight={height}
            position={objectPosition}
            size={backgroundSize}
            src={null}
          />
        </picture>
      )}
      <StyledAnimatedImageLoading style={{ opacity: opacity as any }}>
        <Icon color="grey" name="dots-animated" size="6rem" />
      </StyledAnimatedImageLoading>
    </>
  );
});

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgBlankCard = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 7.417v5.24C16 13.4 15.45 14 14.77 14H1.23C.55 14 0 13.4 0 12.657v-5.24h16zM14.77 2c.68 0 1.23.602 1.23 1.343v2.574H0V3.343C-.001 2.602.55 2 1.23 2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgBlankCard;

import { FC, useEffect, useMemo } from 'react';
import {
  StyledBody,
  StyledContent,
  StyledContentContainer,
  StyledTopContainer,
  StyledTopNotificationsContainer,
} from 'layout/layout/base-layout.styles';
import { SideMenu } from 'layout/side-menu';
import { useLayout } from 'context/layout.context';
import { NotificationsManager } from 'layout/notifications-manager';
import { Menu } from 'layout/menu';
import { LeftMenu } from 'layout/right-menu';
import { useQuery } from 'react-query';
import { api, GET_PAYOUTS, GET_USER } from 'services/api';
import { UserData } from 'services/api/auth';
import { AUTH_TOKEN } from 'constants/common';
import { useCookies } from 'react-cookie';
import { environment } from 'config/environment';
import { useModal } from 'context/modal.context';
import { InactiveModal } from 'layout/modals';
import { initializeFirebase } from 'utils/firebase';
import { LoadingOverlay } from 'layout/loading-overlay';
import { useAuth } from 'context/auth.context';
import { getAnalyticsUserId, loadAnalytics } from 'utils/analytics';
import { useAsync } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { routePaths } from 'src/app-routes';

const redirectToShopSignInPage = () => {
  window.location.replace(
    `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}`,
  );
};

export const BaseLayout: FC = ({ children }) => {
  const { hideSideMenu, sideMenuVisible, toggleSideMenuVisibility } =
    useLayout();

  const [cookies, , removeCookie] = useCookies([AUTH_TOKEN]);

  const [, authDispatch] = useAuth();
  const location = useLocation();

  const navigate = useNavigate();

  const isLoggedIn = !!cookies[AUTH_TOKEN];
  const query = useQuery<UserData, { message: string }>(
    GET_USER,
    api.auth.getLogged,
    {
      enabled: isLoggedIn,
      refetchOnWindowFocus: false,
    },
  );

  const payoutsQuery = useQuery(
    GET_PAYOUTS,
    () => {
      return api.designer.getPayouts();
    },
    {
      enabled: query.status === 'success',
    },
  );

  useAsync(async () => {
    const loaded = await loadAnalytics();

    if (loaded) {
      authDispatch({
        id: getAnalyticsUserId(),
        type: 'UPDATE_BROWSER_ID',
      });
    }
  }, []);

  const { showModal: showInactiveModal } = useModal({
    template: <InactiveModal />,
    variant: 'dialog',
    width: '46rem',
  });

  const isDesigner = query?.data?.data?.isDesigner;
  const isHomePage = location.pathname === '/';
  const isGuestPage = useMemo(() => {
    return location.pathname === '/accept' || isHomePage;
  }, [location.pathname]);

  useEffect(() => {
    if (!query.isLoading) {
      if (isLoggedIn && !isDesigner && !isGuestPage) {
        navigate(routePaths.home);
      } else if (!isLoggedIn && !isGuestPage) {
        redirectToShopSignInPage();
      }
    }
  }, [query, isLoggedIn]);

  useEffect(() => {
    if (
      query.status === 'success' &&
      !isGuestPage &&
      !query?.data?.data?.designerInfo?.active
    ) {
      if (query.data?.data?.isDesigner) {
        showInactiveModal();
      }
    }
  }, [
    location,
    query.status,
    query?.data?.data?.designerInfo?.active,
    showInactiveModal,
  ]);

  useEffect(() => {
    if (query.data?.firebaseToken) {
      initializeFirebase(query.data?.firebaseToken);
    }
  }, [query.data?.firebaseToken]);

  const logout = async () => {
    await api.auth.logout();

    removeCookie(AUTH_TOKEN, {
      domain: environment.COOKIES_DOMAIN,
      path: '/',
    });
  };

  if (!query?.data?.data?.designerInfo && !isGuestPage) {
    return <LoadingOverlay />;
  }

  const pagesWithoutMenus = [routePaths.accept, routePaths.home];

  return (
    <StyledBody>
      <StyledTopNotificationsContainer>
        <NotificationsManager />
      </StyledTopNotificationsContainer>

      {pagesWithoutMenus.some((path) => {
        return path === location.pathname;
      }) ? (
        <div id="content">{children}</div>
      ) : (
        <>
          <StyledTopContainer>
            <Menu
              userData={query.data?.data}
              onLogout={logout}
              onMenuButtonClick={toggleSideMenuVisibility}
            />
          </StyledTopContainer>

          <StyledContentContainer>
            <StyledContent id="content">
              <LeftMenu
                hasPayouts={!!payoutsQuery.data?.data?.length}
                hasProducts={!!query?.data?.data?.designerInfo?.productsCount}
              />
              {children}
            </StyledContent>
          </StyledContentContainer>
          <SideMenu
            hasPayouts={!!payoutsQuery.data?.data?.length}
            user={query.data?.data}
            visible={sideMenuVisible}
            onLogout={logout}
            onMenuClose={hideSideMenu}
          />
        </>
      )}
    </StyledBody>
  );
};

import { GridProps } from './grid.props';

export const GridPresets: GridProps = {
  backgroundColor: 'unset',
  flow: 'row',
  gap: { lg: 's3', md: 's3', sm: 's1' },
  height: 'unset',
  maxWidth: 'unset',
  padding: 'unset',
  width: 'unset',
};

import { useParams } from 'react-router-dom';
import { ConceptStepOne } from '../concept-step-one';
import { ConceptStepTwo } from '../concept-step-two';
import { ConceptStepThree } from '../concept-step-three';
import { ConceptStepPageParams } from '../../concept-step.types';

export const ConceptStepContent = () => {
  const { stepNumber } = useParams<ConceptStepPageParams>();

  switch (stepNumber) {
    case '1':
      return <ConceptStepOne />;

    case '2':
      return <ConceptStepTwo />;

    case '3':
      return <ConceptStepThree />;

    default:
      return null;
  }
};

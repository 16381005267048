import { FC, useState } from 'react';
import { TimePicker } from 'core/time-picker/time-picker';
import { StackedContainer } from 'layout/stacked-container';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { DatePicker } from 'core/date-picker';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { TimePickerChangeEvent } from 'core/time-picker/time-picker.types';
import { useMutation, useQueryClient } from 'react-query';
import { api, GET_PRODUCT, LaunchRequest } from 'services/api';
import { useNotification } from 'context/notification.context';
import { formatDate } from 'utils/date';
import { ScheduleLaunchProps } from './schedule-launch.types';

export const ScheduleLaunch: FC<ScheduleLaunchProps> = props => {
  const [date, setDate] = useState<Date>(null);
  const [time, setTime] = useState<string>(null);

  const queryClient = useQueryClient();
  const { hidePreloader, showPreloader } = useNotification();

  const launchMutation = useMutation(
    async (data: LaunchRequest) => api.product.launch(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_PRODUCT);
      },
    },
  );

  const handleLaunch = async () => {
    showPreloader();

    await launchMutation.mutateAsync({
      id: props.productId,
      scheduledAt: `${formatDate(date)} ${time}`,
    });

    hidePreloader();
  };

  return (
    <StackedContainer gap="s4" maxWidth="32rem" padding="unset">
      <Cell>
        <Typography text="Schedule launch" variant="h3" />
      </Cell>

      <Cell>
        <Typography marginBottom="s1" text="Date" />
        <DatePicker allowPastDates={false} value={date} onChange={setDate} />
      </Cell>

      <Cell>
        <FlexContainer alignItems="center" marginBottom="s1">
          <Typography marginRight="s1" text="Time" />
          <Typography color="grey" text="(Pacific time, USA)" variant="p3" />
        </FlexContainer>

        <TimePicker
          onChange={(data: TimePickerChangeEvent) => setTime(data.formatted)}
        />
      </Cell>

      <Cell>
        <Button
          disabled={!date || !time}
          minWidth="12rem"
          text="Launch"
          width="12rem"
          onClick={handleLaunch}
        />
      </Cell>
    </StackedContainer>
  );
};

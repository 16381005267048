import { Designer } from 'types/designer.type';

export const GET_STATS = 'GET_STATS';

export const GET_PRODUCTS = 'GET_PRODUCTS';

export const GET_PRODUCT = 'GET_PRODUCT';

export interface GetSkusResponse {
  productLeadTime: {
    completedBySec: number;
    day: string;
    estDeliveryBy: string;
    estDeliveryBySec: number;
    estInStockDeliveryBy: string;
    estInStockDeliveryBySec: number;
    estimatedShipment: string;
    inStock: string;
    inStockSec: number;
    leadTime: string;
    leadTimeSec: number;
    month: string;
    weeks: string;
    year: string;
  };

  products: Product[];

  success: boolean;
}

export interface GetStatsRequest {
  endDate: string;
  filterType: 'category' | 'product' | 'sku';
  groupBy: 'daily' | 'monthly' | 'weekly';
  productCategory?: string[];
  productId?: string[];
  sku?: string[];
  startDate: string;
}

export interface GetStatsResult {
  cart: StatsRecord;
  cities: City[];
  devices: Device[];
  orders: StatsRecord;
  otherProducts: OtherProduct[];
  refunds: StatsRecord;
  units: StatsRecord;
  view: StatsRecord;
  visitor: StatsRecord;
  wishlist: StatsRecord;
}

export interface GetStatsResponse {
  groupBy: string;
  result: GetStatsResult;
  success: boolean;
}

export interface GetProductsResponse {
  data: Product[];
  success: boolean;
}

export interface City {
  city: string;
  percent: number;
  productCategory?: string;
  productId?: number;
  sku?: string;
  state: string;
  users: number;
}

export interface StatsRecord {
  details: Array<{
    color: string;
    count: number;
    earning?: number;
    revenue?: number;
    value?: number;
  }>;
  totalCount?: number;
  totalEarning?: number;

  totalRevenue?: number;
}

export interface OtherProduct {
  percent: number;
  productId: number;
  productInfo: ProductInfo;
  sku: string;
  state: 'Other' | string;
  units: number;
}

export interface ProductInfo {
  category: string;
  designerName: string;
  fullName: string;
  id: number;
  name: string;
  thumbnailLink: string;
}

export interface Device {
  count: number;
  id: number;
  percent: number;
  state: 'Other' | string;
}

export interface GetProductResponse {
  options: Options;
  product: Product;
  stats: Stats;
  success: boolean;
}

export interface Options {
  colors: ProductOption[];
  materials: string[];
  productCategories: ProductCategory[];
  reviewSteps: ReviewSteps;
}

export interface ProductOption {
  code: string;
  name: string;
}

export interface ProductCategory {
  description: string;
  name: string;
  photoURL: string;
  photoURLOff: string;
  sizes: SizeElement[];
  subCategories: SubCategory[];
}

export interface SizeElement {
  code: string;
  description: string;
  label: string;
  name: string;
}

export interface SubCategory {
  description: string;
  name: string;
  photoURL: string;
  photoURLOff: string;
}

export interface ReviewSteps {
  concept: { [key: string]: Concept };
  model: Model;
}

export interface Concept {
  header: string;
  sections: Section[];
}

export interface Section {
  approved: boolean;
  description: string;
  list?: string[];
  messages: any[];
  title: string;
}

export interface Model {}

export type ProductStatus =
  | 'In preparation'
  | 'Ready'
  | 'Active'
  | 'Off Market';

export interface Product {
  asSeenOn: AsSeenOnRecord[];
  bulbChangeInstructions: BulbChangeInstruction[];
  category: string;
  colors: ProductOption[];
  content: string;
  description: string;
  designer: Designer;
  designs: any[];
  fullURLName: string;
  handouts: Handout[];
  heading: string;
  id: number;
  launchedAt: string;
  launchedAtSec?: string;
  margin: string;
  name: string;
  offMarketAt: null;
  rooms: string[];
  scheduleLaunchedAt: null;
  sizes: ProductOption[];
  skuAssets?: {
    [sku: string]: {
      dimmingPhotoDark: string | null;
      dimmingPhotoLight: string | null;
      scalePhoto: string | null;
      selectedWhiteBackgroundPhoto: string | null;
      usdz: string | null;
      whiteBackgroundPhotos: string[];
    };
  };
  skuLabor: SkuLabor;
  skus: Skus[];
  skusData: SkusData;
  status: ProductStatus;
  subCategory: string;
  tags: string[];
  topline?: {
    earning: number;
    sales: number;
    units: number;
  };
  usdzUrls: UsdzUrls;
  variants: any[];
}

export interface BulbChangeInstruction {
  content: string;
  imageUrl: string;
}

export interface Badges {
  awardWinner: boolean;
  newDesigner: boolean;
  newProduct: boolean;
}

export interface Handout {
  createdAt: Date;
  id: number;
  link: string;
  name: string;
  productID: number | null;
  sku: string;
  type: string;
  universal: boolean;
  updatedAt: Date;
}

export interface SkuLabor {
  [name: string]: LaborData;
}

export interface LaborData {
  assemble: Assemble;
  bondo: Assemble;
  mask: Assemble;
  pack: Assemble;
  paint: Assemble;
  print: Assemble;
  qa: Assemble;
  sand: Assemble;
  total: Assemble;
}

export interface Assemble {
  cost: number;
  duration: number;
  jobs: number;
  totalCost: number;
}

export interface Skus {
  code: string;
  color: ProductOption;
  fullProductName: string;
  fullSkuName: string;
  size: SkusSize;
  variant: null;
}

export interface SkusSize {
  code: string;
  id: number;
  name: string;
}

export interface SkusData {
  [sku: string]: ProductSku;
}

export interface ProductSku {
  archivedParts: any[];
  assemblyInstructions: Handout[];
  bom: BOM;
  bomPrice: number;
  bulb: string;
  cableLength: string;
  compatibleWith: null;
  cost: number;
  designEarnings: number;
  dimension: Dimension;
  footprint: Footprint;
  garndPrice: number;
  manufacturerPrices: number;
  margin: string;
  material: string;
  parts: PartElement[];
  photoUrls: ProductPhotos;
  popularity: number;
  price: number;
  productionPrice: number;
  schemaData: null;
  selectedProductPhoto: string;
  stepJobTemplates: StepJobTemplate[];
  weight: string;
}

export interface BOM {
  total: { totalCost: number };
}

export interface Dimension {
  depth: number;
  height: number;
  width: number;
}

export interface Footprint {
  depth: number;
  width: number;
}

export interface PartElement {
  id: number;
  jobTemplates: JobTemplate[];
  name: Name;
}

export interface JobTemplate {
  createdAt: Date;
  description: string;
  duration: number;
  id: number;
  instruction: Thumbnail;
  inventoryRequests: InventoryRequest[];
  machineID: null;
  machineType: MachineType;
  part: JobTemplatePart;
  partTemplateID: number;
  productID: number;
  sortOrder: number;
  step: JobTemplateStep;
  type: JobTemplateType;
}

export interface Thumbnail {
  fileName?: string;
  url?: string;
}

export interface InventoryRequest {
  id: number;
  inventory: Inventory;
  inventoryID: number;
  need: number;
}

export interface Inventory {
  id: number;
  name: string;
  status: string;
  type: InventoryType;
  unit: Unit;
}

export enum InventoryType {
  Hardware = 'Hardware',
  LightingComponent = 'Lighting Component',
  Material = 'Material',
  Packaging = 'Packaging',
}

export enum Unit {
  Each = 'Each',
  Gallon = 'Gallon',
  Kg = 'KG',
  Roll = 'Roll',
}

export enum MachineType {
  CrealityHighTempPLA = 'Creality High-Temp PLA',
  CrealityTranslucentPLA = 'Creality Translucent PLA',
  Empty = '',
}

export interface JobTemplatePart {
  id: number;
  material: Material;
  name: Name;
}

export enum Material {
  OpaqueGPP = 'Opaque GPP',
  TranslucentGPP = 'Translucent GPP',
}

export enum Name {
  Base = 'Base ',
  BaseCap = 'Base Cap',
  Diffuser = 'Diffuser ',
  Shade = 'Shade ',
  SocketHolder = 'Socket Holder',
  WeightRetainer = 'Weight Retainer ',
}

export enum JobTemplateStep {
  Finish = 'Finish',
  Print = 'Print',
}

export enum JobTemplateType {
  Mask = 'Mask',
  Paint = 'Paint',
  Print = 'Print',
  QA = 'QA',
  Sand = 'Sand',
}

export interface ProductPhotos {
  'dimming-photos': string[];
  'lifestyle-photos': string[];
  'product-photos': string[];
  'scale-photo': string[];
}

export type ProductPhotosFileType =
  | 'dimming-photos'
  | 'product-photos'
  | 'lifestyle-photos'
  | 'scale-photo';

export interface Photos {
  lifestyle?: ProductPhoto[];
  lifestyleset: ProductPhoto[];
  product: ProductPhoto[];
  scale: Scale[];
}

export interface ProductPhoto {
  desktop: string;
  fileName: string;
  mobile: string;
}

export interface Scale {
  desktop: string;
  fileName: string;
  height: number;
  mobile: string;
  width: number;
}

export interface StepJobTemplate {
  createdAt: Date;
  description: Description;
  duration: number;
  id: number;
  instruction: Thumbnail | null;
  inventoryRequests: InventoryRequest[];
  machineID: null;
  machineType: null;
  part: null;
  partTemplateID: null;
  productID: number;
  sortOrder: number;
  statusInfo?: StatusInfo;
  step: TypeEnum;
  type: TypeEnum;
}

export enum Description {
  GioTable = 'Gio Table',
  GioTableMain = 'Gio Table Main',
  StageGio = 'Stage Gio',
}

export interface StatusInfo {
  wrong: Wrong;
}

export interface Wrong {
  date: number;
  description: string;
  ownerID: number;
  status: string;
  user: Stats;
}

export interface Stats {}

export enum TypeEnum {
  Assemble = 'Assemble',
  Pack = 'Pack',
  QA = 'QA',
  Stage = 'Stage',
}

export interface UsdzUrls {
  [name: string]: UsdzUrl;
}

export interface UsdzUrl {
  fileName: string;
  fileURL: string;
}

export interface AsSeenOnRecord {
  source?: string;
  url?: string;
}

export interface UpdateAsSeenOnRequest {
  asSeenOn: AsSeenOnRecord[];
}

export interface UpdateAsSeenOnResponse {
  notice: string;
  success: true;
}

export interface LaunchRequest {
  id: number;
  scheduledAt: string;
}

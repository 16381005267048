import { useFormikContext } from 'formik';
import { Flex, FileUploader, Typography } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { ModelStepPageParams } from '../../../../model-step.types';
import { ModelStepTwoFormDef } from '../../model-step-two.types';
import { NewPartUploaderPropsDef } from './new-part-uploader.types';

export const NewPartUploader = (props: NewPartUploaderPropsDef) => {
  const { expectedExtensions, maxFileSizeMB } = props;

  const { id } = useParams<ModelStepPageParams>();

  const { setFieldValue, values } = useFormikContext<ModelStepTwoFormDef>();

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: undefined,
    handleUploadsComplete: async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue('parts', [
        ...values.parts,
        {
          fileExtension,
          fileName,
          fileSize,
          fileUrl,
          loading: false,
          percentage: 100,
        },
      ]);
    },
    onFileDelete: undefined,
    path: `designs/${id}`,
  });

  return (
    <Flex alignItems="center" gap="x">
      <FileUploader
        {...fileUploaderProps}
        buttonProps={{ text: 'Upload your STEP file' }}
        expectedExtensions={expectedExtensions}
        fileName={undefined}
        isUploaderOnly
        maxFileSizeMB={maxFileSizeMB}
        variant="button"
      />

      <Typography
        color="t2"
        text={`Max size ${maxFileSizeMB}MB`}
        variant="p3"
      />
    </Flex>
  );
};

import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import { useImmerReducer } from 'use-immer';
import { v4 as uuid4 } from 'uuid';
import { DialogsManager } from 'layout/dialogs-manager';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { DialogVariant } from 'core/dialog/dialog.props';

export interface ModalInstance {
  closable?: boolean;
  height?: ResolutionAwareProp<string>;
  key?: string;
  onClose?: (data?: any) => void;
  template: ReactNode;
  variant?: ResolutionAwareProp<DialogVariant>;
  width?: ResolutionAwareProp<string>;
}

interface State {
  instances?: ModalInstance[];
  queuedInstances?: ModalInstance[];
}

const initialState: State = {
  instances: [],
  queuedInstances: [],
};

type Action =
  | { instance: ModalInstance; type: 'REGISTER' }
  | { key: string; type: 'UNREGISTER' }
  | { type: 'UNREGISTER_LAST_OPENED' }
  | { type: 'UNREGISTER_ALL' }
  | { type: 'RESET' };

function modalReducer(draft: State, action: Action) {
  switch (action.type) {
    case 'REGISTER':
      if (draft.instances.length) {
        draft.queuedInstances.push(action.instance);
      } else {
        draft.instances.push(action.instance);
      }
      return draft;
    case 'UNREGISTER':
      draft.instances = draft.instances.filter(
        instance => instance.key === action.key,
      );

      if (!draft.instances.length && draft.queuedInstances.length) {
        draft.instances = [draft.queuedInstances.pop()];
      }

      return draft;
    case 'UNREGISTER_LAST_OPENED':
      draft.instances.pop();

      if (!draft.instances.length && draft.queuedInstances.length) {
        draft.instances = [draft.queuedInstances.pop()];
      }

      return draft;
    case 'UNREGISTER_ALL':
      draft.queuedInstances = [];
      draft.instances = [];
      return draft;
    case 'RESET':
      return initialState;
  }
}

const ModalStateContext = createContext<State>(initialState);

const ModalDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

export const ModalProvider = (props: {
  children: ReactNode;
  value?: typeof initialState;
}) => {
  const { children, value } = props;

  const [state, dispatch] = useImmerReducer(modalReducer, {
    ...initialState,
    ...value,
  });

  const instanceValue = { ...state };

  const closeLastOpened = () => {
    dispatch({ type: 'UNREGISTER_LAST_OPENED' });
  };

  return (
    <ModalStateContext.Provider value={instanceValue}>
      <ModalDispatchContext.Provider value={dispatch}>
        {children}

        <DialogsManager
          closeLastOpened={closeLastOpened}
          instances={state.instances}
        />
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState(): State {
  const context = useContext(ModalStateContext);

  if (context === undefined) {
    throw new Error(`useModal must be used inside an ModalProvider`);
  }

  return context;
}

export function useModalDispatch() {
  const context = useContext(ModalDispatchContext);

  if (context === undefined) {
    throw new Error(`useModal must be used inside an ModalProvider`);
  }

  return context;
}

export const useModal = (instance: ModalInstance, dependencies: any[] = []) => {
  const key = uuid4();
  const dispatch = useModalDispatch();

  const showModal = useCallback(() => {
    dispatch({
      instance: { ...instance, key, variant: instance.variant ?? 'standard' },
      type: 'REGISTER',
    });
  }, [key, dependencies]);

  const hideModal = useCallback(() => {
    dispatch({ key, type: 'UNREGISTER' });
  }, [key]);

  return { hideModal, showModal };
};

export const useModalClose = () => {
  const dispatch = useModalDispatch();

  const closeLastOpened = useCallback(() => {
    dispatch({ type: 'UNREGISTER_LAST_OPENED' });
  }, []);

  const clearModalsStack = useCallback(() => {
    dispatch({ type: 'UNREGISTER_ALL' });
  }, []);

  return { clearModalsStack, closeLastOpened };
};

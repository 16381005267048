import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgReturns = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 52 62"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-14 71h80V-9h-80z" />
        <path
          d="M26 40.275L8.068 32.306v20.722L26 61.001zM26 40.275l17.932-7.969v20.722L26 61.001zM8.069 32.306l17.932-7.972 17.931 7.972M17.511 28.106l17.235 8.232M51 7.537H1.288M7.064 1.001L1 7.537l6.064 6.539"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
  );
});
export default SvgReturns;

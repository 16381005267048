import { FC, useEffect } from 'react';
import { StyledEditActionsContainer } from 'core/edit-actions/edit-actions.styles';
import { useToggle } from 'react-use';
import { Typography } from 'core/typography';
import { EditActionsProps } from './edit-actions.types';

export const EditActions: FC<EditActionsProps> = props => {
  const { editing, onEditingChange, onSave } = props;
  const [innerEditing, toggleInnerEditing] = useToggle(false);

  useEffect(() => {
    toggleInnerEditing(editing);
  }, [editing]);

  useEffect(() => {
    onEditingChange?.(innerEditing);
  }, [innerEditing]);

  const handleSave = () => {
    onSave();
  };

  return (
    <StyledEditActionsContainer>
      {innerEditing && (
        <>
          <Typography
            color="primary"
            text="Save"
            textStyle="bold"
            variant="p3"
            onClick={handleSave}
          />
          <Typography
            color="grey"
            text="Cancel"
            textStyle="bold"
            variant="p3"
            onClick={() => toggleInnerEditing(false)}
          />
        </>
      )}

      {!innerEditing && (
        <Typography
          color="primary"
          text="Edit"
          textStyle="bold"
          variant="p3"
          onClick={toggleInnerEditing}
        />
      )}
    </StyledEditActionsContainer>
  );
};

import { FormikInput, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Form, Formik } from 'formik';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { DesignFieldTextArea } from '../design-field-text-area';
import { ConceptStepHeader } from '../concept-step-header';
import { useConceptNavigation } from '../../hooks/use-concept-navigation';
import { ConceptStepPageParams } from '../../concept-step.types';
import { StepFooter } from '../../../../components/step-footer';
import { useOnUpdateConcept } from '../../hooks/use-on-update-concept';
import { conceptStepOneFormSchema } from './concept-step-one.schema';
import { ConceptSketchesSection } from './components/sketches-section';

export const ConceptStepOne = () => {
  const { id } = useParams<ConceptStepPageParams>();

  const { onNavigateToNext, onNavigateToStepList } = useConceptNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isApproved, isSubmitted } = getDesignStatusDetails(
    design?.concept?.status,
  );

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateConcept(
    {
      design,
      isApproved,
      onNavigateTo: onNavigateToStepList,
    },
  );

  const { onSubmit, processing: isSubmitting } = useOnUpdateConcept({
    design,
    isApproved,
    onNavigateTo: onNavigateToNext,
  });

  const isDisabled = isApproved || isSubmitted;

  return design ? (
    <Formik
      enableReinitialize
      initialValues={design.concept.subSteps[4]}
      validateOnMount
      validationSchema={conceptStepOneFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Stack gap="5x">
              <ConceptStepHeader
                design={design}
                question="What's your design?"
                showBackButton
              />

              <ConceptSketchesSection design={design} isDisabled={isDisabled} />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: It's a compact task light that provides directional illumination. Its small footprint allows the light to fit onto a variety of surfaces, from personal work desks to side tables."
                    disabled={isDisabled}
                    label="Please describe your design"
                  />
                }
                name="describe"
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: My design is inspired by my love for patterns and textures. Geometric order is beautiful, and it heightens the value of a design."
                    disabled={isDisabled}
                    label="What's the purpose of your design?"
                  />
                }
                name="purpose"
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: My design is inspired by my love for patterns and textures. Geometric order is beautiful, and it heightens the value of a design."
                    disabled={isDisabled}
                    label="What's the inspiration behind your design?"
                  />
                }
                name="inspiration"
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: I discovered my love for patterns and textures while studying for my masters in design. My favorite classes focused on establishing contrast."
                    disabled={isDisabled}
                    label="Is there anything that connects you to the inspiration?"
                  />
                }
                name="inspirationConnect"
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: My design blends familiar forms with totally unique surface patterns. No other design sports this combination."
                    disabled={isDisabled}
                    label="What's unique about your design?"
                  />
                }
                name="uniqueDesign"
              />

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

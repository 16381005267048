import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { animated } from 'react-spring';
import { Typography } from 'core/typography';
import { legacyTheme } from 'styles/theme';

export const StyledSideMenuContainer = styled(animated.div)`
  ${({ theme }) => {
    return css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: fixed;
      top: ${legacyTheme.layout.header.height};
      right: 0;
      height: calc(100vh - ${legacyTheme.layout.header.height});
      background-color: ${legacyTheme.colors.white};
      z-index: 1;
      padding-bottom: ${legacyTheme.spacing.s6};

      ${media.lessThan('sm')`
      top: ${legacyTheme.layout.header.heightSmaller};
      height: calc(100vh);
    `}
    `;
  }}
`;

export const StyledSideMenu = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${legacyTheme.spacing.s5} ${legacyTheme.spacing.s5} 0;
      display: grid;
      row-gap: 2rem;

      &:not(:first-of-type) {
        padding-top: ${legacyTheme.spacing.s4};
      }
    `;
  }}
`;

export const StyledSideMenuLink = styled.div`
  ${({ theme }) => {
    return css`
      width: max-content;
      cursor: pointer;
      position: relative;

      &.active > p {
        color: ${legacyTheme.colors.black};
      }
    `;
  }}
`;

export const StyledSubmenuTitle = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyleBackArrowIconContainer = styled.div`
  position: absolute;
  left: -40px;
  display: inline-flex;
`;

export const StyledSideMenuBackdrop = styled.div`
  ${({ theme }) => {
    return css`
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: ${legacyTheme.colors.translucentBlack};
    `;
  }}
`;

export const StyledNewTag = styled(Typography)`
  position: absolute;
  top: 6px;
  right: -3rem;
`;

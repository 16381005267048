import { useGenericFetchQuery } from 'api/hooks/use-generic-fetch-query';
import { getGenericQueryObj } from 'api/hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from 'api/hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGetInvalidateQueryCache } from 'api/hooks/use-query-fetch';
import { fetchDesigns } from './fetch-designs';
import { FetchDesignsResponse, FetchDesignsArgs } from './fetch-designs.types';

const fetchDesignsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-designs',
  query: fetchDesigns,
});

export const useFetchDesigns = <TransformedData = FetchDesignsResponse>(
  props?: GenericFetchProps<
    FetchDesignsArgs,
    FetchDesignsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch designs.',
    queryObj: fetchDesignsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchDesignsCache = () => {
  const invalidateFetchDesignsCache = useGetInvalidateQueryCache(
    fetchDesignsQueriesObj,
  );

  return { invalidateFetchDesignsCache };
};

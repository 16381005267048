import {
  Cell,
  Flex,
  Grid,
  Icon,
  MaxWidth,
  Stack,
  Typography,
} from 'gantri-components';
import { FC, memo } from 'react';
import { trackAnalyticsEvent } from 'utils/analytics';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import { Link } from 'react-router-dom';
import { StyledLinksContainer, StyledPolicyLinks } from './footer.styles';
import { storefrontRoutePaths } from '../../../../storefront-routes';

export const NEW_HORIZONTAL_PADDING: ResolutionAwareProp<any> = {
  lg: '3x',
  md: '2x',
};

export const VERTICAL_PADDING: ResolutionAwareProp<any> = {
  lg: '18x',
  md: '12x',
  sm: '8x',
};

export const SUPPORT_EMAIL = 'support@gantri.com';

const shopLinks = [
  {
    analyticsEvent: 'footer_shop_latest_releases',
    path: storefrontRoutePaths.latestReleases,
    text: 'Latest Releases',
  },
  {
    analyticsEvent: 'footer_shop_table',
    path: storefrontRoutePaths.shop.tableLights,
    text: 'Table Lights',
  },
  {
    analyticsEvent: 'footer_shop_wall',
    path: storefrontRoutePaths.shop.wallLights,
    text: 'Wall Lights',
  },
  {
    analyticsEvent: 'footer_shop_floor',
    path: storefrontRoutePaths.shop.floorLights,
    text: 'Floor Lights',
  },
  {
    analyticsEvent: 'footer_shop_clamp',
    path: storefrontRoutePaths.shop.clampLights,
    text: 'Clamp Lights',
  },
  {
    analyticsEvent: 'footer_shop_clamp',
    path: storefrontRoutePaths.shop.pendantLights,
    text: 'Pendant Lights',
  },
  {
    analyticsEvent: 'footer_shop_gift_cards',
    path: storefrontRoutePaths.giftCards,
    text: 'Gift Cards',
  },
];

const aboutLinks = [
  {
    analyticsEvent: 'footer_about_about',
    path: storefrontRoutePaths.about,
    text: 'Our Story',
  },
  {
    analyticsEvent: 'footer_about_materials',
    path: storefrontRoutePaths.materials,
    text: 'Materials',
  },
  {
    analyticsEvent: 'footer_about_process',
    path: storefrontRoutePaths.process,
    text: 'Process',
  },
  {
    analyticsEvent: 'footer_about_design',
    path: storefrontRoutePaths.design,
    text: 'Design',
  },
  {
    analyticsEvent: 'footer_shop_creators',
    path: storefrontRoutePaths.creators,
    text: 'Creators',
  },
  {
    analyticsEvent: 'footer_about_stories',
    path: storefrontRoutePaths.stories,
    text: 'Story',
  },
];

const companyLinks = [
  {
    analyticsEvent: 'footer_company_careers',
    path: storefrontRoutePaths.careers,
    text: 'Careers',
  },
  {
    analyticsEvent: 'footer_company_press',
    path: storefrontRoutePaths.press,
    text: 'Press',
  },
];

const tradeLinks = [
  {
    analyticsEvent: 'footer_trade_landing',
    path: storefrontRoutePaths.trade,
    text: 'Trade Program',
  },
  {
    analyticsEvent: 'footer_trade_faqs',
    path: storefrontRoutePaths.tradeFaqs,
    text: 'Trade FAQs',
  },
];

const helpLinks = [
  {
    analyticsEvent: 'footer_help_faq',
    external: true,
    path: 'https://support.gantri.com/hc/en-us/sections/360007871571-Orders-Shipping',
    text: 'Shipping',
  },
  {
    analyticsEvent: 'footer_help_faq',
    external: true,
    path: 'https://support.gantri.com/hc/en-us/sections/115000094831-Returns-Exchanges',
    text: 'Returns',
  },
  {
    analyticsEvent: 'footer_help_faq',
    external: true,
    path: 'https://support.gantri.com/hc/en-us/articles/360040621592-What-s-the-warranty-on-Gantri-products-',
    text: 'Warranty',
  },
  {
    analyticsEvent: 'footer_help_faq',
    external: true,
    path: 'https://support.gantri.com/',
    text: 'Faqs',
  },
  {
    analyticsEvent: 'footer_help_contact',
    external: true,
    path: `mailto:${SUPPORT_EMAIL}`,
    text: 'Contact',
  },
];

const year = new Date().getFullYear();

const LinksColumn: FC<{
  links: Partial<(typeof helpLinks)[number]>[];
  titleText: string;
}> = (props) => {
  const { links, titleText } = props;

  return (
    <StyledLinksContainer minWidth={{ md: '50%' }}>
      <Typography
        color="alt"
        htmlTag="h2"
        paddingBottom={{ lg: '3x', md: '2x' }}
        text={titleText}
        variant="mp1"
      />

      <Stack gap={{ lg: '.5x', md: 'x' }} height="unset" padding="unset">
        {links.map((link) => {
          return (
            <Link
              key={link.text}
              color="alt"
              target={link.external ? '_blank' : undefined}
              to={link.path}
            >
              <Typography
                color="alt"
                decoration="none"
                lineHeight={{ lg: '2.4rem', md: '1.85rem', sm: '1.75rem' }}
                text={link.text}
                variant="mp2"
              />
            </Link>
          );
        })}
      </Stack>
    </StyledLinksContainer>
  );
};

export const ShopFooter = memo(() => {
  return (
    <MaxWidth backgroundColor="#25352f">
      <Grid
        columns={{ lg: 12, md: 6 }}
        gap="unset"
        horizontalPadding={NEW_HORIZONTAL_PADDING}
        rowGap={{ lg: '6x', md: '4x' }}
        verticalPadding={VERTICAL_PADDING}
      >
        <Cell
          paddingTop={{ md: '4x' }}
          row={{ md: 2 }}
          width={{ lg: 7, md: 6 }}
        >
          <Flex
            justifyContent="space-between"
            rowGap={{ md: '4x' }}
            wrap={{ md: 'wrap' }}
          >
            <LinksColumn links={shopLinks} titleText="Shop" />
            <LinksColumn links={aboutLinks} titleText="About" />
            <LinksColumn links={companyLinks} titleText="Company" />
            <LinksColumn links={tradeLinks} titleText="Trade" />
            <LinksColumn links={helpLinks} titleText="Help" />
          </Flex>
        </Cell>

        <Cell
          alignContent="start"
          column={{ lg: 10, md: 1 }}
          row={{ md: 1 }}
          rowGap={{ lg: '3x', md: '2x' }}
          width={{ lg: 3, md: 6 }}
        >
          <Typography
            color="alt"
            htmlFor="subscribeEmail"
            htmlTag="label"
            text="Inspired"
            variant="mp1"
          />

          <Flex gap={{ lg: '3x', md: '1.2rem' }}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href="https://www.pinterest.com/gantridesigns"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackAnalyticsEvent('footer_pinterest');
              }}
            >
              <Icon color="alt" name="logos:logo_pinterest" size="4rem" />
            </a>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href="http://www.instagram.com/gantri"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackAnalyticsEvent('footer_instagram');
              }}
            >
              <Icon color="alt" name="logos:logo_instagram" size="4rem" />
            </a>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href="https://www.tiktok.com/@gantridesigns"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackAnalyticsEvent('footer_tiktok');
              }}
            >
              <Icon color="alt" name="logos:logo_tiktok" size="4rem" />
            </a>
          </Flex>
        </Cell>

        <Cell width={{ lg: 12 }}>
          <Flex
            alignItems={{ lg: 'center', md: 'flex-start' }}
            direction={{ md: 'column' }}
            gap={{ lg: '3x', md: '.5x' }}
            paddingTop={{ md: '4x' }}
          >
            <Typography
              color="t3"
              text={`© ${year} Gantri, Inc. All Rights Reserved.`}
              variant="p3"
            />

            <StyledPolicyLinks>
              <Link to={storefrontRoutePaths.legal.privacy}>
                <Typography color="alt" text="Privacy Policy" variant="mp3" />
              </Link>
              <Link to={storefrontRoutePaths.legal.terms}>
                <Typography color="alt" text="Terms of Use" variant="mp3" />
              </Link>
            </StyledPolicyLinks>
          </Flex>
        </Cell>
      </Grid>
    </MaxWidth>
  );
});

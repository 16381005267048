import * as yup from 'yup';

export const ShippingInfoSchema = yup.object().shape({
  city: yup.string().required({ tx: 'errors.required' }),
  country: yup.string().required({ tx: 'errors.required' }),
  firstName: yup.string().required({ tx: 'errors.required' }),
  lastName: yup.string().required({ tx: 'errors.required' }),
  phone: yup.string().required({ tx: 'errors.required' }),
  state: yup.string().required({ tx: 'errors.required' }),
  street: yup.string().required({ tx: 'errors.required' }),
  zip: yup.string().required({ tx: 'errors.required' }),
});

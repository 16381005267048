import {
  CheckboxList,
  Stack,
  Typography,
  useMediaSmall,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { ConceptStepTwoFormDef } from '../../concept-step-two.types';
import { ConceptRoomsChecklistSectionPropsDef } from './concept-rooms-checklist-section.types';

export const ConceptRoomsChecklistSection = (
  props: ConceptRoomsChecklistSectionPropsDef,
) => {
  const { isDisabled } = props;

  const rooms = [
    'Bathroom',
    'Bedroom',
    'Entryway',
    'Garage',
    'Kids',
    'Kitchen & Dining',
    'Living',
    'Office',
    'Patio & Outdoor',
  ];

  const isSmall = useMediaSmall();

  const { setFieldValue, values } = useFormikContext<ConceptStepTwoFormDef>();

  return (
    <Stack>
      <Typography text="Which room(s) does your design belong?" variant="h6" />

      <CheckboxList
        getIsDisabled={(item) => {
          const isAtMaxRooms = values.rooms.length >= 3;

          const isChecked = values.rooms.some((selectedRoom) => {
            return selectedRoom === item;
          });

          return isDisabled || (isAtMaxRooms && !isChecked);
        }}
        gridProps={{
          columns: isSmall ? 2 : 3,
          justifySelf: 'start',
          rowGap: 'x',
        }}
        items={rooms}
        values={values.rooms}
        onValueChange={async (rooms) => {
          await setFieldValue('rooms', rooms.sort());
        }}
      />
    </Stack>
  );
};

import { FC } from 'react';
import { FlexContainer } from 'layout/flex-container';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon';

export const ProductPreparation: FC = () => (
  <FlexContainer
    alignItems="center"
    backgroundColor="cream"
    direction="column"
    horizontalPadding={{ lg: '20%', md: '15%' }}
    marginBottom="s1"
    verticalPadding={{ lg: 's6', md: 's5' }}
  >
    <Icon color="primary" name="clock" size="6rem" />
    <Typography
      align="center"
      marginTop="s2"
      text="Just a moment"
      variant="p1"
    />
    <Typography
      align="center"
      color="grey"
      htmlText="We're uploading all of the content to your product right now. You'll be able to access this product page once we're done. This shouldn't take too long. If you have any questions, contact us at <a href='mailto:support@gantri.com'>support@gantri.com</a>"
      variant="p1"
    />
  </FlexContainer>
);

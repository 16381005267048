import { Grid, Image, Box, Typography } from 'gantri-components';
import { HeaderSubmissionDetailsPropsDef } from './header-submission-details.types';

export const HeaderSubmissionDetails = (
  props: HeaderSubmissionDetailsPropsDef,
) => {
  const { children, descriptionProps, imgProps, titleProps } = props;

  return (
    <Grid
      alignItems="center"
      columns="max-content 1fr max-content"
      gap="2x"
      paddingBottom="2x"
    >
      {imgProps && (
        <Image
          alt={titleProps.text ? String(titleProps.text) : ''}
          containerAspectRatio="1"
          height="4.8rem"
          source="static"
          src={null}
          width="4.8rem"
          {...imgProps}
        />
      )}

      <Box>
        <Typography variant="h3" {...titleProps} />

        {descriptionProps && <Typography color="t2" {...descriptionProps} />}
      </Box>
      {children}
    </Grid>
  );
};

import { environment } from 'config/environment';

export const generateStorageKey = (key: string) =>
  `${key}_${environment.APP_STAGE}`;

export const saveToLocalStorage = (key: string, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key: string) =>
  JSON.parse(localStorage.getItem(key));

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const saveToSessionStorage = (key: string, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key: string) =>
  JSON.parse(sessionStorage.getItem(key));

export const removeFromSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { Page } from 'core/page';
import { Box } from 'gantri-components';
import { ConceptStepContent } from './components/concept-step-content';

export const ConceptStepPage = () => {
  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          <ConceptStepContent />
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

import { FC } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { DashboardProduct } from 'services/api';
import { Thumbnail } from 'core/thumbnail';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Icon } from 'core/icon';
import {
  StyledCheckIconContainer,
  StyledProductLists,
} from './products-list.styles';
import { ProductsListPresets } from './products-list.presets';
import { ProductsListProps } from './products-list.types';

export const ProductsList: FC<ProductsListProps> = (props) => {
  const { products } = { ...ProductsListPresets, ...props };

  return (
    <StackedContainer marginTop="s3" padding="unset">
      {products.map((dashboardProduct: DashboardProduct, index: number) => {
        let imageSrc = '';

        if (
          ['Active', 'Off Market'].includes(dashboardProduct?.product?.status)
        ) {
          imageSrc = Object.values(
            dashboardProduct.product?.skuPhotoUrls || {},
          )?.[0]?.thumbnail?.url;
        } else if (dashboardProduct.design?.sketches?.front?.uploadedFile) {
          imageSrc =
            dashboardProduct.design?.sketches?.front?.uploadedFile?.fileUrl;
        }

        return (
          <Grid key={index} alignItems="center" columns="8rem 1fr" gap="s2">
            <StyledProductLists>
              {!imageSrc && (
                <ImageContainer
                  height={{ lg: '8rem', sm: '5rem' }}
                  src="common/default-design.png"
                  width={{ lg: '8rem', sm: '5rem' }}
                />
              )}

              {!!imageSrc && (
                <Thumbnail objectFit="cover" size="8rem" src={imageSrc} />
              )}

              <StyledCheckIconContainer hasImage={!!imageSrc}>
                <Icon name="check-round-full" size="2rem" />
              </StyledCheckIconContainer>
            </StyledProductLists>

            <div>
              <Typography text={dashboardProduct.header} />
              {!!dashboardProduct.body && (
                <Typography color="grey" text={dashboardProduct.body} />
              )}
              {!!dashboardProduct.link && (
                <Typography
                  color={dashboardProduct.link.color || 'primary'}
                  href={dashboardProduct.link.url}
                  text={dashboardProduct.link.label}
                  variant="link"
                />
              )}
              {!dashboardProduct.link && (
                <Typography
                  color="primary"
                  href={`/design-flow/${dashboardProduct.design.id}/step-list`}
                  text="View"
                  variant="link"
                />
              )}
            </div>
          </Grid>
        );
      })}
    </StackedContainer>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { DatePickerRangeProps } from './date-picker-range.props';
import { DatePickerRangePresets } from './date-picker-range.presets';
import {
  StyledDatePickerContainer,
  StyledDatePickerPopup,
  StyledDatePickerValue,
  StyledResetDateContainer,
} from './date-picker-range.styles';
import DateTimePicker from 'react-datepicker';
import { useToggle } from 'react-use';

import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';

export const DatePickerRange = (props: DatePickerRangeProps) => {
  const { allowFutureDates, displayFormat, from, name, onChange, to } = {
    ...DatePickerRangePresets,
    ...props,
  };

  const [opened, toggleOpened] = useToggle(false);

  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);

  useEffect(() => {
    setStartDate(from);
  }, [formatDate(from)]);

  useEffect(() => {
    setEndDate(to);
  }, [formatDate(to)]);

  const handleChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChange?.({
      from: start,
      to: end,
    });

    if (start && end) {
      toggleOpened(false);
    }
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);

    onChange?.({
      from: null,
      to: null,
    });
  };

  const maxDate = useMemo(
    () => (allowFutureDates ? null : new Date()),
    [allowFutureDates],
  );

  return (
    <StyledDatePickerContainer>
      <StyledDatePickerValue onClick={toggleOpened}>
        <Typography text={startDate ? formatDate(startDate) : ''} />
        <Icon color="grey" name="arrow-right" size="1.8rem" />
        <Typography text={endDate ? formatDate(endDate) : ''} />
        <Icon name="calendar" size="2rem" />
      </StyledDatePickerValue>

      {opened && (
        <StyledDatePickerPopup visible onClickOutside={toggleOpened}>
          <DateTimePicker
            endDate={endDate}
            inline
            maxDate={maxDate}
            selected={startDate}
            selectsRange
            startDate={startDate}
            onChange={handleChange}
          />

          <StyledResetDateContainer>
            <Typography
              align="center"
              color="primary"
              text="Reset dates"
              textStyle="bold"
              onClick={resetDates}
            />
          </StyledResetDateContainer>
        </StyledDatePickerPopup>
      )}
    </StyledDatePickerContainer>
  );
};

import axios from 'axios';
import { userApiUrl } from 'helpers/auth';
import { DeleteDesignArgs, DeleteDesignResponse } from './delete-design.types';

export const deleteDesign = ({ id }: DeleteDesignArgs) => {
  return axios
    .delete<DeleteDesignResponse>(`${userApiUrl}/designs/${id}`)
    .then(({ data }) => {
      return data;
    });
};

import { useMemo, useState } from 'react';

export const useMultipleSelectionState = (
  values: {
    [prop: string]: boolean;
  } = {},
) => {
  const [state, setState] = useState(values || {});

  const setProperty = (property: string, value?: boolean) => {
    if (value === true || value === false) {
      setState(current => ({ ...current, [property]: value }));
    } else {
      setState(current => ({ ...current, [property]: !current[property] }));
    }
  };

  const selection = useMemo(
    () => Object.keys(state).filter(key => state[key]),
    [state],
  );

  const isPropertySelected = (property: string) => !!state[property];

  const resetSelection = () => setState({});

  return {
    isPropertySelected,
    resetSelection,
    selection,
    setProperty,
  };
};

import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { legacyTheme } from 'styles/theme';

export const StyledSelectOverview = styled.ul`
  ${({ theme }) => {
    return css`
      padding: ${legacyTheme.spacing.s2} ${legacyTheme.spacing.s2} 0
        ${legacyTheme.spacing.s2};
      display: grid;
      row-gap: ${legacyTheme.spacing.s1};
      user-select: none;
      cursor: default;
      min-width: 24rem;
      min-height: 5rem;
      overflow-y: initial;

      ${media.lessThan('sm')`
      min-width: 18rem;
    `}
    `;
  }}
`;

export const StyledSelectOption = styled.ul`
  cursor: pointer;
  display: flex;
  align-items: center;

  & > *:first-child:not(:last-child) {
    margin-right: 1rem;
  }

  &:last-child {
    padding-bottom: 2rem;
  }
`;

export const StyledSelectCloseIcon = styled.div`
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  z-index: 1;
  cursor: pointer;
`;

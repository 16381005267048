import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import { Form, Formik } from 'formik';
import { StackedContainer } from 'layout/stacked-container';
import { TextInput } from 'core/text-input';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  AcceptInviteRequest,
  AcceptInviteResponse,
  api,
  VALIDATE_TOKEN,
} from 'services/api';
import { FlexContainer } from 'layout/flex-container';
import { Icon } from 'core/icon';
import { useAuth } from 'context/auth.context';
import { useNotification } from 'context/notification.context';
import { environment } from 'config/environment';
import { AUTH_TOKEN, FIREBASE_TOKEN } from 'constants/common';
import { Cookies } from 'react-cookie';
import { StyledLogoContainer } from './accept.styles';
import { AcceptInviteForm } from './accept-form.schema';

export const AcceptPage: FC = () => {
  const { search } = useLocation();
  const [{ campaignInfo }] = useAuth();
  const [token, setToken] = useState('');

  const { notifyError } = useNotification();

  useEffect(() => {
    if (search) {
      const url = new URLSearchParams(search);

      setToken(url.get('token'));
    }
  }, [search]);

  const query = useQuery([VALIDATE_TOKEN, token], () => {
    if (token) {
      return api.designer.validateToken(token);
    }
  });

  const acceptInviteMutation = useMutation(
    async (request: AcceptInviteRequest) => {
      return api.designer.acceptInvite(request);
    },
    {
      onError: (err) => {
        notifyError(err['message']);
      },
      onSuccess: async (response: AcceptInviteResponse) => {
        new Cookies().set(AUTH_TOKEN, response.token, {
          domain: environment.COOKIES_DOMAIN,
          path: '/',
        });

        new Cookies().set(FIREBASE_TOKEN, response.firebaseToken, {
          domain: environment.COOKIES_DOMAIN,
          path: '/',
        });

        window.location.replace('/');
      },
    },
  );

  const queryClient = useQueryClient();
  // const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
  //   refetchOnWindowFocus: false,
  // });

  const handleSubmit = async (values) => {
    acceptInviteMutation.mutate({
      ...values,
      browserUserId: campaignInfo.browserUserId,
      firstName: query.data?.user.firstName,
      lastName: query.data?.user.lastName,
      token,
    });
  };

  return (
    <div>
      <StyledLogoContainer>
        <Link to="/">
          <Icon
            color="black"
            height={{ lg: '3rem', md: '2rem' }}
            name="gantri-logo"
            width={{ lg: '12rem', md: '9rem' }}
          />
        </Link>
      </StyledLogoContainer>

      <Grid
        alignContent={{ lg: 'unset', md: 'start' }}
        alignItems={{ lg: 'center', md: 'start' }}
        columns={{ lg: '1fr 4fr 1fr 6fr', md: '1fr' }}
        height={{ lg: 'calc(100vh - 15rem)', md: 'unset' }}
        horizontalPadding={{ lg: 's3', md: 's2', sm: 's1' }}
        marginBottom={{ lg: 'unset', md: 's5' }}
        rowGap="s4"
      >
        <Cell column={{ lg: 2, md: 1 }} row={{ lg: 1, md: 2 }}>
          {query.data?.success && (
            <>
              {query.data.accepted && (
                <StackedContainer gap={{ lg: 's4', md: 's3' }} padding="unset">
                  <Icon
                    color="black"
                    height={{ lg: '3rem', md: '2rem' }}
                    name="gantri-logo"
                    width={{ lg: '12rem', md: '9rem' }}
                  />

                  <Icon name="frown" size="6.2rem" />

                  <div>
                    <Typography
                      color="grey"
                      text={`Hi ${query.data.user.firstName}!`}
                      textStyle="regular"
                      variant="h3"
                    />

                    <Typography
                      text="You already accepted the invite!"
                      textStyle="regular"
                      variant="h3"
                    />
                  </div>

                  <Typography
                    color="grey"
                    text="Log in to start creating new designs today."
                  />

                  <Link to={`${environment.SHOP_URL}/dashboard`}>
                    <Button text="Log In" width={{ lg: '15rem', md: '100%' }} />
                  </Link>
                </StackedContainer>
              )}

              {!query.data.accepted && (
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: query.data?.user?.email,
                    password: '',
                  }}
                  validateOnMount
                  validationSchema={AcceptInviteForm}
                  onSubmit={handleSubmit}
                >
                  {(props) => {
                    return (
                      <Form autoComplete="off">
                        <StackedContainer gap="s4" padding="unset">
                          <div>
                            <Typography text="Congrats!" variant="h2" />

                            <Typography
                              color="grey"
                              marginTop="s1"
                              text={`${query.data?.nameDesigner} invited you to Gantri. Create account to accept invitation.`}
                              variant="p1"
                            />
                          </div>

                          <TextInput
                            error={props.errors.email as string}
                            label="Email"
                            name="email"
                            touched={props.touched.email as boolean}
                            value={props.values.email}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />

                          <TextInput
                            error={props.errors.password as string}
                            label="Password"
                            name="password"
                            placeholder="min. 6 characters"
                            touched={props.touched.password as boolean}
                            type="password"
                            value={props.values.password}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />

                          <FlexContainer direction="column">
                            <Button
                              disabled={!props.isValid}
                              text="Create Account"
                              type="submit"
                            />

                            <Typography
                              color="grey"
                              marginTop="s1"
                              text="By proceeding, you agree to our terms & private policy."
                              variant="p3"
                            />
                          </FlexContainer>

                          <Typography
                            color="gold"
                            text={`Your invite will expire in ${query.data?.time}`}
                          />
                        </StackedContainer>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
        </Cell>

        <Cell column={{ lg: 4, md: 1 }}>
          <ImageContainer
            aspectRatio="1:1"
            src="pages/accept-invite/square-invite.jpeg"
          />
        </Cell>
      </Grid>
    </div>
  );
};

import * as yup from 'yup';
import { ConceptStepThreeFormDef } from './concept-step-three.types';

const requiredText = 'Required';

export const conceptStepThreeFormSchema: yup.SchemaOf<ConceptStepThreeFormDef> =
  yup.object().shape({
    changeBulb: yup.string().nullable().required(requiredText),
    howDesignUsed: yup.string().nullable().required(requiredText),
    whenDesignUsed: yup.string().nullable().required(requiredText),
  });

import * as yup from 'yup';
import { fileDetailsFormSchema } from 'src/pages/design-flow/pages/concept-step/components/concept-step-one/concept-step-one.schema';
import { ModelStepOneFormDef } from './model-step-one.types';

const requiredText = 'Required';

export const modelStepOneFormSchema: yup.SchemaOf<ModelStepOneFormDef> = yup
  .object()
  .shape({
    assemblyFile: fileDetailsFormSchema.required(requiredText),
  });

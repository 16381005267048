import { useParams } from 'react-router-dom';
import { StepHeader } from '../../../../components/step-header';
import { ConceptStepHeaderPropsDef } from './concept-step-header.types';
import { ConceptStepPageParams } from '../../concept-step.types';

export const ConceptStepHeader = (props: ConceptStepHeaderPropsDef) => {
  const { stepNumber } = useParams<ConceptStepPageParams>();

  return (
    <StepHeader
      stepNumber={Number(stepNumber)}
      stepType="CONCEPT"
      totalStepNumber={3}
      {...props}
    />
  );
};

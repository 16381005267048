import { Conditional, Stack } from 'gantri-components';
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN } from 'constants/common';
import { useQuery } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { HeroSection } from './components/hero-section';
import { CallingWorldTalentsSection } from './components/calling-world-talents-section';
import { HowWeWorkSection } from './components/how-we-work-section';
import { TailoredSection } from './components/tailored-section';
import { CategoriesSection } from './components/categories-section';
import { ApplySection } from './components/apply-section';
import { ShopFooter } from './components/footer';

export const HomePage = () => {
  const [cookies] = useCookies([AUTH_TOKEN]);

  const query = useQuery<UserData, { message: string }>(
    GET_USER,
    api.auth.getLogged,
    {
      enabled: !!cookies[AUTH_TOKEN],
      refetchOnWindowFocus: false,
    },
  );

  const isDesigner = query?.data?.data?.isDesigner;

  return (
    <Stack gap="0">
      <HeroSection />

      <CallingWorldTalentsSection />

      <HowWeWorkSection />

      <TailoredSection />

      <CategoriesSection />

      <Conditional condition={!isDesigner}>
        <ApplySection />
      </Conditional>

      <ShopFooter />
    </Stack>
  );
};

import styled, { css } from 'styled-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { BoxContainer } from '../box-container';
import { FlexContainerProps } from './flex-container.props';

export const StyledFlexContainer = styled(BoxContainer)<FlexContainerProps>`
  ${({
    alignItems,
    backgroundColor,
    direction,
    height,
    hidden,
    justifyContent,
    width,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'flex-direction',
        resolutionAwareProp: direction,
      },
      {
        cssProperty: 'justify-content',
        resolutionAwareProp: justifyContent,
      },
      {
        cssProperty: 'align-items',
        resolutionAwareProp: alignItems,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value === true ? 'none' : 'flex'),
      },
      {
        cssProperty: 'background-color',
        resolutionAwareProp: backgroundColor,
        valueFormatter: value => getThemeColor(value),
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'width',
        resolutionAwareProp: width,
      },
    ];

    const generatedCss = generateStylesForResolutionAwareProps(entries);

    return css`
      display: flex;
      width: 100%;
      ${generatedCss}
    `;
  }}
`;

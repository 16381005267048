import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgGift = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.88 8.8c.16 0 .32.16.32.32v6.56c0 .16-.16.32-.32.32H1.6c-.48 0-.8-.32-.8-.8V8.96c0-.08.08-.16.16-.16zm8.16 0c.08 0 .16.08.16.16v6.24c0 .48-.32.8-.8.8H9.12c-.16 0-.32-.16-.32-.32V9.12c0-.16.16-.32.32-.32zM11.2 0c1.36 0 2.4 1.04 2.4 2.4 0 .64-.24 1.2-.64 1.6h2.24c.48 0 .8.32.8.8v2.24c0 .08-.08.16-.16.16H9.12c-.16 0-.32-.16-.32-.32V4.32c0-.16-.16-.32-.32-.32h-.96c-.16 0-.32.16-.32.32v2.56c0 .16-.16.32-.32.32H.16C.08 7.2 0 7.12 0 7.04V4.8c0-.48.32-.8.8-.8h2.24c-.4-.4-.64-.96-.64-1.6C2.4 1.04 3.44 0 4.8 0 5.6 0 7.04.72 8 1.68 8.96.72 10.4 0 11.2 0zm0 1.6c-.48 0-1.84.88-2.32 1.44.24.08 2.32.16 2.32.16.48 0 .8-.32.8-.8 0-.48-.32-.8-.8-.8zm-6.4 0c-.48 0-.8.32-.8.8 0 .48.32.8.8.8 0 0 2.08-.08 2.32-.16-.4-.48-1.84-1.44-2.32-1.44z"
        fill="#1B7B76"
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgGift;

import { FC, useState } from 'react';
import { Grid } from 'layout/grid';
import { TextInput } from 'core/text-input';
import { FiltersDropdown } from 'core/filters-dropdown';
import { Typography } from 'core/typography';
import { Checkbox } from 'core/checkbox';
import { useMultipleSelectionState } from 'utils/hooks';
import { Icon } from 'core/icon';
import { Cell } from 'layout/cell';
import { OrderStatus, OrderType } from 'services/api/order';
import { DatePickerRange } from 'core/date-picker-range';
import { DatePickerValues } from 'core/date-picker-range/date-picker-range.props';
import { formatDate } from 'utils/date';
import { OrdersFiltersPresets } from './orders-filters.presets';
import { OrdersFiltersProps } from './orders-filters.types';

const types = [
  'Order',
  'Replacement',
  'Trade',
  'Refund',
  'Trade Refund',
  'Third Party',
  'Marketing',
  'Third Party Refund',
  'R&D',
  'Designer',
];

const statuses = [
  'Processed',
  'Refunded',
  'In progress',
  'Cancelled',
  'Shipped',
  'Unpaid',
  'Delivered',
];

export const OrdersFilters: FC<OrdersFiltersProps> = props => {
  const { count, onApply, onReset } = {
    ...OrdersFiltersPresets,
    ...props,
  };
  const [search, setSearch] = useState('');
  const [created, setCreated] = useState<DatePickerValues>();
  const [completed, setCompleted] = useState<DatePickerValues>();

  const {
    isPropertySelected: isTypesPropertySelected,
    resetSelection: resetTypesSelection,
    selection: typesSelection,
    setProperty: setTypesProperty,
  } = useMultipleSelectionState();

  const {
    isPropertySelected: isStatusPropertySelected,
    resetSelection: resetStatusSelection,
    selection: statusSelection,
    setProperty: setStatusProperty,
  } = useMultipleSelectionState();

  const handleReset = () => {
    resetTypesSelection();
    resetStatusSelection();
    setCreated(null);
    setCompleted(null);
    onReset();
  };

  const apply = (search: string = '') => {
    onApply?.({
      completedEndDate: completed?.to ? formatDate(completed.to) : '',
      completedStartDate: completed?.from ? formatDate(completed.from) : '',
      endDate: created?.to ? formatDate(created.to) : '',
      search,
      startDate: created?.from ? formatDate(created.from) : '',
      statuses: statusSelection as OrderStatus[],
      types: typesSelection as OrderType[],
    });
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    apply(value);
  };

  return (
    <Grid columns={{ lg: 'repeat(2, 24rem)', md: 'repeat(2, 1fr)' }}>
      <FiltersDropdown
        count={count}
        onApply={() => apply()}
        onReset={handleReset}
      >
        <Grid
          columns={{ lg: 2, md: 1 }}
          rowGap={{ lg: 's5', md: 's4', sm: 's3' }}
          width={{ lg: '58rem', md: '83vw' }}
        >
          <Cell>
            <Typography
              color="grey"
              marginBottom="s2"
              text="CREATED"
              variant="h5"
            />

            <DatePickerRange
              allowFutureDates={false}
              from={created?.from}
              to={created?.to}
              onChange={setCreated}
            />
          </Cell>

          <Cell>
            <Typography
              color="grey"
              marginBottom="s2"
              text="COMPLETED"
              variant="h5"
            />

            <DatePickerRange
              allowFutureDates={false}
              from={completed?.from}
              to={completed?.to}
              onChange={setCompleted}
            />
          </Cell>

          <Cell>
            <Typography color="grey" text="TYPE" variant="h5" />

            <Grid
              columns="repeat(2, max-content)"
              marginTop="s2"
              padding="unset"
              rowGap="s1"
            >
              {types.map(type => (
                <Checkbox
                  key={type}
                  checked={isTypesPropertySelected(type)}
                  labelText={type}
                  labelTransform="capitalize"
                  onChange={(value: boolean) => setTypesProperty(type, value)}
                />
              ))}
            </Grid>
          </Cell>

          <Cell>
            <Typography color="grey" text="STATUS" variant="h5" />

            <Grid
              columns="repeat(2, max-content)"
              marginTop="s2"
              padding="unset"
              rowGap="s1"
            >
              {statuses.map(type => (
                <Checkbox
                  key={type}
                  checked={isStatusPropertySelected(type)}
                  labelText={type}
                  labelTransform="capitalize"
                  onChange={(value: boolean) => setStatusProperty(type, value)}
                />
              ))}
            </Grid>
          </Cell>
        </Grid>
      </FiltersDropdown>

      <TextInput
        clearable
        paddingLeft="3rem"
        placeholder="Search"
        prefixComponent={<Icon name="search" />}
        value={search}
        onTextChange={handleSearchChange}
      />
    </Grid>
  );
};

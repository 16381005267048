import { TextInputProps } from './text-input.props';

export const TextInputPresets: TextInputProps = {
  actionColor: 'primary',
  clearable: true,
  descriptionColor: 'grey',
  id: '',
  labelColor: 'black',
  paddingLeft: 'unset',
  placeholderColor: 'grey',
  type: 'text',
  variant: 'default',
};

import { StepHeader } from 'src/pages/design-flow/components/step-header';
import { useParams } from 'react-router-dom';
import { ModelStepHeaderPropsDef } from './model-step-header.types';
import { ModelStepPageParams } from '../../model-step.types';

export const ModelStepHeader = (props: ModelStepHeaderPropsDef) => {
  const { stepNumber } = useParams<ModelStepPageParams>();

  return (
    <StepHeader
      stepNumber={Number(stepNumber)}
      stepType="MODEL"
      totalStepNumber={3}
      {...props}
    />
  );
};

import styled from 'styled-components';
import { css } from 'styled-components';

export const StyledProductLists = styled.div`
  position: relative;
`;

export const StyledCheckIconContainer = styled.span<{ hasImage?: boolean }>`
  ${({ hasImage }) => css`
    position: absolute;
    bottom: ${hasImage ? '1rem' : '0.4rem'};
    right: 1rem;
  `}
`;

import { FC } from 'react';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { FirebaseUploader } from 'core/firebase-uploader';
import { useNotification } from 'context/notification.context';
import { useMutation, useQueryClient } from 'react-query';
import { DesignerInfo } from 'types/user.type';
import { api, GET_USER } from 'services/api';
import { useAuth } from 'context/auth.context';
import { Thumbnail } from 'core/thumbnail';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import {
  StyledLogo,
  StyledLogoContainer,
  StyledRemovePhotoContainer,
} from './logo-form.styles';
import { BrandFormProps } from './logo-form.types';

export const LogoForm: FC<BrandFormProps> = props => {
  const { designerInfo, logoUrl, onLogoFormSubmit, userId } = props;

  const { notifyError } = useNotification();
  const queryClient = useQueryClient();
  const [{ campaignInfo }] = useAuth();

  const updateDesignerMutation = useMutation(
    async (data: DesignerInfo) => api.designer.update(data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const updateLogoUrl = async (logoUrl: string) => {
    await updateDesignerMutation.mutateAsync({
      ...designerInfo,
      browserUserId: campaignInfo.browserUserId,
      logoUrl,
      userId,
    });
  };

  const handlePhotoRemoval = async () => {
    await updateDesignerMutation.mutateAsync({
      ...designerInfo,
      browserUserId: campaignInfo.browserUserId,
      logoUrl: '',
      userId,
    });
  };

  return (
    <FlexContainer
      alignItems="center"
      direction="column"
      justifyContent="space-between"
      minWidth={{ lg: 'unset', md: '70vw' }}
    >
      {logoUrl ? (
        <StyledLogoContainer>
          <Thumbnail objectFit="cover" size="20rem" src={logoUrl} />

          <StyledRemovePhotoContainer onClick={handlePhotoRemoval}>
            <Icon name="trash" size="1.6rem" />
          </StyledRemovePhotoContainer>
        </StyledLogoContainer>
      ) : (
        <FirebaseUploader
          component={
            <StyledLogo>
              <Icon color="primary" name="camera-square" size="5rem" />
              <Typography color="grey" text="Min 400px x 400px" variant="p3" />
            </StyledLogo>
          }
          filename="designer-logo.png"
          path={`/users/${userId}/designer/`}
          onError={notifyError}
          onImageUploaded={updateLogoUrl}
        />
      )}

      <Spacer height="s4" />

      <Button
        disabled={!logoUrl}
        text="Continue"
        width="100%"
        onClick={onLogoFormSubmit}
      />
    </FlexContainer>
  );
};

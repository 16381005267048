import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgGiftGold = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 40 38"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke={color}>
        <path d="M1.4 17.777h37.2V11.26H1.4zM3.018 37.33h33.964V17.777H3.018zM14.339 11.261v26.07M25.661 37.33V11.26" />
        <path
          d="M29.15 7.624C27.571 9.214 20 11.26 20 11.26s1.85-7.807 3.43-9.397a4.024 4.024 0 015.72 0 4.1 4.1 0 010 5.761zM10.85 7.624C12.43 9.214 20 11.26 20 11.26s-1.852-7.807-3.43-9.397a4.023 4.023 0 00-5.72 0 4.096 4.096 0 000 5.761z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
});
export default SvgGiftGold;

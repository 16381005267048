import axios, { InternalAxiosRequestConfig } from 'axios';
import { getTokenCookie, SESSION_IDENTIFIER } from 'helpers/auth';

axios.interceptors.request.use(
  async (config) => {
    const axiosConfig: InternalAxiosRequestConfig = {
      ...config,
      // @ts-expect-error
      headers: {
        ...config.headers,
        Accept: 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
        'Session-Identifier': SESSION_IDENTIFIER,
      },
    };

    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

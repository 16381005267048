import { Cell, Grid } from 'gantri-components';
import { UploaderSectionHeader } from '../../../../../../components/uploader-section-header';
import { ConceptSketchesSectionPropsDef } from './sketches-section.types';
import { ConceptSketchesUploader } from './components/concept-sketches-uploader';

export const ConceptSketchesSection = (
  props: ConceptSketchesSectionPropsDef,
) => {
  const { design, isDisabled } = props;

  const expectedExtensions = ['jpg', 'png', 'gif', 'pdf'];
  const maxFileSizeMB = 10;

  return (
    <Cell>
      <UploaderSectionHeader
        description="Please upload sketches or basic renders of your concept. While we're looking for a rough draft, please ensure that your sketch/render demonstrates thought into how it will be broken up into parts and how it will be assembled."
        expectedExtensions={expectedExtensions}
        label="Sketches"
        maxFileSizeMB={maxFileSizeMB}
        tooltipSrc="graphite/design-flow/example-sketch_2x.jpg"
      />

      <Grid alignItems="end" columns={{ lg: 'repeat(2, max-content)', sm: 1 }}>
        <ConceptSketchesUploader
          concept={design.concept}
          designId={design.id}
          expectedExtensions={expectedExtensions}
          isDisabled={isDisabled}
          maxFileSizeMB={maxFileSizeMB}
          sketchCode="top"
          view="Top View"
        />
        <ConceptSketchesUploader
          concept={design.concept}
          designId={design.id}
          expectedExtensions={expectedExtensions}
          isDisabled={isDisabled}
          maxFileSizeMB={maxFileSizeMB}
          sketchCode="front"
          view="Front View"
        />
        <ConceptSketchesUploader
          concept={design.concept}
          designId={design.id}
          expectedExtensions={expectedExtensions}
          isDisabled={isDisabled}
          maxFileSizeMB={maxFileSizeMB}
          sketchCode="side"
          view="Side View"
        />
        <ConceptSketchesUploader
          concept={design.concept}
          description="with assembly, bulb access and material call-outs"
          designId={design.id}
          expectedExtensions={expectedExtensions}
          isDisabled={isDisabled}
          maxFileSizeMB={maxFileSizeMB}
          sketchCode="model"
          view="Section View"
        />
      </Grid>
    </Cell>
  );
};

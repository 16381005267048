import { ApisauceInstance, create } from 'apisauce';
import { UserApi } from 'services/api/user/user.api';
import { ProductApi } from 'services/api/product';
import { AuthApi } from 'services/api/auth';
import { DesignerApi } from 'services/api/designer';
import { Cookies } from 'react-cookie';
import { AUTH_TOKEN } from 'constants/common';
import { DownloadsApi } from 'services/api/downloads';
import { OrdersApi } from 'services/api/order';
import { DashboardApi } from 'services/api/dashboard';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';

export class Api {
  apisauce: ApisauceInstance;

  config: ApiConfig;

  auth: AuthApi;

  dashboard: DashboardApi;

  designer: DesignerApi;

  download: DownloadsApi;

  order: OrdersApi;

  product: ProductApi;

  user: UserApi;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.setup();

    const token = new Cookies().get(AUTH_TOKEN);

    if (token) {
      this.registerAuthHeader(token);
    }
  }

  setup(): void {
    this.apisauce = create({
      baseURL: this.config.url,
      headers: {
        Accept: 'application/json',
      },
      timeout: this.config.timeout,
    });

    this.auth = new AuthApi(this.apisauce);
    this.dashboard = new DashboardApi(this.apisauce);
    this.designer = new DesignerApi(this.apisauce);
    this.download = new DownloadsApi(this.apisauce);
    this.order = new OrdersApi(this.apisauce);
    this.product = new ProductApi(this.apisauce);
    this.user = new UserApi(this.apisauce);
  }

  registerAuthHeader(token: string): void {
    this.apisauce.setHeader('Authorization', `Bearer ${token}`);
  }

  clearAuthHeader(): void {
    this.apisauce.deleteHeader('Authorization');
  }
}

export const api = new Api();

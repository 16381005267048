import styled from 'styled-components';

export const StyledTimeSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 10rem);
  justify-items: start;
  gap: 1rem;

  select {
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
  }
`;

import { memo, useCallback, useState } from 'react';
import { useIntersectionObserver, useMediaLarge } from 'utils/hooks';
import { SizedContainer } from 'layout/sized-container';
import { requireImage } from 'layout/image-container/image.utils';
import { AnimatedImage } from 'layout/image-container/animated-image/animated-image';
import { ProgressiveImage } from 'layout/image-container/progressive-image';
import { useSpring } from '@react-spring/core';
import { ImageContainerPresets } from './image-container.presets';
import {
  StyledImageChildrenContainer,
  StyledImageContainer,
  StyledOverlay,
} from './image-container.styles';
import { ImageContainerProps } from './image-container.props';

export const ImageContainer = memo((props: ImageContainerProps) => {
  const {
    alt,
    backgroundColor,
    backgroundSize,
    borderRadius,
    children,
    containerHeight,
    imageHeight,
    imageWidth,
    immediateAvailable,
    objectFit,
    objectPosition,
    observerMargin,
    overlaySrc,
    renderMode,
    roundContainer,
    source,
    src,
    ...others
  } = { ...ImageContainerPresets, ...props };
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [canFetchImage, setCanFetchImage] = useState(renderMode === 'always');

  const large = useMediaLarge();

  const { observerRef } = useIntersectionObserver({
    onIntersect: ([{ isIntersecting }]) => {
      if (renderMode === 'viewport' && isIntersecting) {
        setCanFetchImage(true);
      }
    },
    rootMargin: observerMargin,
    threshold: 0,
    triggerOnce: true,
  });

  const overlayStyleProps: any = useSpring({
    opacity: overlayVisible ? 1 : 0,
  });

  const toggleOverlayVisibility = useCallback(() => {
    if (!!overlaySrc) {
      setOverlayVisible(!overlayVisible);
    }
  }, [overlaySrc, overlayVisible, setOverlayVisible]);

  return (
    <StyledImageContainer
      ref={observerRef}
      height={containerHeight}
      round={roundContainer}
      onMouseEnter={toggleOverlayVisibility}
      onMouseLeave={toggleOverlayVisibility}
    >
      <SizedContainer backgroundColor={backgroundColor} {...others}>
        {source === 'local' ? (
          <ProgressiveImage
            alt={alt}
            height={imageHeight}
            imageData={requireImage(src)}
            immediateAvailable={immediateAvailable}
            objectFit={objectFit}
            objectPosition={objectPosition}
            visible={canFetchImage}
            width={imageWidth}
          />
        ) : (
          <AnimatedImage
            alt={alt}
            backgroundSize={backgroundSize}
            height={imageHeight}
            objectFit={objectFit}
            objectPosition={objectPosition}
            src={src}
            visible={canFetchImage}
          />
        )}

        {large && !!overlaySrc && canFetchImage && (
          <StyledOverlay style={overlayStyleProps}>
            {source === 'local' ? (
              <ProgressiveImage
                alt={alt}
                height={imageHeight}
                imageData={requireImage(overlaySrc)}
                visible={canFetchImage}
              />
            ) : (
              <AnimatedImage
                alt={alt}
                src={overlaySrc}
                visible={canFetchImage}
              />
            )}
          </StyledOverlay>
        )}

        {!!children && (
          <StyledImageChildrenContainer>
            {children}
          </StyledImageChildrenContainer>
        )}
      </SizedContainer>
    </StyledImageContainer>
  );
});

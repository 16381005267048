import { ButtonProps } from './button.props';

export const ButtonPresets: Partial<ButtonProps> = {
  borderRadius: '2.5rem',
  disabled: false,
  height: { lg: '4.8rem', sm: '4rem' },
  iconLocation: 'left',
  linkPrefetch: false,
  linkTarget: '_self',
  textVariant: 'p2',
  type: 'button',
  variant: 'primary',
};

import { atom } from 'recoil';

const atomKeyPrefix = 'SPINNER';

export const fullPageLoadingSpinnerAtoms = {
  showSpinner: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-show-spinner`,
  }),
};

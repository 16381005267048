import { Fragment } from 'react';
import {
  Box,
  Conditional,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { StyledHowWeWorkStack } from './how-we-work-section.styles';
import { maxPageWidthMid, sectionVerticalPadding } from '../../home.constants';

export const HowWeWorkSection = () => {
  return (
    <StyledHowWeWorkStack
      horizontalPadding="2x"
      justifyContent="center"
      verticalPadding={sectionVerticalPadding}
    >
      <Stack alignContent="center" gap="8x" maxWidth={maxPageWidthMid}>
        <Typography
          align="center"
          color="alt"
          text="How we work"
          variant="h2"
        />

        <Grid
          columns={{
            lg: '1fr max-content 1fr max-content 1fr max-content 1fr',
            md: 1,
          }}
          gap="4x"
        >
          {[
            {
              description:
                'Develop new products in CAD using Gantri components, materials and guidelines.',
              title: 'Design',
            },
            {
              description:
                'Sell approved products under your brand and earn royalty on sales.',
              title: 'Sell',
            },
            {
              description:
                'Gantri uses digital manufacturing to produce, manage inventory and fulfill orders for you.',
              title: 'Fulfill',
            },
            {
              description:
                'See live order status and visualize your sales stats over time. Be better informed.',
              title: 'Track',
            },
          ].map(({ description, title }, index) => {
            const stepNum = `0${index + 1}`;

            return (
              <Fragment key={title}>
                <Conditional condition={!!index}>
                  <ArrowIcon />
                </Conditional>

                <Stack
                  gap=".5x"
                  justifyItems="center"
                  justifySelf="center"
                  maxWidth="24rem"
                >
                  <Typography
                    align="center"
                    color="t3"
                    text={stepNum}
                    variant="p3"
                  />

                  <Typography
                    align="center"
                    color="alt"
                    paddingBottom="x"
                    text={title}
                    textStyle="bold"
                    variant="h4"
                  />

                  <Typography align="center" color="t3" text={description} />
                </Stack>
              </Fragment>
            );
          })}
        </Grid>
      </Stack>
    </StyledHowWeWorkStack>
  );
};

const ArrowIcon = () => {
  return (
    <>
      <Box hidden={{ lg: false, md: true }} justifySelf="center">
        <Icon color="t3" name="arrows:arrow_right_24" size="2.4rem" />
      </Box>
      <Box hidden={{ lg: true, md: false }} justifySelf="center">
        <Icon color="t3" name="arrows:arrow_down_24" size="2.4rem" />
      </Box>
    </>
  );
};

import { TypographyWithIconProps } from 'core/typography-with-icon/typography-with-icon.props';
import { TypographyWithIconPresets } from 'core/typography-with-icon/typography-with-icon.presets';
import { StyledTypographyWithIcon } from 'core/typography-with-icon/typography-with-icon.styles';
import { Typography } from 'core/typography';

export const TypographyWithIcon = (props: TypographyWithIconProps) => {
  const { cursor, gap, icon, iconPosition, ...others } = {
    ...TypographyWithIconPresets,
    ...props,
  };

  return (
    <StyledTypographyWithIcon cursor={cursor} gap={gap}>
      {iconPosition === 'left' && icon}
      <Typography {...others} />
      {iconPosition === 'right' && icon}
    </StyledTypographyWithIcon>
  );
};

import { FC } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from 'layout/grid';
import { Button } from 'core/button';
import { TextInput } from 'core/text-input';
import { Spacer } from 'layout/spacer';
import { BrandFormProps, BrandFormValues } from './brand-form.types';
import { BrandFormSchema } from './brand-form.schema';

export const BrandForm: FC<BrandFormProps> = props => {
  const handleSubmit = (values: BrandFormValues) => {
    props?.onBrandFormSubmit(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        designerName: props.designerName,
        title: props.title,
      }}
      validateOnMount
      validationSchema={BrandFormSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form autoComplete="off" style={{ width: '100%' }}>
          <Grid
            alignContent="space-between"
            backgroundColor={{ md: 'white' }}
            gap="s4"
            height="100%"
            minWidth={{ lg: 'unset', md: '70vw' }}
            padding="unset"
            width="100%"
          >
            <div>
              <TextInput
                error={props.errors.designerName}
                name="designerName"
                placeholder="Designer name"
                touched={props.touched.designerName}
                value={props.values.designerName}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />

              <Spacer height="s4" />

              <TextInput
                error={props.errors.title}
                name="title"
                placeholder="Tagline"
                touched={props.touched.title}
                value={props.values.title}
                onBlur={props.handleBlur}
                onChange={props.handleChange}
              />
            </div>

            <Button
              disabled={!props.isValid}
              text="Continue"
              type="submit"
              variant="primary"
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

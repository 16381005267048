import omit from 'lodash/omit';
import { GetGenericQueryObjProps } from './get-generic-query-obj.types';

/**
 * @example
 * const query = (body: FetchStockSummaryArgs) =>
 *   axios
 *     .post<FetchStockSummaryResponse>(`${coreApiUrl}/EXAMPLE_URL`, body)
 *     .then(({ data }) => data);
 *
 * export const fetchStockSummaryQueryObj = getGenericQueryObj({
 *   groupName: YOUR_UNIQUE_GROUP_NAME,
 *   query,
 * });
 */

export const getGenericQueryObj = <
  FetchArgs extends Record<string, any>,
  FetchResponse extends Record<any, any>,
>(
  props: GetGenericQueryObjProps<FetchArgs, FetchResponse>,
) => {
  const { groupName, query } = props;

  const groupKey = () => {
    return groupName;
  };

  const key = (args?: FetchArgs) => {
    const key = [
      groupKey(),
      JSON.stringify(
        // TODO: not all keys are valid to be included it as part of the key
        omit(args, ['enabled', 'transform', 'transformDependencies']),
      ),
    ].join('|');

    return [
      {
        groupKey: groupKey(),
        key,
      },
    ] as const;
  };

  return {
    groupKey,
    key,
    query,
  };
};

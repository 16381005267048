import { useEffect, useMemo, useState } from 'react';
import { DatePickerProps } from './date-picker.props';
import { DatePickerPresets } from './date-picker.presets';
import {
  StyledDatePickerContainer,
  StyledDatePickerPopup,
  StyledDatePickerValue,
  StyledResetDateContainer,
} from './date-picker.styles';
import DateTimePicker from 'react-datepicker';
import { useToggle } from 'react-use';

import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { addToDate, formatDate } from 'utils/date';

export const DatePicker = (props: DatePickerProps) => {
  const {
    allowFutureDates,
    allowPastDates,
    displayFormat,
    max,
    min,
    name,
    onChange,
    placeholder,
    value,
  } = { ...DatePickerPresets, ...props };

  const [opened, toggleOpened] = useToggle(false);

  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [formatDate(value)]);

  const handleChange = (date: Date) => {
    setInnerValue(date);
    onChange?.(date);
    toggleOpened(false);
  };

  const minDate = useMemo(() => {
    if (min) {
      return min;
    }

    if (!allowPastDates) {
      return addToDate(new Date(), 1, 'days').toDate();
    }
  }, [min, allowPastDates]);

  const maxDate = useMemo(() => {
    if (max) {
      return max;
    }

    if (!allowFutureDates) {
      return new Date();
    }
  }, [max, allowFutureDates]);

  const resetDates = () => {
    setInnerValue(null);
    onChange?.(null);
  };

  return (
    <StyledDatePickerContainer>
      <StyledDatePickerValue onClick={toggleOpened}>
        <Typography
          color={innerValue ? 'black' : 'grey'}
          text={innerValue ? formatDate(innerValue) : placeholder}
        />
        <Icon name="calendar" size="2rem" />
      </StyledDatePickerValue>

      {opened && (
        <StyledDatePickerPopup visible onClickOutside={toggleOpened}>
          <DateTimePicker
            inline
            maxDate={maxDate}
            minDate={minDate}
            selected={innerValue}
            selectsRange={false}
            onChange={handleChange}
          />

          <StyledResetDateContainer>
            <Typography
              align="center"
              color="primary"
              text="Reset dates"
              textStyle="bold"
              onClick={resetDates}
            />
          </StyledResetDateContainer>
        </StyledDatePickerPopup>
      )}
    </StyledDatePickerContainer>
  );
};

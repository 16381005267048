import { FC, useEffect, useState } from 'react';
import range from 'lodash/range';
import { SelectInput } from 'core/select-input';
import { TimePickerPresets } from 'core/time-picker/time-picker.presets';
import { TimePickerProps } from 'core/time-picker/time-picker.types';
import { StyledTimeSelector } from './time-picker.styles';

const hours = range(1, 13).map(item => (item < 10 ? `0${item}` : String(item)));
const minutes = ['00', '30'];
const meridiems = ['AM', 'PM'];

export const TimePicker: FC<TimePickerProps> = props => {
  const { hour, meridiem, minute } = {
    ...TimePickerPresets,
    ...props,
  };
  const [innerHour, setInnerHour] = useState(hour);
  const [innerMinute, setInnerMinute] = useState(minute);
  const [innerMeridiem, setInnerMeridiem] = useState(meridiem);

  useEffect(() => {
    setInnerHour(hour);
  }, [hour]);

  useEffect(() => {
    setInnerMinute(minute);
  }, [minute]);

  useEffect(() => {
    setInnerMeridiem(meridiem);
  }, [meridiem]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange({
        formatted: `${innerHour}:${innerMinute} ${innerMeridiem}`,
        hour: innerHour,
        meridiem: innerMeridiem,
        minute: innerMinute,
      });
    }
  }, [innerHour, innerMinute, innerMeridiem]);

  return (
    <StyledTimeSelector>
      <SelectInput
        options={hours}
        placeholder=""
        value={innerHour}
        onChange={setInnerHour}
      />
      <SelectInput
        options={minutes}
        placeholder=""
        value={innerMinute}
        onChange={setInnerMinute}
      />
      <SelectInput
        options={meridiems}
        placeholder=""
        value={innerMeridiem}
        onChange={setInnerMeridiem}
      />
    </StyledTimeSelector>
  );
};

import {
  ColorPickerItem,
  productColorsMap,
  Typography,
  ProductColorCode,
  Icon,
  Conditional,
} from 'gantri-components';
import { ColorSwatchAndLabelProps } from './color-swatch-and-label.types';
import { StyledFlex } from './color-swatch-and-label.styles';

export const ColorSwatchAndLabel = (props: ColorSwatchAndLabelProps) => {
  const {
    active,
    colorCode,
    disabled,
    hideUnpaintedIcon,
    iconColor = 't2',
    labelColor = 't1',
    onSelect,
    size = '2.2rem',
    text,
    ...flexProps
  } = props;

  const productColorDetails = productColorsMap[colorCode as ProductColorCode];
  const productColorCode = productColorDetails?.code;
  const productColorName = productColorDetails?.shortColorName;

  const onColorClick =
    onSelect && !disabled
      ? async () => {
          await onSelect?.({
            code: productColorCode || 'unpainted',
            isActive: !active,
            name: productColorName || text || 'Unpainted',
          });
        }
      : undefined;

  return (
    <StyledFlex
      alignItems="center"
      disabled={disabled}
      gap=".5rem"
      justifySelf="start"
      width="auto"
      onClick={onColorClick}
      {...flexProps}
    >
      <Conditional
        condition={!!productColorDetails}
        Fallback={
          <>
            <Conditional condition={!hideUnpaintedIcon}>
              <Icon color={iconColor} name="work:no_entry" size="1.6rem" />
            </Conditional>
            <Typography color={labelColor} text={text || 'Unpainted'} />
          </>
        }
      >
        <ColorPickerItem
          active={active}
          color={{
            code: productColorCode,
            name: productColorName,
          }}
          size={size}
          onColorClick={onColorClick}
        />

        <Typography color={labelColor} text={text || productColorName} />
      </Conditional>
    </StyledFlex>
  );
};

import {
  useInvalidateFetchDesignCache,
  useInvalidateFetchDesignsCache,
  useUpdateDesign,
} from 'api/designs/routes';
import { DesignDetails } from 'api/designs/routes/fetch-design/fetch-design.types';
import { UpdateDesignModelArgs } from 'api/designs/routes/update-design/update-design.types';

export const useOnUpdateModel = (props: {
  design: DesignDetails;
  isApproved: boolean;
  isFinalSubmission?: boolean;
  onNavigateTo: () => void;
}) => {
  const { design, isApproved, isFinalSubmission, onNavigateTo } = props;

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();
  const { invalidateFetchDesignsCache } = useInvalidateFetchDesignsCache();

  const { isLoading, onUpdateDesign } = useUpdateDesign({
    onSuccess: async () => {
      onNavigateTo();
      await invalidateFetchDesignCache();
      await invalidateFetchDesignsCache();
    },
  });

  const onSubmit = async (values: Partial<UpdateDesignModelArgs> = {}) => {
    if (isApproved) {
      onNavigateTo();
    } else {
      await onUpdateDesign({
        assemblyFile: design?.model?.subSteps?.[1]?.assemblyFile,
        colors: design?.model?.subSteps?.[3]?.colors || [],
        id: design?.id,
        parts: design?.model?.subSteps?.[2]?.parts || [],
        step: 'Model',
        submit: isFinalSubmission,
        ...values,
      });
    }
  };

  return { onSubmit, processing: isLoading };
};

export interface FetchDesignsArgs {}

export interface FetchDesignsResponse {
  data: Design[];
  success: boolean;
}

export interface TimelineItem {
  action: string;
  createdAt: string;
  createdAtSec: number;
  description: string;
  email: string;
  firstName: string;
  lastName: string;
  status: string;
  userId: number;
}

export interface Design {
  action: {
    color: string;
    label: string;
    url: string;
  };
  category: string;
  completedAt: string;
  concept: {
    approvedAt: string;
    completedAt: string;
    refinementsNeededAt: null;
    /** Shown on the feedback page. */
    review: Record<
      `${number}`,
      {
        header: string;
        sections: {
          approved: boolean;
          description: string;
          messages: unknown[];
          title: string;
        }[];
      }
    >;
    status: DesignStatus;
    stepProgress: number;
    subSteps: {
      '1': {
        category: string;
      };
      '2': {
        subCategory: string;
      };
      '3': {
        size: {
          code: string;
          description: string;
          label: string;
          name: string;
        };
      };
      '4': {
        describe: string;
        inspiration: string;
        inspirationConnect: string;
        name: string;
        purpose: string;
        sketches: Record<
          'front' | 'model' | 'side' | 'top',
          {
            fileToUpload?: Record<never, never>;
            uploadedFile: FileDetails;
          }
        >;
        uniqueDesign: string;
      };
      '5': {
        interiorUrls: FileDetails[];
        rooms: string[];
        targetUser: string;
        userNeeds: string;
      };
      '6': {
        changeBulb: string;
        howDesignUsed: string;
        whenDesignUsed: string;
      };
    };
  };
  content: {
    completedAt: string;
    productFinalization: {
      approveDesignerProfile: {
        approved: boolean;
        completedAt: string;
      };
      completedAt: string;
      copy: {
        approved: boolean;
        completedAt: string;
      };
      packagingAssets: {
        approved: boolean;
        completedAt: string;
      };
      photos: {
        approved: boolean;
        completedAt: string;
      };
      technicalSpecs: {
        approved: boolean;
        completedAt: string;
      };
    };
    status: DesignStatus;
    stepProgress: number;
  };
  createdAt: string;
  createdAtSec: number;
  currentStep: string;
  designerName: string;
  engineering: {
    completedAt: string;
    finalization: {
      assemblyInstructions: boolean;
      completedAt: string;
      finishedProductsInAllColorways: boolean;
      finishedPrototypes: ({
        color: string;
        name: string;
      } & FileDetails)[];
      gCode: boolean;
      gatheringProductSpecs: boolean;
      stepProgress: number;
    };
    modelingOptimization: {
      assemblyAndMatingFeatures: boolean;
      completedAt: string;
      detailWork: boolean;
      functionalComponentAccommodation: boolean;
      gCodeSlicingSetting: boolean;
      lightTransmission: boolean;
      overhangMitigation: boolean;
      partitioningAndPrintOrientation: boolean;
      stepProgress: number;
      usability: boolean;
    };
    prototyping: {
      completedAt: string;
      images: ({
        name: string;
        nameOfPhoto: string;
      } & FileDetails)[];
    };
    qualityTesting: {
      assembly: boolean;
      completedAt: string;
      finishing: boolean;
      heat: boolean;
      interaction: boolean;
      light: boolean;
      shipping: boolean;
      stepProgress: number;
      strength: boolean;
    };
    status: DesignStatus;
    stepProgress: number;
  };
  failReason: string;
  failedAt: string;
  id: number;
  messages: [];
  model: {
    approvedAt: string;
    completedAt: string;
    refinementsNeededAt: null;
    /** Shown on the feedback page. */
    review: {
      '1': {
        header: string;
        sections: {
          approved: boolean;
          description: string;
          list?: string[];
          messages: unknown[];
          title: string;
        }[];
      };
    };
    status: DesignStatus;
    stepProgress: number;
    subSteps: {
      '1': {
        assemblyFile: FileDetails;
      };
      '2': {
        parts: ({
          material: string;
          name: string;
        } & FileDetails)[];
      };
      '3': {
        colors: {
          code: string;
          name: string;
          selected?: boolean;
        }[];
      };
    };
  };
  name: string;
  newStep: string;
  notificationLink: {
    color: string;
    label: string;
    url: string;
  };
  size: {
    code: string;
    description: string;
    label: string;
    name: string;
  };
  sketchUrls: null;
  sketches: Record<
    'front' | 'model' | 'side' | 'top',
    {
      fileToUpload?: Record<never, never>;
      uploadedFile: FileDetails;
    }
  >;
  status: DesignStatus;
  step: string;
  subCategory: string;
  timelines: TimelineItem[];
  updatedAt: string;
  updatedAtSec: number;
  userId: number;
}

export interface FileDetails {
  fileExtension: string;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  loading: boolean;
  percentage: number;
}

export type DesignStatus = (typeof designStatuses)[keyof typeof designStatuses];

export const designStatuses = {
  approved: 'Approved',
  completed: 'Completed',
  error: 'Error',
  failed: 'Failed',
  inProgress: 'In Progress',
  inprogress: 'In progress',
  returned: 'Returned',
  submitted: 'Submitted',
  waiting: 'Waiting',
} as const;

import { Fragment, Suspense } from 'react';
import '@firebase/storage';
import { GlobalStyle } from 'styles/base';
import { LoadingOverlay } from 'layout/loading-overlay';
import { ModalProvider as ModalProviderOld } from 'context/modal.context';
import { ModalProvider } from 'gantri-components';
import { BrowserRouter } from 'react-router-dom';
import { BaseLayout } from './layout';
import { AppProviders } from './app-providers';
import { AppRoutes } from './app-routes';
import './i18n';

const App = () => {
  return (
    <>
      <GlobalStyle />

      <Suspense fallback={<LoadingOverlay />}>
        <BrowserRouter>
          <AppProviders>
            <ModalProvider rootComponent={Fragment}>
              <ModalProviderOld>
                <BaseLayout>
                  <AppRoutes />
                </BaseLayout>
              </ModalProviderOld>
            </ModalProvider>
          </AppProviders>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;

import {
  ChangeEvent,
  FC,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useEnsuredForwardedRef } from 'react-use';
import { useMounted } from 'utils/hooks';
import { TextAreaProps } from './text-area.props';
import { TextAreaPresets } from './text-area.presets';
import {
  StyledBodyContainer,
  StyledInputContainer,
  StyledInputContent,
  StyledInputDescription,
  StyledPlaceholder,
  StyledTextArea,
} from './text-area.styles';
import { Typography } from '../typography';

const canvas = document.createElement('canvas');

function getTextWidthInPixels(text, font) {
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export const TextArea: FC<TextAreaProps> = forwardRef(
  (props: TextAreaProps, ref: any) => {
    const name = props.name || props.label || props.labelTx;
    const {
      autoFocus,
      description,
      descriptionColor,
      descriptionTx,
      descriptionVariant,
      disabled,
      error,
      errorTx,
      id,
      label,
      labelColor,
      labelTx,
      maxLength,
      onBlur: onInputBlur,
      onChange,
      onEnter,
      onFocus: onInputFocus,
      onTextChange,
      placeholder,
      placeholderColor,
      placeholderTx,
      readonly,
      selectOnFocus,
      value,
      ...others
    } = { ...TextAreaPresets, ...props, id: props.id, name };

    const textAreaRef: any = useEnsuredForwardedRef(ref);

    const [text, setText] = useState(value);
    const [focused, setFocused] = useState(false);

    const mounted = useMounted();

    useEffect(() => {
      if (mounted) {
        const font = window
          .getComputedStyle(textAreaRef.current, null)
          .getPropertyValue('font');

        const { width } = textAreaRef.current.getBoundingClientRect();
        const textBlocks = String(text).split(/\n/);
        let numberOfLines = 1;

        textBlocks.forEach(block => {
          const textWidth = getTextWidthInPixels(block || ' ', font);
          numberOfLines += Math.max(1, Math.ceil(textWidth / width));
        });

        textAreaRef.current.rows = numberOfLines;
      }
    }, [text, mounted]);

    const focus = useCallback(() => {
      textAreaRef.current.focus();
    }, []);

    const onFieldChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;

      setText(value);

      if (onTextChange) {
        onTextChange(value);
      }

      if (onChange) {
        onChange(event);
      }
    };

    const onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (onEnter && event.key === 'Enter') {
        onEnter(value.toString());
      }
    };

    const onFocus = event => {
      setFocused(true);

      if (selectOnFocus) {
        textAreaRef.current.select();
      }

      if (onInputFocus) {
        onInputFocus(event);
      }
    };

    const onBlur = event => {
      setFocused(false);

      if (onInputBlur) {
        onInputBlur(event);
      }
    };

    useEffect(() => {
      if (autoFocus) {
        focus();
      }
    }, [autoFocus]);

    useEffect(() => {
      setText(value);
    }, [value]);

    return (
      <StyledInputContainer>
        {(label || labelTx) && (
          <Typography
            color={props.touched && (error || errorTx) ? 'red' : labelColor}
            text={label}
            tx={labelTx}
            variant="label"
          />
        )}
        {(description || descriptionTx) && (
          <StyledInputDescription
            color={descriptionColor}
            text={description}
            tx={descriptionTx}
            variant={descriptionVariant}
          />
        )}
        <StyledBodyContainer>
          <StyledInputContent
            color={labelColor}
            focused={focused}
            invalid={!!(errorTx || error)}
          >
            <StyledTextArea
              ref={textAreaRef}
              disabled={disabled}
              id={id}
              invalid={!!(errorTx || error)}
              labelColor={labelColor}
              maxLength={maxLength}
              name={name}
              readOnly={readonly}
              value={text ?? ''}
              onBlur={onBlur}
              onChange={onFieldChange}
              onClick={event => event.preventDefault()}
              onFocus={onFocus}
              onKeyUp={onKeyUp}
              {...others}
            />

            {!text && (placeholder || placeholderTx) && (
              <StyledPlaceholder
                color={
                  props.touched && (error || errorTx) ? 'red' : placeholderColor
                }
                text={placeholder}
                tx={placeholderTx}
              />
            )}
          </StyledInputContent>
        </StyledBodyContainer>
      </StyledInputContainer>
    );
  },
);

import { forwardRef } from 'react';
import { FlexContainerProps } from './flex-container.props';
import { StyledFlexContainer } from './flex-container.styles';
import { FlexContainerPresets } from './flex-container.presets';

export const FlexContainer = forwardRef(
  (props: FlexContainerProps, ref: any) => {
    const elementProps = { ...FlexContainerPresets, ...props };
    return <StyledFlexContainer ref={ref} {...elementProps} />;
  },
);

import { environment } from 'config/environment';

export interface ApiConfig {
  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;

  /**
   * The URL of the api.
   */
  url: string;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  timeout: 60000,
  url: environment.API_URL,
};

import { designStatuses } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import {
  Flex,
  Conditional,
  Icon,
  Typography,
  Box,
  LinearProgress,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { EngineeringSectionHeaderPropsDef } from './engineering-section-header.types';

export const EngineeringSectionHeader = (
  props: EngineeringSectionHeaderPropsDef,
) => {
  const { date, progress, title } = props;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" gap="x" width="auto">
        <Conditional condition={progress === 100}>
          <Icon
            color="link"
            name="ui-control:check_mark_circle_filled_24"
            size="2.4rem"
          />
        </Conditional>
        <Typography text={title} variant="h5" />
      </Flex>

      <Conditional
        condition={!!date}
        Fallback={
          <Box width="16rem">
            <LinearProgress current={progress} showText total={100} />
          </Box>
        }
      >
        <Typography
          color="t2"
          text={`${designStatuses.completed} ${formatDate(date)}`}
        />
      </Conditional>
    </Flex>
  );
};

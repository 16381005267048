import axios from 'axios';
import { userApiUrl } from 'helpers/auth';
import { UpdateDesignArgs, UpdateDesignResponse } from './update-design.types';

export const addDesign = ({ id, ...body }: UpdateDesignArgs) => {
  return axios
    .put<UpdateDesignResponse>(`${userApiUrl}/designs/${id}/update`, body)
    .then(({ data }) => {
      return data;
    });
};

import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { legacyTheme } from 'styles/theme';

export const StyledTableContainer = styled.div`
  ${({ theme }) => {
    return css`
      display: block;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      border-left: 1px solid ${legacyTheme.colors.lightGrey};
      border-right: 1px solid ${legacyTheme.colors.lightGrey};

      ${media.lessThan('md')`
      max-width: calc(100vw - 2.4rem);
    `}
      table {
        border-spacing: 0;
        border-top: 1px solid ${legacyTheme.colors.lightGrey};
        border-bottom: 1px solid ${legacyTheme.colors.lightGrey};
        width: 100%;

        tr {
          :last-child {
            td {
              border-bottom: 0;
            }
          }
        }

        th {
          background-color: ${legacyTheme.colors.cream};
          text-align: left;
          font-weight: 400;
        }

        td {
          color: ${legacyTheme.colors.grey};
        }

        th,
        td {
          margin: 0;
          padding: ${legacyTheme.spacing.s1};
          border-bottom: 1px solid ${legacyTheme.colors.lightGrey};
          user-select: none;

          &.collapse {
            width: 0.0000000001%;
          }

          &:first-child {
            padding-left: ${legacyTheme.spacing.s2};

            ${media.lessThan('sm')`
            padding-left: ${legacyTheme.spacing.s1};
          `}
          }

          :last-child {
            border-right: 0;
            padding-right: ${legacyTheme.spacing.s2};

            ${media.lessThan('sm')`
            padding-right: ${legacyTheme.spacing.s1};
          `}
          }
        }

        tfoot {
          tr td {
            color: ${legacyTheme.colors.black};
            font-weight: 600;
            border-top: 1px solid ${legacyTheme.colors.lightGrey};
          }
        }
      }
    `;
  }}
`;

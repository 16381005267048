import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgArrowDown = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 17 21"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 21h15V3.188H0z" />
        <path
          d="M8.501 19.5L8.106.801M15.86 13.276l-7.329 6.66-7.331-6.66"
          stroke={color}
        />
      </g>
    </svg>
  );
});
export default SvgArrowDown;

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgVisa = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 26 18"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect
          fill="#F4F4F4"
          fillRule="nonzero"
          height={17}
          rx={2}
          stroke="#E4E5E6"
          width={25}
          x={0.5}
          y={0.5}
        />
        <path
          d="M17.46 6.126a3.977 3.977 0 00-1.426-.255c-1.573 0-2.681.822-2.69 2-.01.872.79 1.358 1.394 1.648.62.297.828.486.825.752-.004.406-.495.592-.952.592-.637 0-.976-.092-1.499-.318l-.205-.097-.223 1.357c.372.17 1.06.316 1.773.324 1.674 0 2.76-.813 2.772-2.071.006-.69-.418-1.215-1.336-1.647-.557-.281-.897-.468-.894-.752 0-.252.289-.522.912-.522.52-.008.897.11 1.191.233l.143.07.216-1.314M19.58 9.89c.132-.35.634-1.695.634-1.695-.01.016.13-.35.211-.578l.108.522.368 1.75H19.58zm1.963-3.908h-1.23c-.381 0-.666.108-.834.503l-2.364 5.556h1.672s.273-.747.335-.911l2.039.002c.047.213.193.909.193.909h1.477l-1.288-6.059zM8.76 5.98l-1.558 4.135-.166-.84c-.29-.969-1.194-2.018-2.205-2.543l1.425 5.302 1.684-.002 2.507-6.051H8.76"
          fill="#00579F"
        />
        <path
          d="M5.756 5.977H3.189l-.02.126c1.997.502 3.318 1.715 3.867 3.172l-.558-2.786c-.097-.384-.376-.499-.722-.512"
          fill="#F9A51A"
        />
        <path d="M11.09 5.976l-.994 6.063h1.59l.997-6.063z" fill="#00579F" />
      </g>
    </svg>
  );
});
export default SvgVisa;

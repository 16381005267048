import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { Typography } from 'core/typography';
import { media } from 'styles/media';
import { Icon } from 'core/icon/icon';
import { legacyTheme } from 'styles/theme';

export const StyledMenuContainer = styled.ul`
  display: flex;
  align-items: center;
  user-select: none;
  height: 100%;
`;

export const StyledMenuItem = styled.div`
  position: relative;
  height: 100%;
  display: grid;
`;

export const StyledMenuItemContainer = styled.div`
  padding: 0 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: max-content;
  position: relative;

  svg {
    margin-left: 0.8rem;
  }
`;

export const StyledMenuItemText = styled(Typography)`
  ${({ color, theme }) => {
    return css`
      &:hover {
        color: ${color === 'white'
          ? legacyTheme.colors.white
          : legacyTheme.colors.black};
      }
    `;
  }}
`;

export const StyledCartCount = styled(Typography)`
  margin-left: 3px;

  ${media.lessThan('sm')`
    margin-left: 1px
  `}
`;

const menuButton = css`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('sm')`
    width: 4rem;
    height: 4rem;
  `}
`;

export const StyledCartInfo = styled.div<{ active: boolean }>`
  ${({ active, theme }) => {
    return css`
      ${menuButton};
      border-radius: ${theme.borderRadius};
      transition: all ${theme.transitions.base};
      user-select: none;

      ${active &&
      css`
        background-color: ${legacyTheme.colors.primary};
      `};
    `;
  }}
`;

export const StyledSearchIcon = styled(Icon)`
  position: relative;
  top: 3px;
  cursor: pointer;
`;

export const StyledAccountChevron = styled(Icon)`
  margin-left: 5px;
  position: relative;
  top: 3px;
`;

export const StyledMenuIconContainer = styled.div`
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 48px;
  height: 48px;
  position: relative;
  display: none;

  ${media.lessThan('md')`
    display: flex;
  `}
`;

export const StyledMenuIconLine = styled.span<{
  active?: boolean;
}>`
  ${({ active, theme }) => {
    return css`
      display: block;
      width: 20px;
      left: 50%;
      height: 2px;
      background-color: ${legacyTheme.colors.grey};
      transform-origin: center;
      transition: transform ${theme.transitions.base};

      &:first-child {
        margin-bottom: 5px;
        transform: ${active ? 'rotate(45deg) translate(0px, -4px)' : 'unset'};
        transform-origin: left;
      }

      &:last-child {
        transform: ${active ? 'rotate(-45deg) translate(5px, -9px)' : 'unset'};
        transform-origin: right;
      }
    `;
  }}
`;

export const StyledMenuActiveIndicator = styled(animated.div)`
  height: 3px;
  position: absolute;
  bottom: -9px;
  left: 10px;
  right: 10px;
`;

export const StyledNewTag = styled(Typography)`
  position: relative;
  top: -4px;
  right: -4px;
`;

export const StyledDesignerLogoContainer = styled.div`
  display: grid;
  grid-template-columns: 4rem max-content;
  column-gap: 1rem;
`;

import {
  Conditional,
  FileUploader,
  Grid,
  HandleDeleteFile,
  HandleUploadsComplete,
  Stack,
  Typography,
} from 'gantri-components';
import { useFirebaseFileUploader } from 'utils/hooks/use-firebase-file-uploader';
import { FirebaseUploadedFileDetails } from 'utils/hooks/use-firebase-file-uploader/use-firebase-file-uploader.types';
import { useFormikContext } from 'formik';
import {
  ConceptSketchesUploaderPropsDef,
  SketchCode,
} from './concept-sketches-uploader.types';
import { ConceptStepOneFormDef } from '../../../../concept-step-one.types';

export const ConceptSketchesUploader = (
  props: ConceptSketchesUploaderPropsDef,
) => {
  const {
    concept,
    description,
    designId,
    expectedExtensions,
    isDisabled,
    maxFileSizeMB,
    sketchCode,
    view,
  } = props;

  const { setFieldValue, values } = useFormikContext<ConceptStepOneFormDef>();

  const getHandleUploadsComplete = (
    sketchCode: SketchCode,
  ): HandleUploadsComplete<FirebaseUploadedFileDetails> => {
    return async ([props]) => {
      const { fileExtension, fileName, fileSize, fileUrl } = props;

      await setFieldValue('sketches', {
        ...values.sketches,
        [sketchCode]: {
          fileToUpload: {},
          uploadedFile: {
            fileExtension,
            fileName,
            fileSize,
            fileUrl,
            loading: false,
            percentage: 100,
          },
        },
      });
    };
  };

  const getOnFileDelete = (sketchCode: SketchCode): HandleDeleteFile => {
    return async () => {
      await setFieldValue('sketches', {
        ...values.sketches,
        [sketchCode]: {
          fileToUpload: {},
          uploadedFile: {},
        },
      });
    };
  };

  const { fileName, fileUrl } =
    concept?.subSteps?.[4]?.sketches?.[sketchCode]?.uploadedFile || {};

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl,
    handleUploadsComplete: getHandleUploadsComplete(sketchCode),
    onFileDelete: getOnFileDelete(sketchCode),
    path: `designs/${designId}/sketches`,
  });

  return (
    <Stack gap=".5x" height="unset">
      <Grid
        alignItems="baseline"
        columns={description ? { lg: 'max-content 1fr', sm: 1 } : 1}
        gap={{ lg: 'x', sm: 'unset' }}
      >
        <Typography text={view} variant="h6" />
        <Conditional condition={!!description}>
          <Typography color="t2" text={description} variant="p3" />
        </Conditional>
      </Grid>
      <FileUploader
        {...fileUploaderProps}
        expectedExtensions={expectedExtensions}
        fileName={fileName}
        isDisabled={isDisabled}
        maxFileSizeMB={maxFileSizeMB}
        thumbnailSize="36rem"
        transformFileName={() => {
          return sketchCode;
        }}
        variant="thumbnail"
      />
    </Stack>
  );
};

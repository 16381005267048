import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';

export const StyledImageContainer = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledImage = styled(animated.img)<any>`
  ${({ fit, innerheight, innerwidth, position }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'height',
        resolutionAwareProp: innerheight ?? '100%',
      },
      {
        cssProperty: 'width',
        resolutionAwareProp: innerwidth ?? '100%',
      },
      {
        cssProperty: 'object-fit',
        resolutionAwareProp: fit,
      },
      {
        cssProperty: 'object-position',
        resolutionAwareProp: position,
      },
    ];

    return css`
      position: absolute;
      left: 0;
      top: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

import { memo } from 'react';
import { DropdownItemProps } from 'core/dropdown/dropdown-item.props';
import { StyledDropdownItem } from 'core/dropdown/dropdown.styles';

export const DropdownItem = memo((props: DropdownItemProps) => {
  const { children, onClick, to } = props;

  return to ? (
    <a href={to}>
      <StyledDropdownItem>{children}</StyledDropdownItem>
    </a>
  ) : (
    <StyledDropdownItem onClick={onClick}>{children}</StyledDropdownItem>
  );
});

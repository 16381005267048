import { Box, Grid, Stack, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { StyledImageWithBorder } from './calling-world-talents-section.styles';
import { sectionVerticalPadding } from '../../home.constants';
import { storefrontRoutePaths } from '../../../../storefront-routes';

export const CallingWorldTalentsSection = () => {
  const designersList = [
    {
      link: storefrontRoutePaths.designer.muka,
      location: 'Bilbao',
      name: 'Muka Design Lab',
      src: 'https://res.cloudinary.com/gantri/image/upload/c_pad,h_120,w_120,dpr_2/dynamic-assets/gantri/designers/12194/profile/logo_1679488730961',
    },
    {
      link: storefrontRoutePaths.designer.ammunition,
      location: 'San Francisco',
      name: 'Ammunition',
      src: 'https://res.cloudinary.com/gantri/image/upload/c_pad,h_120,w_120,dpr_2/dynamic-assets/gantri/designers/13145/profile/logo_1679489040284',
    },
    {
      link: storefrontRoutePaths.designer.chrisGranneberg,
      location: 'Los Angeles',
      name: 'Chris Granneberg',
      src: 'https://res.cloudinary.com/gantri/image/upload/c_pad,h_120,w_120,dpr_2/dynamic-assets/gantri/designers/11419/profile/logo_1679488639403',
    },
  ];

  return (
    <Stack
      alignContent="center"
      gap="5x"
      horizontalPadding="2x"
      justifySelf="center"
      maxWidth="78rem"
      verticalPadding={sectionVerticalPadding}
    >
      <Typography
        align="center"
        text="Calling the world's best design talents."
        variant="h2"
      />

      <Typography
        align="center"
        color="t2"
        text="Gantri is a revolutionary way to develop, manufacture and sell new modern design. We help you build your design brand and earn income by selling your products directly to consumers. We do not charge for our services. Instead, we share revenue with you when your products sell."
        variant="p1"
      />

      <Grid columns={{ lg: 3, sm: 1 }}>
        {designersList.map(({ link, location, name, src }) => {
          return (
            <Link key={link} to={link}>
              <Stack gap=".5x" justifyItems="center">
                <Box paddingBottom="x">
                  <StyledImageWithBorder
                    alt={name}
                    height={{ lg: '12rem', sm: '6rem' }}
                    source="absolute"
                    src={src}
                    width={{ lg: '12rem', sm: '6rem' }}
                  />
                </Box>
                <Typography align="center" text={name} textStyle="bold" />
                <Typography
                  align="center"
                  color="t2"
                  text={location}
                  variant="p3"
                />
              </Stack>
            </Link>
          );
        })}
      </Grid>
    </Stack>
  );
};

import { IconProps } from './icon.props';

export const IconPresets: Partial<IconProps> = {
  color: 'grey',

  cursor: 'unset',

  opacity: 1,

  size: 'unset',

  strokeWidth: '10',
};

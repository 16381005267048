import { FC } from 'react';
import { Dropdown } from 'core/dropdown';
import { Overlay } from 'core/filters-dropdown/components';
import { useToggle } from 'react-use';
import {
  StyledArrowIcon,
  StyledContent,
  StyledFiltersBackdrop,
  StyledValueContainer,
} from 'core/filters-dropdown/filters-dropdown.styles';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon';
import { FiltersDropdownPresets } from './filters-dropdown.presets';
import { FiltersDropdownProps } from './filters-dropdown.props';

export const FiltersDropdown: FC<FiltersDropdownProps> = props => {
  const { children, count, onApply, onReset } = {
    ...FiltersDropdownPresets,
    ...props,
  };

  const [overlayVisible, setOverlayVisible] = useToggle(false);

  return (
    <>
      <Dropdown
        closeBehavior="manual"
        opened={overlayVisible}
        overlay={
          <Overlay
            children={children}
            onApply={onApply}
            onClose={() => setOverlayVisible(false)}
            onReset={onReset}
          />
        }
        style={{ left: 0, right: 'unset', top: '3.5rem' }}
        onClose={() => setOverlayVisible(false)}
        onOpen={() => setOverlayVisible(true)}
      >
        <StyledContent>
          <StyledValueContainer>
            <Typography text="Filters" />
            <Typography color="grey" text={count || 0} />
          </StyledValueContainer>
          <StyledArrowIcon rotated={overlayVisible}>
            <Icon name="menu-down" width="1rem" />
          </StyledArrowIcon>
        </StyledContent>
      </Dropdown>

      {overlayVisible && (
        <StyledFiltersBackdrop onClick={() => setOverlayVisible(false)} />
      )}
    </>
  );
};

import { FC } from 'react';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { formatDate } from 'utils/date';
import { Thumbnail } from 'core/thumbnail';
import { formatCurrency } from 'utils/formaters';
import { StyledGiftCard, StyledGiftCardContent } from './gift-card.styles';
import { GiftCardProps } from './gift-card.types';

export const GiftCard: FC<GiftCardProps> = ({ card }) => (
  <StyledGiftCard>
    <TypographyWithIcon
      color="primary"
      gap=".5rem"
      icon={<Icon color="primary" name="gift-card" size="2rem" />}
      text="Digital Gift Card"
      textStyle="bold"
    />

    <Typography
      text={`Send on ${formatDate(card.sendDateStr, 'MMM DD')}`}
      variant="h4"
    />

    <Typography
      color={card.status === 'Redeemed' ? 'black' : 'grey'}
      marginBottom={{ lg: 's3', md: 's2' }}
      text={card.status}
    />

    <StyledGiftCardContent>
      <Thumbnail height="5.5rem" src={card.design.photo} width="8rem" />
      <Typography text={card.design.title} />
      <Typography color="grey" text={formatCurrency(card.amount)} />
    </StyledGiftCardContent>
  </StyledGiftCard>
);

import {
  Stack,
  Typography,
  Grid,
  Cell,
  Line,
  Flex,
  Conditional,
  Icon,
} from 'gantri-components';
import { ChecklistReviewListPropsDef } from './checklist-review-list.types';

export const ChecklistReviewList = (props: ChecklistReviewListPropsDef) => {
  const {
    descriptionAlignment,
    isApproved,
    isPending,
    list = [],
    showApprovedStatus,
    title,
  } = props;

  return (
    <Stack>
      <Conditional condition={!!title}>
        <Typography text={title} variant="h5" />
      </Conditional>

      <Grid columns={{ lg: '2fr 3fr', sm: '1fr' }} rowGap="unset">
        <Cell verticalPadding="x" width={{ lg: 2, sm: 1 }}>
          <Line />
        </Cell>

        {list.map(({ approved, description, message, title }) => {
          return (
            <>
              <Flex alignItems="center" gap="x">
                <Conditional condition={!isPending}>
                  <Conditional condition={showApprovedStatus}>
                    <Conditional condition={isApproved || approved === true}>
                      <Icon
                        color="link"
                        name="ui-control:check_mark_circle_filled_24"
                        size="2.4rem"
                      />
                    </Conditional>
                    <Conditional condition={!isApproved && approved === false}>
                      <Icon
                        color="alert"
                        name="alert:i_circle_24"
                        size="2.4rem"
                      />
                    </Conditional>
                  </Conditional>
                </Conditional>
                <Typography text={title} />
              </Flex>

              <Cell>
                <Conditional
                  condition={isPending}
                  Fallback={
                    <>
                      {(showApprovedStatus && !approved && !isApproved
                        ? message || description
                        : description
                      )
                        ?.split('\n')
                        ?.map((text) => {
                          return (
                            <Typography
                              key={text}
                              align={descriptionAlignment}
                              color={
                                showApprovedStatus && !approved && !isApproved
                                  ? 'alert'
                                  : 't2'
                              }
                              text={text}
                            />
                          );
                        })}
                    </>
                  }
                >
                  {description?.split('\n')?.map((text) => {
                    return (
                      <Typography
                        key={text}
                        align={descriptionAlignment}
                        color="t2"
                        text={text}
                      />
                    );
                  })}
                </Conditional>
              </Cell>
              <Cell verticalPadding="x" width={{ lg: 2, sm: 1 }}>
                <Line />
              </Cell>
            </>
          );
        })}
      </Grid>
    </Stack>
  );
};

import * as yup from 'yup';
import { FileDetails } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { ConceptStepOneFormDef } from './concept-step-one.types';

const requiredText = 'Required';

export const fileDetailsFormSchema: yup.SchemaOf<FileDetails> = yup
  .object()
  .shape({
    fileExtension: yup.string(),
    fileName: yup.string(),
    fileSize: yup.number(),
    fileUrl: yup.string(),
    loading: yup.boolean(),
    percentage: yup.number(),
  });

export const conceptStepOneFormSchema: yup.SchemaOf<ConceptStepOneFormDef> = yup
  .object()
  .shape({
    describe: yup.string().nullable().required(requiredText),
    inspiration: yup.string().nullable().required(requiredText),
    inspirationConnect: yup.string().nullable().required(requiredText),
    name: yup.string().nullable().required(requiredText),
    purpose: yup.string().nullable().required(requiredText),
    sketches: yup.object().shape({
      front: yup.object().shape({
        fileToUpload: yup.object().shape({}),
        uploadedFile: fileDetailsFormSchema.required(requiredText),
      }),
      model: yup.object().shape({
        fileToUpload: yup.object().shape({}),
        uploadedFile: fileDetailsFormSchema.required(requiredText),
      }),
      side: yup.object().shape({
        fileToUpload: yup.object().shape({}),
        uploadedFile: fileDetailsFormSchema.required(requiredText),
      }),
      top: yup.object().shape({
        fileToUpload: yup.object().shape({}),
        uploadedFile: fileDetailsFormSchema.required(requiredText),
      }),
    }),
    uniqueDesign: yup.string().nullable().required(requiredText),
  });

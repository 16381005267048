import { Form, Formik } from 'formik';
import { FC } from 'react';
import { Grid } from 'layout/grid';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { BASE_MODAL_PADDING } from 'constants/common';
import { StackedContainer } from 'layout/stacked-container';
import { TextInput } from 'core/text-input';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { DesignerInfo } from 'types/user.type';
import { useModalClose } from 'context/modal.context';
import { useNotification } from 'context/notification.context';
import { UserResponse } from 'services/api/user/user-api.types';
import { PaypalInfoSchema } from './paypal-info.schema';

export const PaypalInfoModal: FC = () => {
  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });
  const queryClient = useQueryClient();
  const { notify } = useNotification();
  const { closeLastOpened } = useModalClose();

  const updateDesignerMutation = useMutation(
    async (data: DesignerInfo) => api.designer.update(data),
    {
      onSuccess: async (response: UserResponse) => {
        closeLastOpened();
        notify(response.notice);
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const handleSubmit = async values => {
    await updateDesignerMutation.mutateAsync({
      ...userQuery.data.data.designerInfo,
      paypalInfo: values,
      userId: userQuery.data.data.id,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: userQuery.data.data.designerInfo.paypalInfo?.email || '',
        name: userQuery.data.data.designerInfo.paypalInfo?.name || '',
      }}
      validateOnMount
      validationSchema={PaypalInfoSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form autoComplete="off">
          <Grid gap="3rem" {...BASE_MODAL_PADDING}>
            <Typography
              align="center"
              marginBottom={{ lg: 's3', md: 's1' }}
              text="Paypal Info"
              variant="h3"
            />

            <TextInput
              error={props.errors.name as string}
              label="PayPal name"
              name="name"
              touched={props.touched.name as boolean}
              value={props.values.name}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
            />

            <TextInput
              error={props.errors.email as string}
              label="PayPal email"
              name="email"
              touched={props.touched.email as boolean}
              type="email"
              value={props.values.email}
              onBlur={props.handleBlur}
              onChange={props.handleChange}
            />

            <StackedContainer
              flow="column"
              marginTop={{ lg: 's3', md: 's1' }}
              padding="unset"
            >
              <Button
                text="Cancel"
                variant="secondary"
                onClick={closeLastOpened}
              />

              <Button
                disabled={!props.dirty || !props.isValid}
                loading={updateDesignerMutation.isLoading}
                text="Save"
                type="submit"
                variant="primary"
              />
            </StackedContainer>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

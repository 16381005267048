import { addToDate } from 'utils/date';

export const FIRST_TABLE_OPTIONS = [
  {
    text: 'Visitors',
    value: 'visitor',
  },
  {
    text: 'Views',
    value: 'view',
  },
];

export const SECOND_TABLE_OPTIONS = [
  {
    text: 'Add to cart',
    value: 'cart',
  },
  {
    text: 'Wishlist',
    value: 'wishlist',
  },
];

export const THIRD_TABLE_OPTIONS = [
  {
    text: 'Orders',
    value: 'orders',
  },
  {
    text: 'Units',
    value: 'units',
  },
  {
    text: 'Revenue',
    totalProperty: 'totalRevenue',
    type: 'currency',
    value: 'units',
    valueProperty: 'revenue',
  },
  {
    text: 'Earnings',
    totalProperty: 'totalEarning',
    type: 'currency',
    value: 'units',
    valueProperty: 'earning',
  },
];

export const FOURTH_TABLE_OPTIONS = [
  {
    text: 'Refunds',
    value: 'refunds',
  },
];

export const MAXIMUM_DATES_IN_RANGE_BY_GROUP = {
  daily: 14,
  monthly: 365,
  weekly: 84,
};

export const TYPE_OPTIONS = ['product', 'category', 'SKU'].map(
  (option: string) => ({
    id: option.toLowerCase(),
    name: `By ${option}`,
  }),
);

export const INITIAL_DATE_RANGE = {
  from: addToDate(new Date(), -2, 'years'),
  to: new Date(),
};

export const GROUP_BY_PERIOD = {
  month: 'monthly',
  week: 'weekly',
  year: 'yearly',
};

export const CATEGORIES_OPTIONS = [
  'Table Light',
  'Wall Light',
  'Floor Light',
].map((option: string) => ({
  chartDisplayName: option,
  id: option,
  name: `${option}s`,
}));

export const DATE_FORMAT_BY_GROUP = {
  month: 'MMM YY',
  week: 'MMM DD',
  year: 'YYYY',
};

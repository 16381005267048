import { ClickAwareContainerProps } from 'layout/click-aware-container/click-aware-container.props';
import {
  StyledClickAwareContainer,
  StyledClickAwareContainerBackdrop,
} from 'layout/click-aware-container/click-aware-container.styles';
import { ClickAwareContainerPresets } from 'layout/click-aware-container/click-aware-container.presets';

export const ClickAwareContainer = (props: ClickAwareContainerProps) => {
  const { children, onClickOutside, visible } = {
    ...ClickAwareContainerPresets,
    ...props,
  };

  return (
    <div>
      <StyledClickAwareContainer>{children}</StyledClickAwareContainer>
      {visible && (
        <StyledClickAwareContainerBackdrop onClick={onClickOutside} />
      )}
    </div>
  );
};

import { FC, useMemo } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { api, GET_PAYOUT, PayoutItem } from 'services/api';
import { Typography } from 'core/typography';
import { Table } from 'core/table';
import { TableColumn } from 'core/table/table.props';
import { Grid } from 'layout/grid';
import { formatCurrency } from 'utils/formaters';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { Cell } from 'layout/cell';
import { Thumbnail } from 'core/thumbnail';
import { LoadingOverlay } from 'layout/loading-overlay';
import { getFileUrl } from 'gantri-components';
import { placeholderImageSrc } from 'constants/common';

export const PayoutPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const query = useQuery([GET_PAYOUT, id], () => api.designer.getPayout(+id));

  const totals = useMemo(() => {
    if (query.data?.payout.items?.length) {
      return query.data.payout.items.reduce(
        (acc: Partial<PayoutItem>, current: PayoutItem) => ({
          amount: acc.amount + current.amount,
          quantity: acc.quantity + current.quantity,
        }),
        {
          amount: 0,
          quantity: 0,
        },
      );
    }

    return {};
  }, [query.data?.payout?.items]);

  const columns = [
    {
      Cell: ({ value }) => <Typography color="grey" text={formatDate(value)} />,
      Footer: 'TOTAL',
      Header: 'Date',
      accessor: 'date',
    },
    {
      Cell: ({ row, value }) => (
        <TypographyWithIcon
          color="grey"
          icon={
            <Typography
              color="primary"
              href={`/orders/${row.original.orderId}`}
              text={`#${row.original.orderId}`}
              variant="link"
            />
          }
          iconPosition="right"
          text={value}
        />
      ),
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      minWidth: '17rem',
      style: { textTransform: 'uppercase' },
    },
    {
      Footer: totals.quantity,
      Header: 'Quantity',
      accessor: 'quantity',
      footerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
    },
    {
      Cell: ({ value }) => (
        <Typography align="right" color="grey" text={formatCurrency(value)} />
      ),
      Footer: formatCurrency(totals.amount),
      Header: 'Amount',
      accessor: 'amount',
      footerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
    },
    {
      Cell: ({ row }) => (
        <Typography
          align="right"
          color="grey"
          text={formatCurrency(row.original.amount)}
        />
      ),
      Footer: formatCurrency(totals.amount),
      Header: 'Total',
      accessor: 'total',
      footerStyle: { textAlign: 'right' },
      style: { textAlign: 'right' },
    },
  ] as TableColumn<PayoutItem>[];

  if (!query.data?.payout) {
    return <LoadingOverlay />;
  }

  return (
    <Page>
      <Grid alignContent="start" gap={{ lg: 's5', md: 's4', sm: 's3' }}>
        <Typography text={`Payout #${id}`} variant="h2" />

        <Grid
          columnGap="unset"
          columns={{ lg: 'minmax(60rem, 7fr) 2fr 3fr', md: '1fr' }}
        >
          <Cell>
            <Typography
              marginBottom={{ lg: 's3', md: 's2' }}
              text="Items"
              variant="h3"
            />

            <Table
              columns={columns}
              data={query.data.payout.items || []}
              footerVisible
            />

            <Typography
              marginBottom={{ lg: 's3', md: 's2' }}
              marginTop={{ lg: 's5', md: 's4' }}
              text="Notes"
              variant="h3"
            />
            <Typography color="grey" text={query.data.payout.notes} />
          </Cell>

          <Cell column={{ lg: 3, md: 1 }} row={1}>
            <Grid
              alignItems={{ lg: 'unset', md: 'center' }}
              columns={{ lg: '1fr', md: 'repeat(2, max-content)' }}
              gap="s2"
            >
              <Thumbnail
                objectFit="cover"
                size="8rem"
                src={
                  query.data.payout.logoUrl
                    ? getFileUrl<'designers'>({
                        directory: 'designers',
                        fileName: query.data.payout.logoUrl,
                        fileType: 'logo',
                        identifiers: {
                          userId: query.data.payout.userId,
                        },
                      })
                    : placeholderImageSrc
                }
              />

              <Cell>
                <Typography text={query.data.payout.designer} variant="h3" />
                <Typography
                  color="grey"
                  text={`${query.data.payout?.location?.city}, ${query.data.payout?.location?.state}`}
                />
              </Cell>
            </Grid>

            <div>
              <Typography
                marginTop="s3"
                text={formatCurrency(query.data.payout.amount.royalty)}
                textStyle="regular"
                variant="h2"
              />

              <Grid columns="max-content 1fr" marginTop="s3" rowGap="unset">
                <Cell>
                  <Typography text="Period" />
                </Cell>
                <Cell>
                  <Typography
                    color="grey"
                    text={`${formatDate(
                      query.data.payout.startDate,
                      'MM/DD',
                    )} - ${query.data.payout.endDate}`}
                  />
                </Cell>
                <Cell>
                  <Typography text="Status" />
                </Cell>
                <Cell>
                  <Typography color="grey" text={query.data.payout.status} />
                </Cell>
              </Grid>

              {query.data.payout.status === 'Paid' && (
                <>
                  <Typography
                    marginBottom={{ lg: 's3', md: 's2' }}
                    marginTop={{ lg: 's5', md: 's4' }}
                    text="Payment"
                    variant="h3"
                  />

                  <Grid columns="max-content 1fr" marginTop="s3" rowGap="unset">
                    <Cell>
                      <Typography text="Method" />
                    </Cell>
                    <Cell>
                      <Typography
                        color="grey"
                        text={query.data.payout.method}
                      />
                    </Cell>
                    <Cell>
                      <Typography text="Name" />
                    </Cell>
                    <Cell>
                      <Typography
                        color="grey"
                        text={query.data.payout.designer}
                      />
                    </Cell>
                    <Cell>
                      <Typography text="Email" />
                    </Cell>
                    <Cell>
                      <Typography color="grey" text={query.data.payout.email} />
                    </Cell>
                    <Cell>
                      <Typography text="Date" />
                    </Cell>
                    <Cell>
                      <Typography
                        color="grey"
                        text={formatDate(query.data.payout.paidDate)}
                      />
                    </Cell>
                  </Grid>
                </>
              )}
            </div>
          </Cell>
        </Grid>
      </Grid>
    </Page>
  );
};

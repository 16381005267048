import { FC, useEffect, useMemo, useState } from 'react';
import { Page } from 'core/page';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { StackedContainer } from 'layout/stacked-container';
import { useTheme } from 'styled-components';
import { ImageContainer } from 'layout/image-container';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Checkbox } from 'core/checkbox';
import { FlexContainer } from 'layout/flex-container';
import { Icon } from 'core/icon';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  api,
  GET_DASHBOARD,
  GET_DASHBOARD_STATS,
  GET_USER,
  GetDashboardStatsRequest,
  UpdateDashboardRequest,
  UserData,
} from 'services/api';
import { Link } from 'react-router-dom';
import { firstDateOfMonth, formatDate } from 'utils/date';
import { useModal } from 'context/modal.context';
import { routePaths } from 'src/app-routes';
import { legacyTheme } from 'styles/theme';
import { DashboardStats, ProductsList } from './components';
import { StyledDashboardListItem } from './dashboard.styles';
import { IntroModal } from './modals';

export const DashboardPage: FC = () => {
  const [scheduleAnIntroChat, setScheduleAnIntroChat] = useState(false);
  const [readOurDocumentations, setReadOurDocumentations] = useState(false);
  const [downloadOurReferenceDesigns, setDownloadOurReferenceDesigns] =
    useState(false);

  const queryClient = useQueryClient();
  const theme = useTheme();

  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });

  const [dateRange, setDateRange] = useState<GetDashboardStatsRequest>({
    endDate: formatDate(new Date()),
    startDate: formatDate(firstDateOfMonth()),
  });

  const dashboardQuery = useQuery(
    GET_DASHBOARD,
    () => {
      return api.dashboard.getData();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const statsQuery = useQuery(
    [GET_DASHBOARD_STATS, dateRange],
    () => {
      return api.dashboard.getStats(dateRange);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { showModal } = useModal({
    template: <IntroModal />,
    variant: { lg: 'standard', md: 'fullscreen' },
    width: { lg: '75rem', md: 'unset' },
  });

  useEffect(() => {
    if (
      userQuery?.data?.data?.designerInfo &&
      !userQuery?.data?.data?.designerInfo?.profileComplete
    ) {
      showModal();
    }
  }, [userQuery?.data?.data?.designerInfo?.profileComplete]);

  useEffect(() => {
    if (userQuery.data?.data) {
      const checklist = userQuery.data.data.designerInfo.dashboardChecklist;

      setScheduleAnIntroChat(checklist.scheduleAnIntroChat);
      setReadOurDocumentations(checklist.readOurDocumentations);
      setDownloadOurReferenceDesigns(checklist.downloadOurReferenceDesigns);
    }
  }, [userQuery.data?.data?.designerInfo]);

  const checklistVisible = useMemo(() => {
    if (userQuery.data?.data) {
      const checklist = userQuery.data.data.designerInfo.dashboardChecklist;

      return (
        !checklist.downloadOurReferenceDesigns ||
        !checklist.readOurDocumentations ||
        !checklist.scheduleAnIntroChat
      );
    }

    return false;
  }, [userQuery.data?.data?.designerInfo]);

  const updateDashboardMutation = useMutation(
    async (data: UpdateDashboardRequest) => {
      return api.dashboard.updateDashboard(data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const onScheduleAnIntroChatChange = (value: boolean) => {
    updateDashboardMutation.mutate({ label: 'scheduleAnIntroChat', value });
    setScheduleAnIntroChat(value);
  };

  const onReadOurDocumentationsChange = (value: boolean) => {
    updateDashboardMutation.mutate({ label: 'readOurDocumentations', value });
    setReadOurDocumentations(value);
  };

  const onDownloadOurReferenceDesignsChange = (value: boolean) => {
    updateDashboardMutation.mutate({
      label: 'downloadOurReferenceDesigns',
      value,
    });
    setDownloadOurReferenceDesigns(value);
  };

  return (
    <Page>
      <Grid gap={{ lg: 's5', md: 's4' }}>
        <Typography text="Dashboard" variant="h2" />

        <Grid columns={{ lg: '7fr 2fr 3fr', md: '1fr' }} rowGap="s4">
          <Cell>
            <StackedContainer
              border={`1px solid ${legacyTheme.colors.lightGrey}`}
              padding={{ lg: 's3', md: 's2', sm: 's1' }}
            >
              <Typography text="Next up" variant="h3" />

              {!dashboardQuery.data?.nextUps?.length && (
                <Grid
                  alignContent="center"
                  alignItems="center"
                  columns="max-content 1fr"
                >
                  <Cell>
                    <ImageContainer
                      height="8rem"
                      src="common/default-design.png"
                      width="8rem"
                    />
                  </Cell>
                  <Cell>
                    <Typography text="Create a new design" />
                    <TypographyWithIcon
                      color="grey"
                      gap="0.5rem"
                      icon={
                        <Typography
                          color="primary"
                          href={routePaths.designs}
                          text="Click here"
                          variant="link"
                        />
                      }
                      iconPosition="right"
                      text="Ready to get started?"
                    />
                  </Cell>
                </Grid>
              )}

              {!!dashboardQuery.data?.nextUps?.length && (
                <ProductsList products={dashboardQuery.data?.nextUps} />
              )}

              {checklistVisible && (
                <Cell>
                  <Grid
                    columnGap="1rem"
                    columns="max-content 1fr"
                    marginTop={{ lg: 's5', md: 's4', sm: 's3' }}
                    rowGap="s3"
                  >
                    <Cell>
                      <Checkbox
                        checked={scheduleAnIntroChat}
                        onChange={onScheduleAnIntroChatChange}
                      />
                    </Cell>
                    <Cell>
                      <Typography text="Schedule an intro chat" />
                      <Typography
                        color="grey"
                        text="Talk to a Gantri design engineer to understand how our process works and to ask any questions you might have."
                      />
                      <a href="mailto:id@gantri.com">
                        <Typography
                          color="primary"
                          text="Schedule"
                          textStyle="bold"
                        />
                      </a>
                    </Cell>

                    <Cell>
                      <Checkbox
                        checked={readOurDocumentations}
                        onChange={onReadOurDocumentationsChange}
                      />
                    </Cell>
                    <Cell>
                      <Typography text="Read our documentations" />
                      <Typography
                        color="grey"
                        text="Learn about what we're looking for in our design submissions so you can be prepared when you start."
                      />
                      <a
                        href="https://docs.gantri.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Typography
                          color="primary"
                          text="Learn"
                          textStyle="bold"
                        />
                      </a>
                    </Cell>

                    <Cell>
                      <Checkbox
                        checked={downloadOurReferenceDesigns}
                        onChange={onDownloadOurReferenceDesignsChange}
                      />
                    </Cell>
                    <Cell>
                      <Typography text="Download our Reference products" />
                      <Typography
                        color="grey"
                        text="Get your hands on our Reference products by downloading their 3D models."
                      />
                      <Typography
                        href="/downloads"
                        text="Download"
                        variant="link"
                      />
                    </Cell>
                  </Grid>
                </Cell>
              )}
            </StackedContainer>

            <DashboardStats
              data={statsQuery.data?.totalStatsData || []}
              onDateChange={setDateRange}
            />
          </Cell>

          <Cell column={{ lg: 3, md: 1 }}>
            <StackedContainer
              marginTop={{ lg: 'unset', md: 's5' }}
              padding="unset"
              rowGap="s5"
            >
              <StackedContainer padding="unset">
                <FlexContainer justifyContent="space-between" marginBottom="s2">
                  <Typography text="Designs" variant="h4" />
                  <a href={routePaths.designs}>
                    <Icon color="primary" height="2rem" name="arrow-right" />
                  </a>
                </FlexContainer>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Concept" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.designs.concept || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Model" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.designs.model || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Engineering" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.designs.engineering || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Content" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.designs.content || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>
              </StackedContainer>

              <StackedContainer padding="unset">
                <FlexContainer justifyContent="space-between" marginBottom="s2">
                  <Typography text="Products" variant="h4" />
                  <Link to={routePaths.products}>
                    <Icon color="primary" height="2rem" name="arrow-right" />
                  </Link>
                </FlexContainer>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Preparation" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.products.preparation || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Active" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.products.active || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>

                <StyledDashboardListItem>
                  <Typography color="grey" text="Off market" variant="p1" />
                  <Typography
                    color="grey"
                    text={dashboardQuery.data?.counts.products.offMarket || 0}
                    variant="p1"
                  />
                </StyledDashboardListItem>
              </StackedContainer>

              <Grid
                columnGap="s1"
                columns="1fr max-content"
                paddingTop="s3"
                style={{
                  borderTop: `1px solid ${legacyTheme.colors.lightGrey}`,
                }}
              >
                <Cell>
                  <Typography text="We're here to help" variant="h4" />
                  <Typography
                    color="grey"
                    maxWidth="54rem"
                    text="Any questions, reach out to the Gantri ID team and one of our engineers will come to your rescue."
                    verticalMargin="s1"
                  />
                  <a href="mailto:id@gantri.com">
                    <TypographyWithIcon
                      color="primary"
                      icon={<Icon height="2.4rem" name="email" />}
                      text="Contact ID team"
                      textStyle="bold"
                    />
                  </a>
                </Cell>
                <Cell>
                  <ImageContainer
                    height="4.8rem"
                    src="common/icon-big-info.png"
                    width="4.8rem"
                  />
                </Cell>
              </Grid>
            </StackedContainer>
          </Cell>
        </Grid>
      </Grid>
    </Page>
  );
};

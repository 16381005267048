import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SideMenuProps } from 'layout/side-menu/side-menu.props';
import {
  StyledNewTag,
  StyledSideMenu,
  StyledSideMenuBackdrop,
  StyledSideMenuContainer,
  StyledSideMenuLink,
} from 'layout/side-menu/side-menu.styles';
import { useSpring } from 'react-spring';
import { MenuItemProps } from 'layout/menu/menu.props';
import { SideMenuItem } from 'layout/side-menu/side-menu-item';
import { NavLink } from 'react-router-dom';
import { useMediaLarge, useMounted } from 'utils/hooks';
import { useLayout } from 'context/layout.context';
import { Icon } from 'core/icon';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { environment } from 'config/environment';
import { routePaths } from 'src/app-routes';
import { storefrontRoutePaths } from '../../storefront-routes';

const siteItems: MenuItemProps[] = [
  {
    icon: <Icon color="black" name="dashboard" width="1.6rem" />,
    to: routePaths.dashboard,
    tx: 'menu.dashboard',
  },
  {
    icon: <Icon color="black" height="2rem" name="pencil" width="1.6rem" />,
    to: routePaths.designs,
    tx: 'menu.designs',
  },
  {
    icon: (
      <Icon
        color="black"
        height="2rem"
        name="dollar"
        top="1px"
        width="1.6rem"
      />
    ),
    to: routePaths.payouts,
    tx: 'menu.payouts',
  },
  {
    icon: (
      <Icon color="black" height="2rem" name="lamp" top="1px" width="1.6rem" />
    ),
    to: routePaths.products,
    tx: 'menu.products',
  },
  {
    icon: (
      <Icon color="black" height="2rem" name="box" top="1px" width="1.6rem" />
    ),
    to: routePaths.orders,
    tx: 'menu.orders',
  },
  {
    icon: <Icon color="black" height="2rem" name="stats" width="1.6rem" />,
    to: routePaths.stats,
    tx: 'menu.stats',
  },
  {
    external: true,
    icon: <Icon color="black" name="docs" width="1.6rem" />,
    to: 'https://gantri.notion.site/Creator-Documents-1f7f31f6049f4a368aa9919ff7db130c/',
    tx: 'menu.docs',
  },
  {
    icon: <Icon color="black" name="download" width="1.6rem" />,
    to: routePaths.downloads,
    tx: 'menu.downloads',
  },
];

const accountItems: MenuItemProps[] = [
  {
    submenu: [
      { to: storefrontRoutePaths.about, tx: 'shopMenu.ourStory' },
      { to: storefrontRoutePaths.materials, tx: 'shopMenu.materials' },
      { to: storefrontRoutePaths.process, tx: 'shopMenu.factory' },
      { to: storefrontRoutePaths.press, tx: 'shopMenu.press' },
    ],
    tx: 'shopMenu.about',
  },
  { to: storefrontRoutePaths.refer, tx: 'shopMenu.earn20' },
  {
    external: true,
    textVariant: 'p2',
    to: 'https://support.gantri.com/',
    tx: 'shopMenu.help',
  },
];

export const SideMenu = (props: SideMenuProps) => {
  const { hasPayouts, onLogout, onMenuClose, user, visible } = props;
  const [subMenuItems, setSubMenuItems] =
    useState<MenuItemProps[]>(accountItems);
  const menuClose = useCallback(onMenuClose, [onMenuClose]);
  const { hideSideMenu } = useLayout();
  const mounted = useMounted();
  const isLarge = useMediaLarge();

  const spring = useSpring({
    config: { duration: 200 },
    opacity: visible ? 1 : 0,
    width: visible ? 260 : 0,
  });

  useEffect(() => {
    if (user) {
      const temporal = [
        ...accountItems,
        {
          submenu: [
            {
              to: storefrontRoutePaths.account.settings,
              tx: 'shopMenu.settings',
            },
            {
              to: storefrontRoutePaths.account.addresses,
              tx: 'shopMenu.addresses',
            },
            {
              to: storefrontRoutePaths.account.payments,
              tx: 'shopMenu.payments',
            },
            {
              to: storefrontRoutePaths.account.wishlist,
              tx: 'shopMenu.wishlist',
            },
            { to: storefrontRoutePaths.account.orders, tx: 'shopMenu.orders' },
          ],
          text: user.firstName,
          textVariant: 'p2',
        },
        { external: true, to: environment.SHOP_URL, tx: 'shopMenu.shop' },
        {
          onClick: onLogout,
          textVariant: 'p2',
          tx: 'shopMenu.logout',
        },
      ];

      setSubMenuItems(temporal);
    }
  }, [user, onLogout]);

  const renderMenuItem = (item: MenuItemProps): ReactNode => {
    return item.external ? (
      <a key={item.tx ?? item.text} href={item.to ?? '/'}>
        <StyledSideMenuLink
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            hideSideMenu();
          }}
        >
          <TypographyWithIcon
            color={item.color ?? 'grey'}
            icon={item.icon}
            text={item.text}
            tx={item.tx}
          />

          {item.isNew && (
            <StyledNewTag
              color="primary"
              textStyle="bold"
              tx="shopMenu.new"
              variant="p3"
            />
          )}
        </StyledSideMenuLink>
      </a>
    ) : (
      <NavLink
        key={item.tx ?? item.text}
        className={({ isActive }) => {
          return isActive ? 'active-link' : undefined;
        }}
        to={item.to ?? '/'}
      >
        <StyledSideMenuLink
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            hideSideMenu();
          }}
        >
          <TypographyWithIcon
            color={item.color ?? 'grey'}
            icon={item.icon}
            text={item.text}
            tx={item.tx}
          />

          {item.isNew && (
            <StyledNewTag
              color="primary"
              textStyle="bold"
              tx="shopMenu.new"
              variant="p3"
            />
          )}
        </StyledSideMenuLink>
      </NavLink>
    );
  };

  if (!mounted || isLarge) {
    return null;
  }

  return (
    <>
      <StyledSideMenuContainer style={spring}>
        <StyledSideMenu>
          {siteItems.map((item: MenuItemProps) => {
            return item.submenu && visible ? (
              <SideMenuItem key={item.tx ?? item.text} item={item} />
            ) : (
              renderMenuItem(item)
            );
          })}
        </StyledSideMenu>

        <StyledSideMenu>
          {subMenuItems.map((item: MenuItemProps) => {
            return item.submenu && visible ? (
              <SideMenuItem
                key={item.tx ?? item.text}
                item={item}
                lineHeight="4rem"
              />
            ) : (
              renderMenuItem(item)
            );
          })}
        </StyledSideMenu>
      </StyledSideMenuContainer>

      {visible && <StyledSideMenuBackdrop onClick={menuClose} />}
    </>
  );
};

import styled, { css } from 'styled-components';
import { legacyTheme } from 'styles/theme';

export const StyledCheckboxContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => {
    return css`
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      pointer-events: ${disabled ? 'none' : 'unset'};
      opacity: ${disabled ? 0.5 : 1};
    `;
  }}
`;

export const StyledCheckbox = styled.div`
  ${({ theme }) => {
    return css`
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 0.3rem;
      border: 1px solid ${legacyTheme.colors.lightGrey};
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${legacyTheme.colors.white};
    `;
  }}
`;

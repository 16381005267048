import { PageProps } from 'core/page/page.props';
import { PagePresets } from 'core/page/page.presets';
import { useEffect } from 'react';
import { Box } from 'gantri-components';

export const Page = (props: PageProps) => {
  const { backgroundColor, children, minHeight } = {
    ...PagePresets,
    ...props,
  };

  useEffect(() => {
    const content = document.getElementById('content');

    content?.scrollTo(0, 0);
  }, []);

  return (
    <Box
      backgroundColor={backgroundColor}
      minHeight={minHeight}
      paddingBottom={{ lg: '6x', md: '5x' }}
      width="100%"
    >
      {children}
    </Box>
  );
};

import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { Page } from 'core/page';
import { Box, Grid, Line, productColorsMap, Stack } from 'gantri-components';
import { useFetchDesign } from 'api/designs/routes';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { StepHeader } from '../../components/step-header';
import { ChecklistReviewList } from '../submit-concept/checklist-review-list';
import { HeaderSubmissionDetails } from '../../components/step-header/components/header-submission-details';
import { engineeringStatuses } from './hooks/use-create-section-lists/use-create-section-lists.constants';
import { EngineeringSectionHeader } from './components/engineering-section-header';
import { useCreateSectionLists } from './hooks/use-create-section-lists';
import { EngineeringStepImage } from './components/engineering-step-image';

export const EngineeringStepPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const lists = useCreateSectionLists({ design });

  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          {design && (
            <Stack gap="5x">
              <StepHeader design={design} showBackButton>
                <HeaderSubmissionDetails
                  titleProps={{ text: "We're optimizing your design" }}
                />
              </StepHeader>

              <Stack gap="6x">
                <Stack>
                  <EngineeringSectionHeader
                    date={design.engineering.modelingOptimization.completedAt}
                    progress={
                      design.engineering.modelingOptimization.stepProgress
                    }
                    title={engineeringStatuses.modelingOptimization.title}
                  />
                  <ChecklistReviewList
                    list={lists.modelingOptimization}
                    showApprovedStatus
                  />
                </Stack>

                <Line />

                <Stack>
                  <EngineeringSectionHeader
                    date={design.engineering.prototyping.completedAt}
                    progress={
                      design.engineering.prototyping.completedAt ? 100 : 0
                    }
                    title={engineeringStatuses.prototyping.title}
                  />
                  <Grid columns={{ lg: 2, sm: 1 }}>
                    {design.engineering.prototyping.images.map(
                      ({ fileUrl, nameOfPhoto }) => {
                        return (
                          <EngineeringStepImage
                            key={fileUrl}
                            fileUrl={fileUrl}
                            text={nameOfPhoto}
                          />
                        );
                      },
                    )}
                  </Grid>
                </Stack>

                <Line />

                <Stack>
                  <EngineeringSectionHeader
                    date={design.engineering.qualityTesting.completedAt}
                    progress={design.engineering.qualityTesting.stepProgress}
                    title={engineeringStatuses.qualityTesting.title}
                  />
                  <ChecklistReviewList
                    list={lists.qualityTesting}
                    showApprovedStatus
                  />
                </Stack>

                <Line />

                <Stack>
                  <EngineeringSectionHeader
                    date={design.engineering.finalization.completedAt}
                    progress={design.engineering.finalization.stepProgress}
                    title={engineeringStatuses.finalization.title}
                  />
                  <ChecklistReviewList
                    list={lists.finalization}
                    showApprovedStatus
                  />
                  <Grid columns={{ lg: 2, sm: 1 }}>
                    {design.engineering.finalization.finishedPrototypes.map(
                      ({ color: colorCode, fileUrl }) => {
                        const colorName =
                          productColorsMap[colorCode]?.shortColorName ||
                          capitalize(colorCode);

                        return (
                          <EngineeringStepImage
                            key={fileUrl}
                            fileUrl={fileUrl}
                            text={colorName}
                          />
                        );
                      },
                    )}
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

import { Stack, TextField } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';

export const AddDesignModalNameStepContent = () => {
  const [name, setName] = useRecoilState(addDesignModalAtoms.name);

  return (
    <Stack>
      <TextField
        placeholder="Name of your design"
        value={name}
        onTextChange={setName}
      />
    </Stack>
  );
};

import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { Page } from 'core/page';
import {
  Box,
  Conditional,
  Grid,
  Image,
  Stack,
  Typography,
} from 'gantri-components';
import { useFetchDesign } from 'api/designs/routes';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { designStatuses } from 'api/designs/routes/fetch-designs/fetch-designs.types';
import { useMemo } from 'react';
import { StepHeader } from '../../components/step-header';
import { getDesignStatusDetails } from '../../helpers/get-design-status-details';
import { ChecklistReviewList } from '../submit-concept/checklist-review-list';

export const ContentStepPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isCompleted } = getDesignStatusDetails(design?.content?.status);

  const getDescription = (completedDate: string | number) => {
    return completedDate
      ? `${designStatuses.completed} ${formatDate(completedDate)}`
      : undefined;
  };

  const list = useMemo(() => {
    return [
      {
        approved: design?.content?.productFinalization?.photos?.approved,
        description: getDescription(
          design?.content?.productFinalization?.photos?.completedAt,
        ),
        title: 'Photos',
      },
      {
        approved: design?.content?.productFinalization?.copy?.approved,
        description: getDescription(
          design?.content?.productFinalization?.copy?.completedAt,
        ),
        title: 'Copy',
      },
      {
        approved:
          design?.content?.productFinalization?.technicalSpecs?.approved,
        description: getDescription(
          design?.content?.productFinalization?.technicalSpecs?.completedAt,
        ),
        title: 'Technical specs',
      },
      {
        approved:
          design?.content?.productFinalization?.packagingAssets?.approved,
        description: getDescription(
          design?.content?.productFinalization?.packagingAssets?.completedAt,
        ),
        title: 'Packing assets',
      },
      {
        approved:
          design?.content?.productFinalization?.approveDesignerProfile
            ?.approved,
        description: getDescription(
          design?.content?.productFinalization?.approveDesignerProfile
            ?.completedAt,
        ),
        title: 'Approve designer profile',
      },
    ];
  }, [design]);

  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          {design && (
            <Stack gap="5x">
              <StepHeader design={design} showBackButton />

              <Conditional condition={isCompleted}>
                <Grid
                  alignItems="center"
                  columns="16rem 1fr"
                  paddingBottom="5x"
                >
                  <Image
                    alt={design.name}
                    containerAspectRatio="1"
                    source="absolute"
                    src={design.engineering.prototyping.images[0].fileUrl}
                  />
                  <Typography
                    text={`${design.name} is completed.`}
                    variant="p1"
                  />
                </Grid>
              </Conditional>

              <Typography
                text="We're preparing your design for sale"
                variant="h3"
              />

              <ChecklistReviewList
                descriptionAlignment="right"
                list={list}
                showApprovedStatus
              />
            </Stack>
          )}
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';

export const StyledDropdown = styled.div`
  cursor: pointer;
  position: relative;
  user-select: none;
`;

export const StyledDropdownMenu = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${legacyTheme.colors.white};
      border: 1px solid ${legacyTheme.colors.cream};
      min-width: 16rem;
      border-radius: 0.3rem;
      overflow: initial;
      position: absolute;
      right: 0;
      top: calc(100% + 1rem);
      z-index: 1;
      box-shadow: 0 1px 2rem rgba(0, 0, 0, 0.1);
    `;
  }}
`;

export const StyledDropdownItem = styled.li`
  ${({ theme }) => {
    return css`
      list-style: none;
      padding: 0.8rem 2rem;
      cursor: pointer;
      transition: ${theme.transitions.base};
      user-select: none;

      &:hover {
        background-color: ${legacyTheme.colors.cream};
      }
    `;
  }}
`;

export const StyledDropdownLinkItem = styled.div`
  ${({ theme }) => {
    return css`
      list-style: none;
      padding: 0.8rem 2rem;
      cursor: pointer;
      transition: ${theme.transitions.base};
      user-select: none;

      &:hover {
        background-color: ${legacyTheme.colors.lightGrey};
      }
    `;
  }}
`;

export const StyledDropdownDivider = styled.div`
  ${({ theme }) => {
    return css`
      width: 100%;
      height: 1px;
      background: ${legacyTheme.colors.lightGrey};
      margin-top: 1rem;
      margin-bottom: 1rem;
    `;
  }}
`;

export const StyledDesignerBadgeContainer = styled.div`
  margin-left: 10px;
  top: -4px;
  position: relative;
`;

export const StyledDropdownMenuContainer = styled.div<{ width?: string }>`
  ${({ width }) => {
    return css`
      width: ${width};
      padding: 1rem 0;
      user-select: none;

      a {
        display: block;
      }
    `;
  }}
`;

import { Typography } from 'gantri-components';
import { PropsWithChildren } from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithFallback,
} from 'react-error-boundary';
import { StyledBox } from './simple-error-boundary.styles';

export const SimpleErrorBoundary = ({
  children,
  ...rest
}: PropsWithChildren<Partial<ErrorBoundaryPropsWithFallback>>) => {
  return (
    <ErrorBoundary
      fallback={
        <StyledBox padding="x">
          <Typography text="There was an error rendering this content. Please let our support team know." />
        </StyledBox>
      }
      {...rest}
    >
      {children}
    </ErrorBoundary>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgStats = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 16 16"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 14v2H0v-2h16zM9.25 3.988v9.013h-2.5V3.988h2.5zm-4.5 1.594V13h-2.5V5.582h2.5zm9-5.583V13h-2.5V-.001h2.5z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgStats;

import { placeholderImageSrc } from 'constants/common';
import {
  Button,
  Cell,
  Conditional,
  Flex,
  Grid,
  Image,
  Line,
  Stack,
  Typography,
  useModal,
} from 'gantri-components';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { routePaths } from 'src/app-routes';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { StyledCardStack, StyledExampleBadge } from './design-card.styles';
import { StepDetails } from './components/step-details';
import { ArchiveDesignModal } from './components/archive-design-modal';
import { DesignCardPropsDef } from './design-card.types';

export const DesignCard = (props: DesignCardPropsDef) => {
  const { design } = props;

  const {
    category,
    concept,
    content,
    createdAt,
    engineering,
    id,
    model,
    name,
    size,
    status,
  } = design;

  const isExample = [1, 2, 3].some((exampleId) => {
    return exampleId === id;
  });
  const thumbnail =
    concept?.subSteps?.[4]?.sketches?.front?.uploadedFile?.fileUrl ||
    placeholderImageSrc;

  const { isCompleted: isDesignCompleted, isReturned: isDesignReturned } =
    getDesignStatusDetails(status);

  const { isReturned: isConceptReturned, isSubmitted: isConceptSubmitted } =
    getDesignStatusDetails(concept.status);

  const showContinueButton =
    !concept.status ||
    (!isConceptReturned && !isConceptSubmitted && !model.status);

  const [showArchiveModal, hideArchiveModal] = useModal(() => {
    return <ArchiveDesignModal id={id} onClose={hideArchiveModal} />;
  }, [id]);

  return (
    <StyledCardStack key={id} gap="5x" padding="2x">
      <Grid alignItems="center" columns="8rem 1fr">
        <Image
          alt={name}
          containerAspectRatio="1"
          source="absolute"
          src={thumbnail}
        />
        <Stack gap="x" height="unset">
          <Conditional condition={isExample}>
            <Flex>
              <StyledExampleBadge text="EXAMPLE" />
            </Flex>
          </Conditional>
          <Cell>
            <Typography text={name || 'New Design'} variant="h5" />
            <Typography
              color="t2"
              text={[category, size?.name].filter(Boolean).join(', ')}
            />
          </Cell>
        </Stack>
      </Grid>

      <Grid columns="max-content max-content 1fr" gap="x">
        <Cell width={3}>
          <Line />
        </Cell>

        <StepDetails step={concept} stepName="Concept" stepNumber={1} />

        <Cell width={3}>
          <Line />
        </Cell>

        <StepDetails step={model} stepName="Model" stepNumber={2} />

        <Cell width={3}>
          <Line />
        </Cell>

        <StepDetails
          designFailedAt={design.failedAt}
          step={engineering}
          stepName="Engineering"
          stepNumber={3}
        />

        <Cell width={3}>
          <Line />
        </Cell>

        <StepDetails step={content} stepName="Content" stepNumber={4} />

        <Cell width={3}>
          <Line />
        </Cell>
      </Grid>

      <Grid
        alignItems="center"
        columns={['1fr', 'max-content', !isDesignCompleted && 'max-content']
          .filter(Boolean)
          .join(' ')}
      >
        <Link to={routePaths.designFlowStepList(id)}>
          <Conditional
            condition={showContinueButton || isDesignReturned}
            Fallback={<Button size="large" text="View" variant="secondary" />}
          >
            <Conditional condition={showContinueButton}>
              <Button size="large" text="Continue" variant="primary" />
            </Conditional>
            <Conditional condition={isDesignReturned}>
              <Button size="large" text="Edit" variant="primaryAlert" />
            </Conditional>
          </Conditional>
        </Link>

        <Typography
          align="center"
          color="t2"
          text={`Created ${formatDate(createdAt)}`}
          variant="p3"
        />

        <Conditional condition={!isDesignCompleted}>
          <Button
            justifySelf="end"
            size="small"
            text="Archive"
            textVariant="p3"
            variant="ghost"
            onClick={showArchiveModal}
          />
        </Conditional>
      </Grid>
    </StyledCardStack>
  );
};

import { forwardRef, memo } from 'react';
import { BoxContainerProps } from './box-container.props';
import { BoxContainerPresets } from './box-container.presets';
import { StyledBoxContainer } from './box-container.styles';

export const BoxContainer = memo(
  forwardRef((props: BoxContainerProps, ref: any) => {
    const elementProps = { ...BoxContainerPresets, ...props };

    return <StyledBoxContainer ref={ref} {...elementProps} />;
  }),
);

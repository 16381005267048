import { forwardRef } from 'react';
import { GridProps } from './grid.props';
import { StyledGrid } from './grid.styles';
import { GridPresets } from './grid.presets';

export const Grid = forwardRef((props: GridProps, ref: any) => {
  const { children, ...others } = { ...GridPresets, ...props };

  return (
    <StyledGrid ref={ref} {...others}>
      {children}
    </StyledGrid>
  );
});

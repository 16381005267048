import axios from 'axios';
import { userApiUrl } from 'helpers/auth';
import { AddDesignArgs, AddDesignResponse } from './add-design.types';

export const addDesign = (body: AddDesignArgs) => {
  return axios
    .post<AddDesignResponse>(`${userApiUrl}/designs/new`, body)
    .then(({ data }) => {
      return data;
    });
};

import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { legacyTheme } from 'styles/theme';

export const StyledRemovePhotoContainer = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      right: 1rem;
      top: 1rem;
      background-color: ${legacyTheme.colors.white};
      border: 1px solid ${legacyTheme.colors.cream};
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      cursor: pointer;
      display: none;
      justify-content: center;
      align-items: center;
    `;
  }}
`;

export const StyledLogoContainer = styled.div`
  position: relative;

  &:hover ${StyledRemovePhotoContainer} {
    display: flex;
  }
`;

export const StyledLogo = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px solid ${legacyTheme.colors.cream};
      border-radius: 0.6rem;
      width: 20rem;
      height: 20rem;
      display: grid;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      gap: 1rem;
      margin-bottom: 2rem;

      ${media.lessThan('sm')`
      width: calc(100vw - ${legacyTheme.spacing.s1} * 2);
      height: calc(100vw - ${legacyTheme.spacing.s1} * 2);
    `}
    `;
  }}
`;

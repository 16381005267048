import { BoxDimension } from 'gantri-components/dist/components/box/box.types';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';

export const maxPageWidthLarge = '192rem';

export const maxPageWidthMid = '120rem';

export const sectionVerticalPadding: ResolutionAwareProp<BoxDimension> = {
  lg: '15x',
  md: '8x',
};

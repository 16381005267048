import { useFetchDesigns } from 'api/designs/routes';
import { Page } from 'core/page';
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Typography,
  useModal,
} from 'gantri-components';
import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { DesignCard } from './components/design-card';
import { AddDesignModal } from './components/add-design-modal';

export const DesignsPage = () => {
  const { data } = useFetchDesigns();

  const designs = data?.data || [];

  const [showAddModal, hideAddModal] = useModal(() => {
    return <AddDesignModal onClose={hideAddModal} />;
  }, []);

  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="140rem">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            paddingBottom="8x"
          >
            <Typography text="Designs" variant="h1" />
            <Button
              icon={<Icon name="ui-control:plus_circle" />}
              text="New Design"
              variant="ghost"
              onClick={showAddModal}
            />
          </Flex>
          <Grid columnGap="2x" columns={{ lg: 2, sm: 1 }} rowGap="4x">
            {designs.map((design) => {
              return <DesignCard key={design.id} design={design} />;
            })}
          </Grid>
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

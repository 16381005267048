import { Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledIconStack = styled(Stack)<{ $isActive: boolean }>`
  ${({ $isActive, theme }) => {
    return css`
      background-color: ${$isActive
        ? theme.colors.surfaces.green.t3
        : theme.colors.surfaces.monochrome.t4};
    `;
  }}
`;

import { Fragment, FC, useState } from 'react';
import { SelectInput } from 'core/select-input';
import { Grid } from 'layout/grid';
import { TextInput } from 'core/text-input';
import { Cell } from 'layout/cell';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Icon } from 'core/icon';
import { AsSeenOnRecord } from 'services/api';
import { AsSeenOnProps } from './as-seen-on.types';

const outlets = [
  'Architectural Digest',
  'Apartment Therapy',
  'Business Insider',
  'Cleverest Awards',
  'Cnet',
  'Cool Hunting',
  'Core77',
  'Designboom',
  'Dezeen',
  'Dwell',
  'Fast Company',
  'House Beautiful',
  'HYPEBEAST',
  'MyDomaine',
  'Refinery29',
  'SanFrancisco Magazine',
  'Uncrate',
  'Wired',
  'Yanko Design',
];

export const AsSeenOn: FC<AsSeenOnProps> = props => {
  const { onUpdate, product } = props;
  const [asSeenOn, setAsSeenOn] = useState(product.asSeenOn);

  const updateAsSeenOn = (records: AsSeenOnRecord[]) => {
    onUpdate(
      records.filter(
        (record: AsSeenOnRecord) => !!record.url && !!record.source,
      ),
    );
  };

  const addRecord = () => {
    setAsSeenOn(old => [...old, {}]);
  };

  const editRecord = (index: number, record: AsSeenOnRecord) => {
    const temporal = asSeenOn.map((item: AsSeenOnRecord, idx: number) => {
      if (index === idx) {
        return { ...item, ...record };
      }

      return item;
    });

    setAsSeenOn(temporal);
    updateAsSeenOn(temporal);
  };

  const removeSource = (index: number) => {
    const temporal = asSeenOn.filter((_, idx) => idx !== index);
    setAsSeenOn(temporal);
    updateAsSeenOn(temporal);
  };

  return (
    <Grid
      alignContent="end"
      alignItems="end"
      columns={{ lg: 'repeat(2, 22rem)', sm: '1fr' }}
      marginTop="s2"
    >
      {asSeenOn.map((_, index: number) => (
        <Fragment key={index}>
          <SelectInput
            label="Source"
            options={outlets}
            value={asSeenOn[index].source}
            onChange={source => editRecord(index, { source })}
          />

          <Grid alignItems="end" columns="1fr 1.5rem" gap="3px" padding="unset">
            <TextInput
              autoComplete="off"
              label="URL"
              name="url"
              value={asSeenOn[index].url}
              onBlur={event => editRecord(index, { url: event.target.value })}
            />

            <Icon
              color="red"
              cursor="pointer"
              height="1.7rem"
              name="trash"
              onClick={() => removeSource(index)}
            />
          </Grid>
        </Fragment>
      ))}

      {asSeenOn.length < 5 && (
        <Cell width={{ lg: 2, sm: 1 }}>
          <TypographyWithIcon
            color="primary"
            cursor="pointer"
            icon={<Icon name="plus-circle" />}
            text="Add source"
            textStyle="bold"
            onClick={addRecord}
          />
        </Cell>
      )}
    </Grid>
  );
};

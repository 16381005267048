import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgCheckRoundFull = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="#159F9E" height={20} rx={10} width={20} />
        <path
          d="M5.5 9.636l3.136 3.137L14.91 6.5"
          stroke="#FFF"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
});
export default SvgCheckRoundFull;

import styled, { css } from 'styled-components';
import { Box } from 'gantri-components';

export const StyledPolicyLinks = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      position: relative;
      gap: 1.2rem;

      & > a:first-child {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          background-color: ${theme.colors.palette.monochrome_300};
          right: -0.7rem;
          top: 0.3rem;
          width: 1px;
          height: 1rem;
        }
      }

      a,
      p {
        text-decoration: none;
      }
    `;
  }}
`;

export const StyledLinksContainer = styled(Box)`
  a:hover > p {
    text-decoration: underline;
  }
`;

import { FC, useEffect, useMemo, useState } from 'react';
import { Page } from 'core/page';
import { Typography } from 'core/typography';
import { ButtonToggleGroup } from 'core/button-toggle-group';
import { ButtonToggle } from 'core/button-toggle';
import { Select } from 'core/select';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { useQuery } from 'react-query';
import { api, Device, GET_USER, OtherProduct, UserData } from 'services/api';
import { addToDate, formatDate, formatPstDate } from 'utils/date';
import { Product } from 'types/order.type';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import {
  StyledColor,
  StyledColorOutline,
} from 'core/colors-list/colors-list.styles';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { StackedContainer } from 'layout/stacked-container';
import { LoadingOverlay } from 'layout/loading-overlay';
import { CitiesChart, PurchasesList, StatsChart } from './components';
import { getProductName } from './stats.adapter';
import { DevicesChart } from './components/devices-chart';
import {
  FIRST_TABLE_OPTIONS,
  FOURTH_TABLE_OPTIONS,
  GROUP_BY_PERIOD,
  INITIAL_DATE_RANGE,
  SECOND_TABLE_OPTIONS,
  THIRD_TABLE_OPTIONS,
  TYPE_OPTIONS,
} from './stats.constants';
import { StyledChartContainer, StyledPeriodContainer } from './stats.styles';
import { StatsPagePresets } from './stats.presets';
import { StatsPageProps } from './stats.types';

export const StatsPage: FC<StatsPageProps> = props => {
  const {} = { ...StatsPagePresets, ...props };
  const [selectedPeriod, setSelectedPeriod] = useState('year');
  const [selectedType, setSelectedType] = useState<
    'category' | 'product' | 'sku'
  >('product');
  const [selectedFilter, setSelectedFilter] = useState({});

  const [dateRange, setDateRange] = useState(INITIAL_DATE_RANGE);

  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
    staleTime: 300000,
  });

  const query = useQuery(
    [
      'FETCH_STATS',
      selectedType,
      selectedFilter,
      formatPstDate(dateRange.from),
    ],
    () =>
      api.product.getStats({
        endDate: formatPstDate(dateRange.to),
        filterType: selectedType,
        groupBy: GROUP_BY_PERIOD[selectedPeriod],
        startDate: formatPstDate(dateRange.from),
        ...getApiConditionalParams(),
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    let { from } = INITIAL_DATE_RANGE;

    if (selectedPeriod === 'week') {
      from = addToDate(new Date(), -12, 'weeks');
    } else if (selectedPeriod === 'month') {
      from = addToDate(new Date(), -12, 'months');
    } else if (selectedPeriod === 'year') {
      from = addToDate(new Date(), -2, 'years');
    }

    setDateRange({
      from,
      to: new Date(),
    });
  }, [selectedPeriod]);

  const filterOptions: any[] = useMemo(() => {
    if (userQuery.status === 'success') {
      if (selectedType === 'category') {
        return uniq(
          userQuery.data.data.designerInfo.products.map(
            (product: Product) => product.category,
          ),
        )
          .reverse()
          .map((category: string) => ({
            chartDisplayName: category,
            id: category,
            name: `${category}s`,
          }));
      }

      if (selectedType === 'product') {
        return userQuery.data.data.designerInfo.products.map(
          (product: Product) => ({
            id: product.id,
            name: getProductName(product),
          }),
        );
      }

      return flatten(
        userQuery.data.data.designerInfo.products.map((product: Product) =>
          Object.keys(product.skuPrices),
        ),
      ).map((sku: string) => ({ id: sku, name: sku }));
    }
  }, [selectedType, userQuery?.data?.data?.designerInfo?.products]);

  const skus = useMemo(() => {
    if (userQuery.status === 'success') {
      return flatten(
        userQuery.data.data.designerInfo.products.map((product: Product) =>
          Object.keys(product.skuPrices),
        ),
      );
    }
    return [];
  }, [userQuery?.data?.data?.designerInfo?.products]);

  const getApiConditionalParams = () => {
    const value = Object.keys(selectedFilter);

    if (value?.length) {
      if (selectedType === 'category') {
        return { productCategory: value };
      }
      if (selectedType === 'product') {
        return { productId: value };
      }
    }

    if (selectedType === 'sku') {
      return { sku: value.length ? value : skus };
    }
  };

  const onTypeChange = (value: any) => {
    setSelectedType(value);
    setSelectedFilter({});
  };

  const itemsNames = (filterOptions || []).map(
    item => item.chartDisplayName || item.name,
  );

  const dateRangeString = `${formatDate(dateRange.from)} - ${formatDate(
    dateRange.to,
  )}`;

  const otherProducts = useMemo(
    () =>
      query?.data?.result?.otherProducts
        .filter((product: OtherProduct) => product.state !== 'Other')
        .slice(0, 6),
    [query?.data?.result?.otherProducts],
  );

  const devices = useMemo(
    () =>
      query?.data?.result?.devices
        .filter((device: Device) => !!device.percent)
        .slice(0, 6),
    [query?.data?.result?.devices],
  );

  const statsData = useMemo(
    () => query?.data?.result || [],
    [query?.data?.result],
  );

  return (
    <Page>
      <StackedContainer padding="unset">
        <Typography tx="statsPage.title" variant="h2" />

        <Grid
          columnGap="s1"
          columns={{ lg: '8fr 2fr 2fr', md: 'repeat(2, 1fr)' }}
          marginTop="s3"
          rowGap="s3"
        >
          <Cell row={{ lg: 1, md: 2 }} width={{ lg: 1, md: 2 }}>
            <StyledPeriodContainer>
              <div>
                <ButtonToggleGroup
                  value={selectedPeriod}
                  onChange={setSelectedPeriod}
                >
                  <ButtonToggle tx="statsPage.types.year" value="year" />
                  <ButtonToggle tx="statsPage.types.month" value="month" />
                  <ButtonToggle tx="statsPage.types.week" value="week" />
                </ButtonToggleGroup>
              </div>

              <Typography
                color="grey"
                marginTop="s1"
                text="Time zone: PST (Pacific Standard Time) UTC-7h"
                variant="p3"
              />

              <Typography
                color="grey"
                text="Full tracking for Views, Visitors, Add to Cart and Wishlist started in May 2021."
                variant="p3"
              />
            </StyledPeriodContainer>
          </Cell>

          <Cell>
            <Select
              options={TYPE_OPTIONS}
              value={selectedType}
              onChange={onTypeChange}
            />
          </Cell>

          <Cell>
            <Select
              multiple
              options={filterOptions}
              placeholderTx="statsPage.filterPlaceholder"
              renderCheckbox={
                selectedType === 'sku'
                  ? (option, selected: boolean) => {
                      const color = option.name.split('-')[2];
                      return (
                        <StyledColorOutline
                          color={getThemeColor(color)}
                          size="2.4rem"
                          style={{
                            borderColor: getThemeColor(
                              selected ? 'primary' : 'cream',
                            ),
                          }}
                        >
                          <StyledColor
                            color={color}
                            size={selected ? '2rem' : '2.4rem'}
                          />
                        </StyledColorOutline>
                      );
                    }
                  : null
              }
              value={selectedFilter}
              onChange={setSelectedFilter}
            />
          </Cell>
        </Grid>

        {query.isLoading && <LoadingOverlay />}

        <>
          <StackedContainer
            horizontalPadding="unset"
            marginTop="s5"
            verticalPadding="unset"
          >
            <StatsChart
              data={statsData}
              dateRange={dateRangeString}
              group={selectedPeriod}
              itemsName={itemsNames}
              options={FIRST_TABLE_OPTIONS}
            />

            <StatsChart
              data={statsData}
              dateRange={dateRangeString}
              group={selectedPeriod}
              itemsName={itemsNames}
              options={SECOND_TABLE_OPTIONS}
            />

            <StatsChart
              data={statsData}
              dateRange={dateRangeString}
              group={selectedPeriod}
              itemsName={itemsNames}
              options={THIRD_TABLE_OPTIONS}
            />

            <StatsChart
              data={statsData}
              dateRange={dateRangeString}
              group={selectedPeriod}
              itemsName={itemsNames}
              options={FOURTH_TABLE_OPTIONS}
            />
          </StackedContainer>

          <div>
            <Typography
              marginBottom={{ lg: 's4', md: 's3' }}
              tx="statsPage.customersTitle"
              variant="h3"
            />

            <Grid columns={{ lg: 3, md: 1 }} gap="s1">
              <StyledChartContainer>
                <Typography tx="statsPage.otherProductsTitle" variant="h5" />

                <PurchasesList products={otherProducts || []} />
              </StyledChartContainer>

              <StyledChartContainer>
                <Typography tx="statsPage.topCitiesTitle" variant="h5" />

                <CitiesChart cities={query?.data?.result?.cities || []} />
              </StyledChartContainer>

              <StyledChartContainer>
                <Typography tx="statsPage.devicesTitle" variant="h5" />

                <DevicesChart devices={devices || []} />
              </StyledChartContainer>
            </Grid>
          </div>
        </>
      </StackedContainer>
    </Page>
  );
};

import styled, { css } from 'styled-components';
import { legacyTheme } from 'styles/theme';

export const StyledDashboardListItem = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${legacyTheme.colors.lightGrey};
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: ${legacyTheme.spacing.s1};
    `;
  }}
`;

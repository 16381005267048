import { Cell, Stack, Image, Typography } from 'gantri-components';
import { TailoredNeedsHeaderPropsDef } from './tailored-needs-header.types';

export const TailoredNeedsHeader = (props: TailoredNeedsHeaderPropsDef) => {
  const { alignment, description, src, title, width } = props;

  return (
    <Cell width={width}>
      <Stack
        gap="x"
        justifyItems={alignment}
        paddingBottom={{ lg: 'unset', md: '3x' }}
      >
        <Image
          alt="For designers"
          height="4.8rem"
          source="static"
          src={src}
          width="4.8rem"
        />

        <Typography align={alignment} text={title} variant="h4" />

        <Typography
          align={alignment}
          color="t2"
          maxWidth={{ md: '32rem' }}
          text={description}
        />
      </Stack>
    </Cell>
  );
};

import { useEffect, useRef, useState } from 'react';
import { FirebaseUploaderProps } from 'core/firebase-uploader/firebase-uploader.props';
import { FirebaseUploaderPresets } from 'core/firebase-uploader/firebase-uploader.presets';
import {
  StyledFirebaseFileInput,
  StyledFirebaseUploader,
  StyledFirebaseUploaderContent,
  StyledFirebaseUploaderImageContent,
} from 'core/firebase-uploader/firebase-uploader.styles';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { ImageContainer } from 'layout/image-container';
import {
  deleteImage,
  isValidImage,
  uploadBlob,
} from 'core/firebase-uploader/firebase-uploader.helpers';

export const FirebaseUploader = (props: FirebaseUploaderProps) => {
  const {
    component,
    description,
    filename,
    maxFileSize,
    onError,
    onImageDeleted,
    onImageUploaded,
    path,
    src,
  } = { ...FirebaseUploaderPresets, ...props };

  const [uploaded, setUploaded] = useState(false);
  const ref = useRef<any>();

  useEffect(() => {
    setUploaded(!!src);
  }, [src]);

  const openFileSelector = () => {
    ref.current.click();
  };

  const onFileInputChange = event => {
    const file = event.target.files[0];

    if (event.target.files.length > 0 && file) {
      const { name } = file;
      const extension = file.type.split('/')[1];
      const temporalName =
        filename || `${name}-${Date.now()}` || `${name}.${extension}`;
      const contentType = `image/${extension}`;

      const metadata = {
        contentType,
        name,
      };

      const blob = new Blob(event.target.files, { type: contentType });

      const img = new Image();

      img.src = window.URL.createObjectURL(blob);
      img.onload = () => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = async () => {
          const { errorMessage, valid } = isValidImage({
            file,
            imgInstance: img,
            maxFileSize,
            minHeight: 400,
            minWidth: 400,
          });

          if (valid) {
            uploadBlob({
              blob,
              filename: temporalName || name,
              metadata,
              onComplete: (url: string) => {
                return onImageUploaded(url, filename);
              },
              onError,
              path,
            });
          } else {
            onError(errorMessage);
          }
        };
      };
    }

    ref.current.value = null;
  };

  const onDeleteImage = () => {
    deleteImage({
      fileUrl: src,
      onComplete: onImageDeleted,
      onError,
    });
  };

  return (
    <>
      {!!component ? (
        <div onClick={openFileSelector}>{component}</div>
      ) : (
        <StyledFirebaseUploader>
          {!uploaded && (
            <StyledFirebaseUploaderContent onClick={openFileSelector}>
              <Icon color="primary" name="camera-square" size="5rem" />
              <Typography color="grey" text={description} variant="p3" />
            </StyledFirebaseUploaderContent>
          )}

          {uploaded && (
            <StyledFirebaseUploaderImageContent>
              <ImageContainer aspectRatio="1:1" source="external" src={src} />
              <Icon height="1.8rem" name="trash" onClick={onDeleteImage} />
            </StyledFirebaseUploaderImageContent>
          )}
        </StyledFirebaseUploader>
      )}
      <StyledFirebaseFileInput
        ref={ref}
        accept="image/*"
        type="file"
        onChange={onFileInputChange}
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { CheckboxProps } from 'core/checkbox/checkbox.props';
import { CheckboxPresets } from 'core/checkbox/checkbox.presets';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon/icon';
import { StyledCheckbox, StyledCheckboxContainer } from './checkbox.styles';

export const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    disabled,
    extraProps,
    labelPosition,
    labelText,
    labelTransform,
    labelTx,
    labelVariant,
    onChange,
  } = { ...CheckboxPresets, ...props };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const toggleChecked = event => {
    event.preventDefault();

    const temporal = !isChecked;
    setIsChecked(temporal);

    if (onChange) {
      onChange(temporal, extraProps);
    }
  };

  return (
    <StyledCheckboxContainer disabled={disabled} onClick={toggleChecked}>
      <StackedContainer flow="column" gap="s1" padding="unset">
        {(labelTx || labelText) && labelPosition === 'left' && (
          <Typography
            color={isChecked ? 'black' : 'grey'}
            text={labelText}
            transform={labelTransform}
            tx={labelTx}
            variant={labelVariant}
          />
        )}

        <StyledCheckbox>
          {isChecked && <Icon color="black" name="check" size="1.2rem" />}
        </StyledCheckbox>

        {(labelTx || labelText) && labelPosition === 'right' && (
          <Typography
            color={isChecked ? 'black' : 'grey'}
            text={labelText}
            transform={labelTransform}
            tx={labelTx}
            variant={labelVariant}
          />
        )}
      </StackedContainer>
    </StyledCheckboxContainer>
  );
};

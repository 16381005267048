import { useFetchDesign } from 'api/designs/routes/fetch-design';
import { SimpleErrorBoundary } from 'components/simple-error-boundary';
import { Page } from 'core/page';
import { Box, Conditional, Line, Stack, Typography } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { Timeline } from 'core/timeline';
import { formatDate } from 'utils/date';
import { StepItemConcept } from './components/step-item-concept';
import { StepItemModel } from './components/step-item-model';
import { StepItemEngineering } from './components/step-item-engineering';
import { StepItemContent } from './components/step-item-content';
import { StepHeader } from '../../components/step-header';
import { getStepListTitle } from './helpers/get-step-list-title';
import { getDesignStatusDetails } from '../../helpers/get-design-status-details';
import { HeaderSubmissionDetails } from '../../components/step-header/components/header-submission-details';

export const StepListPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isFailed: isDesignFailed } = getDesignStatusDetails(design?.status);

  return (
    <Page>
      <SimpleErrorBoundary>
        <Box maxWidth="92rem">
          {design && (
            <Stack gap="5x">
              <StepHeader design={design}>
                <Conditional
                  condition={isDesignFailed}
                  Fallback={
                    <Typography text={getStepListTitle(design)} variant="h3" />
                  }
                >
                  <HeaderSubmissionDetails
                    descriptionProps={{
                      color: 'alert',
                      text: `Engineering ended on ${formatDate(
                        design.failedAt,
                      )}`,
                    }}
                    imgProps={{
                      src: 'graphite/design-flow/icon-engineering_2x.png',
                    }}
                    titleProps={{ text: "There's an issue with your design" }}
                  />

                  <Typography
                    color="t2"
                    text={`Engineering ended because of ${design.failReason}`}
                  />
                </Conditional>
              </StepHeader>

              <Line />

              <StepItemConcept design={design} />

              <Line />

              <StepItemModel design={design} />

              <Conditional condition={!isDesignFailed}>
                <Line />

                <StepItemEngineering design={design} />

                <Line />

                <StepItemContent design={design} />
              </Conditional>

              <Typography paddingTop="5x" text="Timeline" variant="h3" />

              <Timeline timeline={design.timelines} />
            </Stack>
          )}
        </Box>
      </SimpleErrorBoundary>
    </Page>
  );
};

import { atom } from 'recoil';
import {
  AddDesignModalStep,
  LightCategory,
  LightSizeDetails,
  LightSubCategory,
} from './add-design-modal.types';
import {
  addDesignModalSteps,
  lightCategories,
} from './add-design-modal.constants';

const atomKeyPrefix = 'ADD_DESIGN_MODAL';

export const addDesignModalAtoms = {
  category: atom<LightCategory>({
    default: lightCategories.tableLight.name,
    key: `${atomKeyPrefix}-category`,
  }),
  name: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-name`,
  }),
  sizeDetails: atom<LightSizeDetails>({
    default: null,
    key: `${atomKeyPrefix}-size-details`,
  }),
  step: atom<AddDesignModalStep>({
    default: addDesignModalSteps.category,
    key: `${atomKeyPrefix}-step`,
  }),
  subCategory: atom<LightSubCategory>({
    default: null,
    key: `${atomKeyPrefix}-sub-category`,
  }),
  type: atom<string>({
    default: 'light',
    key: `${atomKeyPrefix}-type`,
  }),
};

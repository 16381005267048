import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { Grid } from 'layout/grid';
import { StyledCell } from 'layout/cell/cell.styles';
import { media } from 'styles/media';

export const StyledCardContainer = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px solid ${legacyTheme.colors.lightGrey};
      padding: ${legacyTheme.spacing.s3};

      ${media.lessThan('sm')`
      padding: ${legacyTheme.spacing.s2};
    `}

      ${media.lessThan('sm')`
      padding: ${legacyTheme.spacing.s1};
    `}
    `;
  }}
`;

export const StyledCardContentGrid = styled(Grid)`
  ${({ theme }) => {
    return css`
      ${StyledCell}:nth-child(n+3) {
        border-top: 1px solid ${legacyTheme.colors.lightGrey};
        padding: ${legacyTheme.spacing.s1} 0;
      }

      ${StyledCell}:nth-child(-n+2) {
        padding-bottom: ${legacyTheme.spacing.s3};

        ${media.lessThan('sm')`
        padding-bottom: ${legacyTheme.spacing.s2};
      `}
      }
    `;
  }}
`;

import { TailoredNeedsHeader } from '../tailored-needs-header';
import { ForBrandsHeaderPropsDef } from './for-brands-header.types';

export const ForBrandsHeader = (props: ForBrandsHeaderPropsDef) => {
  const { alignment, width } = props;

  return (
    <TailoredNeedsHeader
      alignment={alignment}
      description="Designers or influencers who have a large number of followers or an audience."
      src="graphite/home/icon-brands_2x.png"
      title="For brands"
      width={width}
    />
  );
};

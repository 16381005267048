import { useFetchDesign } from 'api/designs/routes/fetch-design';
import { Stack, Typography } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { Form, Formik } from 'formik';
import { StepFooter } from 'src/pages/design-flow/components/step-footer';
import { ModelStepHeader } from '../model-step-header';
import { useModelNavigation } from '../../hooks/use-model-navigation';
import { ModelStepPageParams } from '../../model-step.types';
import { useOnUpdateModel } from '../../hooks/use-on-update-model';
import { modelStepOneFormSchema } from './model-step-one.schema';
import { ModelAssemblyFileUploader } from './components/model-assembly-file-uploader';

export const ModelStepOne = () => {
  const { id } = useParams<ModelStepPageParams>();

  const { onNavigateToNext, onNavigateToStepList } = useModelNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isApproved: isModelApproved } = getDesignStatusDetails(
    design?.model?.status,
  );

  const isDisabled = isModelApproved;

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToStepList,
  });

  const { onSubmit, processing: isSubmitting } = useOnUpdateModel({
    design,
    isApproved: isModelApproved,
    onNavigateTo: onNavigateToNext,
  });

  return design ? (
    <Formik
      enableReinitialize
      initialValues={design.model.subSteps[1]}
      validateOnMount
      validationSchema={modelStepOneFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Stack gap="5x">
              <ModelStepHeader design={design} showBackButton />

              <Typography
                text="Upload assembly file of your design"
                variant="h4"
              />

              <ModelAssemblyFileUploader
                design={design}
                isDisabled={isDisabled}
              />

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

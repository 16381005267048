import { TabsProps } from './tabs.props';

export const TabsPresets: TabsProps = {
  config: [],
  headerBorderVisible: true,
  headingActiveColor: 'black',
  headingAlign: 'flex-start',
  headingColor: 'grey',
  headingGap: 0,
  headingTypographyProps: {},
  headingWidth: '1fr',
  selectedTab: 0,
};

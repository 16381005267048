import { useGenericFetchQuery } from 'api/hooks/use-generic-fetch-query';
import { getGenericQueryObj } from 'api/hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from 'api/hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { useGetInvalidateQueryCache } from 'api/hooks/use-query-fetch';
import { fetchDesign } from './fetch-design';
import { FetchDesignResponse, FetchDesignArgs } from './fetch-design.types';

const fetchDesignQueriesObj = getGenericQueryObj({
  groupName: 'fetch-design',
  query: fetchDesign,
});

export const useFetchDesign = <TransformedData = FetchDesignResponse>(
  props?: GenericFetchProps<
    FetchDesignArgs,
    FetchDesignResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch design.',
    queryObj: fetchDesignQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchDesignCache = () => {
  const invalidateFetchDesignCache = useGetInvalidateQueryCache(
    fetchDesignQueriesObj,
  );

  return { invalidateFetchDesignCache };
};

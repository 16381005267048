import { NotificationDetails } from 'api/hooks/useNotification/use-notification.types';
import { atom } from 'recoil';

const atomKeyPrefix = 'NOTIFICATION';

export const notificationAtoms = {
  notifications: atom<NotificationDetails[]>({
    default: [],
    key: `${atomKeyPrefix}-notifications`,
  }),
};

import { FirebaseUploaderProps } from 'core/firebase-uploader/firebase-uploader.props';

const noop = () => null;

export const FirebaseUploaderPresets: FirebaseUploaderProps = {
  description: 'Min 400px x 400px',
  maxFileSize: 1000000,
  onError: noop,
  onImageDeleted: noop,
  onImageUploaded: noop,
  path: '',
};

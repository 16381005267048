import { useGenericMutateQuery } from 'api/hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from 'api/hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { deleteDesign } from './delete-design';
import { DeleteDesignResponse, DeleteDesignArgs } from './delete-design.types';

export const useDeleteDesign = <TransformedData = DeleteDesignResponse>(
  props?: GenericMutateQueryProps<
    DeleteDesignArgs,
    DeleteDesignResponse,
    TransformedData
  >,
) => {
  const { onMutate: onDeleteDesign, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to archive design.',
    mutationFn: deleteDesign,
    ...props,
  });

  return { ...rest, onDeleteDesign };
};

import { TailoredNeedsHeader } from '../tailored-needs-header';
import { ForDesignersHeaderPropsDef } from './for-designers-header.types';

export const ForDesignersHeader = (props: ForDesignersHeaderPropsDef) => {
  const { alignment, width } = props;

  return (
    <TailoredNeedsHeader
      alignment={alignment}
      description="Independent designers who want to bring their product ideas to market."
      src="graphite/home/icon-designers_2x.png"
      title="For designers"
      width={width}
    />
  );
};

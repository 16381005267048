import styled, { css } from 'styled-components';
import { FloatingContainer } from 'layout/floating-container';
import { legacyTheme } from 'styles/theme';

export const StyledDatePickerContainer = styled.div`
  position: relative;
  user-select: none;
`;

export const StyledDatePickerValue = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: 7.5rem 2rem 7.5rem 2rem;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      border-bottom: 1px solid ${legacyTheme.colors.lightGrey};
      cursor: pointer;
      padding-bottom: 0.4rem;
      height: 3rem;

      &:hover {
        border-bottom: 1px solid ${legacyTheme.colors.black};
      }
    `;
  }}
`;

export const StyledDatePickerPopup = styled(FloatingContainer)`
  ${({ theme }) => {
    return css`
      top: calc(100% + 4px);
      border: 1px solid ${legacyTheme.colors.lightGrey};
      border-radius: ${theme.borderRadius};
      box-shadow: ${theme.shadow};
      z-index: 1;
      left: unset;
      right: 0;
      background-color: ${legacyTheme.colors.white};

      .react-datepicker {
        border: unset;
        padding: ${legacyTheme.spacing.s2} ${legacyTheme.spacing.s1} 0;

        &__header {
          background-color: transparent;
          border-bottom: unset;
          padding: unset;
        }

        &__navigation {
          top: 1.8rem;

          &-icon {
            width: 0;

            &::before {
              border-color: ${legacyTheme.colors.grey};
              width: 1rem;
              height: 1rem;
            }
          }
        }

        &__current-month {
          font-size: 1.5rem;
          line-height: 2.4rem;
        }

        &__day {
          width: 4.5rem;
          height: 4.5rem;
          color: ${legacyTheme.colors.grey};
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: unset;
          margin: unset;

          &--keyboard-selected {
            background-color: transparent;
          }

          &--selected {
            background-color: ${legacyTheme.colors.primary};
            color: ${legacyTheme.colors.white};

            &.react-datepicker__day--in-selecting-range {
              color: ${legacyTheme.colors.grey};
            }
          }

          &--in-range {
            background-color: rgba(27, 123, 118, 0.1);
            color: ${legacyTheme.colors.black};
          }

          &--in-selecting-range {
            background-color: rgba(27, 123, 118, 0.1);
          }

          &--range-start,
          &--range-end {
            background-color: ${legacyTheme.colors.primary};
            color: ${legacyTheme.colors.white};
          }

          &--disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          &--outside-month {
            cursor: default;
            pointer-events: none;
            color: transparent;
            background-color: transparent;
          }

          &-names {
            background-color: transparent;
            border-top: 1px solid ${legacyTheme.colors.lightGrey};
            margin-top: ${legacyTheme.spacing.s2};
            margin-bottom: ${legacyTheme.spacing.s1};
          }

          &-name {
            width: 4.5rem;
            height: 4.5rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: unset;
          }
        }
      }
    `;
  }}
`;

export const StyledResetDateContainer = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${legacyTheme.colors.lightGrey};
      margin: 0 ${legacyTheme.spacing.s1};
      padding: 1rem 0 2rem;
      cursor: pointer;
    `;
  }}
`;

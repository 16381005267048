import { Box, Grid, Icon, Stack } from 'gantri-components';
import { Link } from 'react-router-dom';
import { ApplyOrSignIn } from '../apply-or-sign-in';
import { StyledHeroStack, StyledShadowTypography } from './hero-section.styles';
import { maxPageWidthLarge } from '../../home.constants';
import { storefrontRoutePaths } from '../../../../storefront-routes';

export const HeroSection = () => {
  return (
    <StyledHeroStack
      justifySelf="center"
      maxWidth={maxPageWidthLarge}
      rows="max-content 1fr max-content"
      width="100%"
    >
      <Box padding={{ lg: '4x', md: '2x' }}>
        <Link to={storefrontRoutePaths.home}>
          <Icon
            color="alt"
            cursor="pointer"
            height={{ lg: '3rem', sm: '2rem' }}
            name="logos:logo_gantri"
            width={{ lg: '12rem', sm: '8rem' }}
          />
        </Link>
      </Box>

      <Stack
        alignContent="center"
        justifyContent="center"
        justifyItems="center"
        padding="2x"
      >
        <StyledShadowTypography
          align="center"
          color="alt"
          text="Sell your designs."
          variant="h1"
        />
        <StyledShadowTypography
          align="center"
          color="t3"
          text="Become a Gantri designer to create designs and sell them online. All for free."
          variant="p1"
        />
        <ApplyOrSignIn />
      </Stack>

      <Grid columns={{ lg: 3, md: 1 }} padding={{ lg: '4x', md: '2x' }}>
        <Box>
          <StyledShadowTypography color="alt" text="Sam Gwilt" />
          <StyledShadowTypography
            color="alt"
            text="London, United Kingdom"
            variant="p3"
          />
        </Box>

        <Box hidden={{ lg: false, md: true }} justifySelf="center">
          <Icon
            color="alt"
            name="arrows:arrow_down_24"
            size="2.4rem"
            onClick={(event) => {
              event.currentTarget.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          />
        </Box>
      </Grid>
    </StyledHeroStack>
  );
};

export const engineeringStatuses = {
  finalization: {
    sections: [
      {
        keyName: 'gatheringProductSpecs',
        title: 'Gathering product specs',
      },
      {
        keyName: 'finishedProductsInAllColorways',
        title: 'Finished products in all colorways',
      },
      {
        keyName: 'gCode',
        title: 'G-code',
      },
      {
        keyName: 'assemblyInstructions',
        title: 'Assembly instructions',
      },
    ],
    title: 'Finalization',
  },
  modelingOptimization: {
    sections: [
      {
        keyName: 'assemblyAndMatingFeatures',
        title: 'Assembly and mating features',
      },
      {
        keyName: 'partitioningAndPrintOrientation',
        title: 'Partitioning and print orientation',
      },
      {
        keyName: 'lightTransmission',
        title: 'Light transmission',
      },
      {
        keyName: 'functionalComponentAccommodation',
        title: 'Functional component accommodation',
      },
      {
        keyName: 'overhangMitigation',
        title: 'Overhang mitigation',
      },
      {
        keyName: 'detailWork',
        title: 'Detail work',
      },
      {
        keyName: 'usability',
        title: 'Usability',
      },
      {
        keyName: 'gCodeSlicingSetting',
        title: 'GCode slicing settings',
      },
    ],
    title: 'Modeling optimization',
  },
  prototyping: {
    title: 'Prototyping',
  },
  qualityTesting: {
    sections: [
      {
        keyName: 'assembly',
        title: 'Assembly (full assembly)',
      },
      {
        keyName: 'interaction',
        title: 'Interaction (evaluator accessing the bulb)',
      },
      {
        keyName: 'strength',
        title: 'Strength (product knocked over)',
      },
      {
        keyName: 'heat',
        title: 'Heat (product in heat chamber)',
      },
      {
        keyName: 'finishing',
        title: 'Finishing (first painted prototype)',
      },
      {
        keyName: 'light',
        title: 'Light (product illuminated)',
      },
      {
        keyName: 'shipping',
        title: 'Shipping (product packaged)',
      },
    ],
    title: 'Quality testing',
  },
} as const;

import { legacyTheme } from 'styles/theme';
import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledGiftCard = styled.div`
  ${({ theme }) => {
    return css`
      border: 1px solid ${legacyTheme.colors.lightGrey};
      padding: ${legacyTheme.spacing.s3};
      border-radius: 0.6rem;
    `;
  }}
`;

export const StyledGiftCardContent = styled.div`
  ${({ theme }) => {
    return css`
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      column-gap: ${legacyTheme.spacing.s2};

      ${media.lessThan('sm')`
      grid-template-columns: max-content 1fr;
    `}
    `;
  }}
`;

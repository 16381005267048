import { useEffect, useRef, useState } from 'react';
import {
  StyledDropdown,
  StyledDropdownMenu,
} from 'core/dropdown/dropdown.styles';
import { useClickAway } from 'react-use';
import { DropdownPresets } from './dropdown.presets';
import { DropdownProps } from './dropdown.props';

export const Dropdown = (props: DropdownProps) => {
  const {
    canShowDropdown,
    children,
    closeBehavior,
    onClose,
    onOpen,
    opened,
    overlay,
    style,
  } = { ...DropdownPresets, ...props };
  const [expanded, setExpanded] = useState(opened);
  const containerRef = useRef(null);

  useEffect(() => {
    setExpanded(opened);
  }, [opened]);

  useClickAway(containerRef, () => {
    if (closeBehavior === 'automatic') {
      setExpanded(false);
    }
  });

  useEffect(() => {
    if (expanded) {
      onOpen?.();
    }
  }, [expanded]);

  const toggleExpanded = () => {
    if (canShowDropdown && (!expanded || closeBehavior === 'automatic')) {
      setExpanded(!expanded);
    }
  };

  return (
    <StyledDropdown ref={containerRef} onClick={toggleExpanded}>
      {children}
      {expanded && (
        <StyledDropdownMenu style={style}>{overlay}</StyledDropdownMenu>
      )}
    </StyledDropdown>
  );
};

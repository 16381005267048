import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { CellProps } from './cell.props';

export const StyledCell = styled.div<CellProps>`
  ${({ column, height, hidden, row, textAlign, width }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: value => (value ? 'none' : 'grid'),
      },
      {
        cssProperty: 'text-align',
        resolutionAwareProp: textAlign,
      },
    ];

    if (width) {
      entries.push({
        cssProperty: 'grid-column',
        resolutionAwareProp: width,
        valueFormatter: value => `span ${value}`,
      });
    } else if (column) {
      entries.push({
        cssProperty: 'grid-column',
        resolutionAwareProp: column,
      });
    }

    if (height) {
      entries.push({
        cssProperty: 'grid-row',
        resolutionAwareProp: height,
        valueFormatter: value => `span ${value}`,
      });
    } else if (row) {
      entries.push({
        cssProperty: 'grid-row',
        resolutionAwareProp: row,
      });
    }

    return css`
      display: grid;
      align-content: start;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

import {
  Button,
  Cell,
  Conditional,
  Grid,
  Icon,
  Line,
  Typography,
} from 'gantri-components';
import { Fragment, useState } from 'react';
import { StyledTailoredSectionStack } from './tailored-section.styles';
import { maxPageWidthMid, sectionVerticalPadding } from '../../home.constants';
import { ForDesignersHeader } from './components/for-designers-header';
import { ForBrandsHeader } from './components/for-brands-header';
import { cells } from './tailored-section.constants';

export const TailoredSection = () => {
  const [showDesignerNeeds, setShowDesignerNeeds] = useState<boolean>(true);

  return (
    <StyledTailoredSectionStack
      horizontalPadding="2x"
      justifyContent={{ lg: 'center', md: 'unset' }}
      verticalPadding={sectionVerticalPadding}
    >
      <StyledTailoredSectionStack
        alignContent="center"
        gap="5x"
        maxWidth={maxPageWidthMid}
      >
        <Typography
          align="center"
          text="Tailored for your needs"
          variant="h2"
        />

        <Grid columns={3} hidden={{ lg: false, md: true }}>
          <Cell />
          <ForDesignersHeader />
          <ForBrandsHeader />

          {cells.map(({ forBrands, forDesigners, label }) => {
            return (
              <Fragment key={label}>
                <Cell width={{ lg: 3, md: 2 }}>
                  <Line />
                </Cell>

                <Typography text={label} />
                <Typography color="t2" text={forDesigners} />
                <Typography color="t2" text={forBrands} />
              </Fragment>
            );
          })}
        </Grid>

        <Grid columns={2} hidden={{ lg: true, md: false }}>
          <Conditional
            condition={showDesignerNeeds}
            Fallback={
              <>
                <ForBrandsHeader alignment="center" width={2} />

                {cells.map(({ forBrands, label }) => {
                  return (
                    <Fragment key={label}>
                      <Cell width={{ lg: 3, md: 2 }}>
                        <Line />
                      </Cell>

                      <Typography text={label} />
                      <Typography color="t2" text={forBrands} />
                    </Fragment>
                  );
                })}
              </>
            }
          >
            <ForDesignersHeader alignment="center" width={2} />

            {cells.map(({ forDesigners, label }) => {
              return (
                <Fragment key={label}>
                  <Cell width={{ lg: 3, md: 2 }}>
                    <Line />
                  </Cell>

                  <Typography text={label} />
                  <Typography color="t2" text={forDesigners} />
                </Fragment>
              );
            })}
          </Conditional>

          <Cell justifyItems="center" paddingTop="2x" width={2}>
            <Button
              icon={<Icon color="link" name="arrows:arrow_right" />}
              iconLocation="right"
              text={showDesignerNeeds ? 'For brands' : 'For Designer'}
              variant="ghost"
              onClick={() => {
                setShowDesignerNeeds((value) => {
                  return !value;
                });
              }}
            />
          </Cell>
        </Grid>
      </StyledTailoredSectionStack>
    </StyledTailoredSectionStack>
  );
};

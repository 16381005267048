import { useMemo, FC, useState } from 'react';
import { Page } from 'core/page';
import { useQuery } from 'react-query';
import { Download, GET_DOWNLOADS } from 'services/api/downloads';
import { api } from 'services/api';
import { Typography } from 'core/typography';
import { Table } from 'core/table';
import { Thumbnail } from 'core/thumbnail';
import { formatDate } from 'utils/date';
import { Icon } from 'core/icon';
import { TableColumn } from 'core/table/table.props';
import { StyledInlineFlexContainer } from 'layout/layout/base-layout.styles';
import { Grid } from 'layout/grid';
import { DownloadsFilters } from './components';

const initial = {
  search: '',
  types: [],
};

export const DownloadsPage: FC = () => {
  const [filters, setFilters] = useState(initial);

  const query = useQuery([GET_DOWNLOADS, filters], () =>
    api.download.fetchAll({
      sortingField: '',
      sortingType: '',
      ...filters,
    }),
  );

  const columns = useMemo(
    () =>
      [
        {
          Cell: ({ row, value }) => (
            <StyledInlineFlexContainer>
              <Thumbnail
                height="6rem"
                src={row.original.thumbnail}
                width="6rem"
              />

              <Typography color="grey" text={value} />
            </StyledInlineFlexContainer>
          ),
          Header: 'Name',
          accessor: 'name',
          sortable: true,
        },
        {
          Header: 'Description',
          accessor: 'description',
          minWidth: '40vw',
        },
        {
          Header: 'Type',
          accessor: 'type',
          collapse: true,
        },
        {
          Cell: ({ value }) => (
            <Typography color="grey" text={formatDate(value)} />
          ),
          Header: 'Created',
          accessor: 'createdAt',
          collapse: true,
          sortable: true,
        },
        {
          Cell: ({ value }) => (
            <a href={value} rel="noreferrer" target="_blank">
              <Icon color="primary" name="download" />
            </a>
          ),
          Header: 'Link',
          accessor: 'link',
          style: {
            textAlign: 'center',
          },
          width: '2rem',
        },
      ] as TableColumn<Download>[],
    [],
  );

  return (
    <Page>
      <Grid gap={{ lg: 's5', md: 's4' }}>
        <Typography tx="downloadsPage.title" variant="h2" />

        <DownloadsFilters
          count={query.data?.downloads?.length}
          onApply={setFilters}
          onReset={() => setFilters(initial)}
        />

        <Table columns={columns} data={query.data?.downloads || []} />
      </Grid>
    </Page>
  );
};

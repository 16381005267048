import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';
import { SpacerProps } from 'layout/spacer/spacer.props';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { legacyTheme } from 'styles/theme';

export const StyledSpacer = styled.div<SpacerProps>`
  ${({ height, hidden, width }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        isImportant: true,
        lookIn: legacyTheme.spacing,
        resolutionAwareProp: width,
      },
      {
        cssProperty: 'height',
        isImportant: true,
        lookIn: legacyTheme.spacing,
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'display',
        resolutionAwareProp: hidden,
        valueFormatter: (value) => (value ? 'none' : 'block'),
      },
    ];

    return css`
      ${generateStylesForResolutionAwareProps(entries)}
    `;
  }}
`;

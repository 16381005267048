import { ApiInstance } from 'services/api/api-instance';
import { ApisauceInstance } from 'apisauce';
import {
  AcceptInviteRequest,
  AcceptInviteResponse,
  GetInfoResponse,
  GetPayoutResponse,
  GetPayoutsResponse,
  UpdateThankYouMessageResponse,
  ValidateTokenResponse,
} from 'services/api';
import { UserResponse } from 'services/api/user/user-api.types';
import { DesignerInfo } from 'types/user.type';

export class DesignerApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/designers');
  }

  async getInfo(profileLink: string): Promise<GetInfoResponse> {
    const response = await this.api.get<GetInfoResponse>(
      `/designers/${profileLink}`,
    );

    return this.handleResponse<GetInfoResponse>(response);
  }

  async getPayouts(): Promise<GetPayoutsResponse> {
    const response = await this.api.get<GetPayoutsResponse>('/user/payouts');

    return this.handleResponse<GetPayoutsResponse>(response);
  }

  async getPayout(id: number): Promise<GetPayoutResponse> {
    const response = await this.api.get<GetPayoutResponse>(
      `/user/payouts/${id}`,
    );

    return this.handleResponse<GetPayoutResponse>(response);
  }

  async update(request: DesignerInfo): Promise<UserResponse> {
    const response = await this.api.put<UserResponse>('/designer', request);

    return this.handleResponse<UserResponse>(response);
  }

  async updateThankYouMessage(
    bodyMessage: string,
  ): Promise<UpdateThankYouMessageResponse> {
    const response = await this.api.put<UpdateThankYouMessageResponse>(
      '/designer/body-message',
      {
        bodyMessage,
      },
    );

    return this.handleResponse<UpdateThankYouMessageResponse>(response);
  }

  async validateToken(token: string): Promise<ValidateTokenResponse> {
    const response = await this.api.post<ValidateTokenResponse>(
      '/invite/validationToken',
      {
        token,
      },
    );

    return this.handleResponse<ValidateTokenResponse>(response);
  }

  async acceptInvite(
    request: AcceptInviteRequest,
  ): Promise<AcceptInviteResponse> {
    const response = await this.api.post<AcceptInviteResponse>(
      '/invite/accept',
      request,
    );

    return this.handleResponse<AcceptInviteResponse>(response);
  }
}

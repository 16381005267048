import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';

export interface RequiredImage {
  lgSrc?: string;
  lgSrcWebP?: string;
  mdSrc?: string;
  mdSrcWebP?: string;
  multiple?: boolean;
  smSrc?: string;
  smSrcWebP?: string;
  src?: string;
  srcWebP?: string;
}

/* eslint-disable @typescript-eslint/no-var-requires */
export const requireImage = (
  path: ResolutionAwareProp<string>,
): RequiredImage => {
  if (typeof path === 'string') {
    const src = require(`../../assets/images/${path}`);
    const srcWebP = require(`../../assets/images/${path}?webp`);

    return {
      src: src.default || src,
      srcWebP: srcWebP.default || srcWebP,
    };
  }
  const lgSrc = path.lg ? require(`../../assets/images/${path.lg}`) : null;
  const lgSrcWebP = path.lg
    ? require(`../../assets/images/${path.lg}?webp`)
    : null;

  const smSrc = path.sm
    ? require(`../../assets/images/${path.sm ?? path.md}`)
    : null;
  const smSrcWebP = path.sm
    ? require(`../../assets/images/${path.sm ?? path.md}?webp`)
    : null;

  let mdSrc = smSrc;
  let mdSrcWebP = smSrcWebP;

  if (path.md) {
    mdSrc = require(`../../assets/images/${path.md}`);
    mdSrcWebP = require(`../../assets/images/${path.md}`);
  }

  return {
    lgSrc: lgSrc.default || lgSrc,
    lgSrcWebP: lgSrcWebP.default || lgSrcWebP,
    mdSrc: mdSrc.default || mdSrc,
    mdSrcWebP: mdSrcWebP.default || mdSrcWebP,
    multiple: true,
    smSrc: smSrc.default || smSrc,
    smSrcWebP: smSrcWebP.default || smSrcWebP,
  };
};

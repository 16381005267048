import { useRecoilState } from 'recoil';
import { addDesignModalAtoms } from '../../../add-design-modal.atoms';
import { AddDesignModalContentTiles } from '../../add-design-modal-content-tiles';
import { ContentTileItemDefaultDef } from '../../add-design-modal-content-tiles/add-design-modal-content-tiles.types';

export const AddDesignModalTypeStepContent = () => {
  const [type, setType] = useRecoilState(addDesignModalAtoms.type);

  const items = [
    {
      code: 'light',
      description: 'Start a completely new design',
      name: 'New product',
    },
  ] satisfies ContentTileItemDefaultDef[];

  return (
    <AddDesignModalContentTiles
      getIsActive={({ code }) => {
        return code === type;
      }}
      items={items}
      onSetActiveItem={(item) => {
        setType(item.code);
      }}
    />
  );
};

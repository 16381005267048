import { FormikInput, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Form, Formik } from 'formik';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { DesignFieldTextArea } from '../design-field-text-area';
import { ConceptStepHeader } from '../concept-step-header/concept-step-header';
import { useConceptNavigation } from '../../hooks/use-concept-navigation';
import { StepFooter } from '../../../../components/step-footer';
import { useOnUpdateConcept } from '../../hooks/use-on-update-concept';
import { conceptStepThreeFormSchema } from './concept-step-three.schema';

export const ConceptStepThree = () => {
  const { id } = useParams<{ id: string }>();

  const { onNavigateToPrev, onNavigateToSubmitConcept } =
    useConceptNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const { isApproved, isSubmitted } = getDesignStatusDetails(
    design?.concept?.status,
  );

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateConcept(
    {
      design,
      isApproved,
      onNavigateTo: onNavigateToPrev,
    },
  );

  const { onSubmit, processing: isSubmitting } = useOnUpdateConcept({
    design,
    isApproved,
    onNavigateTo: onNavigateToSubmitConcept,
  });

  const isDisabled = isApproved || isSubmitted;

  return design ? (
    <Formik
      enableReinitialize
      initialValues={design.concept.subSteps[6]}
      validateOnMount
      validationSchema={conceptStepThreeFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Stack gap="5x">
              <ConceptStepHeader
                design={design}
                question="How to use your design?"
                showBackButton
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: For the entryway of a rustic home full of natural materials. I envision the user getting home, turning on the light, and putting their keys down on the product."
                    disabled={isDisabled}
                    label="How is your design used?"
                  />
                }
                fieldVariant="standard"
                name="howDesignUsed"
              />
              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: The light is used when leaving and entering the home. The light is left after entering, signalling that someone is home."
                    disabled={isDisabled}
                    label="When is your design used?"
                  />
                }
                fieldVariant="standard"
                name="whenDesignUsed"
              />
              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: The diffuser is unscrewed from the base, revealing the bulb and bulb socket."
                    disabled={isDisabled}
                    label="How does the user change the bulb?"
                  />
                }
                fieldVariant="standard"
                name="changeBulb"
              />

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

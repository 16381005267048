import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { Button } from 'core/button';

export const InactiveModal = () => (
  <StackedContainer
    horizontalPadding={{ lg: 's5', md: 's2' }}
    justifyContent="center"
    justifyItems="center"
    verticalPadding="s5"
    width="100%"
  >
    <Icon color="primary" name="pencil" width="4.5rem" />
    <Typography tx="common.inactiveModal.title" variant="h4" />
    <Typography
      align="center"
      color="grey"
      marginBottom={{ lg: 's3', md: 's2' }}
      tx="common.inactiveModal.description"
    />
    <a href="mailto:support@gantri.com" rel="noreferrer" target="_blank">
      <Button tx="common.inactiveModal.contactBtn" />
    </a>
  </StackedContainer>
);

export type ThemeColor =
  | 'primary'
  | 'black'
  | 'translucentBlack'
  | 'white'
  | 'translucentWhite'
  | 'grey'
  | 'lightGrey'
  | 'paper'
  | 'cream'
  | 'red'
  | 'lightRed'
  | 'gold'
  | 'lightGold'
  | 'green'
  | 'lightGreen'
  | 'opaqueGreen'
  | 'greyGreen'
  | 'blue'
  | 'lightBlue'
  | 'sedona'
  | 'stone'
  | 'sunrise'
  | 'persimmon'
  | string;

// declare module 'styled-components' {
//   export interface FontInfo {
//     // @ts-expect-error
//     family: string;
//     // @ts-expect-error
//     lineHeight: string;
//     // @ts-expect-error
//     lineHeightSmaller: string;
//     // @ts-expect-error
//     size: string;
//     // @ts-expect-error
//     sizeSmaller: string;
//   }

//   export interface DefaultTheme {
//     border: string;
//     borderRadius: string;
//     colors: {
//       black: string;
//       blue: string;
//       canyon: string;
//       carbon: string;
//       coral: string;
//       cream: string;
//       fog: string;
//       forest: string;
//       gold: string;
//       green: string;
//       grey: string;
//       greyGreen: string;
//       lightBlue: string;
//       lightGold: string;
//       lightGreen: string;
//       lightGrey: string;
//       lightRed: string;
//       meadow: string;
//       midnight: string;
//       opaqueGreen: string;
//       paper: string;
//       persimmon: string;
//       primary: string;
//       red: string;
//       sand: string;
//       sedona: string;
//       sky: string;
//       snow: string;
//       sunrise: string;
//       translucentBlack: string;
//       translucentCream: string;
//       translucentWhite: string;
//       white: string;
//     };
//     fonts: {
//       badge: FontInfo;
//       boldFontFamily: string;
//       h1: FontInfo;
//       h2: FontInfo;
//       h3: FontInfo;
//       h4: FontInfo;
//       h5: FontInfo;
//       label: FontInfo;
//       link: FontInfo;
//       p1: FontInfo;
//       p2: FontInfo;
//       p3: FontInfo;
//       regularFontFamily: string;
//     };
//     grid: {
//       gutter: string;
//       gutterSmaller: string;
//     };
//     layout: {
//       header: {
//         height: string;
//         heightSmaller: string;
//       };
//       maxWidth: string;
//       topMenuHeight: string;
//       topMenuHeightSmaller: string;
//     };
//     shadow: string;
//     spacing: {
//       s1: string;
//       s2: string;
//       s3: string;
//       s4: string;
//       s5: string;
//       s6: string;
//     };
//     transitions: {
//       base: string;
//       slow: string;
//       slowest: string;
//       turtle: string;
//     };
//   }
// }

const colors = {
  black: '#1c1c1c',
  blue: '#1561a3',
  canyon: '#9c3e38',
  carbon: '#1c1c1c',
  coral: '#e6cbc6',
  cream: '#f4f4f4',
  fog: '#bfc2c7',
  forest: '#24322b',
  gold: '#b88d4e',
  green: '#568274',
  grey: '#8f8c8b',
  greyGreen: '#b0b0a6',
  lightBlue: '#eff2f3',
  lightGold: '#f8f6f1',
  lightGreen: '#f1f4f2',
  lightGrey: '#d6d4d2',
  lightRed: '#f6efee',
  meadow: '#c6cebd',
  midnight: '#3e5063',
  opaqueGreen: '#4a5c54',
  paper: '#edeceb',
  persimmon: '#f27d3b',
  primary: '#1b7b76',
  red: '#a34241',
  sand: '#dfd8d0',
  sedona: '#663a31',
  sky: '#bccbce',
  snow: '#ffffff',
  stone: '#e8e8e6',
  sunrise: '#fabc3c',
  white: '#ffffff',
};

// @ts-ignore
export const legacyTheme = {
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: '.6rem',
  colors: {
    ...colors,
    translucentBlack: 'rgba(28, 28, 28, 0.6)',
    translucentCream: 'rgba(245, 245, 245, 0.2)',
    translucentWhite: 'rgba(255, 255, 255, 0.7)',
  },
  fonts: {
    badge: {
      family: 'Univers 55 Roman',
      lineHeight: '2.2rem',
      lineHeightSmaller: '1.8rem',
      size: '1.2rem',
      sizeSmaller: '1rem',
    },
    boldFontFamily: 'Univers 65 Bold',
    h1: {
      family: 'Univers 65 Bold',
      lineHeight: '8rem',
      lineHeightSmaller: '4.8rem',
      size: '6rem',
      sizeSmaller: '3.6rem',
    },
    h2: {
      family: 'Univers 65 Bold',
      lineHeight: '4.8rem',
      lineHeightSmaller: '3.6rem',
      size: '3.6rem',
      sizeSmaller: '2.8rem',
    },
    h3: {
      family: 'Univers 65 Bold',
      lineHeight: '3.6rem',
      lineHeightSmaller: '3rem',
      size: '2.4rem',
      sizeSmaller: '2rem',
    },
    h4: {
      family: 'Univers 65 Bold',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
    },
    h5: {
      family: 'Univers 65 Bold',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
    },
    label: {
      family: 'Univers 55 Roman',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
    },
    link: {
      family: 'Univers 55 Roman',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
    },
    p1: {
      family: 'Univers 55 Roman',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
    },
    p2: {
      family: 'Univers 55 Roman',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2.2rem',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
    },
    p3: {
      family: 'Univers 55 Roman',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
    },
    regularFontFamily: 'Univers 55 Roman',
  },
  grid: {
    gutter: '3rem',
    gutterSmaller: '1.2rem',
  },
  layout: {
    header: {
      height: '6.4rem',
      heightSmaller: '4.8rem',
    },
    maxWidth: '78rem',
    topMenuHeight: '3.2rem',
    topMenuHeightSmaller: '0rem',
  },
  shadow: '0 .2rem .6rem rgba(0, 0, 0, 0.12)',
  spacing: {
    s1: '1.2rem',
    s2: '2rem',
    s3: '3rem',
    s4: '4rem',
    s5: '6rem',
    s6: '12rem',
  },
  transitions: {
    base: '0.15s ease',
    slow: '0.3s ease',
    slowest: '2s ease',
    turtle: '0.8s ease',
  },
};

export const getThemeColor = (
  color: ThemeColor,
  replacements?: { [key: string]: string },
) => {
  return (
    legacyTheme.colors[replacements?.[color] || color] ??
    replacements?.[color] ??
    color
  );
};

export const getThemeSpacing = (property: string) => {
  return (
    legacyTheme.spacing[property] ??
    (property === 'default' ? legacyTheme.grid.gutter : property ?? 'unset')
  );
};

export const themeColors = Object.keys(colors);

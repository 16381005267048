import { FC } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { StyledLeftMenuItem } from 'layout/right-menu/left-menu.styles';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'src/app-routes';

export const LeftMenu: FC<{
  hasPayouts: boolean;
  hasProducts: boolean;
}> = ({ hasPayouts, hasProducts }) => {
  return (
    <div>
      <StackedContainer
        gap="s2"
        height="auto"
        hidden={{ lg: false, md: true }}
        style={{ position: 'sticky', top: 0 }}
      >
        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active-link' : undefined;
          }}
          to={routePaths.dashboard}
        >
          <StyledLeftMenuItem
            color="grey"
            icon={<Icon color="black" name="dashboard" width="1.6rem" />}
            textStyle="regular"
            tx="menu.dashboard"
          />
        </NavLink>

        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active-link' : undefined;
          }}
          to={routePaths.profile}
        >
          <StyledLeftMenuItem
            color="grey"
            icon={<Icon color="black" left="-1px" name="user" size="1.9rem" />}
            marginLeft="-2px"
            textStyle="regular"
            tx="menu.profile"
          />
        </NavLink>

        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active-link' : undefined;
          }}
          to={routePaths.designs}
        >
          <StyledLeftMenuItem
            color="grey"
            icon={
              <Icon color="black" height="2rem" name="pencil" width="1.6rem" />
            }
            textStyle="regular"
            tx="menu.designs"
          />
        </NavLink>

        {hasProducts && (
          <>
            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active-link' : undefined;
              }}
              to={routePaths.products}
            >
              <StyledLeftMenuItem
                color="grey"
                icon={
                  <Icon
                    color="black"
                    height="2rem"
                    name="lamp"
                    top="1px"
                    width="1.6rem"
                  />
                }
                textStyle="regular"
                tx="menu.products"
              />
            </NavLink>

            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active-link' : undefined;
              }}
              to={routePaths.orders}
            >
              <StyledLeftMenuItem
                color="grey"
                icon={
                  <Icon
                    color="black"
                    height="2rem"
                    name="box"
                    top="1px"
                    width="1.6rem"
                  />
                }
                textStyle="regular"
                tx="menu.orders"
              />
            </NavLink>

            {hasPayouts && (
              <NavLink
                className={({ isActive }) => {
                  return isActive ? 'active-link' : undefined;
                }}
                to={routePaths.payouts}
              >
                <StyledLeftMenuItem
                  color="grey"
                  icon={
                    <Icon
                      color="black"
                      height="2rem"
                      name="dollar"
                      top="1px"
                      width="1.6rem"
                    />
                  }
                  textStyle="regular"
                  tx="menu.payouts"
                />
              </NavLink>
            )}

            <NavLink
              className={({ isActive }) => {
                return isActive ? 'active-link' : undefined;
              }}
              to={routePaths.stats}
            >
              <StyledLeftMenuItem
                color="grey"
                icon={<Icon height="2rem" name="stats" width="1.6rem" />}
                textStyle="regular"
                tx="menu.stats"
              />
            </NavLink>
          </>
        )}

        <a
          href="https://gantri.notion.site/Creator-Documents-1f7f31f6049f4a368aa9919ff7db130c/"
          rel="noreferrer"
          target="_blank"
        >
          <StyledLeftMenuItem
            color="grey"
            icon={<Icon color="black" name="docs" width="1.6rem" />}
            textStyle="regular"
            tx="menu.docs"
          />
        </a>

        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active-link' : undefined;
          }}
          to={routePaths.downloads}
        >
          <StyledLeftMenuItem
            color="grey"
            icon={<Icon color="black" name="download" width="1.6rem" />}
            textStyle="regular"
            tx="menu.downloads"
          />
        </NavLink>
      </StackedContainer>
    </div>
  );
};

import { FC } from 'react';
import { Page } from 'core/page';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Cell } from 'layout/cell';
import { Form, Formik } from 'formik';
import { StackedContainer } from 'layout/stacked-container';
import { Button } from 'core/button';
import { TextInput } from 'core/text-input';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, GET_USER, UserData } from 'services/api';
import { LoadingOverlay } from 'layout/loading-overlay';
import { SelectInput } from 'core/select-input';
import {
  CANADA_PROVINCES,
  COUNTRIES,
  US_STATES_FULL_NAME,
} from 'constants/countries';
import { DesignerInfo } from 'types/user.type';
import { UserResponse } from 'services/api/user/user-api.types';
import { useNotification } from 'context/notification.context';
import { useAuth } from 'context/auth.context';
import { getFileUrl, Image } from 'gantri-components';
import { ProfileSchema } from './profile.schema';
import { StyledProfilePhotoContainer } from './profile.styles';

export const ProfilePage: FC = () => {
  const userQuery = useQuery<UserData>(GET_USER, api.auth.getLogged, {
    refetchOnWindowFocus: false,
  });
  const [{ campaignInfo }] = useAuth();
  const queryClient = useQueryClient();
  const { notify, notifyError } = useNotification();

  const updateDesignerMutation = useMutation(
    async (data: DesignerInfo) => api.designer.update(data),
    {
      onSuccess: async (response: UserResponse) => {
        notify(response.notice);
        await queryClient.invalidateQueries(GET_USER);
      },
    },
  );

  const handleSubmit = async ({
    behance,
    instagram,
    twitter,
    website,
    ...designerProps
  }) => {
    await updateDesignerMutation.mutateAsync({
      ...userQuery.data.data.designerInfo,
      userId: userQuery.data.data.id,
      ...designerProps,
      links: {
        behance,
        instagram,
        twitter,
        website,
      },
    });
  };

  const updateLogoUrl = async (logoUrl: string) => {
    await updateDesignerMutation.mutateAsync({
      ...userQuery.data.data.designerInfo,
      browserUserId: campaignInfo.browserUserId,
      logoUrl,
      userId: userQuery.data.data.id,
    });
  };

  if (!userQuery.data?.data) {
    return <LoadingOverlay />;
  }

  return (
    <Page>
      <Formik
        enableReinitialize
        initialValues={{
          behance: userQuery.data.data.designerInfo.links?.behance,
          city: userQuery.data.data.designerInfo.city,
          country: userQuery.data.data.designerInfo.country,
          instagram: userQuery.data.data.designerInfo.links?.instagram,
          name: userQuery.data.data.designerInfo.name,
          profileLink: userQuery.data.data.designerInfo.profileLink,
          state: userQuery.data.data.designerInfo.state,
          title: userQuery.data.data.designerInfo.title,
          twitter: userQuery.data.data.designerInfo.links?.twitter,
          website: userQuery.data.data.designerInfo.links?.website,
        }}
        validateOnMount
        validationSchema={ProfileSchema}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form autoComplete="off">
            <Grid
              gap={{ lg: 's5', md: 's4', sm: 's3' }}
              maxWidth={{ lg: '50vw', md: '100vw' }}
            >
              <Typography marginBottom="s1" text="Profile" variant="h2" />

              <TypographyWithIcon
                icon={<Typography marginTop="6px" text="(required)" />}
                iconPosition="right"
                text="Profile info"
                variant="h3"
              />

              <Grid columns={{ lg: 6 }}>
                <Cell width={{ lg: 6 }}>
                  <Typography text="Logo" textStyle="bold" />
                  <Typography
                    color="grey"
                    marginBottom="s1"
                    text="Your brand. We recommend that you use the same logo across social channels."
                    variant="p3"
                  />

                  <StyledProfilePhotoContainer>
                    <Image
                      alt=""
                      containerAspectRatio="1:1"
                      fallbackColor="transparent"
                      source="dynamic"
                      src={getFileUrl<'designers'>({
                        directory: 'designers',
                        fileName: userQuery.data.data.designerInfo.logoUrl,
                        fileType: 'logo',
                        identifiers: { userId: userQuery.data.data.id },
                      })}
                      transformations={{ height: 305, width: 305 }}
                    />
                  </StyledProfilePhotoContainer>
                </Cell>

                <Cell width={{ lg: 6 }}>
                  <Typography text="Designer name" textStyle="bold" />
                  <Typography
                    color="grey"
                    marginBottom="s1"
                    text="What your brand would be. This can be your full name or studio name."
                    variant="p3"
                  />

                  <TextInput
                    error={props.errors.name as string}
                    name="name"
                    touched={props.touched.name as boolean}
                    value={props.values.name}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell width={{ lg: 6 }}>
                  <Typography text="Tagline" textStyle="bold" />
                  <Typography
                    color="grey"
                    marginBottom="s1"
                    text="Describe what's special about you in one line."
                    variant="p3"
                  />

                  <TextInput
                    error={props.errors.title as string}
                    name="title"
                    touched={props.touched.title as boolean}
                    value={props.values.title}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell width={{ lg: 2, sm: 3 }}>
                  <Typography marginBottom="s1" text="City" textStyle="bold" />

                  <TextInput
                    error={props.errors.city as string}
                    name="city"
                    touched={props.touched.city as boolean}
                    value={props.values.city}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell width={{ lg: 2, sm: 3 }}>
                  <Typography marginBottom="s1" text="State" textStyle="bold" />

                  {props.values.country === 'US' && (
                    <SelectInput
                      error={props.values.state}
                      name="state"
                      options={US_STATES_FULL_NAME}
                      value={props.values.state}
                      onBlur={props.handleBlur}
                      onNativeChange={props.handleChange}
                    />
                  )}

                  {props.values.country === 'CA' && (
                    <SelectInput
                      error={props.values.state}
                      name="state"
                      options={CANADA_PROVINCES}
                      value={props.values.state}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                    />
                  )}

                  {!['US', 'CA'].includes(props.values.country) && (
                    <TextInput
                      error={props.errors.state as string}
                      name="state"
                      touched={props.touched.state as boolean}
                      value={props.values.state}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                    />
                  )}
                </Cell>

                <Cell width={{ lg: 2, sm: 6 }}>
                  <Typography
                    marginBottom="s1"
                    text="Country"
                    textStyle="bold"
                  />

                  <SelectInput
                    error={props.values.country}
                    labelProperty="name"
                    name="country"
                    options={COUNTRIES}
                    value={props.values.country}
                    valueProperty="code"
                    onBlur={props.handleBlur}
                    onNativeChange={props.handleChange}
                  />
                </Cell>

                <Cell width={{ lg: 6 }}>
                  <Typography text="Gantri Storefront URL" textStyle="bold" />
                  <Typography
                    color="grey"
                    text="Your URL on Gantri."
                    variant="p3"
                  />

                  <StackedContainer
                    flow="column"
                    marginTop="s1"
                    padding="unset"
                  >
                    <Typography text="gantri.com/" textStyle="bold" />
                    <TextInput
                      error={props.errors.profileLink as string}
                      name="name"
                      touched={props.touched.profileLink as boolean}
                      value={props.values.profileLink}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                    />
                  </StackedContainer>
                </Cell>
              </Grid>

              <TypographyWithIcon
                icon={<Typography marginTop="2.6rem" text="(optional)" />}
                iconPosition="right"
                marginTop="s2"
                text="Social links"
                variant="h3"
              />

              <Grid columns={{ lg: 2, sm: 1 }}>
                <Cell>
                  <Typography
                    marginBottom="s1"
                    text="Website"
                    textStyle="bold"
                  />

                  <TextInput
                    error={props.errors.website as string}
                    name="website"
                    placeholder="johnsmith.com"
                    touched={props.touched.website as boolean}
                    value={props.values.website}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell>
                  <Typography
                    marginBottom="s1"
                    text="Instagram handle"
                    textStyle="bold"
                  />

                  <TextInput
                    error={props.errors.instagram as string}
                    name="instagram"
                    placeholder="No @ needed"
                    touched={props.touched.instagram as boolean}
                    value={props.values.instagram}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell>
                  <Typography
                    marginBottom="s1"
                    text="Twitter"
                    textStyle="bold"
                  />

                  <TextInput
                    error={props.errors.twitter as string}
                    name="twitter"
                    placeholder="No @ needed"
                    touched={props.touched.twitter as boolean}
                    value={props.values.twitter}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>

                <Cell>
                  <Typography
                    marginBottom="s1"
                    text="Behance username"
                    textStyle="bold"
                  />

                  <TextInput
                    error={props.errors.twitter as string}
                    name="twitter"
                    placeholder="No @ needed"
                    touched={props.touched.twitter as boolean}
                    value={props.values.twitter}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                  />
                </Cell>
              </Grid>
            </Grid>

            <StackedContainer
              flow="column"
              marginTop={{ lg: 's5', md: 's4' }}
              padding="unset"
            >
              <Button
                disabled={!props.dirty || !props.isValid}
                loading={updateDesignerMutation.isLoading}
                text="Save Profile"
                type="submit"
                variant="primary"
              />
            </StackedContainer>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

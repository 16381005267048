import { forwardRef } from 'react';
import { RoundButtonProps } from 'core/round-button/round-button.props';
import { RoundButtonPresets } from 'core/round-button/round-button.presets';
import { StyledRoundButton } from 'core/round-button/round-button.styles';

export const RoundButton = forwardRef((props: RoundButtonProps, ref: any) => {
  const { color, disabled, icon, onClick, opacity, size } = {
    ...RoundButtonPresets,
    ...props,
  };

  return (
    <StyledRoundButton
      ref={ref}
      color={color}
      disabled={disabled}
      opacity={opacity}
      size={size}
      onClick={onClick}
    >
      {icon}
    </StyledRoundButton>
  );
});

import { useGenericMutateQuery } from 'api/hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from 'api/hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { reEditDesign } from './re-edit-design';
import { ReEditDesignResponse, ReEditDesignArgs } from './re-edit-design.types';

export const useReEditDesign = <TransformedData = ReEditDesignResponse>(
  props?: GenericMutateQueryProps<
    ReEditDesignArgs,
    ReEditDesignResponse,
    TransformedData
  >,
) => {
  const { onMutate: onReEditDesign, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to re-open design.',
    mutationFn: reEditDesign,
    ...props,
  });

  return { ...rest, onReEditDesign };
};

import { FormikInput, Stack } from 'gantri-components';
import { useParams } from 'react-router-dom';
import { useFetchDesign } from 'api/designs/routes';
import { Form, Formik } from 'formik';
import { getDesignStatusDetails } from 'src/pages/design-flow/helpers/get-design-status-details';
import { DesignFieldTextArea } from '../design-field-text-area';
import { ConceptStepHeader } from '../concept-step-header/concept-step-header';
import { useConceptNavigation } from '../../hooks/use-concept-navigation';
import { StepFooter } from '../../../../components/step-footer';
import { useOnUpdateConcept } from '../../hooks/use-on-update-concept';
import { ConceptStepPageParams } from '../../concept-step.types';
import { conceptStepTwoFormSchema } from './concept-step-two.schema';
import { ConceptInteriorsSection } from './components/concept-interiors-section';
import { ConceptRoomsChecklistSection } from './components/concept-rooms-checklist-section';
import { useConceptStepTwoInitialValues } from './hooks/use-concept-step-two-initial-values';

export const ConceptStepTwo = () => {
  const { id } = useParams<ConceptStepPageParams>();

  const { onNavigateToNext, onNavigateToPrev } = useConceptNavigation();

  const { data } = useFetchDesign({
    fetchArgs: {
      id: Number(id),
    },
  });

  const design = data?.data;

  const initialValues = useConceptStepTwoInitialValues({
    design,
  });

  const { isApproved, isSubmitted } = getDesignStatusDetails(
    design?.concept?.status,
  );

  const { onSubmit: onSaveProgress, processing: isSaving } = useOnUpdateConcept(
    {
      design,
      isApproved,
      onNavigateTo: onNavigateToPrev,
    },
  );

  const { onSubmit, processing: isSubmitting } = useOnUpdateConcept({
    design,
    isApproved,
    onNavigateTo: onNavigateToNext,
  });
  const isDisabled = isApproved || isSubmitted;

  return design ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      validationSchema={conceptStepTwoFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Stack gap="5x">
              <ConceptStepHeader
                design={design}
                question="Who are you designing for?"
                showBackButton
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: Someone who loves to climb, hike, bike. Loves spending the day in nature, and when they return home, they want to relax next to a fireplace and read a book."
                    disabled={isDisabled}
                    label="Describe the target user of your design"
                  />
                }
                fieldVariant="standard"
                name="targetUser"
              />

              <FormikInput
                Field={
                  <DesignFieldTextArea
                    description="Example: The user needs a reading light that brightly illuminates his book but doesn't irritate his partner sitting in a nearby chair."
                    disabled={isDisabled}
                    label="What is the user need or pain point that you're designing for?"
                  />
                }
                fieldVariant="standard"
                name="userNeeds"
              />

              <ConceptInteriorsSection isDisabled={isDisabled} />

              <ConceptRoomsChecklistSection isDisabled={isDisabled} />

              <StepFooter
                backButtonProps={{
                  onClick: async () => {
                    await onSaveProgress(values);
                  },
                  processing: isSaving,
                }}
                nextButtonProps={{
                  disabled: !isValid,
                  processing: isSubmitting,
                  type: 'submit',
                }}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

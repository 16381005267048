import { useEffect, useRef, useState } from 'react';
import {
  StyledSelectedTabIndicator,
  StyledTabHeading,
  StyledTabsContainer,
  StyledTabsContent,
  StyledTabsContentItem,
  StyledTabsHeadingContainer,
} from 'core/tabs/tabs.styles';
import { Typography } from 'core/typography';
import { useSpring, useSprings } from 'react-spring';
import useResizeObserver from 'use-resize-observer/polyfilled';
import * as easings from 'd3-ease';
import { extractValueFromResolutionAwarePropertyForCurrentScreenSize } from 'helpers/layout.helpers';
import sum from 'lodash/sum';
import { TabsPresets } from './tabs.presets';
import { TabConfig, TabsProps } from './tabs.props';

export const Tabs = (props: TabsProps) => {
  const {
    config,
    headerBorderVisible,
    headingActiveColor,
    headingAlign,
    headingColor,
    headingGap,
    headingTypographyProps,
    headingWidth,
    onTabSelected,
    selectedTab,
  } = { ...TabsPresets, ...props };

  const [active, setActive] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [xGap, setXGap] = useState(0);
  const currentGap = useRef(0);

  useEffect(() => {
    setActive(selectedTab);
  }, [selectedTab]);

  const headingRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const indicatorShownRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      setXGap(headingRef.current?.offsetLeft || 0);
    }, 200);
  }, [headingRef.current?.offsetLeft]);

  const [{ opacity: indicatorOpacity, x }, set] = useSpring(() => {
    const firstHeadingX = headingRef.current?.offsetLeft || 0;

    const headingsWidthSum = sum(
      config.slice(0, active).map(item => item.width || indicatorWidth),
    );

    return {
      config: {
        duration: indicatorShownRef.current ? 150 : 0,
        easing: easings.easeCubicInOut,
      },
      from: {
        opacity: indicatorShownRef.current ? 1 : 0,
        x: 0,
      },
      to: async next => {
        await next({
          opacity: 1,
          x: headingsWidthSum + firstHeadingX + currentGap.current * active,
        });

        indicatorShownRef.current = true;
      },
    };
  }, [
    active,
    indicatorWidth,
    headingRef.current,
    xGap,
    indicatorShownRef.current,
  ]);

  useResizeObserver({
    onResize: () => {
      const width = headingRef.current?.offsetWidth || 0;
      setIndicatorWidth(width);

      currentGap.current =
        extractValueFromResolutionAwarePropertyForCurrentScreenSize(headingGap);

      set({ x: active * width + headingRef.current?.offsetLeft || 0 });
    },
    ref: containerRef,
  });

  const [springs, setSprings] = useSprings(
    config.length,
    index => ({
      opacity: active === index ? 1 : 0,
      scale: active === index ? 1 : 0,
    }),
    [active],
  );

  const setSelectedTab = index => () => {
    if (active !== index) {
      setActive(index);

      if (config[index].width) {
        setIndicatorWidth(config[index].width);
      }

      setSprings(idx => ({
        config: {
          tension: 500,
        },
        opacity: index === idx ? 1 : 0,
        scale: index === idx ? 1 : 0,
      }));

      if (onTabSelected) {
        onTabSelected(index);
      }
    }
  };

  return (
    <StyledTabsContainer>
      <StyledTabsHeadingContainer
        ref={containerRef}
        headerBorderVisible={headerBorderVisible}
        headingAlign={headingAlign}
        headingGap={headingGap}
        headingWidth={headingWidth}
      >
        {config.map((item: TabConfig, index: number) => (
          <StyledTabHeading
            key={item.headingTx || item.heading}
            ref={index === 0 ? headingRef : null}
            size={item.width ? `${item.width}px` : headingWidth}
            onClick={setSelectedTab(index)}
          >
            <Typography
              color={active === index ? headingActiveColor : headingColor}
              text={item.heading}
              tx={item.headingTx}
              variant="h5"
              {...headingTypographyProps}
            />
          </StyledTabHeading>
        ))}

        <StyledSelectedTabIndicator
          style={{
            backgroundColor: headingActiveColor,
            opacity: indicatorOpacity,
            width: indicatorWidth,
            x,
          }}
        />
      </StyledTabsHeadingContainer>

      <StyledTabsContent>
        {springs.map(({ opacity }, index) => (
          <StyledTabsContentItem
            key={index}
            style={{
              opacity,
              position: active === index ? 'relative' : 'absolute',
              zIndex: active === index ? 2 : 1,
            }}
          >
            {config[index].content}
          </StyledTabsContentItem>
        ))}
      </StyledTabsContent>
    </StyledTabsContainer>
  );
};

import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgPlay = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <circle cx={12} cy={12} r={9.5} stroke={color} />
        <path d="M10 16.5l6-4.5-6-4.5z" fill={color} />
      </g>
    </svg>
  );
});
export default SvgPlay;

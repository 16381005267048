import { useRef, memo } from 'react';
import { getThemeColor, legacyTheme } from 'styles/theme';
import { IconProps } from '../icon.props';
import { IconPresets } from '../icon.presets';

const SvgBehance = memo((iconProps: IconProps) => {
  const ref = useRef();
  const {
    color: colorProp,
    height,
    strokeWidth,
    width,
    ...props
  } = { ...IconPresets, ...iconProps, ref };
  const color = getThemeColor(colorProp);
  return (
    <svg
      height="1em"
      viewBox="0 0 24 15"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.336 2.503h6.355V.683h-6.355v1.82zm1.506 3.973c-.405.412-.66.97-.765 1.675h4.943c-.052-.752-.31-1.32-.764-1.711-.46-.388-1.025-.582-1.703-.582-.734 0-1.306.206-1.711.618zm4.29-2.444c.766.336 1.396.871 1.893 1.6.453.644.744 1.39.876 2.238.078.495.109 1.214.096 2.148h-7.99c.047 1.084.425 1.846 1.148 2.281.437.272.964.408 1.582.408.65 0 1.182-.165 1.59-.495.224-.18.422-.428.591-.745h2.928c-.077.64-.433 1.293-1.062 1.956-.982 1.05-2.358 1.577-4.126 1.577-1.46 0-2.75-.443-3.863-1.33-1.119-.888-1.675-2.333-1.675-4.332 0-1.875.504-3.313 1.51-4.314 1.01-.998 2.315-1.5 3.922-1.5.955 0 1.814.17 2.58.508zM2.955 8.19v3.902h3.601c.644 0 1.146-.086 1.503-.258.65-.32.975-.927.975-1.826 0-.763-.313-1.285-.944-1.57-.353-.16-.848-.243-1.483-.248H2.956zm0-5.65V5.77h3.607c.642 0 1.166-.121 1.57-.363.4-.243.602-.671.602-1.284 0-.683-.266-1.133-.796-1.353-.46-.152-1.044-.228-1.754-.228H2.956zm8.055-.98c.453.621.68 1.365.68 2.232 0 .892-.227 1.612-.686 2.155-.257.304-.633.582-1.132.833.757.272 1.327.703 1.715 1.291.384.588.577 1.303.577 2.144 0 .868-.22 1.646-.662 2.333-.28.456-.629.84-1.05 1.152-.472.358-1.03.601-1.675.735-.644.132-1.341.196-2.095.196H0V0h7.167c1.809.026 3.089.546 3.844 1.56z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});
export default SvgBehance;

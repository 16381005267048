import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledTimelineItemBox = styled(Box)<{ $showLine: boolean }>`
  ${({ $showLine, theme }) => {
    return css`
      ${$showLine && `border-left: 1px solid ${theme.colors.typography.link};`}

      &:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: ${theme.colors.typography.link};
        top: 4px;
        left: -8px;
        outline: 4px solid ${theme.colors.surfaces.monochrome.t1};
      }
    `;
  }}
`;

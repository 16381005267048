import styled, { css } from 'styled-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'types/layout';
import { generateStylesForResolutionAwareProps } from 'helpers/layout.helpers';

export const StyledThumbnail = styled.img<any>`
  ${({
    backgroundColor,
    border,
    borderRadius,
    height,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    objectFit,
    round,
    size,
    theme,
    width,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width ?? size,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height ?? size,
      },
      {
        cssProperty: 'min-width',
        resolutionAwareProp: width ?? size,
      },
      {
        cssProperty: 'min-height',
        resolutionAwareProp: height ?? size,
      },
      {
        cssProperty: 'border',
        resolutionAwareProp: border,
      },
      {
        cssProperty: 'border-radius',
        resolutionAwareProp: round ? '50%' : borderRadius,
      },
      {
        cssProperty: 'margin',
        lookIn: theme.spacing,
        resolutionAwareProp: margin,
      },
      {
        cssProperty: 'margin-left',
        lookIn: theme.spacing,
        resolutionAwareProp: marginLeft,
      },
      {
        cssProperty: 'margin-right',
        lookIn: theme.spacing,
        resolutionAwareProp: marginRight,
      },
      {
        cssProperty: 'margin-top',
        lookIn: theme.spacing,
        resolutionAwareProp: marginTop,
      },
      {
        cssProperty: 'margin-bottom',
        lookIn: theme.spacing,
        resolutionAwareProp: marginBottom,
      },
      {
        cssProperty: 'object-fit',
        resolutionAwareProp: objectFit,
      },
      {
        cssProperty: 'background-color',
        resolutionAwareProp: backgroundColor,
      },
    ];

    return css`
      overflow: hidden;
      display: block;
      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

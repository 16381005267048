import { createGlobalStyle } from 'styled-components';
import { getThemeColor, legacyTheme } from 'styles/theme';

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    outline: none;
  }

  html {
    /* defines 1rem = 10px value */
    font-size: 62.5%;
  }

  body {
    box-sizing: border-box;
    font-family: 'Univers 55 Roman', "Helvetica", sans-serif;
    line-height: 2.5rem;
    font-size: 1.5rem;
  }

  * {
    font-family: 'Univers 55 Roman', "Helvetica", sans-serif;
    line-height: 2.5rem;
    font-size: 1.5rem;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  a,
  a:active,
  a:visited {
    font-family: "Univers 65 Bold", "Helvetica", sans-serif;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: ${getThemeColor('primary')};

    &.affirm-modal-trigger:hover {
      text-decoration: underline;
    }
  }

  svg {
    overflow: visible;
  }

  #fb-root {
    z-index: 999;
    display: block;
  }

  input, select {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  @font-face {
    font-family: 'Univers 55 Roman';
    src: url('/fonts/univers-55-roman.woff2') format('woff2'),
    url('/fonts/univers-55-roman.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Univers 65 Bold';
    src: url('/fonts/univers-65-bold.woff2') format('woff2'),
    url('/fonts/univers-65-bold.woff') format('woff');
    font-display: swap;
  }

  .active-link {
    svg, path {
      fill: ${getThemeColor('primary')};
    }

    * {
      color: ${getThemeColor('primary')} !important;
    }
  }
`;
